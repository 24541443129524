import styled       from 'styled-components';

export const RocketIconStyled = styled.div`
    .trail {
        position: absolute;
        top: -66px;
        right: 90px;
        transform: rotate(64deg);
        width: 120px;
        height: 120px;
        border-radius: 100%;
        background: linear-gradient(5deg, transparent, var(--color-content-neutral-secondary));

        &:before {
            content: '';
            position: absolute;
            top: -3px;
            right: 0;
            width: calc(100% - 2px);
            height: 100%;
            border-radius: inherit;
            background-color: var(--color-bg-underground);
        }
    }
`;