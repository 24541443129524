import React, { useContext }                        from 'react';
import { ThemeContext }                             from 'styled-components';
import { Button, ButtonIcon, ButtonGroup, Icon, Text
}                                                   from '../../../designSystem/components';
import assetUtils                                   from '../../../utils/assetUtils';


//------------------------------------------------------------------------------
const AssetTypeButtonGroup = (props) =>
{
    //------------------------------------------------------------------------------
    const { countByGroup }  = props;
    const theme             = useContext(ThemeContext);

    //------------------------------------------------------------------------------
    return (
        <div className='flex items-center gap-1'>
            <ButtonIcon
                onClick={props.clearAssetTypeFilter}
                className={`
                    fas fa-times-circle
                    ${props.assetTypeFilter.length > 0 ? '' : 'invisible'}
                    ${props.clearButtonPosition === 'right' ? 'order-1' : ''}
                `}
                color='tertiary'
                size='xxsmall'
            />
            <ButtonGroup outlined className='flex-wrap'>
                {Object.keys(countByGroup).map(assetListName =>
                {
                    const assetType  = assetUtils.getAssetTypeFromAssetListName(assetListName);
                    const assetLabel = assetUtils.getAssetLabelFromAssetListName(assetListName);
                    const assetColor = theme.assets[assetType]?.color;

                    return (
                        <Button
                            key={assetType}
                            onClick={() => props.toggleAssetTypeFilter(assetType)}
                            tooltipTitle={<span>{assetLabel}</span>}
                            tooltipProps={{
                                color       : assetColor,
                                fontColor   : 'var(--color-bg-ground)'
                            }}
                            size='xsmall'
                            color='tertiary'
                            noPadding
                            style={props.assetTypeFilter.includes(assetType) ? {
                                backgroundColor : assetColor,
                                color           : 'var(--color-bg-ground)'
                            } : {
                                color           : assetColor
                            }}
                            valueClassName='px-1 items-center'
                        >
                            <Text as='span' className='text-xs' style={{ paddingRight: '2px' }}>
                                {countByGroup[assetListName]}
                            </Text>
                            <Icon
                                name='asset'
                                assetType={assetType || undefined}
                                size='xsmall'
                                style={props.assetTypeFilter.includes(assetType) ? { color: 'inherit' } : {}}
                            />
                        </Button>
                    );
                })}
            </ButtonGroup>
        </div>
    );
};

export default AssetTypeButtonGroup;
