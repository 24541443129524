//------------------------------------------------------------------------------
import React, { Fragment }  from "react";
import styled               from 'styled-components';

//------------------------------------------------------------------------------
import {
    Icon,
    ButtonIcon,
    ControlText,
    ControlCheckbox,
    Tooltip,
    Text,
    Stack
} from '../../../designSystem/components';

//------------------------------------------------------------------------------
export const MainStackStyled = styled(Stack)`
    width : 100%;
    padding: .5rem .5rem;
`;

//------------------------------------------------------------------------------
const default3dverseDevPluginURL = "http://localhost:4502";
const defaultDevtoolURL = "http://localhost:3000";

//------------------------------------------------------------------------------
export default class DevTool extends React.Component
{
    //--------------------------------------------------------------------------
    constructor(props)
    {
        super(props);

        const use3dverseDevPlugin = localStorage.getItem(`3dverse-microverse-use-dev-tool-plugin`) === 'true';

        this.state = {
            serverURL   : use3dverseDevPlugin ? default3dverseDevPluginURL : defaultDevtoolURL,
            status      : null,
            isConnected : false,
            use3dverseDevPlugin
        };
    }

    //--------------------------------------------------------------------------
    render()
    {
        if(this.state.isConnected && !this.state.status)
        {
            return (
                <iframe className="w-full h-full" allow="camera;microphone" src={this.state.pluginURL}/>
            );
        }

        return (
            <MainStackStyled horizontal>
            {
                !this.state.isConnected &&
                <div className='flex flex-col  gap-2 mt-1 w-full'>
                    <div className='flex flex-row'>
                        <ControlText
                            value={this.state.serverURL}
                            onChange={event => this.setState({serverURL : event.target.value})}
                            placeholder="Placeholder"
                            required
                        />

                        <Tooltip title='Connect'>
                            <ButtonIcon
                                onClick={this.connect}
                                iconClassName='fa fa-wifi'
                                style={{
                                    marginLeft: '.5rem'
                                }}
                            />
                        </Tooltip>
                    </div>
                    <ControlCheckbox
                        label='Use 3dverse development plugin:'
                        labelDirection={'horizontal'}
                        labelDescription={'Enable this if you work with the 3dverse Plugin Dev Tools for VS Code'}
                        labelStyle={{flex: 'unset' }}
                        value={this.state.use3dverseDevPlugin}
                        onChange={(e, value) =>
                        {
                            this.setState( {
                                use3dverseDevPlugin: value,
                                serverURL: value ? default3dverseDevPluginURL : defaultDevtoolURL
                            });
                            localStorage.setItem(`3dverse-microverse-use-dev-tool-plugin`, value);
                        }}
                    />
                </div>
            }
            {
                this.state.status &&
                <Stack horizontal vCenter>
                    <Icon
                        className={this.state.status.icon}
                        style={{
                            color : this.state.status.color
                        }}
                    />
                    <Text>
                        {this.state.status.text}
                    </Text>
                </Stack>
            }
            </MainStackStyled>
        )
    }

    //--------------------------------------------------------------------------
    componentDidMount()
    {
        if (location.hostname === 'localhost')
        {
            // this.connect();
        }
    }

    //--------------------------------------------------------------------------
    componentWillUnmount()
    {
        if(this.socket)
        {
            this.socket.onclose = () => {};
            this.socket.close();
        }
    }

    //--------------------------------------------------------------------------
    connect = async () =>
    {
        this.setStatus('wait', 'Connecting...', {isConnected : true});
        if(!this.state.use3dverseDevPlugin)
        {
            this.loadDevServerURL();
            return;
        }

        this.socket         = new WebSocket(this.state.serverURL.replace('http', 'ws') + '/notify');
        this.socket.onopen  = () =>
        {
            const pathname  = document.location.pathname;

            this.socket.send(JSON.stringify(
            {
                host        : document.location.host,
                pathname    : pathname.substr(0, pathname.lastIndexOf("/"))
            }));

            this.loadDevServerURL();
            this.socket.onclose     = this.onSocketClosed;
        };

        this.socket.onclose     = this.onConnectionFailed;
        this.socket.onmessage   = this.onMessageReceived;
    }

    //--------------------------------------------------------------------------
    loadDevServerURL()
    {
        this.setState({status : null, pluginURL : this.getDevServerURL()});
    }

    //--------------------------------------------------------------------------
    onConnectionFailed = () =>
    {
        this.setStatus('warning', 'Failed to connect', {isConnected : false});
    }

    //--------------------------------------------------------------------------
    onSocketClosed = () =>
    {
        this.setStatus('danger', 'The connection was closed', {isConnected : false});
    }

    //--------------------------------------------------------------------------
    onMessageReceived = (message) =>
    {
        const data = message.data;
        console.log(data);

        if(data == 'build-start')
        {
            this.setStatus('wait', 'Building...');
        }
        else if(data == 'build-end')
        {
            this.setState({status : null, pluginURL : this.getDevServerURL()});
        }
        else if(data == 'build-error')
        {
            this.setStatus('danger', 'Build error');
        }
    }

    //--------------------------------------------------------------------------
    getDevServerURL()
    {
        const urlParams = new URLSearchParams();
        urlParams.set('random', Math.random());
        urlParams.set('3dverseWebApiURL', apiGatewayURL);
        urlParams.set('3dverseApiToken', apiToken);
        urlParams.set('3dverseEditorBackendURL', editorBackendURL);

        return `${this.state.serverURL}?${urlParams.toString()}`;
    }

    //--------------------------------------------------------------------------
    setStatus(type, message, optionalState = {})
    {
        let icon, color;
        switch (type)
        {
            case "success":
                icon    = "fas fa-check";
                color   = "green";
                break;

            case "wait":
                icon    = "fas fa-sync";
                color   = "rgb(92, 124, 250)";
                break;

            case "warning":
                icon    = "fas fa-exclamation-triangle";
                color   = "orange";
                break;

            case "danger":
                icon    = "fas fa-exclamation-circle";
                color   = "red";
                break;
        }

        this.setState(
        {
            status : {icon, color, text : message},
            ...optionalState
        });
    }
}
