//------------------------------------------------------------------------------
import CallAPI from '../../utils/api';
let rolePromise = null;

//------------------------------------------------------------------------------
export async function getRoleListWithPermissionsAPI()
{
    if(!rolePromise)
    {
        rolePromise = CallAPI('/user/getRoleListWithPermissions', 'GET');
    }
    return await rolePromise;
}

//------------------------------------------------------------------------------
export async function loginAPI(body)
{
    return await CallAPI('/authentication/login', 'POST', body);
}

//------------------------------------------------------------------------------
export async function logoutAPI()
{
    return await CallAPI('/authentication/logout', 'POST');
}

//------------------------------------------------------------------------------
export async function signupAPI(body)
{
    return await CallAPI('/authentication/signup', 'POST', body);
}

//------------------------------------------------------------------------------
export async function sendResetPasswordEmailAPI(body)
{
    return await CallAPI('/authentication/sendResetPasswordEmail', 'POST', body);
}

//------------------------------------------------------------------------------
export async function resetPasswordAPI(body)
{
    return await CallAPI('/authentication/resetPassword', 'POST', body);
}

//------------------------------------------------------------------------------
export async function googleOAuthAppClientId()
{
    return await CallAPI('/google/oauthAppClientId', 'GET');
}
//------------------------------------------------------------------------------
export async function googleOAuthSign(idToken)
{
    return await CallAPI(`/google/sign?idToken=${encodeURIComponent(idToken)}`, 'GET');
}
//------------------------------------------------------------------------------
export async function googleOAuthLinkAccount(idToken)
{
    return await CallAPI('/google/linkAccount', 'POST', { idToken });
}


//------------------------------------------------------------------------------
export async function oauthSign(provider, code, )
{
    return await CallAPI(`/oauth/${provider}/sign?code=${code}`, 'GET');
}
//------------------------------------------------------------------------------
export async function oauthLinkAccount(provider, tokenInfo)
{
    return await CallAPI(`/oauth/${provider}/linkAccount`, 'POST', { tokenInfo });
}
