//------------------------------------------------------------------------------
import React, { useState, useEffect }   from 'react';

//------------------------------------------------------------------------------
import { Button, Popper }       from '../../../../designSystem/components';
import { createAssetAPI, _TO_REMOVE_linkMaterialDependencies } from '../../../assets/api';
import PluginContainer          from '../../../../plugins/PluginContainer';

//------------------------------------------------------------------------------
let MaterialListPromise;

//------------------------------------------------------------------------------
function GetDefaultMaterials()
{
    if(!MaterialListPromise)
    {
        MaterialListPromise = new Promise(async (resolve) =>
        {
            const pluginURL     = await PluginContainer.resolvePluginURL('Material Editor');
            const { exports }   = await import(/* webpackIgnore: true */pluginURL);
            const materials     = exports.MaterialEditor.generateDefaultMaterials();

            resolve([...materials, {name : 'Custom', isCustom : true}]);
        });
    }

    return MaterialListPromise;
}

//------------------------------------------------------------------------------
export default function MaterialPopover(props)
{
    //--------------------------------------------------------------------------
    const { wrapperProps = {}, workingFolder, buttonProps } = props;

    //--------------------------------------------------------------------------
    const [materialList, setMaterialList] = useState([]);

    useEffect(() =>
    {
        GetDefaultMaterials().then(setMaterialList);
    }, []);

    //--------------------------------------------------------------------------
    return (
        <Popper
            open={props.open}
            anchorEl={props.anchorEl}
            placement={props.placement}
            onClose={props.onClose}
            style={{ zIndex: '20001' }}
        >
            <section
                {...wrapperProps}
                className={`flex flex-col gap-1px bg-color-underground mx-1 shadow-md ${wrapperProps.className ? wrapperProps.className : ''}`}
                style={{
                    minWidth: '10rem',
                    ...wrapperProps.style
                }}
            >
                {materialList.map((material, index) =>

                    <Button
                        key={index}
                        value={material.name}
                        onClick={async () =>
                        {
                            props.onClose();
                            props.onCloseMainPopover();

                            const { uuid } = await (material.isCustom
                                ? createAssetAPI(workingFolder.workspaceUUID, 'material', '')
                                : createAssetAPI(workingFolder.workspaceUUID, 'material', '', material)
                            );
                            await _TO_REMOVE_linkMaterialDependencies(uuid, material);
                            props.createAssetCallback({ uuid, assetType : 'material' });
                        }}
                        startIconProps={{
                            name        : 'asset',
                            assetType   : 'material'
                        }}
                        {...buttonProps}
                    />
                )}
            </section>
        </Popper>
    );
}
