import React                            from 'react';
import MainLayout                       from '../../components/Layout';
import { Link, Text }                   from '../../designSystem/components';


//------------------------------------------------------------------------------
function NoMatchPage()
{
    return (
        <MainLayout>
            <section className='absolute top-0 flex flex-col items-center justify-center gap-4 w-full h-full'>

                <Text format='title1'>
                    No match
                </Text>

                <Link to='/' className='link'>
                    Go back to home
                </Link>

            </section>
        </MainLayout>
    );
}

export default NoMatchPage;
