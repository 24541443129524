import React, { useEffect, useState, useRef }       from 'react';
import { useTranslation }                           from 'react-i18next';
import { Button, ButtonIcon, Text }                 from '../../../designSystem/components';
import { routerUseHistory, plural as p }            from '../../../designSystem/utils';
import FolderTree                                   from '../../../components/FolderTree';
import ProjectContent                               from '../../projects/ProjectContent';


//------------------------------------------------------------------------------
function LibraryPublicDetails(props)
{
    const { t }                             = useTranslation();
    const history                           = routerUseHistory();
    const { library }                       = props;

    //------------------------------------------------------------------------------
    // Content height

    //------------------------------------------------------------------------------
    const folderTreeContainerRef                        = useRef();
    const [ contentHeight, setContentHeight ]           = useState(400);

    //------------------------------------------------------------------------------
    useEffect(() =>
    {
        const _contentHeight = folderTreeContainerRef?.current?.clientHeight;

        if (_contentHeight)
        {
            setContentHeight(_contentHeight);
        }
    }, [folderTreeContainerRef]);

    //------------------------------------------------------------------------------
    const [ searchString, setSearchString ]     = useState('');

    //------------------------------------------------------------------------------
    const [ assetDetails, setAssetDetails ]     = useState(null);

    //------------------------------------------------------------------------------
    const [ currentFolder, setCurrentFolder ]   = useState(null);

    //------------------------------------------------------------------------------
    const goToInsideLibrary = (options = {}) =>
    {
        if(options.folderUUID && library)
        {
            setCurrentFolder(library.projectMap.get(options.folderUUID));
        }
        else
        {
            setCurrentFolder(null);
        }
    };

    //------------------------------------------------------------------------------
    const isAlreadyLinked = props.currentProject.libraries.find(l => l.uuid === library.uuid);


    //------------------------------------------------------------------------------
    return (
        <section className='bg-color-ground'>
            <header className='flex justify-between py-3 px-5 border-b border-color-secondary'>
                <div className='flex gap-2'>
                    <ButtonIcon
                        onClick={() => props.setLibraryContent(null)}
                        className='fal fa-chevron-left'
                    />
                    <Text format='title2' as='h1'>
                        {library.name}
                    </Text>
                    <Text format='body4'>
                        {library.description}
                    </Text>
                </div>
                {isAlreadyLinked ?
                    <Button
                        value={'Unlink Library'}
                        onClick={() => props.unlinkLibrary(library.uuid)}
                        color='secondary'
                    />
                :
                    <Button
                        value={'Link Library'}
                        onClick={() => props.linkLibrary(library.uuid)}
                        color='primary'
                    />
                }
            </header>

            <ProjectContent
                simplifiedTree
                canCreateContent={false}
                canManageLibrary={false}
                className='flex-1'
                currentProject={library}
                // projects={props.projects}
                projectMap={library.projectMap}
                libraryMap={library.libraryMap}
                workspaceMap={library.workspaceMap}
                currentFolder={currentFolder}
                // currentLibrary={currentLibrary}
                // projectSessions={projectSessions}
                assetDetails={assetDetails}

                // assetSelection={assetSelection}
                // toggleAssetSelection={toggleAssetSelection}
                // toggleDeleteAssetsModal={toggleDeleteAssetsModal}

                // assetContextMenu={assetContextMenu}
                // setAssetContextMenu={setAssetContextMenu}
                //openAssetContextMenu={props.openAssetContextMenu}

                // tileInRenameMode={tileInRenameMode}
                // setTileInRenameMode={setTileInRenameMode}

                // renameFolder={renameFolder}

                searchString={searchString}
                setSearchString={setSearchString}

                goTo={goToInsideLibrary}
                //openAssetEditor={props.openAssetEditor}
                // requests={props.requests}
                permissions={props.permissions}
            />
            {/*
            {displayAssetDetails &&
                <AssetDetailsStyled
                    className='fixed top-8 right-0 flex w-full sm:w-3/12 border-l border-color-tertiary bg-color-ground shadow-sm z-20'
                    style={{ height: 'calc(100% - var(--spacing-8))' }}
                    isAssetAdvanced={Boolean(assetAdvanced)}
                >
                    {isFolder ?
                        <FolderDetails
                            folder={folderData}
                            onClose={closeAssetDetails}
                            renameFolder={renameFolder}
                        />
                        :
                        <AssetWrapper
                            assetUUID={assetDetails.uuid}
                            assetType={assetDetails.assetType}
                            apiGatewayURL={user.apiGatewayURL}
                            apiToken={user.token}
                        >
                            {(wrapperProps) =>
                                <AssetDetails
                                    currentProject={currentProject}
                                    asset={assetDetails}

                                    assetAdvanced={assetAdvanced}
                                    toggleAssetAdvanced={toggleAssetAdvanced}

                                    assetSelection={assetSelection}
                                    setAssetSelection={setAssetSelection}
                                    clearAssetSelection={clearAssetSelection}
                                    toggleAssetSelection={toggleAssetSelection}

                                    toggleDeleteAssetsModal={toggleDeleteAssetsModal}

                                    openAssetContextMenu={openAssetContextMenu}

                                    onClose={closeAssetDetails}
                                    goTo={goToInsideLibrary}
                                    openAssetEditor={openAssetEditor}
                                    user={props.user}
                                    permissions={props.permissions}
                                    {...wrapperProps}
                                />
                            }
                        </AssetWrapper>
                    }
                </AssetDetailsStyled>
            }

            {assetAdvanced &&
                <OverlayStyled
                    isAssetAdvanced={Boolean(assetAdvanced)}
                    onClick={toggleAssetAdvanced}
                    className='fixed top-0 left-0 w-full h-full bg-color-underground opacity-80 cursor-pointer'
                />
            } */}
        </section>
    );
}

export default LibraryPublicDetails;
