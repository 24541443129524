//------------------------------------------------------------------------------
import React                    from 'react';
import ReactDOM                 from 'react-dom';
import moment                   from 'moment';
import EventEmitter             from 'events';
import * as StyledComponents    from 'styled-components';

//------------------------------------------------------------------------------
import * as MaterialCore        from '@material-ui/core';
import * as MaterialStyles      from '@material-ui/core/styles';
import * as MaterialUtils       from '@material-ui/core/utils';

//------------------------------------------------------------------------------
import App                      from './app.js';
import { installDesignSystem }  from './designSystem.js';

//------------------------------------------------------------------------------
window.React                    = React;
window.ReactDOM                 = ReactDOM;
window.StyledComponents         = StyledComponents;
window.MaterialCore             = MaterialCore;
window.MaterialStyles           = MaterialStyles;
window.MaterialUtils            = MaterialUtils;

window.EventHub                 = new EventEmitter();

//------------------------------------------------------------------------------
const urlParams                 = new URLSearchParams(window.location.search);
window.headless                 = urlParams.get('headless') === 'true';
const isHololensDevice          = urlParams.get('isHololensDevice');

//------------------------------------------------------------------------------
// Define asset browser app URL.
localStorage.setItem('3dverse-asset-browser', basename + 'browser');
// Define api parameters in local storage for the SDK.
localStorage.setItem('3dverse-api-token', apiToken);
localStorage.setItem('3dverse-api-gateway-url', apiGatewayURL);
localStorage.setItem('3dverse-editor-backend-url', editorBackendURL);
if(isHololensDevice === 'false' || isHololensDevice === 'true')
{
    // only set the flag if its value was explicitly passed as query param
    localStorage.setItem('3dverse-is-hololens-device', isHololensDevice.toString());
}

//------------------------------------------------------------------------------
// Shorten `1 hour ago` by `1h ago`
moment.locale('en', {
    relativeTime: {
        future: 'in %s',
        past: '%s ago',
        s:  '1s',
        ss: '%ss',
        m:  '1m',
        mm: '%dm',
        h:  '1h',
        hh: '%dh',
        d:  '1d',
        dd: '%dd',
        M:  '1M',
        MM: '%dM',
        y:  '1Y',
        yy: '%dY'
    }
});

//------------------------------------------------------------------------------
// Hack to fix default object exported from some es modules
const createFunc = window.React.createElement;
window.React.createElement = (...params) =>
{
    if(params[0].default)
    {
        params[0] = params[0].default;
    }
    return createFunc(...params);
};

//------------------------------------------------------------------------------
installDesignSystem().then(() =>
{
    ReactDOM.render(
        <App />,
        document.getElementById('app-container')
    );
});
