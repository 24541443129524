const urlEncodeParams = (params) =>
{
    let result = '';
    for(const paramName in params)
    {
        const paramValue = params[paramName];
        if(Array.isArray(paramValue))
        {
            for(const i in paramValue)
            {
                result += `${paramName}[${i}]` + '=' + encodeURIComponent(paramValue[i]) + '&';
            }
        }
        else
        {
            const value = typeof paramValue === 'object' ? JSON.stringify(paramValue) : paramValue;
            result += paramName + '=' + encodeURIComponent(value) + '&';
        }
    }
    return result;
};

export default urlEncodeParams;