import React, { useContext, useState
}                                                   from 'react';
import { useTranslation }                           from 'react-i18next';
import { Avatar, Button, ButtonIcon, Text
}                                                   from '../../../../designSystem/components';
import Can                                          from '../../../../utils/permissions';
import UserContext                                  from '../../../account/userContext';
import UserListPopover                              from '../../UserListPopover';

//------------------------------------------------------------------------------
function LibraryConsumerSection(props)
{
    //------------------------------------------------------------------------------
    const { currentProject }                        = props;

    //------------------------------------------------------------------------------
    const { t }                                     = useTranslation();
    const userData                                  = useContext(UserContext);
    const [ userListPopover, setUserListPopover ]   = useState(null);

    //------------------------------------------------------------------------------
    const closeUserListPopover = () =>
    {
        setUserListPopover(null);
    };

    //------------------------------------------------------------------------------
    const addConsumer = (consumer) =>
    {
        props.requests.addLibraryConsumer(consumer);
    };

    //------------------------------------------------------------------------------
    const removeConsumer = (consumer) =>
    {
        props.requests.removeLibraryConsumer(consumer);
    };

    //------------------------------------------------------------------------------
    const consumers         = currentProject.consumerGroup.sort((a, b) => a.username.localeCompare(b.username));
    const activeUserUUIDs   = consumers.map(consumer => consumer.userUUID);

    //------------------------------------------------------------------------------
    return (
        <section className='p-4 bg-color-underground border border-color-tertiary-alt rounded'>
            <header className='flex justify-between items-baseline gap-4 mb-3'>
                <Text format='title2'>
                    {t('project:consumers')}
                </Text>

                {Can(props.permissions, 'manageAccess') &&
                    <Button
                        value={t('project:add a consumer')}
                        onClick={(e) => setUserListPopover(e.currentTarget)}
                        size='small'
                    />
                }

                {userListPopover &&
                    <UserListPopover
                        open
                        anchorEl={userListPopover}
                        onClose={closeUserListPopover}
                        hasSearchAPI
                        userData={userData}
                        clientUserUUID={userData.uuid}
                        activeUserUUIDs={activeUserUUIDs}
                        hideMyself
                        onClickOnUser={(user) =>
                        {
                            addConsumer(user);
                            closeUserListPopover();
                        }}
                        searchPlaceholderMessage={t('project:search consumer')}
                        emptyMessage=' '
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                    />
                }
            </header>

            <ul className='flex flex-col gap-2 mt-2'>
                {consumers?.map(consummer =>
                {
                    return (
                        <li
                            key={consummer.userUUID}
                            className='flex items-center justify-between gap-2 pt-2 border-t border-color-tertiary'
                        >
                            <div className='flex items-center gap-2'>
                                <Avatar
                                    uuid={consummer.userUUID}
                                    username={consummer.username}
                                    tooltipTitle={consummer.username}
                                    color='var(--color-accent)'
                                    reversed
                                />
                                <Text format='body3' className='username'>
                                    {consummer.username}
                                </Text>
                            </div>

                            {Can(props.permissions, 'manageAccess') &&
                                <ButtonIcon
                                    className='fal fa-times'
                                    onClick={() => removeConsumer(consummer)}
                                    tooltipTitle={t('project:remove this consumer')}
                                    size='small'
                                />
                            }

                        </li>
                    );
                }
                )}
            </ul>
        </section>
    );
}

//------------------------------------------------------------------------------
export default LibraryConsumerSection;
