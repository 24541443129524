import React, { Fragment, useContext, useEffect, useState, useRef
}                                                   from 'react';
import { useTranslation }                           from 'react-i18next';
import { useSnackbar }                              from 'notistack';
import AutoSizer                                    from 'react-virtualized-auto-sizer';
import { fromEvent }                                from 'file-selector';
import { isMobile }                                 from 'react-device-detect';

import assetUtils                                   from '../../../utils/assetUtils';
import { getFileExtension }                         from '../../../utils/textTools';
import partition                                    from '../../../utils/partition';
import { AvatarGroup, Button, ButtonIcon, ButtonGroup, ControlSearch, ControlSelect, ControlSelectItem, Divider, Icon, Link, Notification, ShortcutHint, Text
}                                                   from '../../../designSystem/components';
import { plural as p, routerUseHistory }            from '../../../designSystem/utils';
import Breadcrumb                                   from '../../../components/Breadcrumb';
import FolderTree                                   from '../../../components/FolderTree';

import UploadContext                                from '../../uploadAsset/uploadContext';
import { supportedFiles }                           from '../../uploadAsset/supportedFiles';
import UploadAssetModal                             from '../../uploadAsset/UploadAssetModal';
import ConversionsTrack                             from '../../uploadAsset/ConversionsTrack';
import AssetTypeButtonGroup                         from '../../assets/AssetTypeButtonGroup';

import { renameAssetAPI, moveAssetAPI, moveSourceFilesAPI, renameSourceFileAPI, getSourceFile
}                                                   from '../../assets/api';


import SelectLibrariesModal                         from '../../libraries/SelectLibrariesModal';
import AddAssetPopover                              from './AddAssetPopover';

import SearchAdvancedPopover                        from './SearchAdvancedPopover';
import AssetView                                    from './AssetView';
import { ProjectContentStyled }                     from './style';
import { unlinkLibraryFromProjectAPI }              from '../../libraries/api';


//------------------------------------------------------------------------------
const SORT_BY = [
    {
        id      : 'ASSET',
        name    : 'Assets'
    },
    {
        id      : 'ABC',
        name    : 'Alphabetical order'
    },
    {
        id      : 'EDITION',
        name    : 'Edition date'
    },
    {
        id      : 'CREATION',
        name    : 'Creation date'
    },
    {
        id      : 'POPULAR',
        name    : 'Popupar'
    },
];

//------------------------------------------------------------------------------
const DISPLAY_MODE = [ 'COLUMN', 'LIST' ];



//------------------------------------------------------------------------------
function ProjectContent(props)
{
    //------------------------------------------------------------------------------
    const {
        currentProject, projectMap, workspaceMap, currentFolder, assetDetails,
        assetSelection, setAssetSelection,
        openAssetContextMenu,
        tileInRenameMode, setTileInRenameMode,
        searchString, setSearchString,
        renameFolder,
        canCreateContent,
        canManageLibrary,
    } = props;

    //------------------------------------------------------------------------------
    const { t }                                 = useTranslation();
    const history                               = routerUseHistory();
    const { enqueueSnackbar, closeSnackbar }    = useSnackbar();
    const uploadContext                         = useContext(UploadContext);

    //------------------------------------------------------------------------------
    const workingFolder         = currentFolder || currentProject;
    const currentWorkspaceUUID  = workingFolder.workspaceUUID;
    const taskGlobalProgress    = props.tasks && (props.tasks.reduce((acc, task) => acc + (task.progress || 0), 0) / props.tasks.length);
    const isLibraryRoot         = currentProject.isLibrary && workingFolder.libraryUUID === null && workingFolder.level === 0 && !searchString && !props.isTrashFolder;

    //------------------------------------------------------------------------------
    // List

    // Expected composition of countList
    // {
    //      totalCountByGroup       : [{ group1 }, { group2 }],
    //      filteredCountByGroup    : [{ group1 }, { group2 }],
    //      countTotal              : 0,
    //      filteredCount           : 0
    // }
    const [ countList, setCountList ]                   = useState(null);

    //------------------------------------------------------------------------------
    // Search
    const [ advancedSearch, setAdvancedSearch ]         = useState(null);
    const controlSearchRef                              = useRef();
    const searchBarRef                                  = useRef();

    //------------------------------------------------------------------------------
    // Filter
    const [ assetTypeFilter, setAssetTypeFilter ]       = useState([]);
    const [ workspaceFilter, setWorkspaceFilter ]       = useState({includeLibraries : true});
    const isSearching                                   = Boolean(searchString || workspaceFilter.rogueDependencies);

    //------------------------------------------------------------------------------
    // Create Folder => Pending Folder
    const [ pendingFolder, setPendingFolder ]           = useState(null);

    //------------------------------------------------------------------------------
    // State to store tree items, updated only when the project change
    const [ treeItems, setTreeItems ]                   = useState(null);



    //------------------------------------------------------------------------------
    useEffect(() =>
    {
        setAssetTypeFilter([]);
        setConversionPopover(false);
        clearSearch();
    }, [currentWorkspaceUUID]);

    //------------------------------------------------------------------------------
    useEffect(() =>
    {
        if(props.simplifiedTree)
        {
            setTreeItems([currentProject]);
            return;
        }

        const treeItems = [
            currentProject,
            {
                divider : true
            },
            {
                uuid        : 'libraries',
                name        : 'Libraries',
                isSystem    : true,
                folders     : currentProject.libraries,
                onClick     : () =>
                {
                    props.goTo(
                    {
                        projectPage     : 'libraries',
                        libraryUUID     : null,
                        folderUUID      : null,
                        sourceFileUUID  : null
                    });
                }
            }
        ]

        if(currentProject.SystemFolders.Trash)
        {
            treeItems.push(
                { divider : true },
                currentProject.SystemFolders.Trash
            );
        }

        setTreeItems(treeItems);

    }, [currentProject]);

    //------------------------------------------------------------------------------
    // Asset details

    let timeout;
    useEffect(() =>
    {
        function escFunction(event)
        {
            switch(event.keyCode)
            {
            case 191: // Key: CTRL + /
                if(event.ctrlKey || event.metaKey)
                {
                    event.stopPropagation();
                    timeout = setTimeout(() =>
                    {
                        controlSearchRef.current.focus();
                    }, 100);
                }
                break;
            default:
                break;
            }
        }
        window.addEventListener('keydown', escFunction);

        return () =>
        {
            clearTimeout(timeout);
            window.removeEventListener('keydown', escFunction);
        };
    }, [assetDetails]);


    //------------------------------------------------------------------------------
    // Content height

    //------------------------------------------------------------------------------
    const folderTreeContainerRef                        = useRef();
    const [ contentHeight, setContentHeight ]           = useState(400);

    //------------------------------------------------------------------------------
    useEffect(() =>
    {
        const _contentHeight = folderTreeContainerRef.current.clientHeight;

        if (_contentHeight)
        {
            setContentHeight(_contentHeight);
        }
    }, [folderTreeContainerRef]);


    //------------------------------------------------------------------------------
    const toggleAssetTypeFilter = (assetType) =>
    {
        if (assetTypeFilter.includes(assetType))
        {
            const _assetTypeFilter = assetTypeFilter.filter(a => a !== assetType);
            setAssetTypeFilter(_assetTypeFilter);
        }
        else
        {
            const _assetTypeFilter = Array.from(assetTypeFilter);
            _assetTypeFilter.push(assetType);
            setAssetTypeFilter(_assetTypeFilter);
        }
    };

    //------------------------------------------------------------------------------
    const clearSearch = () =>
    {
        setSearchString('');
        // Prevent to trigger multiple search instance if rogueDependencies is already false
        if(workspaceFilter.rogueDependencies)
        {
            setWorkspaceFilter({...workspaceFilter, rogueDependencies : false});
        }
    }

    //------------------------------------------------------------------------------
    const clearAssetTypeFilter = () =>
    {
        setAssetTypeFilter([]);
    };


    //------------------------------------------------------------------------------
    // Add Asset and Folder Popover

    //------------------------------------------------------------------------------
    const [ addAssetPopover, setAddAssetPopover ] = useState(null);

    //------------------------------------------------------------------------------
    const addPendingFolder = () =>
    {
        setSearchString('');
        setPendingFolder(true);
    };

    //------------------------------------------------------------------------------
    const createFolder = (name) =>
    {
        if(name)
        {
            const parentFolderUUID  = (workingFolder && workingFolder.level !== 0 && workingFolder.uuid) || undefined;
            props.requests.createFolder({ name, parentFolderUUID });
        }
        setPendingFolder(false);
    };

    //------------------------------------------------------------------------------
    const closePendingFolder = () =>
    {
        setPendingFolder(false);
    };


    //------------------------------------------------------------------------------
    // Libraries

    const [ selectLibrariesModal, setSelectLibrariesModal ] = useState(null);

    const toggleSelectLibrariesModal = () =>
    {
        setSelectLibrariesModal(!selectLibrariesModal);
    }

    //------------------------------------------------------------------------------
    const unlinkLibrary = async (libraryUUID) =>
    {
        await unlinkLibraryFromProjectAPI(currentProject.uuid, libraryUUID);
    };

    //------------------------------------------------------------------------------
    // Upload Asset from Drag n Drop

    //------------------------------------------------------------------------------
    const [ dragOverFiller, setDragOverFiller ] = useState(0);

    //------------------------------------------------------------------------------
    const onDragEnterInFiller = (e) =>
    {
        if (isDroppableInSection(e))
        {
            setDragOverFiller( dragOverFiller + 1 );
        }
    };

    //------------------------------------------------------------------------------
    const onDragLeaveInFiller = (e) =>
    {
        if (isDroppableInSection(e))
        {
            setDragOverFiller( dragOverFiller - 1 );
        }
    };

    //------------------------------------------------------------------------------
    const isDroppableInSection = (e) =>
    {
        return e.dataTransfer.types.includes('Files');
    };


    //------------------------------------------------------------------------------
    // Upload Asset Modal

    //------------------------------------------------------------------------------
    const [ uploadAssetModal, setUploadAssetModal ] = useState(false);


    //------------------------------------------------------------------------------
    const closeRenameMode = () =>
    {
        setTileInRenameMode(null);
    };

    //------------------------------------------------------------------------------
    const renameAsset = async (assetType, assetUUID, isSourceFile, value, name) =>
    {
        if (value !== '' && value !== name)
        {
            if(isSourceFile)
            {
                await renameSourceFileAPI(assetUUID, value);
            }
            else
            {
                await renameAssetAPI(assetType, assetUUID, value);
            }
        }
        closeRenameMode();
    };

    //------------------------------------------------------------------------------
    const createAssetCallback = (asset) =>
    {
        setTileInRenameMode(asset);
    }


    //------------------------------------------------------------------------------
    // Handle Tile Click and Double Click

    //------------------------------------------------------------------------------
    const onTileClick = (event, node, assetType, fromLibrary) =>
    {
        const uuid = node.uuid;

        if (event.ctrlKey || event.metaKey || event.shiftKey)
        {
            let _assetSelection = Array.from(assetSelection);

            // If selection is empty, then add previous selected asset to selection.
            if (_assetSelection.length === 0 && assetDetails)
            {
                _assetSelection = [
                    {
                        uuid            : assetDetails.uuid,
                        assetType       : assetDetails.assetType,
                        isSourceFile    : assetDetails.isSourceFile,
                        fromLibrary
                    }
                ];
            }

            // If already in selection, remove it.
            if (_assetSelection.find(a => a.uuid === uuid))
            {
                _assetSelection = _assetSelection.filter(a => a.uuid !== uuid);
            }
            // Else add it.
            else
            {
                _assetSelection = [..._assetSelection, { ...node, uuid, assetType, fromLibrary }];

                props.goTo({
                    assetDetails: {
                        uuid,
                        assetType,
                        info: node,
                        isSourceFile : node.isSourceFile
                    }
                });
            }

            setAssetSelection(_assetSelection);
        }
        else
        {
            if(assetSelection && assetSelection.length !== 0)
            {
                props.clearAssetSelection();
            }

            if(assetType === 'folder' && isMobile)
            {
                props.goTo({ folderUUID: uuid });
            }
            else
            {
                props.goTo({
                    assetDetails: {
                        uuid,
                        assetType,
                        info: node,
                        isSourceFile : node.isSourceFile
                    }
                });
            }
        }
    }

    //------------------------------------------------------------------------------
    const onTileDoubleClick = (node, assetType) =>
    {
        if (assetType === 'folder')
        {
            props.goTo({ folderUUID: node.uuid, libraryUUID : node.libraryUUID, sourceFileUUID : null });
            setSearchString('');
        }
        else if(props.openAssetEditor)
        {
            props.openAssetEditor(assetType, node.mainAsset ? node.mainAsset.uuid : node.uuid);
        }
    }


    //------------------------------------------------------------------------------
    // Tiles Drag and Drop

    //------------------------------------------------------------------------------
    const onDropInFolder = (e, targetFolder) =>
    {
        if(!e.dataTransfer) return;

        // Stop propagation to onAssetDrop in ProjectContent.
        e.preventDefault();
        e.stopPropagation();

        // Clear selection
        if(assetSelection.length > 0)
        {
            props.clearAssetSelection();
        }

        const isDraggedMultipleAssets   = e.dataTransfer.getData('3dverse/multiple');
        const isDraggedFolder           = e.dataTransfer.getData('3dverse/folder');
        const isDraggedAsset            = e.dataTransfer.getData('3dverse/asset');

        if(isDraggedMultipleAssets)
        {
            const draggedMultipleAssets = JSON.parse(isDraggedMultipleAssets);
            const projectAssets         = draggedMultipleAssets.filter(a => !a.fromLibrary);
            const [sourceFiles, assets] = partition(projectAssets, (a) => a.isSourceFile);

            moveSourceFiles(sourceFiles, targetFolder, e);

            for(const asset of assets)
            {
                asset.assetType === 'folder' ?
                    moveDraggedFolder(asset, targetFolder)
                    :
                    moveDraggedAsset(asset, targetFolder, e);
            }
        }
        else if (isDraggedFolder)
        {
            const draggedFolder = JSON.parse(isDraggedFolder);
            moveDraggedFolder(draggedFolder, targetFolder);
        }
        else if (isDraggedAsset)
        {
            const draggedAsset = JSON.parse(isDraggedAsset);

            if(draggedAsset.fromLibrary)
            {
                return;
            }

            if(draggedAsset.isSourceFile)
            {
                moveSourceFiles([draggedAsset], targetFolder, e);
            }
            else
            {
                moveDraggedAsset(draggedAsset, targetFolder, e);
            }
        }
        else
        {
            const options = {
                isAutoConvert   : true,
                isVolume        : false,
                onUploadProgress: uploadContext.updateProgress,
                onUploadDone,
                onUploadError,
            };

            fromEvent(e).then(_files =>
            {
                if(_files.length === 0)
                {
                    return;
                }

                const files = _files.map(file =>
                {
                    const { filename, extension } = getFileExtension(file.name);
                    return {
                        name        : filename,
                        extension   : extension,
                        size        : file.size,
                        type        : file.type,
                        handle      : file
                    };
                });

                setUploadAssetModal({
                    workspaceUUID: targetFolder.workspaceUUID,
                    files,
                    options,
                })
            });
        }
    };

    //------------------------------------------------------------------------------
    const moveDraggedFolder = (draggedFolder, targetFolder) =>
    {
        // Test if drag in a children
        if (targetFolder.level > 0 && targetFolder.path.startsWith(draggedFolder.path))
        {
            return;
        }

        props.requests.moveFolder({
            folderUUID          : draggedFolder.uuid,
            parentFolderUUID    : (targetFolder.level > 0 || targetFolder.isSystem) ? targetFolder.uuid : undefined
        });
    }

    //------------------------------------------------------------------------------
    const moveDraggedAsset = (draggedAsset, targetFolder, e) =>
    {
        const parentWorkspaceUUID   = (targetFolder.level > 0 || targetFolder.isSystem)
                                    ? targetFolder.workspaceUUID
                                    : currentProject.workspaceUUID;
        const moveDependencies      = e.ctrlKey || e.altKey;

        moveAssetAPI(draggedAsset.assetType, parentWorkspaceUUID, draggedAsset.uuid, {moveDependencies});
    }

    //------------------------------------------------------------------------------
    const moveSourceFiles = (draggedSourceFiles, targetFolder, e) =>
    {
        if(draggedSourceFiles.length === 0)
        {
            return;
        }

        const parentWorkspaceUUID   = targetFolder.level > 0
                                    ? targetFolder.workspaceUUID
                                    : currentProject.workspaceUUID;

        moveSourceFilesAPI(draggedSourceFiles.map(sf => sf.uuid), parentWorkspaceUUID);
    }

    //------------------------------------------------------------------------------
    // Warning: This function should not contain states!
    const onUploadDone = (files, response, isAutoConvert) =>
    {
        uploadContext.hideProgress();

        const goToConversions = (key) =>
        {
            history.push('/profile/conversions');
            closeSnackbar(key);
        };

        const goToFolder = () =>
        {
            const { workspaceUUID, uuid, assetType } = response;
            const { folderUUID } = workspaceMap.get(workspaceUUID);
            props.goTo({ folderUUID });
        }

        const fileNames = () =>
        {
            const [ file, ...otherFiles ] = files;
            return file.name + '.' + file.extension + (otherFiles.length > 1 ? ` and ${otherFiles.length} files.` : '');
        }

        const content = key => (
            <div>
                <Notification
                    notificationUUID={key}
                    type='upload'
                    countUploads={files.length}
                    title={fileNames()}
                    buttons={[
                        {
                            value: 'Open Folder',
                            onClick: () => { goToFolder(); closeSnackbar(key); }
                        }
                    ]}
                    floating
                    background='ground'
                />
            </div>
        );

        enqueueSnackbar('Upload succeeded',
            {
                variant:'success',
                persist: true,
                content,
                key : 'upload-success', // Warn it could be duplicated
            });
    };

    //------------------------------------------------------------------------------
    const onUploadError = (message, response) =>
    {
        enqueueSnackbar(message, { variant:'error' });
        uploadContext.hideProgress();
        console.error('upload failed', response);
    };

    //------------------------------------------------------------------------------
    const onTileDragStart = (event, node, assetType, fromLibrary) =>
    {
        event.dataTransfer.setData(
            `3dverse/${node.uuid}`,
            node.name
        );

        if(assetSelection.length > 1)
        {
            event.dataTransfer.setData(
                '3dverse/multiple',
                JSON.stringify(assetSelection)
            );
        }
        else if(assetType === 'folder')
        {
            event.dataTransfer.setData(
                '3dverse/folder',
                JSON.stringify(node)
            );
        }
        else
        {
            event.dataTransfer.setData(
                '3dverse/asset',
                JSON.stringify(
                {
                    name            : node.name,
                    uuid            : node.uuid,
                    assetType       : assetType,
                    label           : assetUtils.getAssetLabelFromAssetType(assetType),
                    isSourceFile    : node.isSourceFile,
                    mainAssetUUID   : node.mainAsset?.uuid,
                    fromLibrary     : fromLibrary,
                })
            );
        }
    };


    //------------------------------------------------------------------------------
    // Conversion Panel

    //------------------------------------------------------------------------------
    const [ conversionPopover, setConversionPopover ] = useState(false);

    //------------------------------------------------------------------------------
    const toggleConversionPanel = () =>
    {
        setConversionPopover(!conversionPopover);
    }


    //------------------------------------------------------------------------------
    // Display Mode

    //------------------------------------------------------------------------------
    const [ sortBy, setSortBy ]             = useState(SORT_BY[0].id);
    const [ displayMode, setDisplayMode ]   = useState(DISPLAY_MODE[0]);

    //------------------------------------------------------------------------------
    const onChangeSortBy = (event) =>
    {
        setSortBy(event.currentTarget.value);
    };

    //------------------------------------------------------------------------------
    const toggleDisplayMode = () =>
    {
        const newDisplayMode = displayMode === DISPLAY_MODE[0] ? DISPLAY_MODE[1] : DISPLAY_MODE[0];
        setDisplayMode(newDisplayMode);
    };

    //------------------------------------------------------------------------------
    // Current opened source file, stored as a state to use it across different components
    const [ sourceFile, setSourceFile ] = useState(null);

    useEffect(() =>
    {
        if(props.sourceFileUUID)
        {
            setSourceFile({loading : true});
            getSourceFile(props.sourceFileUUID).then(setSourceFile);
        }
        else
        {
            setSourceFile(null);
        }

    }, [props.sourceFileUUID]);

    //------------------------------------------------------------------------------
    const onDropInFiller = (e) =>
    {
        e.preventDefault();
        setDragOverFiller(0);

        if(sourceFile)
        {
            return;
        }

        const targetFolder = workingFolder;
        onDropInFolder(e, targetFolder);
    };

    //------------------------------------------------------------------------------
    const searchPlaceFolder =
        workspaceFilter.restrictToCurrentFolder
            ? t('project:search in current folder')
            : (
                workspaceFilter.includeLibraries
                    ? t('project:search in project and libraries')
                    : t('project:search in project')
            );

    //------------------------------------------------------------------------------
    return (
        <ProjectContentStyled
            className={props.className}
            headlessMode={props.headlessMode}
        >

            <div className='flex flex-col gap-5 wrapper py-3 sm:py-5 border-b border-color-tertiary-alt'>
                <div className='flex gap-5'>
                    <div className='flex-1' ref={searchBarRef}>
                        <ControlSearch
                            value={searchString}
                            onChange={e => props.setSearchString(e.target.value)}
                            placeholder={searchPlaceFolder}
                            inputRef={controlSearchRef}
                            InputProps={{
                                endAdornment:
                                    <ShortcutHint control character='/' className='mr-2' />
                            }}
                            onClick={() => setAdvancedSearch(searchBarRef.current)}
                            onFocus={() => setAdvancedSearch(searchBarRef.current)}
                            style={advancedSearch && {zIndex: '10'}}
                        />
                        {advancedSearch &&
                            <Fragment>
                                <div
                                    onClick={() => setAdvancedSearch(null)}
                                    style={{
                                        position: 'fixed',
                                        width: '100%', height: '100%',
                                        left: '0', top: '0',
                                        zIndex: '20'
                                    }}
                                />
                                <SearchAdvancedPopover
                                    open
                                    anchorEl={advancedSearch}
                                    searchString={searchString}
                                    setSearchString={props.setSearchString}
                                    workspaceFilter={workspaceFilter}
                                    setWorkspaceFilter={setWorkspaceFilter}
                                    transition
                                />
                            </Fragment>
                        }
                    </div>
                </div>
            </div>

            <div className='flex overflow-hidden'>

                <div
                    className='w-1/5 hidden md:block'
                    ref={folderTreeContainerRef}
                >
                    {treeItems &&
                        <FolderTree
                            key={currentProject.uuid}
                            projectUUID={currentProject.uuid}
                            items={treeItems}
                            projectPage={props.projectPage}
                            currentFolder={currentFolder}
                            projectMap={projectMap}
                            libraryMap={props.libraryMap}

                            isSearching={isSearching}
                            clearSearch={clearSearch}

                            onContextMenu={openAssetContextMenu}
                            onDragStart={onTileDragStart}
                            onDrop={onDropInFolder}
                            goTo={props.goTo}

                            height={contentHeight}
                            className='py-3 pl-3 pr-4 border-r border-color-tertiary-alt'
                        />
                    }
                </div>

                <div className='relative flex-1 flex flex-col w-full h-full transition-colors'>
                    {props.projectPage === 'libraries' ?
                            <section className='w-full h-full wrapper py-5'>
                                {currentProject.libraries.length > 0 ?
                                    <div>
                                        <header className='flex justify-between items-baseline mb-3'>
                                            <Text format='body3' color='secondary' as='h1' className='tracking-wide'>
                                                Linked libraries
                                            </Text>
                                            {
                                                canManageLibrary &&
                                                <Button
                                                    value='Link a library'
                                                    onClick={() => setSelectLibrariesModal(true)}
                                                    size='small'
                                                    color='primary'
                                                />
                                            }
                                        </header>
                                        <ul className='w-full gap-1px'>
                                            {currentProject.libraries.map(lib =>
                                                <li key={lib.uuid} className='w-full'>
                                                    <div className='flex justify-between items-center w-full pl-2 pr-4 py-2 border border-color-tertiary-alt rounded'>
                                                        <Button
                                                            value={lib.name}
                                                            onClick={() => props.goTo({ libraryUUID: lib.uuid, folderUUID : null, sourceFileUUID : null })}
                                                            color='tertiary'
                                                        />
                                                        {
                                                            canManageLibrary &&
                                                            <Button
                                                                value='Unlink'
                                                                onClick={() => unlinkLibrary(lib.uuid)}
                                                                size='small'
                                                            />
                                                        }
                                                    </div>
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                :
                                    <div className='flex flex-col justify-center items-center gap-3 w-full h-full'>
                                        <Icon className='fal fa-link opacity-50' style={{fontSize : '2.5rem'}} size='large' />
                                        <Text format='body3' color='secondary'>
                                            No library linked yet.
                                        </Text>
                                        {
                                            canManageLibrary &&
                                            <Button
                                                value='Link a library'
                                                onClick={toggleSelectLibrariesModal}
                                                size='small'
                                            />
                                        }
                                    </div>
                                }
                            </section>
                        :
                        <Fragment>
                            <div className='flex flex-wrap justify-between items-center gap-4 wrapper py-2 border-b border-color-tertiary-alt'>

                                <div className='flex items-center gap-4 flex-1'>
                                    {canCreateContent && props.projectPage !== 'library' && !isLibraryRoot &&
                                        <Fragment>
                                            <ButtonIcon
                                                onClick={(e) => setAddAssetPopover({ anchorEl: e.currentTarget, wrapperProps: { className: 'bg-color-action-secondary' } })}
                                                className='fal fa-plus'
                                                color='primary'
                                                disabled={props.isTrashFolder || isSearching}
                                            />
                                            {addAssetPopover &&
                                                <AddAssetPopover
                                                    open
                                                    {...addAssetPopover}
                                                    user={props.user}
                                                    onClose={() => setAddAssetPopover(null)}
                                                    workingFolder={workingFolder}
                                                    setUploadAssetModal={setUploadAssetModal}
                                                    onUploadDone={onUploadDone}
                                                    onUploadProgress={uploadContext.updateProgress}
                                                    onUploadError={onUploadError}
                                                    addPendingFolder={addPendingFolder}
                                                    createAssetCallback={createAssetCallback}
                                                    toggleSelectLibrariesModal={toggleSelectLibrariesModal}
                                                    requests={props.requests}
                                                />
                                            }
                                        </Fragment>
                                    }

                                    {isSearching ?
                                        <p className='text-sm'>
                                            {countList &&
                                                <span>
                                                    <b>{countList.filteredCount}</b> result{p(countList.filteredCount)}
                                                </span>
                                            }
                                        </p>
                                        :
                                        <Breadcrumb
                                            currentProject={currentProject}
                                            currentFolder={currentFolder}
                                            currentLibrary={props.currentLibrary}
                                            sourceFile={sourceFile}
                                            projectMap={projectMap}
                                            openAssetContextMenu={openAssetContextMenu}
                                            onDrop={onDropInFolder}
                                            goTo={props.goTo}
                                            style={{ marginLeft: '-.5rem' }}
                                            className='flex-1'
                                        />
                                    }
                                </div>

                                <div className='flex items-center justify-between gap-2 order-1 sm:order-none'>
                                    <div className='flex items-center gap-1'>

                                        {countList?.countTotal > 0 &&
                                            <AssetTypeButtonGroup
                                                countByGroup={searchString ? countList.filteredCountByGroup : countList.totalCountByGroup}
                                                assetTypeFilter={assetTypeFilter}
                                                clearAssetTypeFilter={clearAssetTypeFilter}
                                                toggleAssetTypeFilter={toggleAssetTypeFilter}
                                            />
                                        }

                                        {/*
                                        {currentProject.tags.map(tag =>
                                            <li >
                                                <Button
                                                    key={tag.name}
                                                    value={tag.name}
                                                    size='xsmall'
                                                    backgroundColor={tag.color}
                                                />
                                            </li>
                                        )}
                                        <Button
                                            value='My imports'
                                            size='xsmall'
                                            color='tertiary'
                                        />
                                        <Button
                                            value='Clear'
                                            size='xsmall'
                                            color='tertiary'
                                        />
                                        */}
                                    </div>
                                </div>

                                <div className='flex gap-2'>
                                    {/* <ControlSelect
                                        value={sortBy}
                                        onClick={onChangeSortBy}
                                        color='tertiary'
                                        size='xsmall'
                                    >
                                        {SORT_BY.map(item => (
                                            <ControlSelectItem key={item.id} value={item.id}>
                                                Sort by <b>{item.name}</b>
                                            </ControlSelectItem>
                                        ))}
                                    </ControlSelect> */}

                                    <ButtonIcon
                                        onClick={toggleDisplayMode}
                                        tooltipTitle={`Toggle to ${displayMode === 'LIST' ? 'tile' : 'list'} mode`}
                                        className={`fal ${displayMode === 'LIST' ? 'fa-align-justify' : 'fa-th'}`}
                                        color='tertiary'
                                        size='xsmall'
                                    />
                                </div>
                            </div>

                            {isLibraryRoot ?
                                <div className='flex flex-row h-full w-full'>
                                    <Button
                                        onClick={() => props.goTo({ folderUUID: currentProject.SystemFolders.Private.uuid, libraryUUID : null, sourceFileUUID : null })}
                                        className='flex-1'
                                        valueClassName='flex flex-col'
                                        color='tertiary'
                                        fullWidth
                                        fullHeight
                                        square
                                    >
                                        <Icon className='fal fa-lock' size='xxlarge' color='secondary' />
                                        <Text className='my-1'>
                                            Private Folder
                                        </Text>
                                        <Text format='body4' color='secondary'>
                                            Craft your library in isolation.
                                        </Text>
                                    </Button>
                                    <Divider vertical />
                                    <Button
                                        onClick={() => props.goTo({ folderUUID: currentProject.SystemFolders.Public.uuid, libraryUUID : null, sourceFileUUID : null})}
                                        className='flex-1'
                                        valueClassName='flex flex-col'
                                        color='tertiary'
                                        fullWidth
                                        fullHeight
                                        square
                                    >
                                        <Icon className='fal fa-globe' size='xxlarge' color='secondary' />
                                        <Text className='my-1'>
                                            Public Folder
                                        </Text>
                                        <Text format='body4' color='secondary'>
                                            Move your assets here to expose them to other users.
                                        </Text>
                                    </Button>
                                </div>
                            :
                                <section
                                    className={`flex flex-col w-full h-full ${dragOverFiller > 0 ? 'bg-color-overground' : ''}`}
                                    onDrop={onDropInFiller}
                                    onDragOver={(e) => e.preventDefault()}
                                    onDragEnter={onDragEnterInFiller}
                                    onDragLeave={onDragLeaveInFiller}
                                    onContextMenu={e =>
                                    {
                                        const anchorPosition = {
                                            top: e.clientY - 4,
                                            left: e.clientX - 2
                                        };

                                        const buttonProps = {
                                            color: 'tertiary',
                                            size: 'xsmall'
                                        };
                                        setAddAssetPopover({ anchorPosition, buttonProps, wrapperProps: {} });
                                    }}
                                >
                                    {props.tasks && showNotReadyFeatures &&
                                        <aside className='relative bg-color-overground'>
                                            <Button
                                                onClick={toggleConversionPanel}
                                                className='relative z-20'
                                                valueClassName='w-full'
                                                contentAlign='left'
                                                fullWidth
                                                noPadding
                                                square
                                            >
                                                <div className='flex justify-between w-full wrapper'>
                                                    {taskGlobalProgress === 1 ?
                                                        <Text format='body3' color='secondary'>
                                                            <b>{props.tasks.length} task{p(props.tasks.length)}</b> recently converted
                                                        </Text>
                                                        :
                                                        <Text format='body3'>
                                                            <b>{props.tasks.length} task{p(props.tasks.length)}</b> {props.tasks.length > 1 ? 'are' : 'is'} converting
                                                        </Text>
                                                    }
                                                    <AvatarGroup
                                                        avatars={props.tasks}
                                                        tooltipTitle={props.tasks.map(task => task.username).join(' ')}
                                                        size='small'
                                                    />
                                                </div>
                                                {taskGlobalProgress !== 1 &&
                                                    <div
                                                        className='absolute bottom-0 left-0 h-1px bg-color-feedback-informative'
                                                        style={{width : Math.floor(taskGlobalProgress * 100) + '%'}}
                                                    />
                                                }
                                            </Button>
                                            {conversionPopover &&
                                                <Fragment>
                                                    <div className='absolute w-full bg-color-overground z-20'>
                                                        <ConversionsTrack
                                                            tasks={props.tasks}
                                                            goTo={props.goTo}
                                                            displayFolder={false}
                                                            workspaceMap={workspaceMap}
                                                            articleClassName='px-6'
                                                        />
                                                    </div>
                                                    <button
                                                        onClick={toggleConversionPanel}
                                                        className='absolute top-0 left-0 w-full h-screen bg-color-underground opacity-75 z-10'
                                                    />
                                                </Fragment>
                                            }
                                        </aside>
                                    }

                                    <div className='flex flex-col h-full'>

                                        {!pendingFolder &&
                                            <Fragment>
                                                {currentProject?.folders.length > 0 || countList?.countTotal > 0 || !canCreateContent || props.isTrashFolder ?
                                                    <Fragment>
                                                        {countList?.filteredCount === 0 &&
                                                            <div className='flex flex-col items-center gap-2 w-full py-4'>
                                                                {
                                                                    props.isTrashFolder && !searchString ?
                                                                    <div className='absolute flex flex-col items-center gap-3 w-full h-full justify-center m-auto px-5 max-w-lg'>
                                                                        <Icon className='fal fa-trash-alt opacity-50' size='xxlarge' />
                                                                        <Text format='title2'>
                                                                            Trash
                                                                        </Text>
                                                                        <span className='text-sm text-color-neutral-secondary text-center'>
                                                                            Deleted assets go to this folder.<br/>
                                                                            You can restore them by moving them in another folder, or you can permanently delete them from here.
                                                                        </span>
                                                                    </div>
                                                                    :
                                                                    <Text color='secondary' format='body4'>
                                                                        {searchString ? t('t:no result') : 'Empty Folder'}
                                                                    </Text>
                                                                }
                                                                {searchString &&
                                                                    <Button
                                                                        value='Clear Search'
                                                                        onClick={() => setSearchString('')}
                                                                        color='tertiary'
                                                                        size='xsmall'
                                                                    />
                                                                }
                                                            </div>
                                                        }
                                                    </Fragment>
                                                    :
                                                    <div className='flex flex-col items-center justify-center gap-3 w-full max-w-lg h-full m-auto px-5'>
                                                        <Text format='title2'>
                                                            Drop your assets
                                                        </Text>
                                                        <span className='text-xs text-color-neutral-secondary text-center'>
                                                            {supportedFiles}
                                                        </span>
                                                        <span className='text-sm text-color-neutral-secondary'>or</span>
                                                        <Button
                                                            value='Create'
                                                            onClick={(e) => setAddAssetPopover({ anchorEl: e.currentTarget, wrapperProps: { className: 'bg-color-action-secondary' } })}
                                                            startIcon='fal fa-plus'
                                                        />
                                                    </div>
                                                }
                                            </Fragment>
                                        }

                                        <AutoSizer>
                                            {({ width, height }) =>
                                                <AssetView
                                                    key={workingFolder.uuid}
                                                    width={width}
                                                    height={height}
                                                    paddingLeft={isMobile ? 16 : 32}
                                                    paddingRight={isMobile ? 16 : 32}
                                                    paddingBottom={56}

                                                    workingFolder={workingFolder}
                                                    sourceFile={sourceFile}
                                                    updateTrigger={workingFolder.updateTrigger}
                                                    workspaceMap={workspaceMap}
                                                    requests={props.requests}
                                                    pendingFolder={pendingFolder}
                                                    projectSessions={props.projectSessions}

                                                    assetDetailsUUID={assetDetails?.uuid}
                                                    assetSelection={assetSelection}

                                                    searchString={searchString}
                                                    assetTypeFilter={assetTypeFilter}
                                                    workspaceFilter={workspaceFilter}
                                                    sortBy={sortBy} // Unused
                                                    displayMode={displayMode} // Unused

                                                    renameFolder={renameFolder}
                                                    renameAsset={renameAsset}
                                                    tileInRenameMode={props.tileInRenameMode}
                                                    closeRenameMode={closeRenameMode}
                                                    createFolder={createFolder}
                                                    closePendingFolder={closePendingFolder}

                                                    onTileClick={setAssetSelection && onTileClick}
                                                    onTileDoubleClick={onTileDoubleClick}
                                                    onDragStart={onTileDragStart}
                                                    onDrop={onDropInFolder}
                                                    onDragEnterInFiller={onDragEnterInFiller}
                                                    onDragLeaveInFiller={onDragLeaveInFiller}
                                                    goTo={props.goTo}

                                                    openAssetEditor={props.openAssetEditor}
                                                    openAssetContextMenu={openAssetContextMenu}
                                                    setCountList={setCountList}
                                                />
                                            }
                                        </AutoSizer>
                                    </div>
                                </section>
                            }
                        </Fragment>
                    }
                </div>
            </div>

            {selectLibrariesModal &&
                <SelectLibrariesModal
                    open
                    onClose={() => setSelectLibrariesModal(null)}
                    currentProject={currentProject}
                    openAssetContextMenu={openAssetContextMenu}
                    openAssetEditor={props.openAssetEditor}
                    permissions={props.permissions}
                />
            }

            {uploadAssetModal &&
                <UploadAssetModal
                    open
                    onClose={() => setUploadAssetModal(null)}
                    projectMap={projectMap}
                    workspaceMap={workspaceMap}
                    uploadAssetModal={uploadAssetModal}
                    getWorkspaceUUIDs={props.requests.getWorkspaceUUIDs}
                />
            }
        </ProjectContentStyled>
    );
}

//------------------------------------------------------------------------------
export default ProjectContent;
