import React                        from 'react';
import { useTranslation }           from 'react-i18next';
import { ButtonIcon, Link, Text }   from '../../designSystem/components';
import { TileApplicationStyled, TileApplicationInlineStyled } from './style';


//------------------------------------------------------------------------------
function TileApplication(props)
{
    const { thumbnailUUID, projectUUID } = props;
    const { t } = useTranslation();

    //------------------------------------------------------------------------------
    const nodeName = () =>
    {
        if (props.highlightString)
        {
            const regEx = new RegExp(props.highlightString, 'ig');
            const name = props.name.replace(regEx, '<mark>$&</mark>');
            return <span dangerouslySetInnerHTML={{ __html: name }} />;
        }
        else
        {
            return props.name;
        }
    };

    //------------------------------------------------------------------------------
    if (props.inline)
    {
        //------------------------------------------------------------------------------
        return (
            <div className='flex justify-between items-center gap-2'>
                <TileApplicationInlineStyled
                    as={props.href ? 'a' : (props.to ? Link : 'button')}
                    to={props.to}
                    href={props.href}
                    target={props.href ? '_blank' : undefined}
                    onClick={props.onClick}
                    onContextMenu={props.onContextMenu}
                    className={`flex items-center gap-3 w-full rounded-md transition transition-colors hover:bg-color-action-secondary ${props.className ?? null}`}
                    size='small'
                >
                    <div className='w-6 h-6 bg-cover bg-center rounded-md'
                        style={thumbnailUUID ? {
                            backgroundImage: `url(${apiGatewayURL}/asset/thumbnail?assetUUID=${thumbnailUUID}&token=${apiToken}&size=small`
                        } : undefined}
                    />
                    {props.name &&
                        <Text
                            format='body3'
                            className='text-ellipsis'
                            as='span'
                        >
                            {nodeName()}
                        </Text>
                    }
                </TileApplicationInlineStyled>
                {props.editButton &&
                    <ButtonIcon
                        onClick={() => props.goTo({ projectUUID, folderUUID: props.uuid, projectPage: 'applications' })}
                        tooltipTitle={t('application:edit application settings')}
                        className='fal fa-pen'
                        color='tertiary'
                        size='small'
                        htmlColor='var(--color-content-neutral-secondary)'
                    />
                }
            </div>
        )
    }
    //------------------------------------------------------------------------------
    else
    {
        //------------------------------------------------------------------------------
        return (
            <div className='relative h-full'>
                <TileApplicationStyled
                    as={props.href ? 'a' : (props.to ? Link : 'button')}
                    to={props.to}
                    href={props.href}
                    target={props.href ? '_blank' : undefined}
                    onClick={props.onClick}
                    onContextMenu={props.onContextMenu}
                    className={`flex flex-col gap-5 h-full bg-color-overground rounded-xs text-left transition transition-colors overflow-hidden ${props.className ?? null}`}
                    size={props.size}
                    $isFeatured={props.isFeatured}
                >
                    <div className='relative w-full h-full bg-cover bg-center'
                        style={thumbnailUUID ? {
                            backgroundImage: `url(${apiGatewayURL}/asset/thumbnail?assetUUID=${thumbnailUUID}&token=${apiToken}&size=large`
                        } : undefined}
                    >
                        {
                            props.name &&
                            <span className={`info w-full h-full flex-1 flex flex-col items-start justify-end gap-1 p-4 ${props.editButton ? 'pr-7' : ''}`}>
                                <Text
                                    format={props.size === 'large' ? 'title2' : 'title3'}
                                    as='span'
                                    className={`z-10 ${props.size !== 'large' ? 'text-ellipsis' : ''}`}
                                >
                                    {nodeName()}
                                </Text>
                                {props.description &&
                                    <Text format='body4' color='secondary' as='span' className='z-10'>
                                        {props.description}
                                    </Text>
                                }
                            </span>
                        }
                    </div>

                </TileApplicationStyled>

                {props.editButton &&
                    <div className='absolute bottom-2 right-2'>
                        <ButtonIcon
                            onClick={() => props.goTo({ projectUUID, folderUUID: props.uuid, projectPage: 'applications' })}
                            tooltipTitle={t('application:edit application settings')}
                            className='fal fa-pen'
                            color='secondary'
                            size='small'
                        />
                    </div>
                }
            </div>
        );
    }
}

export default TileApplication;
