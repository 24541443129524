import React, { useState }              from 'react';
import { useTranslation }               from 'react-i18next';
import InputAdornment                   from '@material-ui/core/InputAdornment';

import { Button, ButtonIcon, ControlText} from '../../../designSystem/components';
import { routerUseHistory }             from '../../../designSystem/utils';
import { signupAPI }                    from '../api';
import Layout, { Box, SignInUpSwitch }  from '../LoginLayout';


//------------------------------------------------------------------------------
// TODO:
// directly sign up the user would require several things:
//  - verify the email of the oauth account has been verified (through the provider API)
//    as an example Google provide this data in the idToken
//  - usually websites propose a fast sign-up flow without requiring a password creation
//    still there is a random string password generated under the hood but the user doesn't know it
//  - we can choose the left part of the email as the username, but still many username will be invalid
//    this way: username containing invalid character like a dot, username already exists. So the user
//    should be able to change it before signing up.
//  - since we check the email is confirmed on the oauth provider account, we should bypass our email
//    confirmation step.
//  - since we bypass the email confirmation step, the user should be signed-in directly after the
//    sign-up succeeded, and the oauth provider account should be linked to the 3dverse user.
function LoginForm(props)
{
    //------------------------------------------------------------------------------
    const { t }     = useTranslation();
    const history   = routerUseHistory();

    //------------------------------------------------------------------------------
    const [values, setValues] = useState({
        email: {
            value: props.user?.email || '',
            error: false,
            errorMessage: null
        },
        username: {
            value: props.user?.email?.split('@')[0] || '',
            error: false,
            errorMessage: null
        },
        password: {
            value: '',
            error: false,
            errorMessage: null
        },
        firstname: {
            value: props.user?.firstname || '',
            error: false,
            errorMessage: null
        },
        lastname: {
            value: props.user?.lastname || '',
            error: false,
            errorMessage: null
        },
        showPassword: false,
        globalError: false,
        submitted: false
    });

    //------------------------------------------------------------------------------
    const usernameFormatGlobalError = `Usernames can only consist of lowercase, capitals, alphanumeric characters, underscore and hyphens.\n
        It cannot be two underscores or two hypens in a row.\n
        It cannot have a underscore or hypen at the start or end.\n
        It cannot exceed 64 characters.`;

    //------------------------------------------------------------------------------
    const onChange = prop => (event, val) =>
    {
        if (prop === 'username' && val.length > 1)
        {
            return checkUsernameAllowed(val);
        }
        setValues({ ...values,
            [prop]: {
                value: val,
                error: false
            }
        });
    };


    //------------------------------------------------------------------------------
    const onCheckboxChange = (prop, val) =>
    {
        setValues({ ...values,
            [prop]: {
                value: val,
                error: false
            }
        });
    };

    //------------------------------------------------------------------------------
    const checkUsernameAllowed = newUsername =>
    {
        const isUsernameAllowed = /^[a-zA-Z0-9]+(?:[_-]?[a-zA-Z0-9])*$/.test(newUsername);

        setValues({ ...values,
            username: {
                value: newUsername,
                error: !isUsernameAllowed,
                errorMessage: isUsernameAllowed ? null : 'This username is not allowed.'
            },
            globalError: isUsernameAllowed ? false : usernameFormatGlobalError,
        });
    };

    //------------------------------------------------------------------------------
    const onSubmit = async event =>
    {
        event.preventDefault();
        setValues({ ...values, submitted: true });

        try
        {
            const userData = {
                email       : values.email.value,
                username    : values.username.value,
                password    : values.password.value,
                firstname   : values.firstname.value,
                lastname    : values.lastname.value
            };
            const user = await signupAPI(userData);
            history.push({
                pathname: '/signupConfirmEmail',
                state: { userDataFromSignUp: user }
            });
        }
        catch(error)
        {
            console.error(error);

            switch (error.errorNum)
            {
            case 0: {
                // Error: Can't send mail - all recipients were rejected: 501 5.1.6 Recipient addresses in single label domains not accepted
                setValues({ ...values,
                    email: {
                        value: values.email.value,
                        error: true,
                        errorMessage: 'Impossible to send an email.'
                    },
                    submitted: false
                });
                break;
            }
            case 2003: {
                setValues({ ...values,
                    globalError: 'This username is already taken.',
                    username: {
                        value: values.username.value,
                        error: true
                    },
                    submitted: false
                });
                break;
            }
            case 2004: {
                setValues({ ...values,
                    globalError: 'This email is already taken.',
                    email: {
                        value: values.email.value,
                        error: true
                    },
                    submitted: false
                });
                break;
            }
            case 2005: {
                setValues({ ...values,
                    globalError: usernameFormatGlobalError,
                    username: {
                        value: values.username.value,
                        error: true
                    },
                    submitted: false
                });
                break;
            }
            case 2006: {
                setValues({ ...values,
                    globalError: 'This email is not valid.',
                    email: {
                        value: values.email.value,
                        error: true
                    },
                    submitted: false
                });
                break;
            }
            default:
                setValues({ ...values,
                    globalError: `An error has occurred. (${error.errorNum})`,
                    submitted: false
                });
                break;
            }
        }
    };

    //------------------------------------------------------------------------------
    const onClickShowPassword = (event) =>
    {
        event.preventDefault();
        setValues({ ...values, showPassword: !values.showPassword });
    };

    //------------------------------------------------------------------------------
    const onMouseDownPassword = event =>
    {
        event.preventDefault();
    };

    //------------------------------------------------------------------------------
    return (
        <Layout>

            <SignInUpSwitch currentPage='signup' history={history} />

            <Box>
                <form onSubmit={onSubmit}>

                    <h1 className='hidden pb-4 text-title1'>
                        Create an account
                    </h1>

                    {props.user?.oauthProviderText &&
                        <p className='pt-3 text-color-feedback-warning'>
                            {t('account:signup from oauth account', { oauthProvider: props.user.oauthProviderText })}
                        </p>
                    }
                    {Boolean(values.globalError) &&
                        <p className='pt-3 text-color-feedback-warning'>
                            {values.globalError}
                        </p>
                    }

                    <div className='flex flex-col gap-3 w-full'>

                        <div className='flex gap-1 w-full'>
                            <ControlText
                                id='signup-firstname'
                                value={values.firstname.value}
                                onChange={onChange('firstname')}
                                label={t('account:First name')}
                                autoFocus
                                required
                                error={values.firstname.error}
                                helperText={values.firstname.errorMessage}
                                labelClassName='flex-1'
                            />
                            <ControlText
                                id='signup-lastname'
                                value={values.lastname.value}
                                onChange={onChange('lastname')}
                                label={t('account:Last name')}
                                required
                                error={values.lastname.error}
                                helperText={values.lastname.errorMessage}
                                labelClassName='flex-1'
                            />
                        </div>

                        <ControlText
                            id='signup-email'
                            value={values.email.value}
                            onChange={onChange('email')}
                            label={t('account:Email')}
                            required
                            type='email'
                            error={values.email.error}
                            helperText={values.email.errorMessage}
                        />

                        <ControlText
                            id='signup-username'
                            value={values.username.value}
                            onChange={onChange('username')}
                            label={t('account:Username')}
                            required
                            error={values.username.error}
                            helperText={values.username.errorMessage}
                            inputProps={{
                                autoComplete: 'username'
                            }}
                        />

                        <ControlText
                            id='signup-password'
                            value={values.password.value}
                            onChange={onChange('password')}
                            label={t('account:Password')}
                            required
                            type={values.showPassword ? 'text' : 'password'}
                            error={values.password.error}
                            inputProps={{
                                pattern: '^(?=.*?[a-zA-Z])(?=.*?[0-9]).{7,}$',
                                autoComplete: 'new-password'
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position='end'>
                                        <ButtonIcon
                                            className={values.showPassword ? 'fal fa-eye' : 'fal fa-eye-slash'}
                                            onClick={onClickShowPassword}
                                            onMouseDown={onMouseDownPassword}
                                        />
                                    </InputAdornment>
                                ),
                            }}
                            helperText='Use at least a letter, a numeral, and 7 characters.'
                        />

                        <Button
                            value={t('account:Sign up')}
                            type='submit'
                            color='primary'
                            size='large'
                            fullWidth
                            className='mt-5'
                            disabled={values.submitted}
                        />

                    </div>

                    {/* <p className='text-color-neutral-secondary'>
                        Already have an account?
                        {' '}
                        <Link to='/signin' className='link'>
                            Sign in
                        </Link>
                    </p> */}

                </form>
            </Box>
        </Layout>
    );
}

export default LoginForm;
