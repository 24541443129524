//--------------------------------------------------------------------------
import partition from '../../utils/partition';

//--------------------------------------------------------------------------
export const computeProjectMap = (data, workspaceMap, libraryUUID = null, initialLevel = 0) =>
{
    data.path               = '/';
    data.level              = initialLevel;
    data.updateTrigger      = 0;
    data.folders            = data.folders.sort((a, b) => a.name.localeCompare(b.name));
    data.libraryUUID        = libraryUUID || null;
    data.SystemFolders      = {};

    const projectMap        = new Map();
    projectMap.set(data.path, data);
    projectMap.set(data.uuid, data);
    workspaceMap.set(data.workspaceUUID, data);

    const [ rootFolders, excludedSystemFolders ] = partition(
        data.folders,
        folder =>
        {
            folder.parentPath = data.path;

            if(folder.isSystem)
            {
                data.SystemFolders[folder.name] = folder;

                // Exclude trash from root folders
                if(folder.name === 'Trash')
                {
                    folder.level = initialLevel;
                    return false;
                }
            }

            folder.level = initialLevel + 1;
            return true;
        }
    );

    data.folders        = rootFolders;
    const queue         = [...rootFolders, ...excludedSystemFolders];
    let currentFolder   = null;

    while(currentFolder = queue.shift())
    {
        currentFolder.updateTrigger = 0;
        currentFolder.path          = currentFolder.parentPath + currentFolder.name + '/';
        currentFolder.folders       = currentFolder.folders.sort((a, b) => a.name.localeCompare(b.name));
        currentFolder.libraryUUID   = libraryUUID || null;

        projectMap.set(currentFolder.uuid, currentFolder);
        projectMap.set(currentFolder.path, currentFolder);
        workspaceMap.set(currentFolder.workspaceUUID, currentFolder);

        for(const folder of currentFolder.folders)
        {
            folder.parentPath   = currentFolder.path;
            folder.level        = currentFolder.level + 1;
            queue.push(folder);
        }
    }

    return projectMap;
};

//--------------------------------------------------------------------------
export const getUserStateOfProject = (projectUUID) =>
{
    const json = localStorage.getItem(`3dverse-project-${projectUUID}`);
    if(!json)
    {
        return {};
    }

    return JSON.parse(json);
}

//--------------------------------------------------------------------------
export const setUserStateOfProject = (projectUUID, newState) =>
{
    localStorage.setItem(
        `3dverse-project-${projectUUID}`,
        JSON.stringify(newState)
    );
}

//--------------------------------------------------------------------------
export const mergeUserStateOfProject = (projectUUID, newValues) =>
{
    const previousState = getUserStateOfProject(projectUUID);
    setUserStateOfProject(projectUUID, {...previousState, ...newValues});
}

//------------------------------------------------------------------------------
export const computeProjectPath = (options = {}) =>
{
    const { projectUUID, folderUUID, libraryUUID, assetDetails } = options;

    const _projectPage  = options.projectPage
                        ? options.projectPage
                        : (libraryUUID ? 'library' : 'content');

    return {
        pathname : `/p/${projectUUID
                    + (_projectPage ? '/'+_projectPage : '')
                    + (libraryUUID ? '/'+libraryUUID : '')
                    + (folderUUID  ? '/'+folderUUID : '')}`,
        search   :  assetDetails
                    ? '?'
                        + 'assetUUID='+assetDetails.uuid+'&assetType='+assetDetails.assetType
                        + (assetDetails.isSourceFile  ? '&isSourceFile=true' : '')
                    : ''
    };
}

//------------------------------------------------------------------------------
export const getProjectPath = (projectUUID) =>
{
    const userState = getUserStateOfProject(projectUUID);

    return userState.history
        // nullify projectPage to ensure it has been removed from user state of project
        ? computeProjectPath({projectUUID, ...userState.history, projectPage : null})
        : `/p/${projectUUID}`;
}
