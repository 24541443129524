
//------------------------------------------------------------------------------
export default function Can(permissionsList, requiredPermission)
{
    if (!permissionsList) return false;
    return permissionsList.includes(requiredPermission);
}

//------------------------------------------------------------------------------
export function canAtLeast(permissionsList, requiredPermissions)
{
    if (!permissionsList) return false;
    return requiredPermissions.some(
        requiredPermission => Can(permissionsList, requiredPermission)
    );
}
