import React                    from 'react';
import { Stack, Text }          from '../../../../../designSystem/components';
import { ErrorSectionStyled }   from './style';


//------------------------------------------------------------------------------
export default function BetaProgramSection(props)
{
    //------------------------------------------------------------------------------
    return (
        <ErrorSectionStyled>
            <Stack horizontal style={{ width: '100%' }}>
                <Text format='title2' as='h1' style={{ color: 'red', flex: 1 }}>
                    Error {props.status}
                </Text>
            </Stack>
            <Text format='body2'>
                {props.message}
            </Text>
        </ErrorSectionStyled>
    );
}