//------------------------------------------------------------------------------
import React, { useContext, useState, useEffect } from 'react';
import { useTranslation }       from 'react-i18next';

//------------------------------------------------------------------------------
import { Loader, Tab, Tabs, TabPanel, Text
}                               from '../../../designSystem/components';

//------------------------------------------------------------------------------
import Error                    from './Conversions/ErrorSection';
import NotificationContext      from '../../notifications/notificationContext';

//------------------------------------------------------------------------------
import TaskTable 			    from './Conversions/TaskTable';
import TaskDetail			    from './Conversions/TaskDetail';
import ItemLog				    from './Conversions/ItemLog';


//------------------------------------------------------------------------------
export default function Conversions(props)
{
    //------------------------------------------------------------------------------
    const notificationContext                               = useContext(NotificationContext);

    //------------------------------------------------------------------------------
    const { t }                                             = useTranslation();

    //------------------------------------------------------------------------------
    // Load rowPerPage settings from localStorage
    const rowPerPageDefault                                 = JSON.parse(localStorage.getItem('3dverse/rowPerPage')) || {};

    //------------------------------------------------------------------------------
    const tabList = [ 'In progress', 'History' ];
    const [currentTabIndex, setCurrentTabIndex]             = useState(0);
    //------------------------------------------------------------------------------
    const [inProgressPage, setInProgressPage]               = useState(0);
    const [inProgressOrder, setInProgressOrder]             = useState('asc');
    const [inProgressOrderBy, setInProgressOrderBy]         = useState('creationTimestamp');
    const [inProgressRowPerPage, setInProgressRowPerPage]   = useState(rowPerPageDefault.inProgress || 5);

    //------------------------------------------------------------------------------
    const [historyPage, setHistoryPage]                     = useState(0);
    const [historyOrder, setHistoryOrder]                   = useState('asc');
    const [historyOrderBy, setHistoryOrderBy]               = useState('creationTimestamp');
    const [historyRowPerPage, setHistoryRowPerPage]         = useState(rowPerPageDefault.history || 5);

    //------------------------------------------------------------------------------
    // Save rowPerPage settings in localStorage
    useEffect(() =>
    {
        const rowPerPage = {
            inProgress 	: inProgressRowPerPage,
            history		: historyRowPerPage
        };
        localStorage.setItem('3dverse/rowPerPage', JSON.stringify(rowPerPage));

    }, [inProgressRowPerPage, historyRowPerPage]);

    //------------------------------------------------------------------------------
    // TODO : Use a Switch component to route to the appropriate app
    if(props.match.params.uuid1)
    {
        const taskUUID = props.match.params.uuid1;
        if(props.match.params.uuid2)
        {
            const itemUUID = props.match.params.uuid2;
            return (
                <ItemLog
                    projectUUID={props.currentProject.uuid}
                    taskUUID={taskUUID}
                    itemUUID={itemUUID}
                    className={props.className}
                    style={props.style}
                />
            );
        }
        else
        {
            return (
                <TaskDetail
                    projectUUID={props.currentProject.uuid}
                    taskUUID={taskUUID}
                    className={props.className}
                    style={props.style}
                />
            );
        }
    }

    //------------------------------------------------------------------------------
    return (
        <section
            className={`wrapper pt-4 sm:pt-6 pb-10 ${props.className ?? props.className} relative h-full`}
            style={props.style}
        >
            <header className='bg-color-ground sticky top-0 z-10'>
                <Text format='title1'>
                    {t('conversion:Conversions')}
                </Text>

                <Tabs className='my-4'>
                    {tabList.map((tab, i) =>
                        <Tab
                            key={i}
                            label={tab}
                            active={currentTabIndex === i}
                            onClick={() => setCurrentTabIndex(i)}
                        />
                    )}
                </Tabs>
            </header>

            {notificationContext.taskLoading ?
                <Loader centered />
                :
                notificationContext.taskError !== null ?
                    <Error {...notificationContext.taskError} />
                    :
                    <div className='pb-6'>
                        <TabPanel index={0} value={currentTabIndex}>
                            <TaskTable
                                title='In progress'
                                tasks={notificationContext.tasks}
                                page={inProgressPage}
                                setPage={setInProgressPage}
                                order={inProgressOrder}
                                setOrder={setInProgressOrder}
                                orderBy={inProgressOrderBy}
                                setOrderBy={setInProgressOrderBy}
                                rowsPerPage={inProgressRowPerPage}
                                setRowsPerPage={setInProgressRowPerPage}
                                projectUUID={props.currentProject.uuid}
                            />
                        </TabPanel>

                        <TabPanel index={1} value={currentTabIndex}>
                            <TaskTable
                                title='History'
                                tasks={notificationContext.completedTasks}
                                page={historyPage}
                                setPage={setHistoryPage}
                                order={historyOrder}
                                setOrder={setHistoryOrder}
                                orderBy={historyOrderBy}
                                setOrderBy={setHistoryOrderBy}
                                rowsPerPage={historyRowPerPage}
                                setRowsPerPage={setHistoryRowPerPage}
                                projectUUID={props.currentProject.uuid}
                            />
                        </TabPanel>
                    </div>
            }

        </section>
    );
}
