import React, { Fragment, useContext, useEffect, useState } from 'react';
import { ThemeContext }                                     from 'styled-components';
import Moment                                               from 'react-moment';
import { useSnackbar }                                      from 'notistack';

import { Avatar, Button, ButtonGroup, ButtonIcon, ControlTextToggle, Display, Icon, Text, Tooltip
}                                                           from '../../../designSystem/components';
import { plural as p, computeAssetDescriptionDisplay, useCancellablePromise
}                                                           from '../../../designSystem/utils';
import copyToClipboard                                      from '../../../utils/copyToClipboard';
import Can                                                  from '../../../utils/permissions';
import assetUtils                                           from '../../../utils/assetUtils';
import TileAsset                                            from '../../../components/TileAsset';
import SourceFileAssetTag                                   from '../../../components/SourceFileAssetTag';
import SessionTile                                          from '../../session/SessionTile';
import { getAssetProjectAPI }                               from '../../projects/api';
import UserContext                                          from '../../account/userContext';
import { getAssetInfoAPI, renameAssetAPI, renameSourceFileAPI, getPointsOfInterestAPI, getRefAPI, duplicateAssetAPI, getDepGraphAPI
}                                                           from '../api';
import AssetDetailsAdvanced                                 from '../AssetDetailsAdvanced';
import ExportMesh                                           from '../ExportMesh';
import SoundPlayer                                          from '../SoundPlayer';
import ModuleRepository                                     from '../ModuleRepository';
import ShaderDetails                                        from '../ShaderDetails';

//------------------------------------------------------------------------------
const MAX_REF = 30;

//------------------------------------------------------------------------------
function AssetDetails(props)
{
    //------------------------------------------------------------------------------
    const { assetDescription, canEdit, permissions }    = props;

    const assetUUID                                     = props.asset?.uuid;
    const assetType                                     = props.asset?.assetType;

    const isScene                                       = assetType === 'scene';
    const hasThumbnail                                  = props.asset && !(props.asset.hasThumbnail === false); // Undefined is considered as true.
    const shouldDisplayThumbnail                        = (isScene || assetType === 'texture') && hasThumbnail;

    //------------------------------------------------------------------------------
    const user                                          = useContext(UserContext);
    const theme                                         = useContext(ThemeContext);
    const { enqueueSnackbar }                           = useSnackbar();
    const { cancellablePromise, cancelAllPromises }     = useCancellablePromise();

    //------------------------------------------------------------------------------
    const [assetInfo, setAssetInfo]                     = useState(null);
    const [thumbnails, setThumbnails]                   = useState(null);
    const [references, setReferences]                   = useState(null);
    const [dependencies, setDependencies]               = useState(null);
    const [descriptionDisplay, setDescriptionDisplay]   = useState([]);
    const [assetSessions, setAssetSessions]             = useState(null);

    //------------------------------------------------------------------------------
    useEffect(() =>
    {
        //------------------------------------------------------------------------------
        setNewName(null);
        setAssetInfo(null);
        setThumbnails(null);
        setReferences(null);
        setDependencies(null);
        setDescriptionDisplay([]);
        setAssetSessions(null);
        setCarouselIndex(0);

        //------------------------------------------------------------------------------
        cancelAllPromises();

        //------------------------------------------------------------------------------
        const fetchInfo = async () =>
        {
            try
            {
                const _assetInfo = await getAssetInfoAPI(assetUUID, assetType);

                // Set AssetInfo
                if (_assetInfo.contributors)
                {
                    _assetInfo.contributors = _assetInfo.contributors.sort((a, b) => b.contributions - a.contributions);
                }
                _assetInfo.assetType = assetType;
                setAssetInfo(_assetInfo);

                // set AssetSessions
                const folderSessions = props.sessionsContext?.sessions[_assetInfo.workspace.uuid];
                if(folderSessions && folderSessions[assetUUID])
                {
                    setAssetSessions(folderSessions[assetUUID].sessions);
                }

            }
            catch(error)
            {} // Promise canceled
        };

        //------------------------------------------------------------------------------
        const fetchThumbnails = async () =>
        {
            try
            {
                const appURL        = assetType === 'scene' ? '/app-launcher/default/' + assetUUID : '';
                const _thumbnails   = [];

                if(hasThumbnail)
                {
                    _thumbnails.push({
                        uuid          : assetUUID,
                        title         : '',
                        description   : '',
                        url           : apiGatewayURL + '/asset/thumbnail?assetUUID=' + assetUUID + '&token=' + apiToken,
                        appURL        : appURL,
                    });
                }

                if(assetType === 'scene')
                {
                    const pointOfInterests  = await cancellablePromise(getPointsOfInterestAPI(assetUUID));

                    _thumbnails.splice(
                        -1,
                        0,
                        ...pointOfInterests.map((pointOfInterest) =>
                        {
                            return {
                                ...pointOfInterest,
                                url           : apiGatewayURL + '/scene/pointOfInterestThumbnail?sceneUUID=' + assetUUID + '&uuid=' + pointOfInterest.uuid + '&token=' + apiToken,
                                appURL        : appURL + '?pointOfInterestUUID=' + pointOfInterest.uuid,
                            };
                        })
                    )
                }

                setThumbnails(_thumbnails.length > 0 ? _thumbnails : null);
            }
            catch(error)
            {} // Promise canceled
        };

        //------------------------------------------------------------------------------
        const fetchRef = async () =>
        {
            try
            {
                const { references : _references } = await cancellablePromise(getRefAPI(assetType, assetUUID, {count : MAX_REF}));
                const referenceGroups = Object.entries(_references).map(([assetListName, references]) =>
                    ({
                        assetListName,
                        assetType               : assetUtils.getAssetTypeFromAssetListName(assetListName),
                        assets                  : references
                                                    .filter(a => !a.unaccessible)
                                                    .map(a => {a.hasThumbnail = Boolean(a.hasThumbnail); return a;}),
                        unaccessibleAssetCount  : references.filter(a => a.unaccessible).length,
                        count                   : references.length,
                    }));

                setReferences(referenceGroups);
            }
            catch(error)
            {} // Promise canceled
        };

        //------------------------------------------------------------------------------
        const fetchDep = async () =>
        {
            try
            {
                const dependencies = await cancellablePromise(
                    getDepGraphAPI(assetType, assetUUID, { hasThumbnail : true, count: true }, hasThumbnail)
                );
                setDependencies(dependencies);
            }
            catch(error)
            {} // Promise canceled
        };

        //------------------------------------------------------------------------------
        if (assetUUID && assetType)
        {
            fetchInfo();
            fetchRef();
            fetchDep();

            if (assetType === 'scene' || assetType === 'texture')
            {
                fetchThumbnails();
            }
        }
        else if(props.sourceFile)
        {
            setAssetInfo(
            {
                creationTimestamp   : props.sourceFile.creationTimestamp,
                uploadedBy          : {
                    userUUID : props.sourceFile.owner.uuid,
                    username : props.sourceFile.owner.username
                },
                workspace           : props.sourceFile.workspace
            });
        }

    }, [props.asset]);

    //------------------------------------------------------------------------------
    useEffect(() =>
    {
        if(!assetDescription)
        {
            return;
        }

        const descriptionDisplay = computeAssetDescriptionDisplay(assetType, assetDescription);
        setDescriptionDisplay(descriptionDisplay);
    }, [props.updateTrigger, assetDescription, props.sessionsContext]);

    //------------------------------------------------------------------------------
    // Carousel Image

    //------------------------------------------------------------------------------
    const [carouselIndex, setCarouselIndex] = useState(0);

    //------------------------------------------------------------------------------
    const openMobileScene = (assetUUID) =>
    {
        const url       = `/app-launcher/m.viewer/${assetUUID}`;
        const launcher  = window.open(url, props.linkTarget ? props.linkTarget : '_blank');
        launcher.focus();
    };

    //------------------------------------------------------------------------------
    // Rename Asset

    //------------------------------------------------------------------------------
    const [newName, setNewName] = useState(null);

    //------------------------------------------------------------------------------
    const onChangeName = (value) =>
    {
        if(typeof value === 'undefined')
        {
            setNewName(null);
        }
        else
        {
            setNewName(value);
        }
    };

    //------------------------------------------------------------------------------
    const onSubmitName = async (value) =>
    {
        if (value !== '' && value !== assetName)
        {
            if(props.sourceFile)
            {
                await renameSourceFileAPI(props.sourceFile.uuid, value);
            }
            else
            {
                await renameAssetAPI(assetType, assetUUID, value);
            }
        }
        else
        {
            setNewName(assetName);
        }
    };

    //------------------------------------------------------------------------------
    // Move Asset

    //------------------------------------------------------------------------------
    const [moveAssetPopover, setMoveAssetPopover] = useState(null);

    //------------------------------------------------------------------------------
    const toggleMoveAssetPopover = (event) =>
    {
        setMoveAssetPopover( moveAssetPopover ? null : event.currentTarget );
    };

    //------------------------------------------------------------------------------
    // Duplicate asset

    //------------------------------------------------------------------------------
    const duplicateAsset = async () =>
    {
        const newName = `Copy of ${props.asset.name || assetInfo?.name || assetType}`;
        const { assetUUID: duplicatedAssetUUID } = await duplicateAssetAPI(assetUUID, assetType, assetInfo.workspace.uuid, newName);

        props.goTo(
        {
            assetDetails: {
                uuid : duplicatedAssetUUID,
                assetType,
                isAdvanced : Boolean(props.assetAdvanced)
            }
        });

        props.setTileInRenameMode({ uuid: duplicatedAssetUUID, assetType });
    };

    //------------------------------------------------------------------------------
    // Copy Asset UUID

    //------------------------------------------------------------------------------
    const copyUUID = (label, uuid) =>
    {
        copyToClipboard(uuid);
        enqueueSnackbar('👍 ' + label + ' copied');
    };

    //--------------------------------------------------------------------------
    const downloadSource = () =>
    {
        const url = `${apiGatewayURL}/asset/downloadSourceFile/${props.sourceFile.uuid}?token=${apiToken}`;
        window.open(url, '_blank');
    };

    //--------------------------------------------------------------------------
    const generateAssetPackageURL = () =>
    {
        const options = {
            packagePublicDependencies : props.isProjectPublic
        };

        return `${apiGatewayURL}/asset/package/${assetType}/${assetUUID}?options=${encodeURI(JSON.stringify(options))}&token=${apiToken}`;
    };

    //--------------------------------------------------------------------------
    const packageAsset = () =>
    {
        window.open(generateAssetPackageURL(), '_blank');
    };

    //------------------------------------------------------------------------------
    // Show Direct Dependency

    //------------------------------------------------------------------------------
    const [ showDirectDependency, setShowDirectDependency ] = useState(null);

    //------------------------------------------------------------------------------
    useEffect(() =>
    {
        const _showDirectDependency = JSON.parse(localStorage.getItem('3dverse/showDirectDependency'));
        setShowDirectDependency(_showDirectDependency);
    }, []);

    //------------------------------------------------------------------------------
    const toggleShowDirectDependency = (toggle) =>
    {
        const _toggle = toggle || !showDirectDependency;
        localStorage.setItem('3dverse/showDirectDependency', _toggle);
        setShowDirectDependency(_toggle);
    }

    //------------------------------------------------------------------------------
    const workingFolder     = props.currentFolder || props.currentProject;
    const isSameFolder      = !assetInfo || (!props.isInSearchMode && assetInfo.workspace.uuid === workingFolder.workspaceUUID);
    const assetFolder       = assetInfo && props.workspaceMap.get(assetInfo.workspace.uuid);
    const isSameProject     = Boolean(!assetInfo || assetFolder);
    const assetFolderPath   = assetFolder &&
        ((assetFolder.libraryUUID ? '/libraries/' + props.libraryMap.get(assetFolder.libraryUUID)?.name : '') + assetFolder.path);

    //------------------------------------------------------------------------------
    const openParentFolder = async () =>
    {
        const assetDetails  = props.sourceFile
                            ? { uuid : props.sourceFile.uuid, assetType, isSourceFile : true }
                            : { uuid : assetUUID, assetType }

        props.setSearchString('');
        if(isSameProject)
        {
            props.goTo({ folderUUID: assetFolder.uuid, libraryUUID: assetFolder.libraryUUID, sourceFileUUID : null, assetDetails});
            return;
        }

        const { uuid:projectUUID, folderUUID } = await getAssetProjectAPI(assetType, assetUUID);
        props.goTo({ projectUUID, folderUUID, libraryUUID : null, sourceFileUUID : null, assetDetails });
    }

    //------------------------------------------------------------------------------
    const assetName                 = props.asset?.name || assetInfo?.name || props.sourceFile?.name || 'No name';
    const isMaterial                = assetType === 'material' || assetType === 'cubemap' || assetType === 'volumeMaterial';
    const canBeOpen                 = isScene || assetUtils.hasGraph(assetType) || isMaterial || assetType === 'module';
    const hasReferences             = Boolean(references && references.length > 0);
    const referenceCount            = references && references.reduce((acc, group) => acc + group.count, 0);
    const dependencyCount           = dependencies && dependencies.dependencyCount;

    //------------------------------------------------------------------------------
    const canRename                 = Can(permissions, 'editScene');
    const canDelete                 = Can(permissions, 'deleteScene');

    //------------------------------------------------------------------------------
    return (
        <Fragment>

            <div className={`layout w-full overflow-x-visible ${Boolean(props.assetAdvanced) ? 'max-w-md' : ''}`}>

                <div>
                    <header
                        className={`
                            header px-5 pt-4 pb-3 border-b border-color-tertiary
                            ${shouldDisplayThumbnail ? 'flex justify-end bg-cover bg-center imageOverlay' : ''}
                        `}
                        style={shouldDisplayThumbnail ?
                            {
                                height: '15rem',
                                backgroundImage: thumbnails ? `url('${thumbnails[carouselIndex].url}&size=large')` : ''
                            } : {}}
                    >
                        <div className='flex items-start w-full z-10'>
                            <div className='flex-1 flex flex-col items-start justify-end w-full h-full'>
                                <div
                                    className='flex items-center gap-1 mb-1'
                                    hidden={shouldDisplayThumbnail}
                                    style={{ color: theme.assets[assetType]?.color }}
                                >
                                    <Icon
                                        size='xsmall'
                                        name='asset'
                                        assetType={assetType}
                                    />
                                    <Text format='body4' className='capitalize'>
                                        {assetType}
                                    </Text>
                                </div>
                                {canRename ?
                                    <ControlTextToggle
                                        value={newName !== null ? newName : assetName}
                                        onChange={(_, value) => onChangeName(value)}
                                        onSubmit={(_, value) => onSubmitName(value)}
                                        format='title2'
                                        placeholder='Asset Name'
                                    />
                                    :
                                    <Text format='title1' style={{ wordBreak: 'break-word' }}>
                                        {assetName}
                                    </Text>
                                }
                            </div>
                            <div className='relative flex flex-col items-end'>
                                <ButtonIcon
                                    onClick={event => {event.stopPropagation(); props.onClose();}}
                                    className='fal fa-times'
                                    size='small'
                                    color='secondary'
                                />
                                {/* {props.assetAdvanced &&
                                    <ButtonIcon
                                        onClick={props.toggleAssetAdvanced}
                                        className='fal fa-arrow-right absolute -top-5 left-6 z-10'
                                        size='small'
                                        color='secondary'
                                    />
                                } */}
                            </div>
                        </div>
                    </header>

                    {thumbnails?.length > 1 &&
                        <ul className='flex bg-color-underground'>
                            {thumbnails.map((point, i) => (
                                <li key={i}>
                                    <Tooltip title={point.title}>
                                        <button
                                            onClick={() => setCarouselIndex(i)}
                                            className={`w-5 h-5 bg-cover bg-center overflow-hidden border-b border-transparent ${i === carouselIndex ? 'border-accent' : ''}`}
                                            style={{ backgroundImage: `url(${point.url}&size=small)` }}
                                        />
                                    </Tooltip>
                                </li>
                            ))}
                        </ul>
                    }
                </div>

                {/* Sound Player */}
                {assetType === 'sound' &&
                    <SoundPlayer assetUUID={assetUUID} />
                }

                <div className='flex flex-col pb-7 overflow-auto'>
                    <div className='border-b border-color-tertiary'>
                        <div className='flex justify-between gap-2 py-4 px-5'>
                            <div className='flex gap-1'>
                                {canBeOpen &&
                                    <Button
                                        value={assetType === 'scene' ? 'Create a session' : 'Open ' + assetType}
                                        onClick={() => props.openAssetEditor(assetType, assetUUID)}
                                        backgroundColor={theme.assets[assetType]?.color}
                                        backgroundColorHover={`${theme.assets[assetType]?.color}AA`}
                                        fontColor='var(--color-bg-ground)'
                                        size='small'
                                    />
                                }

                                {
                                    !isSameFolder && isSameProject &&
                                    <Button
                                        value='Open folder'
                                        onClick={openParentFolder}
                                        startIcon='fal fa-folder'
                                        title={assetFolderPath}
                                        size='small'
                                        color='secondary'
                                    />
                                }

                                {props.asset && props.assetSelection?.length > 0 &&
                                    <Button
                                        value={props.assetSelection.find(a => a.uuid === assetUUID) ? 'Unselect' : 'Select'}
                                        onClick={() => props.toggleAssetSelection(props.asset)}
                                        color='secondary'
                                        size='small'
                                    />
                                }
                            </div>

                            <div className='flex gap-1'>
                                {/* {canMove &&
                                    <Fragment>
                                        {/* TODO: onClick, check if there are more than 1 workspace */}{/*
                                        <Button
                                            value='Move'
                                            onClick={toggleMoveAssetPopover}
                                            size='xsmall'
                                        />
                                        {moveAssetPopover &&
                                            <AssetMovePopover
                                                open
                                                user={user}
                                                scenes={{ [props.currentProject.uuid] : [{ uuid: sceneUUID, name, workspaceUUID }] }}
                                                onClose={() => setMoveAssetPopover(false)}
                                                moveScenes={moveScenes}
                                            />
                                        }
                                    </Fragment>
                                } */}

                                {
                                    canBeOpen && assetType === 'scene' &&
                                    <ButtonIcon
                                        className='fal fa-head-side-goggles'
                                        onClick={() => props.openAssetEditor(assetType, assetUUID, { xrSession: "immersive-ar" }, 'm.viewer')}
                                        tooltipTitle={'Open with AR'}
                                        size='small'
                                    />
                                }

                                {
                                    props.asset && Can(permissions, 'duplicateAsset') &&
                                    <ButtonIcon
                                        className='fal fa-clone'
                                        onClick={duplicateAsset}
                                        tooltipTitle='Duplicate'
                                        disabled={Boolean(!assetInfo)} // Disable while asset info are unavailable
                                        size='small'
                                    />
                                }


                                {canDelete &&
                                    <ButtonIcon
                                        className='fal fa-trash'
                                        onClick={() =>
                                            props.deleteAssets(
                                                [
                                                    props.sourceFile
                                                    ? { uuid : props.sourceFile.uuid, assetType, isSourceFile : true, name : assetName }
                                                    : { uuid : assetUUID, name : assetName, assetType }
                                                ],
                                                { callback: props.onClose }
                                            )
                                        }
                                        tooltipTitle={hasReferences ? 'You can\'t delete this ' + assetType + ' because it\'s referenced by other assets.' : 'Delete this ' + (props.sourceFile ? 'source file' : assetType)}
                                        // active={Boolean(deleteAssetModal)}
                                        disabled={hasReferences}
                                        size='small'
                                    />
                                }

                                {
                                    props.asset &&
                                    <ButtonIcon
                                        className='fal fa-info-circle'
                                        onClick={props.toggleAssetAdvanced}
                                        tooltipTitle={`${props.assetAdvanced ? 'Less' : 'More'} details`}
                                        color={props.assetAdvanced ? 'primary' : 'tertiary'}
                                        size='small'
                                    />
                                }
                            </div>
                        </div>

                        {
                            !isSameProject &&
                            <section className='flex flex-wrap items-center bg-color-underground text-color-neutral-primary px-2 pt-2'>
                                <Text format='body2' className='mb-2'>
                                    <Icon className='fas fa-exclamation-triangle' size='large'/>
                                    This asset does not belong to your project
                                </Text>
                                <Button
                                    value='Open project'
                                    onClick={openParentFolder}
                                    startIcon='fal fa-folder'
                                    className='ml-4 mb-2'
                                    size='small'
                                    color='secondary'
                                />
                            </section>
                        }

                        {assetType === 'module' && Can(permissions, 'editRepository') &&
                            <div className='pb-4 px-5'>
                                <ModuleRepository
                                    assetUUID={assetUUID}
                                    onOpen={() => props.goTo({ assetDetails: { uuid: assetUUID, assetType, assetAdvanced: 'GIT_PROVIDER' } })}
                                    isAdvanced={Boolean(props.assetAdvanced)}
                                    assetDescription={assetDescription}
                                    user={props.user}
                                />
                            </div>
                        }
                    </div>

                    {assetType === 'shader' &&
                        <section className='relative w-full py-3 px-5 border-b border-color-tertiary'>
                            <ShaderDetails
                                assetUUID={assetUUID}
                                assetDescription={assetDescription}
                                user={props.user}
                            />
                        </section>
                    }

                    {/* Sessions */}
                    {assetSessions && Object.keys(assetSessions) &&
                        <section className='w-full py-3 px-5 border-b border-color-tertiary'>
                            <header className='mb-1'>
                                <Text format='body3' as='h1'>
                                    Join a session
                                </Text>
                            </header>
                            <ul>
                                {Object.keys(assetSessions).map(s =>
                                    <li key={assetSessions[s].uuid}>
                                        <SessionTile {...assetSessions[s]} sceneUUID={assetSessions[s].uuid} />
                                    </li>
                                )}
                            </ul>
                        </section>
                    }

                    {
                        /* Source File */
                        props.sourceFile &&
                        <section className='relative w-full py-3 px-5 border-b border-color-tertiary'>
                            <div className='flex justify-between items-center'>
                                <Text format='body3' as='h1'>
                                    From Source File
                                </Text>
                                <div className='flex gap-2'>
                                    {props.sourceFile.assetCount > 1 &&
                                        <SourceFileAssetTag
                                            sourceFileUUID={props.sourceFile.uuid}
                                            sourceFileAssetCount={props.sourceFile.assetCount}
                                            assetType={assetType}
                                            goTo={props.goTo}
                                            size='small'
                                        />
                                    }
                                    <ButtonIcon
                                        className='fal fa-download'
                                        onClick={downloadSource}
                                        // tooltipTitle={!props.sourceFile ? 'You can\'t download this ' + assetType + ' because it wasn\'t converted from a source file.' : 'Download source file'}
                                        tooltipTitle='Download source file'
                                        // disabled={!props.sourceFile}
                                        size='small'
                                    />
                                </div>
                            </div>
                        </section>
                    }

                    {
                        /* Contributors */
                        props.asset &&
                        <section className='relative w-full py-3 px-5 border-b border-color-tertiary'>
                            <header className='mb-1'>
                                <Text format='body3' as='h1' color={assetInfo?.contributors?.length > 0 ? 'primary' : 'secondary'}>
                                    {assetInfo?.contributors?.length > 1 && `${assetInfo.contributors.length} `}
                                    Contributors
                                </Text>
                            </header>
                            {
                                assetInfo ?
                                <Fragment>
                                    {assetInfo.contributors?.length > 0 ?
                                        <div className='flex flex-col mt-2'>
                                            <ul className='grid gap-2' style={{ gridTemplateColumns: 'repeat(auto-fit, minmax(100px, .5fr))' }}>
                                                {assetInfo.contributors.map(contributor =>
                                                    <li key={contributor.userUUID}>
                                                        <Avatar
                                                            uuid={contributor.userUUID}
                                                            username={contributor.username}
                                                            size='small'
                                                            circleStyle={props.user?.uuid === contributor.userUUID}
                                                            color='var(--color-accent)'
                                                            backgroundColor='transparent'
                                                            reversed
                                                            displayName
                                                            className='w-full'
                                                        />
                                                    </li>
                                                )}
                                            </ul>
                                        </div>
                                        :
                                        <Text format='body4' color='tertiary'>
                                            None
                                        </Text>
                                    }
                                </Fragment>
                                :
                                <Text format='body4' color='tertiary'>
                                    Loading...
                                </Text>
                            }
                        </section>
                    }

                    {
                        props.asset &&
                        <Fragment>
                            {/* References */}
                            <section className='relative w-full py-3 px-5 border-b border-color-tertiary'>
                                <header className='mb-1'>
                                    <Text format='body3' as='h1' color={referenceCount > 0 ? 'primary' : 'secondary'}>
                                        {referenceCount == MAX_REF ? 'More than ' : ''}
                                        {referenceCount > 1 ? referenceCount + ' ' : ''}
                                        Referenced by
                                    </Text>
                                </header>
                                {references ?
                                    <Fragment>
                                        {referenceCount > 0 ?
                                            <ul className='flex flex-col gap-3 mt-2'>
                                                {references.map(({ assetListName, assetType, assets, count, unaccessibleAssetCount }) =>
                                                    <li key={'rep' + assetListName}>
                                                        {references.length > 1 &&
                                                            <Text format='body4' as='h1' className='uppercase mb-1'>
                                                                {count} <span className='text-color-neutral-secondary'>{count > 1 ? assetListName : assetType}</span>
                                                            </Text>
                                                        }
                                                        <ul className='flex flex-col gap-1px w-full'>
                                                            {unaccessibleAssetCount > 0 &&
                                                                <li>
                                                                    <Button
                                                                        color='secondary'
                                                                        size='small'
                                                                        contentAlign='left'
                                                                        className='pt-1 pb-2'
                                                                        valueClassName='text-xs text-ellipsis'
                                                                        fullWidth
                                                                        disabled
                                                                        startIcon='fal fa-empty-set'
                                                                        title={`${unaccessibleAssetCount} unaccessible ${unaccessibleAssetCount > 1 ? assetListName : assetType}`}
                                                                    >
                                                                        <b>{unaccessibleAssetCount}</b>&nbsp;unaccessible {unaccessibleAssetCount > 1 ? assetListName : assetType}
                                                                    </Button>
                                                                </li>
                                                            }
                                                            {assets.map(asset =>
                                                                <li key={asset.uuid}>
                                                                    <TileAsset
                                                                        onClick={() => props.goTo(
                                                                            {
                                                                                assetDetails: {
                                                                                    uuid : asset.uuid,
                                                                                    assetType,
                                                                                    isAdvanced : Boolean(props.assetAdvanced)
                                                                                }
                                                                            })}
                                                                        goTo={props.goTo}
                                                                        uuid={asset.uuid}
                                                                        assetType={assetType}
                                                                        name={asset.name}
                                                                        hasThumbnail={asset.hasThumbnail}
                                                                        displayMode='LIST'
                                                                        style={{ height: 'auto!important' }}
                                                                    />
                                                                </li>
                                                            )}
                                                        </ul>
                                                    </li>
                                                )}
                                            </ul>
                                            :
                                            <Text format='body4' color='tertiary'>
                                                None
                                            </Text>
                                        }
                                    </Fragment>
                                    :
                                    <Text format='body4' color='tertiary'>
                                        Loading...
                                    </Text>
                                }
                            </section>

                            {/* Dependencies */}
                            {assetUtils.couldHaveDependencies(assetType) &&
                                <section className='relative w-full py-3 px-5 border-b border-color-tertiary'>
                                    <header className='flex items-baseline justify-between mb-1'>
                                        <Text format='body3' as='h1' color={dependencyCount > 0 ? 'primary' : 'secondary'}>
                                            {dependencyCount > 1 ? dependencyCount + ' ' : ''}
                                            Dependencies
                                        </Text>
                                        {dependencyCount > 0 &&
                                            <div className='flex gap-1'>
                                                <ButtonGroup outlined>
                                                    <Button
                                                        value='All'
                                                        onClick={() => toggleShowDirectDependency(false)}
                                                        size='xsmall'
                                                        color={!showDirectDependency ? 'primary' : 'tertiary'}
                                                    />
                                                    <Button
                                                        value='Direct'
                                                        onClick={() => toggleShowDirectDependency(true)}
                                                        size='xsmall'
                                                        color={showDirectDependency ? 'primary' : 'tertiary'}
                                                    />
                                                </ButtonGroup>
                                                <ButtonIcon
                                                    title='Open dependency graph'
                                                    className='fal fa-project-diagram'
                                                    onClick={() => props.goTo({ assetDetails: { uuid: assetUUID, assetType, assetAdvanced: 'DEPENDENCIES' } })}
                                                    size='xxsmall'
                                                    color='tertiary'
                                                />
                                            </div>
                                        }
                                    </header>
                                    {dependencies ?
                                        <Fragment>
                                            {dependencyCount > 0 ?
                                                <Fragment>
                                                    {/* <AssetTypeGroup
                                                        assets={dependencies}
                                                        className='mt-2'
                                                    /> */}

                                                    <div className='flex flex-wrap gap-1 mt-2'>
                                                        {(showDirectDependency ? dependencies.groups.filter(g => g.countDirect > 0) : dependencies.groups).map((dependencyGroup, index) =>
                                                        {
                                                            const { assetType, assetLabel } = dependencyGroup;

                                                            return (
                                                                <div key={index}>
                                                                    <Tooltip
                                                                        title={<span>{assetLabel}</span>}
                                                                        color={theme.assets[assetType]?.color}
                                                                        fontColor='var(--color-bg-ground)'
                                                                    >
                                                                        <div
                                                                            style={{ color : theme.assets[assetType]?.color }}
                                                                            className='flex items-center gap-1 w-full pl-2 pr-1 cursor-default rounded border border-color-secondary'
                                                                        >
                                                                            <Text className='text-xs tracking-wider'>
                                                                                {showDirectDependency ? dependencyGroup.countDirect : dependencyGroup.countIndirect}
                                                                            </Text>
                                                                            <Icon
                                                                                name='asset'
                                                                                assetType={assetType}
                                                                                size='xsmall'
                                                                            />
                                                                        </div>
                                                                    </Tooltip>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </Fragment>
                                                :
                                                <Text format='body4' as='h1' color='tertiary'>
                                                    None
                                                </Text>
                                            }
                                        </Fragment>
                                        :
                                        <Text format='body4' color='tertiary'>
                                            Loading...
                                        </Text>
                                    }
                                </section>
                            }

                            {/* Description */}
                            {(descriptionDisplay.length > 0 || assetInfo?.stats?.hasOwnProperty('totalTriangleCount')) &&
                                <section className='w-full py-3 px-5 border-b border-color-tertiary'>
                                    <div className='flex flex-col justify-between gap-1'>
                                        {descriptionDisplay.map((description, index) =>
                                            <Display
                                                key={index}
                                                {...description}
                                            />
                                        )}
                                        {assetInfo?.stats?.hasOwnProperty('totalTriangleCount') &&
                                            <Display
                                                label='Total Triangles'
                                                value={assetInfo?.stats.totalTriangleCount}
                                                type='int'
                                            />
                                        }
                                    </div>
                                </section>
                            }

                            {/* Export Mesh */}
                            {assetType === 'mesh' &&
                                <section className='w-full py-3 px-5 border-b border-color-tertiary'>
                                    <header className='mb-2'>
                                        <Text format='body3' as='h1'>
                                            Export Mesh
                                        </Text>
                                    </header>
                                    <ExportMesh
                                        assetUUID={assetUUID}
                                        assetDescription={assetDescription}
                                    />
                                </section>
                            }

                        </Fragment>
                    }

                    {
                        /* Usage and creation and latest operations */
                        assetInfo &&
                        <section className='w-full py-3 px-5 border-b border-color-tertiary'>

                            {assetInfo.stats &&
                                <div className='flex justify-between gap-2 mb-2'>
                                    <Display label='Opened' value={assetInfo.stats.openCount} unit='times' className='w-1/3' vertical />
                                    <Display label='Edited' value={assetInfo.stats.editCount} unit='times' className='w-1/3' vertical />
                                    <Display label='Viewed' value={assetInfo.stats.timeInSession} unit='s' humanize className='w-1/3' vertical />
                                </div>
                            }

                            <div className='flex justify-between gap-2'>
                                {assetInfo.creationTimestamp &&
                                    <Display
                                        label='Created'
                                        value={<Moment unix fromNow>{assetInfo.creationTimestamp}</Moment>}
                                        // secondLine={<Fragment>by <span className='username'>{assetInfo.uploadedBy.username}</span></Fragment>}
                                        vertical
                                        avatar={{
                                            tooltipTitle: `By ${assetInfo.uploadedBy.username}`,
                                            uuid: assetInfo.uploadedBy.userUUID,
                                            username: assetInfo.uploadedBy.username,
                                            noTitle: true
                                        }}
                                        className='w-1/3'
                                    />
                                }

                                {assetInfo.lastSession &&
                                    <Display
                                        label='Latest open'
                                        value={<Moment unix fromNow>{assetInfo.lastSession.timestamp}</Moment>}
                                        // secondLine={<Fragment>by <span className='username'>{assetInfo.lastSession.username}</span></Fragment>}
                                        vertical
                                        avatar={{
                                            tooltipTitle: `By ${assetInfo.lastSession.username}`,
                                            uuid: assetInfo.lastSession.userUUID,
                                            username: assetInfo.lastSession.username,
                                            noTitle: true
                                        }}
                                        className='w-1/3'
                                    />
                                }

                                {assetInfo.lastEdit &&
                                    <Display
                                        label='Latest edit'
                                        value={<Moment unix fromNow>{assetInfo.lastEdit.timestamp}</Moment>}
                                        // secondLine={<Fragment>by <span className='username'>{assetInfo.lastEdit.username}</span></Fragment>}
                                        vertical
                                        avatar={{
                                            tooltipTitle: `By ${assetInfo.lastEdit.username}`,
                                            uuid: assetInfo.lastEdit.userUUID,
                                            username: assetInfo.lastEdit.username,
                                            noTitle: true
                                        }}
                                        className='w-1/3'
                                    />
                                }
                            </div>

                        </section>
                    }


                    {/* Asset UUID and Source file section*/}
                    <section className='w-full py-3 px-5'>
                        <div className='flex flex-col justify-between gap-1'>
                            {
                                assetUUID &&
                                <Button
                                    noPadding
                                    contentAlign='left'
                                    color='tertiary'
                                    valueClassName='w-full'
                                    size='xsmall'
                                    onClick={() => copyUUID('Asset UUID', assetUUID)}
                                >
                                    <Display label='Asset UUID' value={assetUUID} className='w-full'/>
                                </Button>
                            }
                            {props.sourceFile &&
                                <Fragment>
                                    <Button
                                        noPadding
                                        contentAlign='left'
                                        color='tertiary'
                                        valueClassName='w-full'
                                        size='xsmall'
                                        onClick={() => copyUUID('Source file UUID', props.sourceFile.uuid)}
                                    >
                                        <Display label='Source file UUID' value={props.sourceFile.uuid} className='w-full'/>
                                    </Button>
                                    <Display
                                        label='File size'
                                        value={props.sourceFile.size}
                                        unit='bytes'
                                        type='int'
                                    />
                                </Fragment>
                            }
                        </div>
                    </section>

                </div>
            </div>

            {props.assetAdvanced &&
                <AssetDetailsAdvanced
                    assetAdvanced={props.assetAdvanced}
                    assetUUID={assetUUID}
                    assetType={assetType}
                    assetDescription={assetDescription}
                    undoRedo={props.undoRedo}
                    dependencies={dependencies}
                    permissions={permissions}
                    canEdit={canEdit}
                    assetRequests={props.assetRequests}
                    updateTrigger={props.updateTrigger}
                    goTo={props.goTo}
                    user={props.user}
                    toggleAssetAdvanced={props.toggleAssetAdvanced}
                    getWorkspaceUUIDs={props.getWorkspaceUUIDs}
                    workspaceMap={props.workspaceMap}
                />
            }

        </Fragment>
    );
}

export default AssetDetails;
