import React, { Fragment }                          from 'react';
import { useTranslation }                           from 'react-i18next';

import { AvatarGroup, Button, Icon, Link, Text }    from '../../../designSystem/components';
import TileApplication                              from '../../../components/TileApplication';
import { plural as p }                              from '../../../designSystem/utils';
import Can                                          from '../../../utils/permissions';
import ConversionsTrack                             from '../../uploadAsset/ConversionsTrack';
import SessionTile                                  from '../../session/SessionTile';
import { getAppPublicURL }                          from '../Applications/api';
import { RocketIconStyled }                         from  './style';


//------------------------------------------------------------------------------
function ProjectDetails(props)
{
    //------------------------------------------------------------------------------
    const { currentProject }    = props;

    //------------------------------------------------------------------------------
    const { t }                 = useTranslation();

    //------------------------------------------------------------------------------
    const shareGroup            = currentProject.shareGroup.filter(collaborator => !collaborator.access.revoked);


    //------------------------------------------------------------------------------
    return (
        <aside
            className={`
                md:flex flex-col border-l border-color-tertiary-alt bg-color-underground
                ${props.className ? props.className : ''}
                ${props.projectPage === 'dashboard' ? 'w-full' : 'hidden w-3/12'}
            `}
            style={props.style}
        >
            <Text color='secondary' className='text-title3 wrapper pt-5'>
                {currentProject.name}
            </Text>

            {/* {currentProject.mainAssetUUID &&
                <div className='py-5 wrapper border-b border-color-tertiary'>
                    <Button
                        onClick={() => props.openAssetEditor(currentProject.mainAssetType, currentProject.mainAssetUUID)}
                        size='small'
                        fullWidth
                        className='bg-cover bg-center'
                        style={{
                            backgroundImage : `url(${apiGatewayURL}/asset/thumbnail?assetUUID=${currentProject.mainAssetUUID}&size=medium&token=${apiToken})`,
                            height          : 'var(--spacing-10)'
                        }}
                    >
                        <span className='bg-color-ground text-color-neutral-primary px-3 py-1 rounded'>
                            Open Main <span className='capitalize'>{currentProject.mainAssetType}</span>
                        </span>
                    </Button>
                </div>
            } */}

            {Can(props.permissions, 'viewShareGroup') && shareGroup &&
                <section className='wrapper pb-5 pt-2'>
                    <div className='flex justify-between items-center gap-2'>
                        <Text as='h1' className='text-ellipsis'>
                            {t('project:collaborators')}
                        </Text>
                        <Button
                            onClick={props.openProjectPageSettings}
                            color='tertiary'
                            noPadding
                        >
                            <AvatarGroup
                                avatars={shareGroup}
                                max={10}
                                className='px-1'
                            />
                            {/* Note: Avatars are commented, just in case we roll back. */}
                            {/* <ul className='flex flex-wrap gap-2'>
                                {shareGroup
                                    .filter(collaborator => !collaborator.access.revoked)
                                    .map(collaborator =>
                                        <li key={collaborator.user.uuid}>
                                            <Avatar
                                                uuid={collaborator.user.uuid}
                                                username={collaborator.user.username}
                                                tooltipTitle={`${collaborator.user.username} ${collaborator.access.name === 'Owner' ? '(Owner)' : ''}`}
                                                circleStyle={props.user?.uuid === collaborator.user.uuid}
                                                color='var(--color-accent)'
                                                reversed
                                            />
                                        </li>
                                    )
                                }
                            </ul> */}
                        </Button>
                    </div>
                </section>
            }

            {currentProject.applications?.length > 0 &&
                <section className='relative table w-full overflow-hidden border-t border-color-tertiary wrapper py-5'>
                    <Text as='h1'>
                        {t('project:applications')}
                    </Text>
                    <div className='flex flex-col gap-3 mt-3'>
                        {currentProject.applications.map((application) =>
                            <TileApplication
                                key={application.uuid}
                                {...application}
                                projectUUID={currentProject.uuid}
                                href={getAppPublicURL(application)}
                                size='small'
                                inline
                                editButton
                                goTo={props.goTo}
                            />
                        )}
                    </div>
                    <RocketIconStyled className='absolute top-0 right-0 pointer-events-none z-0'>
                        <Icon
                            className='fa-rocket-launch fad fa-rotate-270 rocket-icon z-10 opacity-30'
                            style={{
                                position: 'absolute',
                                right: '80px',
                                top: '1rem',
                                transform: 'rotate(15deg)',
                                fontSize: '2.1rem',
                                color: 'var(--color-bg-action-primary)'
                            }}
                        />
                        <span className='trail' />
                    </RocketIconStyled>
                </section>
            }

            <section className='border-t border-color-tertiary wrapper py-5'>
                <header className='mb-1'>
                    <Text as='h1'>
                        {props.sessionCount} Current Session{p(props.sessionCount)}
                    </Text>
                </header>
                {props.projectSessions?.length > 0 ?
                    <ul className='flex flex-col gap-1 mt-2'>
                        {props.projectSessions.map((scene, index) => (
                            <Fragment key={index}>
                                {scene.sessions.map(session => (
                                    <li key={session.uuid}>
                                        <SessionTile
                                            {...session}
                                            sceneUUID={scene.sceneUUID}
                                            sceneName={scene.sceneName}
                                            sceneInformation
                                        />
                                    </li>
                                ))}
                            </Fragment>
                        ))}
                    </ul>
                    :
                    <Text color='secondary' className='text-xs'>
                        None
                    </Text>
                }
            </section>

            {props.tasks?.length > 0 && showNotReadyFeatures &&
                <section className='border-t border-color-tertiary wrapper py-5'>
                    <header>
                        <Text as='h1'>
                            Conversions
                        </Text>
                    </header>
                    <ConversionsTrack
                        tasks={props.tasks}
                        goTo={props.goTo}
                    />
                </section>
            }


            {currentProject.activities &&
                <section className='border-t border-color-tertiary wrapper py-5'>
                    <Text as='h1'>
                        Latest Activities
                    </Text>
                    <ul className='flex flex-col gap-1 mt-2'>
                        {currentProject.activities.map(session =>
                            <li key={session}>
                                <Link to='/' className='block bg-color-overground rounded py-3 px-4'>
                                </Link>
                            </li>
                        )}
                    </ul>
                </section>
            }

        </aside>
    );
}

export default ProjectDetails;
