//------------------------------------------------------------------------------
import React, { useEffect, useState } from 'react';

//------------------------------------------------------------------------------
import { AutoSizer }    from 'react-virtualized';
import VirtualList      from 'react-tiny-virtual-list';

//------------------------------------------------------------------------------
import { makeStyles }   from '@material-ui/core/styles';

//------------------------------------------------------------------------------
import Error            from '../ErrorSection';

//------------------------------------------------------------------------------
import CallAPI          from '../../../../../utils/api';
import { Link, Text }   from '../../../../../designSystem/components';

//------------------------------------------------------------------------------
const appStyle = makeStyles(theme => (
    {
        container :
        {
            height: 'calc(100% - 102px)',
            backgroundColor : 'var(--color-bg-underground)',
            borderLeft : '1px solid var(--color-bg-ground)',
            borderRight : '1px solid var(--color-bg-ground)',
            boxSizing : 'content-box',
            margin: 0
        },
        log:
        {
            color: 'white',
            paddingLeft : '20px',
            font: '14px Inconsolata, monospace',
            textShadow: '0 0 5px #C8C8C8',
        }
    }
));

//------------------------------------------------------------------------------
export default function ItemLog(props)
{
    //------------------------------------------------------------------------------
    const {
        taskUUID,
        itemUUID
    } = props;

    const classes           = appStyle();
    const [logs, setLogs]   = useState([]);
    const [error, setError] = useState(null);

    //------------------------------------------------------------------------------
    useEffect(() =>
    {
        async function fetchLogs()
        {
            try
            {
                const logs = await CallAPI(`/upload/getConversionLog?taskUUID=${taskUUID}&itemUUID=${itemUUID}`, 'GET');
                setLogs(logs.split('\n'));
            }
            catch(error)
            {
                if(error.httpCode === 404)
                {
                    error.message = 'No logs found';
                }
                setError(error);
            }
        }
        fetchLogs();

    }, [taskUUID, itemUUID]);

    //------------------------------------------------------------------------------
    return (
        <section className={props.className} style={props.style}>
            <header className='bg-color-ground sticky top-0 p-6 z-10'>
                <Text format='title1'>
                    <Link to={`/p/${props.projectUUID}/conversions/${window.location.search}`} className='link'>
                        Conversions
                    </Link>
                    <span className='mx-3 opacity-20 text-xl'>
                        /
                    </span>
                    Logs
                </Text>
            </header>

            {error ?
                <Error {...error} />
                :
                <div className={classes.container}>
                    <AutoSizer>
                        {({ width, height }) => (
                            <VirtualList
                                width={width - 2}
                                height={height}
                                itemCount={logs.length}
                                itemSize={(index) =>
                                {
                                    const charSize      = 9;
                                    const maxRowSize    = width - 20;
                                    const wordSizes     = logs[index].split(/\s|[-]/).map(str => str.length * charSize);

                                    if(wordSizes.length < 2)
                                    {
                                        return 18;
                                    }

                                    const [firstWordSize, ...leftOver] = wordSizes;

                                    const { rowCount } = leftOver.reduce((accumulator, currentValue) =>
                                    {
                                        accumulator.currentWidth += (currentValue + charSize);
                                        if(accumulator.currentWidth > maxRowSize)
                                        {
                                            accumulator.currentWidth = currentValue;
                                            accumulator.rowCount++;
                                        }

                                        return accumulator;
                                    }, { rowCount : 1, currentWidth : firstWordSize });

                                    return 18 * rowCount;
                                }}
                                renderItem={({ index, style }) =>
                                    <div key={index} className={classes.log} style={style}>
                                        {logs[index]}
                                    </div>
                                }
                            />
                        )}
                    </AutoSizer>
                </div>
            }
        </section>
    );
}
