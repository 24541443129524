//------------------------------------------------------------------------------
export default function getIcon(folder)
{
    switch(folder.name)
    {
        case 'Public':
            return 'fal fa-globe';
        case 'Private':
            return 'fal fa-lock';
        case 'Trash':
            return 'fal fa-trash-alt';
    }
}
