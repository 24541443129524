import React, { useEffect, useState, useContext }   from 'react';
import { useTranslation }                           from 'react-i18next';

import { Button, Divider, Text }                    from '../../../designSystem/components';
import Can                                          from '../../../utils/permissions';

import * as clusterAPI                              from '../../cluster/api';
import UserContext                                  from '../userContext';
import ClusterSelect                                from './ClusterSelect';
import RendererVersionSelect                        from './RendererVersionSelect';
import DeleteAccountModal                           from './DeleteAccountModal';


//------------------------------------------------------------------------------
export default function Advanced ()
{
    //------------------------------------------------------------------------------
    const { t }                                     = useTranslation();
    const userData                                  = useContext(UserContext);

    //------------------------------------------------------------------------------
    const [clusters, setClusters]                   = useState([]);
    const {
        // cluster
        getAllAvailableClustersAPI, // includes available renderer versions per cluster
        updatePreferredClusterAPI,
        removePreferredClusterAPI,

        // renderer
        updatePreferredRendererVersionAPI,
        removePreferredRendererVersionAPI
    } = clusterAPI;

    //------------------------------------------------------------------------------
    useEffect(() =>
    {
        getAllAvailableClustersAPI().then(response => setClusters(response));
    }, []);

    //------------------------------------------------------------------------------
    const userPreferencesLoaded     = clusters.length > 0;

    //------------------------------------------------------------------------------
    // Delete Account

    const [ deleteAccountModal, setDeleteAccountModal ] = useState(null);


    //------------------------------------------------------------------------------
    return (
        <section>
            <header>
                <Text format='title1'>
                    {t('account:advanced')}
                </Text>
            </header>

            <Divider horizontal className='my-5' />

            <section>

                <Text format='title3' as='h1' className='mb-4'>
                    Your Preferences
                </Text>

                <div
                    className='flex gap-4'
                    style={{ visibility: `${userPreferencesLoaded ? 'visible' : 'hidden'}` }}
                >
                    <ClusterSelect
                        preferredClusterUUID={userData.preferredClusterUUID}
                        setPreferredCluster={userData.setPreferredCluster}
                        clusters={clusters}
                        updatePreferredClusterAPI={updatePreferredClusterAPI}
                        removePreferredClusterAPI={removePreferredClusterAPI}
                    />
                    <RendererVersionSelect
                        preferredClusterUUID={userData.preferredClusterUUID}
                        preferredRendererVersion={userData.preferredRendererVersion}
                        setPreferredRendererVersion={userData.setPreferredRendererVersion}
                        clusters={clusters}
                        updatePreferredRendererVersion={updatePreferredRendererVersionAPI}
                        removePreferredRendererVersion={removePreferredRendererVersionAPI}
                    />
                </div>

            </section>

            <Divider horizontal className='my-5' />

            <section>
                <Text format='title3' as='h1' className='mb-4'>
                    {t('account:delete your account')}
                </Text>
                <Button
                    value={t('account:delete my account')}
                    onClick={() => setDeleteAccountModal(true)}
                    color='secondary'
                    size='small'
                />
                {deleteAccountModal &&
                    <DeleteAccountModal
                        open
                        onClose={() => setDeleteAccountModal(null)}
                    />
                }
            </section>

        </section>
    );
}
