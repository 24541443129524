//------------------------------------------------------------------------------
import React, { useState, useEffect }   from 'react';

//------------------------------------------------------------------------------
import { Text, Icon, Tooltip }          from '../../../designSystem/components';
import moduleAPI                        from '../AssetDetailsAdvanced/ModuleDeployment/moduleAPI';

//------------------------------------------------------------------------------
export default function ShaderDetails(props)
{
    //------------------------------------------------------------------------------
    const [status , setStatus] = useState(null);

    //------------------------------------------------------------------------------
    useEffect(() =>
    {
        setStatus(null);

        if(!props.assetDescription.payloadSize)
        {
            setStatus({ icon : getStatusIcon('unavailable'), text : 'This shader is not compilable' });
            return;
        }

        moduleAPI.getGraphModuleCommitIDs(props.assetUUID).then(({ modules = [] }) =>
        {
            if(!modules.length)
            {
                setStatus({ icon : getStatusIcon('unavailable'), text : 'No module found' });
                return;
            }

            const outdatedModules   = modules.filter(({ commitID, lastCommitID }) => commitID !== lastCommitID);
            const isSuccess         = outdatedModules.length === 0;
            const icon              = getStatusIcon(isSuccess ? 'success' : 'warning');
            const text              = isSuccess ? 'No changes in modules since last publication' : `${outdatedModules.length} module${outdatedModules.length > 1 ? 's have' : ' has'} been updated since last publication`;
            const title             = outdatedModules.map(({ name }) => name).join(', ');

            setStatus({ icon, text, title });
        })
            .catch(error =>
            {
                console.log(error);
                setStatus({ icon : getStatusIcon('error'), text : error.message });
            });

    }, [props.assetUUID, props.assetDescription]);

    //------------------------------------------------------------------------------
    function getStatusIcon(status)
    {
        switch(status)
        {
        case 'success':
            return <Icon className='fas fa-check text-color-feedback-positive' fixedSize={false}/>;

        case 'warning':
            return <Icon className='fas fa-exclamation-triangle text-color-feedback-warning' fixedSize={false}/>;

        case 'error':
            return <Icon className='fas fa-exclamation-square text-color-feedback-negative' fixedSize={false}/>;

        case 'unavailable':
            return <Icon className='fal fa-empty-set text-color-neutral-secondary' fixedSize={false}/>;
        }
    }

    //------------------------------------------------------------------------------
    if(!status)
    {
        return (
            <Text format='body4' color='tertiary'>
                Loading...
            </Text>
        );
    }

    //------------------------------------------------------------------------------
    const textElement = (
        <Text format='body4'>
            {status.text}
        </Text>
    );

    //------------------------------------------------------------------------------
    return (
        <div className='flex items-center gap-2'>
            {status.icon}
            {status.title ?
                <Tooltip title={status.title}>
                    {textElement}
                </Tooltip>
                : textElement
            }
        </div>
    );
}
