import styled from 'styled-components';

export const SessionsListStyled = styled.ul`
  max-width: 24rem;
  min-width: 18rem;
  padding: 1rem;

  > li {
    margin-bottom: 1rem;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .workspace-link {
    position: relative;
    display: inline-block;
    padding: .2rem .8rem .2rem 0;
    color: currentColor;

    &:before {
      content: '⌃';
      position: absolute;
      right: 0;
      transform: translate(-.2rem) rotate(90deg);
      opacity: 0;
      transition: transform .35s, opacity .35s;
    }

    &:focus,
    &:hover {
      &:before {
        transform: translate(0) rotate(90deg);
        opacity: 1;
      }
    }
  }

  .workspace-title {
    margin-bottom: .2rem;
  }

  .list {
    > * {
      margin-top: .25rem;
    }
  }
`;
