//------------------------------------------------------------------------------
import React, { useState }  from 'react';
import { useTranslation }   from 'react-i18next';
import { useSnackbar }      from 'notistack';

//------------------------------------------------------------------------------
import { Button, Icon, Modal, Text, ControlText }    from '../../../designSystem/components';

//------------------------------------------------------------------------------
const UploadAssetToProdModal = (props) =>
{
    //--------------------------------------------------------------------------
    const { t }                             = useTranslation();
    const { enqueueSnackbar }               = useSnackbar();

    const { packageName, packageURL }       = props;

    //--------------------------------------------------------------------------
    const [username, setUsername]           = useState('');
    const [password, setPassword]           = useState('');
    const [workspaceUUID, setWorkspaceUUID] = useState('');

    //--------------------------------------------------------------------------
    const uploadAssets = async (e) =>
    {
        e.preventDefault();

        const post = async (url, json) =>
        {
            const response = await fetch('https://labs.3dverse.com/innerAPI/' + url,
            {
                method : 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(json)
            });

            const contentType 	= response.headers.get("content-type");
            const isJSON        = contentType?.indexOf("application/json") !== -1;
            let data;
            try
            {
                if (isJSON)
                {
                    data = await response.json();
                }
                else
                {
                    data = await response.text();
                }
            }
            catch
            {
                data = null;
            }

            if(response.status !== 200)
            {
                let error = data;
                while(error && error.error) error = error.error;
                throw error;
            }
            else
            {
                return data;
            }
        };

        const devPackageURL     = new URL(packageURL);

        try
        {
            const { token } = await post('authentication/login', {username, password})
            await uploadPackage(token, devPackageURL.toString());
            enqueueSnackbar('Upload successful', { variant: 'success' });
            props.onClose();
        }
        catch(error)
        {
            console.error(error);
            enqueueSnackbar('Failed to upload, see console for more details', { variant: 'error' });
        }
    };

    //--------------------------------------------------------------------------
    const uploadPackage = async (token, fileURL) =>
    {
        const formData = new FormData();
        formData.append('workspaceUUID', workspaceUUID);
        formData.append('task-name', 'Upload from dev ' + packageName);
        formData.append('convert', true);
        formData.append('isVolume', false);
        formData.append('isPackage', true);

        const linkFiles = [
            {
                name    : packageName,
                url     : fileURL,
                size    : 0,
                isDir   : true
            }
        ];

        formData.append('linkFiles', JSON.stringify(linkFiles));

        const rawResponse   = await fetch(
            'https://api.3dverse.dev/legacy/upload/submit',
            {
                method  : 'POST',
                headers : { 'x-api-token' : token },
                body    : formData
            }
        );

        if(rawResponse.status !== 200)
        {
            throw new Error('Upload response status ' + rawResponse.status);
        }
    };

    //--------------------------------------------------------------------------
    return (
        <Modal
            open={props.open}
            onClose={props.onClose}
        >
            <form onSubmit={uploadAssets}>

                <header>
                    <Text format='title2'>
                        Upload to Prod
                    </Text>
                </header>

                <div className='flex flex-col gap-2 mt-2 px-2'>
                    <div className='flex gap-1 items-center'>
                        <Text format='body3' style={{flex: "0 0 35%"}}>
                            Username or email
                        </Text>
                        <ControlText
                            value={username}
                            onChange={e => setUsername(e.target.value)}
                            required
                        />
                    </div>

                    <div className='flex gap-1 items-center'>
                        <Text format='body3' style={{flex: "0 0 35%"}}>
                            Password
                        </Text>
                        <ControlText
                            type='password'
                            required
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                        />
                    </div>

                    <div className='flex gap-1 items-center'>
                        <Text format='body3' style={{flex: "0 0 35%"}}>
                            Workspace UUID
                        </Text>
                        <ControlText
                            value={workspaceUUID}
                            className='w-full'
                            onChange={e => setWorkspaceUUID(e.target.value)}
                            required
                        />
                    </div>
                </div>

                <div className='flex items-center justify-between mt-4'>
                    <Text format='body3' color='warning'>
                        <Icon
                            className='fal fa-exclamation-circle mr-1'
                            size='xsmall'
                        />
                        {t('t:this action cannot be undone')}
                    </Text>
                    <Button
                        type='submit'
                        value='Submit'
                        color='primary'
                    />
                </div>
            </form>

        </Modal>
    );
};

export default UploadAssetToProdModal;
