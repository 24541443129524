//------------------------------------------------------------------------------
import i18n                     from 'i18next';
import { initReactI18next }     from 'react-i18next';

//------------------------------------------------------------------------------
import translationEN            from './locales/en/t.json';
import translationFR            from './locales/fr/t.json';

import projectEN                from './locales/en/project.json';
import projectFR                from './locales/fr/project.json';

import applicationEN            from './locales/en/application.json';
import applicationFR            from './locales/fr/application.json';

import conversionEN            from './locales/en/conversion.json';
import conversionFR            from './locales/fr/conversion.json';

import accountEN                from './locales/en/account.json';
import accountFR                from './locales/fr/account.json';

import sceneEN                  from './locales/en/scene.json';
import sceneFR                  from './locales/fr/scene.json';

//------------------------------------------------------------------------------
const resources = {
    en: {
        t           : translationEN,
        project     : projectEN,
        application : applicationEN,
        conversion  : conversionEN,
        scene       : sceneEN,
        account     : accountEN
    },
    fr: {
        t           : translationFR,
        project     : projectFR,
        application : applicationFR,
        conversion  : conversionFR,
        scene       : sceneFR,
        account     : accountFR
    }
};

//------------------------------------------------------------------------------
i18n
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng: 'en',

        keySeparator: false,

        interpolation: {
            escapeValue: false
        }
    });

export default i18n;
