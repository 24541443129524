import React, { Fragment, useEffect, useState }     from 'react';
import { SnackbarProvider }                         from 'notistack';

//------------------------------------------------------------------------------
// Context Providers
import { UserProvider }                             from '../apps/account/userContext';
import { SessionsProvider }                         from '../apps/session/sessionsContext';
import { NotificationProvider }                     from '../apps/notifications/notificationContext';
import { UploadProvider }                           from '../apps/uploadAsset/uploadContext';
import { ProjectProvider }                          from '../apps/projects/ProjectContext';

//------------------------------------------------------------------------------
// Wrapper
import ProjectWrapper                               from '../apps/projects/ProjectWrapper';

//------------------------------------------------------------------------------
// Pages
import HomePage                                     from '../apps/home/Home';

import SigninPage                                   from '../apps/login/Signin';
import SignUpPage                                   from '../apps/login/Signup';
import SignupSendConfirmEmailPage                   from '../apps/login/SignupSendConfirmEmail';
import SignupConfirmEmailPage                       from '../apps/login/SignupConfirmEmail';
import ForgotPasswordPage                           from '../apps/login/ForgotPassword';
import ResetPasswordPage                            from '../apps/login/ResetPassword';

import AccountRootPage                              from '../apps/account/AccountRoot';

import ProjectListPage                              from '../apps/projects/ProjectList';
import ProjectPage                                  from '../apps/projects/Project';

import NoMatchPage                                  from '../apps/NoMatch';

//------------------------------------------------------------------------------
// Utils
import { isWebARonARKit, isWebXRViewer }            from '../utils/userAgent';
import CallAPI                                      from '../utils/api';

//------------------------------------------------------------------------------
// Components
import { Loader, ScrollToTop }                      from '../designSystem/components';

//------------------------------------------------------------------------------
// Router
import { Route, BrowserRouter, Switch }             from '../designSystem/router';


//------------------------------------------------------------------------------
function Routes(props)
{
    //--------------------------------------------------------------------------
    const [ user, setUser ]           = useState({});
    const [ loading, setLoading ]     = useState(true);
    const openSceneLinkTarget         = isWebARonARKit || isWebXRViewer ? '_self' : '_blank';

    //--------------------------------------------------------------------------
    useEffect(() =>
    {
        async function initAuthentication()
        {
            const userData = await getUserData();
            if (userData.token)
            {
                setUser({
                    ...userData,
                    isAuthenticated: true,
                });
            }
            else
            {
                setUser({
                    isAuthenticated: false,
                });
            }
            setLoading(false);
        }
        initAuthentication();
    }, []);

    //--------------------------------------------------------------------------
    async function getUserData()
    {
        try
        {
            return await CallAPI('/authentication/isloggedin', 'GET');
        }
        catch(error)
        {
            return {};
        }
    }

    //--------------------------------------------------------------------------
    function setPreferredCluster(preferredClusterUUID)
    {
        setUser({ ...user, preferredClusterUUID });
    }

    //--------------------------------------------------------------------------
    function setPreferredRendererVersion(preferredRendererVersion)
    {
        setUser({ ...user, preferredRendererVersion });
    }

    //--------------------------------------------------------------------------
    function setAccountPreferredCluster(preferredClusterUUID)
    {
        const nextUserData = { ...user };
        nextUserData.account = { ...user.account, preferredClusterUUID };
        setUser(nextUserData);
    }

    //--------------------------------------------------------------------------
    function setAccountPreferredRendererVersion(preferredRendererVersion)
    {
        const nextUserData = { ...user };
        nextUserData.account = { ...user.account, preferredRendererVersion };
        setUser(nextUserData);
    }

    //--------------------------------------------------------------------------
    function setToken(token)
    {
        localStorage.setItem('3dverse-api-token', apiToken);
        apiToken = token;
        setUser({ ...user, token });
    }

    //-------------------------------------------------------------------------
    useEffect(() =>
    {
        if (user.uuid)
        {
            localStorage.setItem('3dverse-user-id', user.uuid);
        }
    }, [user.uuid]);

    //--------------------------------------------------------------------------
    return (
        <UserProvider
            value={{
                ...user,
                setPreferredCluster,
                setPreferredRendererVersion,
                setAccountPreferredCluster,
                setAccountPreferredRendererVersion,
                openSceneLinkTarget
            }}
        >
            <BrowserRouter basename={basename}>
                <SnackbarProvider
                    maxSnack={4}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    autoHideDuration={2000}
                >
                    <UploadProvider>
                        {loading ? (
                            <Loader centered />
                        ) : (
                            <Fragment>
                                {!user.isAuthenticated ? (
                                    <Switch>
                                        <Route
                                            path='/signin'
                                            render={routerProps => (
                                                <SigninPage {...routerProps} user={user} setUser={setUser} />
                                            )}
                                        />

                                        <Route
                                            path='/signup'
                                            render={routerProps => (
                                                <SignUpPage {...routerProps} user={user} setUser={setUser} />
                                            )}
                                        />

                                        <Route
                                            path='/signupConfirmEmail'
                                            render={routerProps => (
                                                <SignupSendConfirmEmailPage {...routerProps} />
                                            )}
                                        />

                                        <Route
                                            path='/confirmEmail'
                                            render={routerProps => (
                                                <SignupConfirmEmailPage {...routerProps} />
                                            )}
                                        />

                                        <Route
                                            path='/forgotPassword'
                                            render={routerProps => (
                                                <ForgotPasswordPage {...routerProps} />
                                            )}
                                        />

                                        <Route
                                            path='/resetPassword'
                                            render={routerProps => (
                                                <ResetPasswordPage {...routerProps} />
                                            )}
                                        />

                                        <Route
                                            path='/'
                                            render={routerProps => (
                                                <SigninPage {...routerProps} user={user} setUser={setUser} />
                                            )}
                                        />
                                    </Switch>
                                ) : (
                                    <SessionsProvider
                                        apiGatewayURL={user.apiGatewayURL}
                                        apiToken={user.token}
                                    >
                                        <NotificationProvider
                                            apiGatewayURL={user.apiGatewayURL}
                                            apiToken={user.token}
                                        >
                                            <ProjectProvider user={user} setToken={setToken}>
                                                <Switch>
                                                    <Route
                                                        exact
                                                        path='/'
                                                        render={routerProps => (
                                                            <HomePage
                                                                {...routerProps}
                                                                {...props}
                                                                user={user}
                                                            />
                                                        )}
                                                    />

                                                    <Route
                                                        exact
                                                        path='/projects'
                                                        render={routerProps => (
                                                            <ProjectListPage
                                                                {...routerProps}
                                                                {...props}
                                                                user={user}
                                                            />
                                                        )}
                                                    />

                                                    <Route
                                                        path='/account/:page?/:uuid?/:subPage?/:subUUID?'
                                                        render={routerProps => (
                                                            <AccountRootPage
                                                                {...routerProps}
                                                                {...props}
                                                                user={user}
                                                                setUser={setUser}
                                                            />
                                                        )}
                                                    />

                                                    <Route
                                                        path='/p/:projectUUID/:projectPage?/:uuid1?/:uuid2?/:uuid3?'
                                                        render={routerProps => (
                                                            <ProjectWrapper
                                                                {...routerProps}
                                                                {...props}
                                                                user={user}
                                                            >
                                                                {(wrapperProps) =>
                                                                    <ProjectPage
                                                                        {...routerProps}
                                                                        {...wrapperProps}
                                                                        {...props}
                                                                        user={user}
                                                                    />
                                                                }
                                                            </ProjectWrapper>
                                                        )}
                                                    />

                                                    <Route
                                                        render={routerProps => (
                                                            <NoMatchPage {...routerProps}/>
                                                        )}
                                                    />

                                                </Switch>
                                            </ProjectProvider>
                                        </NotificationProvider>
                                    </SessionsProvider>
                                )}
                                <ScrollToTop />
                            </Fragment>
                        )}
                    </UploadProvider>
                </SnackbarProvider>
            </BrowserRouter>
        </UserProvider>
    );
}

export default Routes;
