import React, { useState }                          from 'react';
import { useTranslation }                           from 'react-i18next';
import AvatarEdit                                   from 'react-avatar-edit';
import { Button, Modal, Text }                      from '../../../designSystem/components';
import { AvatarEditStyled }                         from './style';


//------------------------------------------------------------------------------
function ProfileAvatarEdit(props) 
{
    //------------------------------------------------------------------------------
    const { saveAvatar, onClose } = props;

    //------------------------------------------------------------------------------
    const { t } = useTranslation();

    //------------------------------------------------------------------------------
    const [avatar, setAvatar] = useState({
        preview : null,
        src     : ''
    });

    //------------------------------------------------------------------------------
    const clearPreview = () =>
    {
        setAvatar({ preview: null });
    };

    //------------------------------------------------------------------------------
    const onCrop = (preview) =>
    {
        setAvatar({ preview });
    };

    //------------------------------------------------------------------------------
    const onSave = () =>
    {
        saveAvatar( avatar.preview );
    };

    //------------------------------------------------------------------------------
    const onBeforeFileLoad = (elem) =>
    {
        if (elem.target.files[0].size > 716800)
        {
            alert('File is too big!');
            elem.target.value = '';
        }
    };

    //------------------------------------------------------------------------------
    return (
        <Modal
            open={true}
            onClose={onClose}
        >
        
            <form onSubmit={onSave}>
                
                <Text format='title1'>
                    {t('account:change avatar')}
                </Text>

                <AvatarEditStyled hasPreview={!!avatar.preview}>
                    <AvatarEdit
                        width={'100%'}
                        height={304}
                        onCrop={onCrop}
                        onClose={clearPreview}
                        onBeforeFileLoad={onBeforeFileLoad}
                        src={avatar.src}
                    />
                </AvatarEditStyled>

                <Button
                    type='submit'
                    value={t('t:save')}
                    color='primary'
                    size='large'
                    fullWidth
                />
            </form>
          
        </Modal>
    );
}

export default ProfileAvatarEdit;
