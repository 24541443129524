//------------------------------------------------------------------------------
import React, { useEffect, useState }               from 'react';
import { useTranslation }                           from 'react-i18next';

//------------------------------------------------------------------------------
import { Button, Icon, Loader, Text }               from '../../../../../designSystem/components';
import TileAsset                                    from '../../../../../components/TileAsset';


//------------------------------------------------------------------------------
export default function ListDependencies(props)
{
    //------------------------------------------------------------------------------
    const { t } = useTranslation();

    //------------------------------------------------------------------------------
    const [ dependencies, setDependencies ] = useState();

    useEffect(() =>
    {
        setDependencies(props.dependencyGroups);
    }, [props.dependencyGroups]);

    if(!dependencies)
    {
        return (
            <div className='flex'>
                <Loader />
            </div>
        );
    }

    //------------------------------------------------------------------------------
    return (
        <ul className='flex flex-col gap-3 px-5 pb-4'>
            {dependencies.map(dependencyGroup =>
            {
                const assets = props.direct
                    ? dependencyGroup.assets.filter(a => a.direct)
                    : dependencyGroup.assets;

                if(assets.length === 0)
                {
                    return <div/>;
                }

                return (
                    <li key={'dep' + dependencyGroup.assetListName}>
                        <header className='mb-1'>
                            <Button
                                onClick={() =>
                                {
                                    dependencyGroup.hidden = !dependencyGroup.hidden;
                                    setDependencies(Array.from(dependencies));
                                }}
                                color={dependencyGroup.hidden ? 'secondary' : 'tertiary'}
                                valueClassName='w-full justify-between gap-2 pl-1'
                                contentAlign='left'
                                size='small'
                                fullWidth={true}
                                noPadding
                            >
                                <Text format='body4' as='h2' color='secondary' className='uppercase'>
                                    {assets.length}
                                    {props.direct &&
                                        <span> / {dependencyGroup.assets.length}</span>
                                    }
                                    {' '}{assets.length > 1 ? dependencyGroup.assetListName : dependencyGroup.assetType}
                                </Text>
                                <Icon
                                    className={`fal fa-chevron-down transition-transform ${dependencyGroup.hidden ? 'fa-rotate-90' : ''}`}
                                    size='xsmall'
                                />
                            </Button>
                        </header>
                        {!dependencyGroup.hidden &&
                            <ul className='flex flex-col gap-1px w-full'>
                                {assets.map(asset =>
                                    <li key={asset.uuid}>
                                        <TileAsset
                                            onClick={() => props.goTo(
                                                {
                                                    assetDetails: {
                                                        uuid: asset.uuid,
                                                        assetType: dependencyGroup.assetType,
                                                        isAdvanced: true,
                                                    }
                                                })}
                                            goTo={props.goTo}
                                            uuid={asset.uuid}
                                            assetType={dependencyGroup.assetType}
                                            name={asset.name}
                                            label={asset.count > 1 ? asset.count + ' times' : undefined}
                                            hasThumbnail={asset.hasThumbnail}
                                            displayMode='LIST'
                                        />
                                    </li>
                                )}
                            </ul>
                        }
                    </li>
                );
            }
            )}
        </ul>
    );
}
