import styled, { css }           from 'styled-components';


export const TileStyled = styled.div`
    border: 1px solid transparent;
    
    ${props => !props.isFolder && props.isActive && css`
        color: var(--color-bg-ground) !important;
        background-color: var(--asset-color) !important;
    `}

    ${props => props.outline && css`
        border-color: hsla( var(--color-content-neutral-primary-hsl), .2);
    `}

    ${props => props.isActive && props.outline && css`
        border-color: var(--asset-color);
        background-color: inherit !important;
    `}
`;