//------------------------------------------------------------------------------
import React from 'react';

//------------------------------------------------------------------------------
const library       = {};
const componentMap  = {};

//------------------------------------------------------------------------------
export async function installDesignSystem()
{
    const core = await import(/* webpackIgnore: true */ window.location.origin + '/design-system/modules/core.js');

    for(const [componentName, component] of Object.entries(core))
    {
        library[componentName] = component;
    }
}

//------------------------------------------------------------------------------
export function componentWrapper(type, componentName)
{
    return class WrappedComponent extends React.Component
    {
        render()
        {
            const Component = library[type][componentName];
            return (
                <Component {...this.props}/>
            );
        }
    }
}

//------------------------------------------------------------------------------
export const functionWrapper = (type, functionName) =>
{
    return function WrappedFunction()
    {
        const Function = library[type][functionName];
        return Function.apply(this, arguments);
    }
};

//------------------------------------------------------------------------------
export const staticWrapper = (type, objectName) =>
{
    return function WrappedFunction()
    {
        return library[type][objectName];
    };
};

//------------------------------------------------------------------------------
export default function designSystem(type, wrapper, ...components)
{
    if(!componentMap.hasOwnProperty(type))
    {
        componentMap[type] = {};
    }

    const missingComponents = components.filter(c => !componentMap[type].hasOwnProperty(c));

    for(const componentName of missingComponents)
    {
        componentMap[componentName] = wrapper(type, componentName);
    }

    return componentMap;
}