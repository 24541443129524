import React            from 'react';
import { Modal, Text }  from '../../designSystem/components';

//------------------------------------------------------------------------------
const ContentModal = ( props ) =>
{
    //------------------------------------------------------------------------------
    return (
        <Modal
            open={props.open}
            onClose={props.onClose}
        >
            <header className={props.headerClassName}>
                <Text format='title2'>
                    <div dangerouslySetInnerHTML={{__html : props.titleHTML}}/>
                </Text>
            </header>

            { props.children }
        </Modal>
    );
};

export default ContentModal;