import React                            from 'react';

import { Button, Text }                 from '../../../designSystem/components';
import { TileConnectedAppStyled }       from './style';


//------------------------------------------------------------------------------
const TileConnectedApp = (props) =>
{
    //------------------------------------------------------------------------------
    return (
        <TileConnectedAppStyled
            as={!props.isConnected && 'button'}
            onClick={!props.isConnected ? props.onClick : undefined}
            className={`
                block flex items-center justify-between gap-3 w-full pl-2 pr-4 bg-color-overground rounded hover:opacity-100 transition-opacity
                ${props.isConnected ? '' : 'opacity-60'}
            `}
        >
            {Boolean(props.image) &&
                <img
                    src={basename + props.image}
                    alt=''
                    className={`w-8 ${props.imageClassName ? props.imageClassName : ''}`}
                />
            }

            <div className='flex-1 py-3 text-left'>
                <Text format='title3'>
                    {props.name}
                </Text>
                <Text format='body4' color='secondary' className='max-w-md'>
                    {props.description}
                </Text>
            </div>

            {props.isConnected &&
                <Button
                    value='Connected'
                    onClick={props.onClickConnected}
                    round
                />
            }
        </TileConnectedAppStyled>
    );
};

export default TileConnectedApp;