//------------------------------------------------------------------------------
import React, { useState }          from 'react';
//------------------------------------------------------------------------------
import { getSorting, stableSort }   from '../sort';
import {
    getStatusIcon,
    getStatusText
}                                   from '../status';

import IconButton                   from '@material-ui/core/IconButton';
import { Link, ProgressBar }        from '../../../../../designSystem/components';
import SubjectIcon                  from '@material-ui/icons/Subject';
//------------------------------------------------------------------------------
import Table                        from '../Table';
import TableCell                    from '@material-ui/core/TableCell';
//------------------------------------------------------------------------------
import TableRow                     from '@material-ui/core/TableRow';

//------------------------------------------------------------------------------
const headCells = [
    { id: 'name',               align: 'left',  padding: 'none',     sortable : true,  label: 'Name' },
    { id: 'type',               align: 'left',  padding: 'normal',   sortable : true,  label: 'Type' },
    { id: 'status',             align: 'left',  padding: 'normal',   sortable : true,  label: 'Status' },
];

//------------------------------------------------------------------------------
export default function ItemTable(props)
{
    //------------------------------------------------------------------------------
    const [order, setOrder]             = useState('asc');
    const [orderBy, setOrderBy]         = useState('type');
    const [page, setPage]               = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);

    //------------------------------------------------------------------------------
    const displayData = () =>
    {
        const sort = stableSort(props.tasks, getSorting(order, orderBy));
        return sort.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    };

    //------------------------------------------------------------------------------
    return (
        <Table
            title={props.title}
            headCells={headCells}
            order={order}
            setOrder={setOrder}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            rowCount={props.tasks.length}
            className={props.className}
        >
            {displayData().map((item) =>
                <TableRow tabIndex={-1} key={item.uuid}>
                    <TableCell component="th" id={item.uuid} scope="row" padding="none">
                        <Link to={'./' + item.uuid + window.location.search}>
                            {item.name}
                        </Link>
                    </TableCell>
                    <TableCell align="left">
                        <span className='capitalize'>{item.type}</span>
                    </TableCell>
                    <TableCell align="left">
                        <div className='flex gap-6 items-center'>
                            <div className='flex items-center'>
                                {getStatusIcon(item.status)}
                                {getStatusText(item.status)}
                            </div>
                            {[0, 1].includes(item.progress) &&
                                <ProgressBar progress={item.progress || 0} size='small' backgroundColor='light' />
                            }
                        </div>
                    </TableCell>
                </TableRow>
            )}
        </Table>
    );
}