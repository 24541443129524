//------------------------------------------------------------------------------
import React                        from 'react';

//------------------------------------------------------------------------------
import { Button, Text }             from '../../../../designSystem/components';
import { ApplicationTemplateTileStyled }     from './style';

//------------------------------------------------------------------------------
export default function ApplicationTemplateTile(props)
{
    return (
        <ApplicationTemplateTileStyled
            as='button'
            onClick={props.onClick}
            className={`
                block flex items-center justify-between gap-3 w-full pl-2 pr-4 bg-color-overground rounded hover:opacity-100 transition-opacity
                ${props.hasApps ? '' : 'opacity-60'}
            `}
        >
            {Boolean(props.image) &&
                <img
                    src={basename + props.image}
                    alt=''
                    className={`w-8 ${props.imageClassName ? props.imageClassName : ''}`}
                />
            }

            <div className='flex-1 py-3 text-left'>
                <Text format='title3'>
                    {props.gitPath}
                </Text>
                <Text format='body4' color='secondary' className='mt-1 max-w-md'>
                    {props.branchName}
                </Text>
            </div>

            {props.hasApps ?
                <Button
                    value='Publish all apps linked'
                    startIcon='fas fa-upload'
                    onClick={props.onPublish}
                    loader={Boolean(props.pending === props.uuid)}
                    disabled={Boolean(props.pending)}
                    color='primary'
                    size='small'
                />
            :
                <Button
                    value='Delete'
                    startIcon='fal fa-trash'
                    onClick={(e) => {e.stopPropagation(); props.onDelete();}}
                    color='secondary'
                    size='small'
                />
            }
        </ApplicationTemplateTileStyled>
    );
};