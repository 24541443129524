//------------------------------------------------------------------------------
import CallAPI      from '../../utils/api';
import assetUtils   from '../../utils/assetUtils';

//------------------------------------------------------------------------------
const MAX_NODE = 1000;

//------------------------------------------------------------------------------
export async function createAssetAPI(workspaceUUID, assetType, assetName, assetDescription)
{
    return await CallAPI('/asset/create/', 'POST', { assetType, workspaceUUID, assetName, assetDescription });
}

//------------------------------------------------------------------------------
export async function deleteAssetAPI(assetType, assetUUID)
{
    await CallAPI('/asset/delete', 'DELETE', { assetType, assetUUID });
}

//------------------------------------------------------------------------------
export async function renameAssetAPI(assetType, assetUUID, newName)
{
    await CallAPI('/asset/rename', 'POST', { assetType, assetUUID, newName });
}

//------------------------------------------------------------------------------
export async function moveAssetAPI(assetType, dstWorkspaceUUID, assetUUID, options = {moveDependencies : false})
{
    return await CallAPI('/asset/move', 'POST', { assetType, dstWorkspaceUUID, assetUUID, options });
}

//------------------------------------------------------------------------------
export async function deleteSourceFilesAPI(sourceUUIDs)
{
    await CallAPI('/asset/sourceFiles', 'DELETE', { sourceUUIDs });
}

//------------------------------------------------------------------------------
export async function renameSourceFileAPI(sourceUUID, name)
{
    await CallAPI(`/asset/sourceFile/rename/${sourceUUID}`, 'POST', { name });
}

//------------------------------------------------------------------------------
export async function moveSourceFilesAPI(sourceUUIDs, dstWorkspaceUUID)
{
    return await CallAPI('/workspace/moveSourceFiles', 'POST', { sourceUUIDs, dstWorkspaceUUID });
}

//------------------------------------------------------------------------------
export async function getAssetDescription(assetType, assetUUID)
{
    return await CallAPI(`/asset/desc/${assetType}/${assetUUID}`, 'GET');
}

//------------------------------------------------------------------------------
export async function getAssetCode(assetUUID)
{
    return await CallAPI(`/asset/code/${assetUUID}`, 'GET', undefined, false);
}

//------------------------------------------------------------------------------
export async function getPointsOfInterestAPI(sceneUUID)
{
    const req = '?sceneUUID=' + sceneUUID;
    return await CallAPI('/scene/getPointsOfInterest' + req, 'GET');
}

//------------------------------------------------------------------------------
export async function getRefAPI(assetType, assetUUID, _opt = {})
{
    const options = encodeURI(JSON.stringify(
        {
            user: { name : true },
            assetName : true,
            workspace : { name: true },
            hasThumbnail : true,
            ..._opt
        }
    ));
    const req = '/' + assetType + '/' + assetUUID + '?options='+options;
    return await CallAPI('/asset/ref' + req, 'GET');
}

//------------------------------------------------------------------------------
export async function getDepAPI(assetType, assetUUID, options = {})
{
    const _options = encodeURI(JSON.stringify({
        assetName   : true,
        deep        : true,
        ...options
    }));
    return await CallAPI(`/asset/dep/${assetType}/${assetUUID}?options=${_options}`, 'GET');
}

//------------------------------------------------------------------------------
export async function getDepGraphAPI(assetType, assetUUID, options = {}, hasThumbnail)
{
    const _options          = encodeURI(JSON.stringify(options));
    const graph             = await CallAPI(`/asset/depGraph/${assetType}/${assetUUID}?options=${_options}`, 'GET');

    const assetLists        = {};
    const allDependencies   = graph.map(relationShips =>
    ({
        ...relationShips[relationShips.length - 1],
        direct : relationShips.length === 1
    }));

    for(const asset of allDependencies)
    {
        // Will remove duplicated entries and merge 'direct' property
        tryAddAssetPropertiesToList(asset, assetLists);
    }

    const dependencyGroups = Object.entries(assetLists).map(([assetListName, assets]) =>
    ({
        assetListName,
        assets          : assets.map(a => {a.hasThumbnail = Boolean(a.hasThumbnail); return a;}),
        countIndirect   : assets.length,
        countDirect     : assets.filter(a => a.direct).length,
        assetType       : assetUtils.getAssetTypeFromAssetListName(assetListName),
        assetLabel      : assetUtils.getAssetLabelFromAssetListName(assetListName)
    }));

    const nodes = dependencyGroups.reduce(
        (acc, g) =>
        {
            const nodes = g.assets.map(a =>
            ({
                id              : assetUtils.computeAssetIdentifier(a.uuid, a.version),
                name            : `${g.assetLabel} - ${a.name}`,
                assetType       : g.assetType,
                hasThumbnail    : a.hasThumbnail,
                isVisible       : true
            }));

            return [...acc, ...nodes];
        },
        []
    );

    if(nodes.length > MAX_NODE)
    {
        nodes.length = MAX_NODE;
    }

    nodes.push(
    {
        id              : assetUUID,
        name            : 'Root',
        isRoot          : true,
        isVisible       : true,
        hasThumbnail    : hasThumbnail,
        assetType
    });

    const nodeMap = {};
    for(const node of nodes)
    {
        nodeMap[node.id] = true;
    }

    const links = [];

    for(const relationShips of graph)
    {
        const relationShipTypes = relationShips.map(r => assetUtils.getAssetTypeFromAssetListName(assetUtils.getAssetListNameFromAssetLabel(r.type)));

        const firstTarget       = relationShips[0];
        const firstTargetID     = assetUtils.computeAssetIdentifier(firstTarget.uuid, firstTarget.version);

        if(nodeMap[firstTargetID])
        {
            links.push(
            {
                source              : assetUUID,
                target              : firstTargetID,
                relationShipTypes   : relationShipTypes,
                direct              : true,
                isVisible           : true,
                count               : firstTarget.count
            });
        }

        for (let i = 1; i < relationShips.length; ++i)
        {
            const source = relationShips[i - 1];
            const target = relationShips[i];

            const sourceID = assetUtils.computeAssetIdentifier(source.uuid, source.version);
            const targetID = assetUtils.computeAssetIdentifier(target.uuid, target.version);

            if(nodeMap[sourceID] && nodeMap[targetID])
            {
                links.push(
                {
                    source              : sourceID,
                    target              : targetID,
                    relationShipTypes   : relationShipTypes.slice(i),
                    direct              : false,
                    isVisible           : true,
                    count               : target.count
                });
            }
        }
    }

    const dependencyCount = dependencyGroups.reduce((acc, group) => acc + group.assets.length, 0);

    return {groups : dependencyGroups, nodes, links, dependencyCount};
}

//------------------------------------------------------------------------------
// Link material to its dependency, should be implemented in the API instead.
export async function _TO_REMOVE_linkMaterialDependencies(materialUUID, description)
{
    const promises = [];
    if(description.shaderRef)
    {
        promises.push(
            CallAPI('/asset/dep/link', 'POST', {fromAssetUUID : materialUUID, toAssetUUID : description.shaderRef})
        );
    }

    if(description.skinnedShaderRef)
    {
        promises.push(
            CallAPI('/asset/dep/link', 'POST', {fromAssetUUID : materialUUID, toAssetUUID : description.skinnedShaderRef})
        );
    }

    return Promise.all(promises);
}

//------------------------------------------------------------------------------
// Hack to get the asset produced by a scene type item.
// Get dependency graph and prune all dependencies after Material,
// The goal is to remove Texture, Shaders assets but to keep Animation through Animation graphs.
export async function _TO_REMOVE_getSceneDependencies(sceneUUID)
{
    const graph             = await CallAPI(`/asset/depGraph/scene/${sceneUUID}`, 'GET');
    const assetLists        = {};
    const allDependencies   = graph
        .filter(relationShips =>
            {
                const materialIndex = relationShips.findIndex(asset => asset.type === 'Material');
                return materialIndex === -1 || materialIndex === relationShips.length - 1;
            })
        .map(relationShips => relationShips[relationShips.length - 1]);

    for(const asset of allDependencies)
    {
        // Will remove duplicated entries and merge 'direct' property
        tryAddAssetPropertiesToList(asset, assetLists);
    }

    return assetLists;
}

//------------------------------------------------------------------------------
export async function getAssetInfoAPI(assetUUID, assetType)
{
    return await CallAPI('/asset/info/' + assetType + '/' + assetUUID, 'GET');
}

//------------------------------------------------------------------------------
export async function duplicateAssetAPI(assetUUID, assetType, workspaceUUID, newName)
{
    return await CallAPI('/asset/duplicate/' + assetType + '/' + assetUUID, 'POST', {workspaceUUID, newName});
}

//------------------------------------------------------------------------------
export async function getSourceFile(sourceUUID)
{
    return await CallAPI('/asset/sourceFile/' + sourceUUID, 'GET');
}

//------------------------------------------------------------------------------
function tryAddAssetPropertiesToList(assetProperties, assetLists)
{
    const assetLabel = assetProperties.type;

    const listName = assetUtils.getAssetListNameFromAssetLabel(assetLabel);
    if (!assetLists[listName])
    {
        assetLists[listName] = [];
    }
    else if (assetProperties.hasOwnProperty('uuid'))
    {
        const existingAsset = assetLists[listName].find(
            assetProperties.hasOwnProperty('version')
                ? (asset) => asset.uuid === assetProperties.uuid && asset.version === assetProperties.version
                : (asset) => asset.uuid === assetProperties.uuid
        );

        if(existingAsset)
        {
            existingAsset.direct = existingAsset.direct || assetProperties.direct;
            return;
        }
    }

    assetLists[listName].push(assetProperties);
}
