import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';
import styled                                       from 'styled-components';
import { useTranslation }                           from 'react-i18next';
import { Button, ControlSearch, Loader, Modal, ShortcutHint, Text
}                                                   from '../../../designSystem/components';
import { plural as p }                              from '../../../designSystem/utils';
import TileProject                                  from '../../../components/TileProject';
import { listLibraryPublicFoldersAPI, linkProjectToLibraryAPI, unlinkLibraryFromProjectAPI, listLibrariesAPI } from '../api';
import LibraryPublicDetails                         from './LibraryPublicDetails';
import { computeProjectMap }                        from '../../projects/functions';


//------------------------------------------------------------------------------
function SelectLibrariesModal(props)
{
    const { t }                             = useTranslation();
    const { currentProject }                = props;
    const isProjectLibrary                  = currentProject.isLibrary;

    //------------------------------------------------------------------------------
    const [ libraries, setLibraries ]       = useState(null);
    const [ librariesFiltered, setLibrariesFiltered ] = useState(null);
    const [ searchString, setSearchString ] = useState('');
    const controlSearchRef                  = useRef();

    //------------------------------------------------------------------------------
    const [ libraryContent, setLibraryContent ]   = useState(null);

    const fetchLibraryContent = async (library) =>
    {
        const folders                = await listLibraryPublicFoldersAPI(library.uuid);
        const _libraryContent        = { ...library, workspaceUUID : library.publicWorkspaceUUID, folders };
        _libraryContent.workspaceMap = new Map();
        _libraryContent.libraryMap   = new Map();
        _libraryContent.projectMap   = computeProjectMap(_libraryContent, _libraryContent.workspaceMap, library.uuid);
        setLibraryContent(_libraryContent);
    };

    //------------------------------------------------------------------------------
    useEffect(() =>
    {
        //--------------------------------------------------------------------------
        async function listLibraries()
        {
            let _libraries = await listLibrariesAPI();

            if(isProjectLibrary)
            {
                _libraries = _libraries.filter(p => p.uuid !== currentProject.uuid);
            }
            setLibrariesFiltered(_libraries);
            setLibraries(_libraries);
        }
        listLibraries();

    }, []);

    //------------------------------------------------------------------------------
    let timeout;
    useEffect(() =>
    {
        //-------------------------------------------------------------------------
        function escFunction(event)
        {
            switch(event.keyCode)
            {
            case 191: // Key: CTRL + /
                if(event.ctrlKey || event.metaKey)
                {
                    event.stopPropagation();
                    timeout = setTimeout(() =>
                    {
                        controlSearchRef.current.focus();
                    }, 100);
                }
                break;
            default:
                break;
            }
        }

        window.addEventListener('keydown', escFunction);
        return () =>
        {
            clearTimeout(timeout);
            window.removeEventListener('keydown', escFunction);
        };
    }, []);

    //--------------------------------------------------------------------------
    const updateSearchResults = (event) =>
    {
        const _searchString = event.target.value.toLowerCase();
        setSearchString(_searchString);

        const _librariesFiltered = _searchString ?
            libraries.filter(l => l.name.toLowerCase().includes(_searchString))
            :
            libraries;
        setLibrariesFiltered(_librariesFiltered);
    };

    //------------------------------------------------------------------------------
    // const toggleLibraryInSelection = (library) =>
    // {
    //     if(selection.find(l => l.uuid === library.uuid))
    //     {
    //         const _selection = selection.filter(l => l.uuid !== library.uuid);
    //         setSelection(_selection);
    //     }
    //     else
    //     {
    //         setSelection([ ...selection, library]);
    //     }
    // };

    //------------------------------------------------------------------------------
    const linkLibrary = async (libraryUUID) =>
    {
        await linkProjectToLibraryAPI(currentProject.uuid, libraryUUID);
        props.onClose();
    };

    //------------------------------------------------------------------------------
    const unlinkLibrary = async (libraryUUID) =>
    {
        await unlinkLibraryFromProjectAPI(currentProject.uuid, libraryUUID);
        props.onClose();
    };


    //------------------------------------------------------------------------------
    return (
        <Modal
            open={props.open}
            onClose={props.onClose}
            maxWidth='lg'
            noPadding
            position='top'
        >
            <div className='flex flex-col justify-center h-full m-auto'>
                <header className='flex justify-between py-4 px-5 border-b border-color-secondary'>
                    <Text format='title2' as='h1'>
                        Link new Libraries to {isProjectLibrary ? 'Library' : 'Project'} <b>{currentProject.name}</b>
                    </Text>
                    <Button
                        value={t('t:cancel')}
                        onClick={props.onClose}
                        color='tertiary'
                        size='small'
                    />
                </header>

                {libraryContent ?
                    <LibraryPublicDetails
                        currentProject={currentProject}
                        library={libraryContent}
                        setLibraryContent={setLibraryContent}
                        linkLibrary={linkLibrary}
                        unlinkLibrary={unlinkLibrary}
                        openAssetContextMenu={props.openAssetContextMenu}
                        openAssetEditor={props.openAssetEditor}
                        permissions={props.permissions}
                    />
                    :
                    <div className='py-5 px-5'>
                        <ControlSearch
                            value={searchString}
                            onChange={updateSearchResults}
                            placeholder={t('project:search libraries')}
                            autoFocus
                            inputRef={controlSearchRef}
                            size='small'
                            className='mb-4'
                        />
                        {/* <Text format='title3' color='tertiary' as='h2' className='mb-3 uppercase'>
                            Suggestions
                        </Text> */}
                        {libraries ?
                            <Fragment>
                                {libraries.length > 0 ?
                                    <Fragment>
                                        {librariesFiltered.length > 0 ?
                                            <ul className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-2'>
                                                {librariesFiltered.map(library =>
                                                    <li key={library.uuid}>
                                                        <TileProject
                                                            onClick={() => fetchLibraryContent(library)}
                                                            name={library.name}
                                                            highlightString={searchString}
                                                            // isSelected={selection.find(l => l.uuid === library.uuid) || currentProject.libraries.find(l => l.uuid === library.uuid)}
                                                            // isDisabled={currentProject.libraries.find(l => l.uuid === library.uuid)}
                                                            // isPublic={library.isPublic}
                                                            className={`w-full ${currentProject.libraries.find(l => l.uuid === library.uuid) ? 'opacity-50' : ''}`}
                                                        />
                                                    </li>
                                                )}
                                            </ul>
                                            :
                                            <Text format='body4' color='secondary'>
                                                {t('project:no libraries match your filter')}
                                            </Text>
                                        }
                                    </Fragment>
                                    :
                                    <Text format='body4' color='secondary'>
                                        No libraries.
                                    </Text>
                                }
                            </Fragment>
                            :
                            <Loader />
                        }
                    </div>
                }

            </div>
        </Modal>
    );
}

export default SelectLibrariesModal;
