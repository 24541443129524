//------------------------------------------------------------------------------
import React                                from 'react';

//------------------------------------------------------------------------------
import Deferred                             from '../../utils/deferred';

//------------------------------------------------------------------------------
import ProjectContext                       from './ProjectContext';
import { Loader }                           from '../../designSystem/components';
import { getRoleListWithPermissionsAPI }    from '../login/api';
import ErrorPage                            from './ErrorPage';
import { computeProjectMap }                from './functions';


//------------------------------------------------------------------------------
// import UserWrapper from '../UserWrapper';

//------------------------------------------------------------------------------
class ProjectWrapper extends React.Component
{
    //--------------------------------------------------------------------------
    static contextType = ProjectContext;

    //--------------------------------------------------------------------------
    componentDidMount()
    {
        this.requests = {
            updateProject               : this.updateProject,
            deleteProject               : this.deleteProject,
            setMainAsset                : this.setMainAsset,
            leaveProject                : this.leaveProject,

            createFolder                : this.createFolder,
            updateFolder                : this.updateFolder,
            moveFolder                  : this.moveFolder,
            deleteFolder                : this.deleteFolder,

            grantAccess                 : this.grantAccess,
            revokeAccess                : this.revokeAccess,

            addLibraryConsumer          : this.addLibraryConsumer,
            removeLibraryConsumer       : this.removeLibraryConsumer,

            createApplicationTemplate   : this.createApplicationTemplate,
            updateApplicationTemplate   : this.updateApplicationTemplate,
            deleteApplicationTemplate   : this.deleteApplicationTemplate,

            createApplication           : this.createApplication,
            updateApplication           : this.updateApplication,
            deleteApplication           : this.deleteApplication,

            getWorkspaceUUIDs           : this.getWorkspaceUUIDs
        };

        this.subscribe(this.props.match.params.projectUUID);
    }

    //--------------------------------------------------------------------------
    componentWillUnmount()
    {
        this.unsubscribe();
    }

    //--------------------------------------------------------------------------
    shouldComponentUpdate(nextProps, nextState)
    {
        const nextStateProjectUUID = nextState?.root?.uuid;
        if(nextStateProjectUUID)
        {
            const nextProjectUUID = nextProps.match.params.projectUUID;
            if(nextStateProjectUUID !== nextProjectUUID)
            {
                if(this.nextProjectUUID !== nextProjectUUID)
                {
                    this.nextProjectUUID = nextProjectUUID;
                    this.unsubscribe();
                    this.subscribe(nextProjectUUID);
                }

                // When changing project, prevent any render.
                return false;
            }
        }

        return true;
    }

    //--------------------------------------------------------------------------
    subscribe = (projectUUID) =>
    {
        this.context.subscribeToProject(projectUUID);
        this.registerToEvents();
    }

    //--------------------------------------------------------------------------
    unsubscribe = () =>
    {
        this.context.unsubcribeFromCurrentProject();
    }


    //--------------------------------------------------------------------------
    // Project

    //--------------------------------------------------------------------------
    updateProject = ({ name = '', description = '' }) =>
    {
        this.updateProjectUpdateState({ name, description });
        this.sendRequest('update-project', { name, description });
    };

    //--------------------------------------------------------------------------
    updateProjectUpdateState = (updateData) =>
    {
        this.setState({ root: { ...this.state.root, ...updateData } });
    };

    //--------------------------------------------------------------------------
    deleteProject = () =>
    {
        this.sendRequest('delete-project', {});
        this.deleteProjectUpdateState();
    };

    //--------------------------------------------------------------------------
    onProjectLeft = () =>
    {
        this.deleteProjectUpdateState();
    }

    //--------------------------------------------------------------------------
    deleteProjectUpdateState = () =>
    {
        this.props.history.push('/');
    };

    //--------------------------------------------------------------------------
    leaveProject = () =>
    {
        this.sendRequest('leave-project', {});
        this.onProjectLeft();
    }

    //--------------------------------------------------------------------------
    propagatePathChange = (currentFolder, newPath) =>
    {
        this.state.projectMap.delete(currentFolder.path);
        currentFolder.path = newPath;
        this.state.projectMap.set(currentFolder.path, currentFolder);

        for(const child of currentFolder.folders)
        {
            child.parentPath    = currentFolder.path;
            child.level         = currentFolder.level + 1;
            this.propagatePathChange(child, child.parentPath + child.name + '/');
        }
    }

    //--------------------------------------------------------------------------
    setMainAsset = ({ assetType, uuid, mainAsset }) =>
    {
        const mainAssetUUID = mainAsset ? mainAsset.uuid : uuid;

        this.updateProjectUpdateState(
        {
            mainAssetType: assetType,
            mainAssetUUID: mainAssetUUID
        });

        this.sendRequest('set-main-asset', { assetType, assetUUID: mainAssetUUID });
    }


    //--------------------------------------------------------------------------
    //--------------------------------------------------------------------------
    // Folder

    //--------------------------------------------------------------------------
    createFolder = (data) =>
    {
        this.sendRequest('create-folder', data);
    };

    //--------------------------------------------------------------------------
    createfolderUpdateState = (data, projectMap, root, libraryUUID, publicFolderUUID) =>
    {
        const { parentFolderUUID } = data;
        if (parentFolderUUID && (!publicFolderUUID || publicFolderUUID !== parentFolderUUID))
        {
            const parent    = projectMap.get(parentFolderUUID);
            if(!parent)
            {
                console.warn(`Cannot find parent ${parentFolderUUID} in project map`);
                return;
            }

            data.parentPath     = parent.path;
            data.path           = data.parentPath + data.name + '/';
            data.level          = parent.level + 1;
            data.updateTrigger  = 0;

            parent.folders.push(data);
            parent.updateTrigger++;
        }
        else
        {
            data.parentPath     = '/';
            data.path           = `/${data.name}/`;
            data.level          = root.level + 1;
            data.updateTrigger  = 0;

            root.folders.push(data);
            root.updateTrigger++;
        }

        data.folders        = [];
        data.libraryUUID    = libraryUUID || null;
        projectMap.set(data.uuid, data);
        projectMap.set(data.path, data);
        this.state.workspaceMap.set(data.workspaceUUID, data);
        this.refreshState();

        this.context.subscribeToWorkspace(data.workspaceUUID, false);
    }

    //--------------------------------------------------------------------------
    updateFolder = ({ folderUUID, name }) =>
    {
        this.updateFolderUpdateState({ folderUUID, name }, this.state.projectMap);

        this.sendRequest('update-folder', { folderUUID, name });
    };

    //--------------------------------------------------------------------------
    updateFolderUpdateState = ({ folderUUID, ...updateData }, projectMap) =>
    {
        const folder = projectMap.get(folderUUID);
        if(!folder)
        {
            console.warn(`Cannot find folder ${folderUUID} in project map`);
            return;
        }

        // if the folder name has changed
        if(updateData.hasOwnProperty('name') && updateData.name !== folder.name)
        {
            this.propagatePathChange(folder, folder.parentPath + updateData.name + '/');
        }

        for(const propertyName in updateData)
        {
            folder[propertyName] = updateData[propertyName];
        }

        const parent = projectMap.get(folder.parentPath);
        if(!parent)
        {
            console.warn(`Cannot find previous parent ${folder.parentPath} in project map`);
            return;
        }

        folder.updateTrigger++;
        parent.updateTrigger++;

        this.refreshState();
    }

    //--------------------------------------------------------------------------
    moveFolder = ({ folderUUID, parentFolderUUID }) =>
    {
        this.moveFolderUpdateState({ folderUUID, parentFolderUUID }, this.state.projectMap, this.state.root);

        this.sendRequest('move-folder', { folderUUID, parentFolderUUID });
    };

    //--------------------------------------------------------------------------
    moveFolderUpdateState = ({ folderUUID, parentFolderUUID = null }, projectMap, root, publicFolderUUID) =>
    {
        const folder = projectMap.get(folderUUID);
        if(!folder)
        {
            console.warn(`Cannot find folder ${folderUUID} in project map`);
            return;
        }

        const previousParent = projectMap.get(folder.parentPath);
        if(!previousParent)
        {
            console.warn(`Cannot find previous parent ${folder.parentPath} in project map`);
            return;
        }

        const newParent = parentFolderUUID && (!publicFolderUUID || publicFolderUUID !== parentFolderUUID)
                        ? projectMap.get(parentFolderUUID)
                        : root;
        if(!newParent)
        {
            console.warn(`Cannot find new parent ${parentFolderUUID} in project map`);
            return;
        }

        const index = previousParent.folders.findIndex(f => f.uuid === folderUUID);
        if(index === -1)
        {
            console.warn(`Cannot find folder ${folderUUID} in the declared parent ${parentFolderUUID}`);
            return;
        }

        previousParent.folders.splice(index, 1);
        newParent.folders.push(folder);

        previousParent.updateTrigger++;
        newParent.updateTrigger++;
        folder.updateTrigger++;

        folder.parentPath   = newParent.path;
        folder.level        = newParent.level + 1;
        this.propagatePathChange(folder, folder.parentPath + folder.name + '/');

        this.refreshState();
    }

    //--------------------------------------------------------------------------
    deleteFolder = ({ folderUUID }) =>
    {
        this.deleteFolderUpdateState({ folderUUID }, this.state.projectMap);

        this.sendRequest('delete-folder', { folderUUID });
    };

    //--------------------------------------------------------------------------
    deleteFolderUpdateState = ({ folderUUID }, projectMap) =>
    {
        const folder    = projectMap.get(folderUUID);
        if(!folder)
        {
            console.warn(`Cannot find folder ${folderUUID} in project map`);
            return;
        }

        const parent    = projectMap.get(folder.parentPath);
        if(!parent)
        {
            console.warn(`Cannot find parent ${parentFolderUUID} in project map`);
            return;
        }

        parent.folders  = parent.folders.filter(f => f.uuid !== folderUUID);
        parent.updateTrigger++;

        projectMap.delete(folder.path);
        projectMap.delete(folderUUID);
        this.state.workspaceMap.delete(folder.workspaceUUID);

        this.refreshState();
    }


    //--------------------------------------------------------------------------
    //--------------------------------------------------------------------------
    // Share Group

    //--------------------------------------------------------------------------
    grantAccess = ({ username, uuid:userUUID, role }) =>
    {
        this.grantAccessUpdateState({ username, userUUID, role });

        this.sendRequest('grant-access', { userUUID, roleUUID: role.uuid });
    }

    //--------------------------------------------------------------------------
    grantAccessUpdateState = ({ username, userUUID, role }) =>
    {
        const { shareGroup } = this.state.root;

        // If user already exists
        const alreadyExists = shareGroup.find(u => u.user.username === username);
        if (alreadyExists)
        {
            shareGroup.map(user =>
            {
                if (user.user.username === username)
                {
                    user.access = role;
                }
                return user;
            })
        }
        else
        {
            shareGroup.unshift({
                user    : { username, uuid: userUUID },
                access  : role
            })
        }

        this.refreshState();
    }

    //--------------------------------------------------------------------------
    revokeAccess = ({ username, uuid:userUUID }) =>
    {
        this.revokeAccessUpdateState({ userUUID });

        this.sendRequest('revoke-access', { userUUID });
    }

    //--------------------------------------------------------------------------
    revokeAccessUpdateState = ({ userUUID }) =>
    {
        const shareGroup = this.state.root.shareGroup.filter(user => user.user.uuid !== userUUID);
        this.setState({
            root: {
                ...this.state.root,
                shareGroup
            }
        });
    }

    //--------------------------------------------------------------------------
    //--------------------------------------------------------------------------
    // Library Consumers

    //--------------------------------------------------------------------------
    addLibraryConsumer = ({ username, uuid : userUUID }) =>
    {
        this.addLibraryConsumerUpdateState({ username, userUUID });
        this.sendRequest('add-library-consumer', { userUUID });
    }

    //--------------------------------------------------------------------------
    addLibraryConsumerUpdateState = ({ username, userUUID }) =>
    {
        const { consumerGroup } = this.state.root;

        // If user already exists
        const alreadyExists = consumerGroup.find(u => u.userUUID === userUUID);
        if (alreadyExists)
        {
            return;
        }

        consumerGroup.unshift({username, userUUID});
        this.refreshState();
    }

    //--------------------------------------------------------------------------
    removeLibraryConsumer = ({ userUUID }) =>
    {
        this.removeLibraryConsumerUpdateState({ userUUID });
        this.sendRequest('remove-library-consumer', { userUUID });
    }

    //--------------------------------------------------------------------------
    removeLibraryConsumerUpdateState = ({ userUUID }) =>
    {
        const consumerGroup = this.state.root.consumerGroup.filter(u => u.userUUID !== userUUID);
        this.setState({
            root: {
                ...this.state.root,
                consumerGroup
            }
        });
    }

    //--------------------------------------------------------------------------
    //--------------------------------------------------------------------------
    // ApplicationTemplates

    //--------------------------------------------------------------------------
    createApplicationTemplate = async ({provider, gitPath, branchName}) =>
    {
        this.createApplicationTemplateRequest = new Deferred();
        this.sendRequest('create-application-template', { provider, gitPath, branchName });
        await this.createApplicationTemplateRequest.promise;
    }

    //--------------------------------------------------------------------------
    updateApplicationTemplate = (applicationTemplate) =>
    {
        const { uuid : applicationTemplateUUID, ...props } = applicationTemplate;
        this.sendRequest('update-application-template', { applicationTemplateUUID, ...props });
        this.updateApplicationTemplateState(applicationTemplateUUID, props);
    }

    //--------------------------------------------------------------------------
    updateApplicationTemplateState = (applicationTemplateUUID, updateData) =>
    {
        const applicationTemplate = this.state.root.applicationTemplates.find(r => r.uuid === applicationTemplateUUID);
        if(!applicationTemplate)
        {
            console.warn(`Cannot find application template ${applicationTemplateUUID} in project`);
            return;
        }

        for(const propertyName in updateData)
        {
            applicationTemplate[propertyName] = updateData[propertyName];
        }

        this.refreshState();
    }

    //--------------------------------------------------------------------------
    updateApplicationTemplateLinkedApplication(applicationTemplateUUID, applicationUUID, remove = false)
    {
        const applicationTemplate = this.state.root.applicationTemplates.find(r => r.uuid === applicationTemplateUUID);
        if(!applicationTemplate)
        {
            console.warn(`Cannot find application template ${applicationTemplateUUID} in project`);
            return;
        }

        if(!remove)
        {
            applicationTemplate.linkedApplicationUUIDs.push(applicationUUID);
        }
        else
        {
            applicationTemplate.linkedApplicationUUIDs = applicationTemplate.linkedApplicationUUIDs.filter(appUUID => appUUID !== applicationUUID);
        }
    }

    //--------------------------------------------------------------------------
    deleteApplicationTemplate = (applicationTemplateUUID) =>
    {
        this.sendRequest('delete-application-template', { applicationTemplateUUID });
        this.deleteApplicationTemplateState(applicationTemplateUUID);
    }

    //--------------------------------------------------------------------------
    deleteApplicationTemplateState = (applicationTemplateUUID) =>
    {
        this.state.root.applicationTemplates = this.state.root.applicationTemplates.filter(r => r.uuid !== applicationTemplateUUID);
        this.refreshState();
    }

    //--------------------------------------------------------------------------
    //--------------------------------------------------------------------------
    // Applications

    //--------------------------------------------------------------------------
    createApplication = async (applicationData) =>
    {
        this.createApplicationRequest = new Deferred();
        this.sendRequest('create-application', applicationData);
        const createdApplication = await this.createApplicationRequest.promise;
        this.createApplicationUpdateState(createdApplication);
    }

    //--------------------------------------------------------------------------
    createApplicationUpdateState = (applicationData) =>
    {
        this.state.root.applications.push(applicationData);
        this.updateApplicationTemplateLinkedApplication(applicationData.templateUUID, applicationData.uuid, false);
        this.refreshState();
    }

    //--------------------------------------------------------------------------
    updateApplication = async (application) =>
    {
        this.updateApplicationRequest = new Deferred();
        const { uuid : applicationUUID, ...applicationData } = application;
        this.sendRequest('update-application', { applicationUUID, ...applicationData });
        const updatedApplication = await this.updateApplicationRequest.promise;
        this.updateApplicationState(applicationUUID, updatedApplication);
    }

    //--------------------------------------------------------------------------
    updateApplicationState = (applicationUUID, updateData) =>
    {
        const application = this.state.root.applications.find(r => r.uuid === applicationUUID);
        if(!application)
        {
            console.warn(`Cannot find application ${applicationUUID} in project`);
            return;
        }

        if(updateData.templateUUID && application.templateUUID !== updateData.templateUUID)
        {
            this.updateApplicationTemplateLinkedApplication(application.templateUUID, application.uuid, true);
            this.updateApplicationTemplateLinkedApplication(updateData.templateUUID, application.uuid, false);
        }

        for(const propertyName in updateData)
        {
            application[propertyName] = updateData[propertyName];
        }

        this.refreshState();
    }

    //--------------------------------------------------------------------------
    deleteApplication = (applicationUUID) =>
    {
        this.sendRequest('delete-application', { applicationUUID });
        this.deleteApplicationState(applicationUUID);
    }

    //--------------------------------------------------------------------------
    deleteApplicationState = (applicationUUID) =>
    {
        const index = this.state.root.applications.findIndex(r => r.uuid === applicationUUID);
        if(index < 0)
        {
            console.warn(`Cannot find application ${applicationUUID} in project`);
            return;
        }

        const application = this.state.root.applications[index];

        this.updateApplicationTemplateLinkedApplication(application.templateUUID, applicationUUID, true);
        this.state.root.applications.splice(index, 1);
        this.refreshState();
    }

    //--------------------------------------------------------------------------
    //--------------------------------------------------------------------------
    // Register to Events

    //--------------------------------------------------------------------------
    registerToEvents = () =>
    {
        this.context.registerToEvents(async (eventType, data, emitter) =>
        {
            switch(eventType)
            {
                //--------------------------------------------------------------------------
                // Project

                case 'project-info':
                {
                    this.connected                  = true;
                    const workspaceMap              = new Map();
                    const libraryMap                = new Map();

                    const { permissions, ...root }  = data;
                    const projectMap                = computeProjectMap(root, workspaceMap);

                    for(const library of root.libraries)
                    {
                        library.projectMap = computeProjectMap(library, workspaceMap, library.uuid, root.level + 1);
                        libraryMap.set(library.uuid, library);
                    }

                    root.shareGroup = data.group.members.map(member =>
                    ({
                        user : {
                            username : member.username,
                            uuid     : member.userUUID
                        },
                        access : {
                            name    : member.role,
                            uuid    : member.roleUUID
                        }
                    }));

                    if(data.consumerGroup)
                    {
                        root.consumerGroup = data.consumerGroup.members
                            .filter(member => member.userUUID !== data.consumerGroup.creator.userUUID)
                            .map(member =>
                            ({
                                username    : member.username,
                                userUUID    : member.userUUID
                            }));
                    }

                    this.setState({ root, projectMap, permissions, workspaceMap, libraryMap });
                    this.nextProjectUUID = null;

                    const allWorkspaceUUIDs     = Array.from(workspaceMap.keys()); // Include library workspaces
                    const projectWorkspaceUUIDs = this.getWorkspaceUUIDs({includeLibraries : false, includeTrash : true});

                    // Unsubscribe from all workspaces of the previous project (if any)
                    await this.context.unsubscribeFromWatchedWorkspaces();

                    // Subscribe to all workspaces of the current project
                    await this.context.subscribeToWorkspaces(allWorkspaceUUIDs);

                    // Fetch and listen to tasks events for all workspaces of the current project
                    await this.context.watchWorkspaceTasks(projectWorkspaceUUIDs);
                    break;
                }

                case 'update-project':
                case 'set-main-asset':
                {
                    const { projectUUID, ...updateData } = data;
                    if(projectUUID === this.state.root.uuid)
                    {
                        this.updateProjectUpdateState(updateData);
                    }
                    break;
                }

                case 'delete-project':
                // case 'project-deleted':
                {
                    if(this.state.root.uuid === data.projectUUID)
                    {
                        this.deleteProjectUpdateState();
                    }
                    break;
                }

                //--------------------------------------------------------------------------
                // Folder

                case 'create-folder':
                case 'folder-created':
                {
                    this.createfolderUpdateState(data, this.state.projectMap, this.state.root);
                    break;
                }

                case 'update-folder':
                // case 'folder-updated':
                {
                    this.updateFolderUpdateState(data, this.state.projectMap);
                    break;
                }

                case 'move-folder':
                // case 'folder-moved':
                {
                    this.moveFolderUpdateState(data, this.state.projectMap, this.state.root);
                    break;
                }

                case 'delete-folder':
                // case 'folder-deleted':
                {
                    this.deleteFolderUpdateState(data, this.state.projectMap);
                    break;
                }

                //--------------------------------------------------------------------------
                // Access

                case 'grant-access':
                // case 'access-granted':
                {
                    const roleList  = await getRoleListWithPermissionsAPI();
                    const role      = roleList.find(r => r.uuid === data.roleUUID);
                    const uuid      = data.userUUID;
                    this.grantAccessUpdateState({ role, uuid, ...data });
                    break;
                }

                case 'revoke-access':
                case 'leave-project':
                // case 'access-revoked':
                {
                    const { projectUUID, ...accessData } = data;
                    if(projectUUID === this.state.root.uuid)
                    {
                        this.revokeAccessUpdateState(accessData);
                    }
                    break;
                }

                case 'user-access':
                {
                    const { eventType, projectUUID } = data;
                    switch(eventType)
                    {
                        case 'revoke-access':
                        case 'leave-project':
                            // If our own access has been revoked, then get out !
                            if(projectUUID === this.state.root.uuid)
                            {
                                this.onProjectLeft(projectUUID);
                            }
                            break;
                    };
                }
                break;

                //--------------------------------------------------------------------------
                // Library

                case 'convert-to-library':
                {
                    const { shareGroup }    = this.state.root;

                    this.state.workspaceMap.clear();
                    this.state.libraryMap.clear();

                    const root              = data;
                    root.shareGroup         = shareGroup;
                    root.consumerGroup      = [];
                    const projectMap        = computeProjectMap(root, this.state.workspaceMap);

                    for(const library of root.libraries)
                    {
                        library.projectMap = computeProjectMap(library, this.state.workspaceMap, library.uuid, root.level + 1);
                        this.state.libraryMap.set(library.uuid, library);
                    }

                    this.setState({ root, projectMap });
                    break;
                }

                case 'link-library':
                {
                    const library = data;
                    library.projectMap = computeProjectMap(library, this.state.workspaceMap, library.uuid, this.state.root.level + 1);
                    this.state.root.libraries.push(library);
                    this.state.libraryMap.set(library.uuid, library);
                    this.refreshState();

                    const libraryWorkspaceUUIDs = this.getWorkspaceUUIDs({ includeLibraries : [library.uuid], folder : null });
                    this.context.subscribeToWorkspaces(libraryWorkspaceUUIDs);
                    break;
                }

                case 'unlink-library':
                {
                    const { libraryUUID } = data;

                    const index = this.state.root.libraries.findIndex(l => l.uuid === libraryUUID);
                    if(index === -1)
                    {
                        console.warn(`Cannot find library ${libraryUUID}`);
                        return;
                    }
                    this.state.root.libraries.splice(index, 1);
                    this.state.libraryMap.delete(libraryUUID);
                    this.refreshState();
                    break;
                }

                case 'library-update':
                {
                    const { eventType, libraryUUID, data : eventData } = data;

                    const library = this.state.root.libraries.find(l => l.uuid === libraryUUID);
                    if(!library)
                    {
                        console.warn(`Cannot find library ${libraryUUID}`);
                        return;
                    }

                    switch(eventType)
                    {
                        case 'create-folder':
                        {
                            this.createfolderUpdateState(eventData, library.projectMap, library, library.uuid, library.publicFolderUUID);
                            break;
                        }

                        case 'update-folder':
                        {
                            this.updateFolderUpdateState(eventData, library.projectMap);
                            break;
                        }

                        case 'move-folder':
                        {
                            this.moveFolderUpdateState(eventData, library.projectMap, library, library.publicFolderUUID);
                            break;
                        }

                        case 'delete-folder':
                        {
                            this.deleteFolderUpdateState(eventData, library.projectMap);
                            break;
                        }

                        case 'update-project':
                        case 'set-main-asset':
                        {
                            for(const propertyName in eventData)
                            {
                                library[propertyName] = eventData[propertyName];
                            }
                            this.refreshState();
                            break;
                        }
                    };
                    break;
                }

                case 'grant-public-access':
                {
                    this.state.root.isPublic = true;
                    this.refreshState();
                    break;
                }

                case 'add-library-consumer':
                {
                    this.addLibraryConsumerUpdateState(data);
                    break;
                }

                case 'remove-library-consumer':
                {
                    this.removeLibraryConsumerUpdateState(data);
                    break;
                }

                //--------------------------------------------------------------------------
                // ApplicationTemplates

                case 'application-template-created':
                    this.createApplicationTemplateRequest.resolve();
                case 'create-application-template':
                {
                    this.state.root.applicationTemplates.push({...data, linkedApplicationUUIDs : []});
                    this.refreshState();
                    break;
                }

                case 'update-application-template':
                {
                    const { applicationTemplateUUID, ...props } = data;
                    this.updateApplicationTemplateState(applicationTemplateUUID, props);
                    break;
                }

                case 'delete-application-template':
                    this.deleteApplicationTemplateState(data.applicationTemplateUUID);
                    break;

                //--------------------------------------------------------------------------
                // Applications

                case 'application-created':
                    this.createApplicationRequest.resolve(data);
                    break;

                case 'create-application':
                {
                    const { projectUUID, ...props } = data;
                    if(this.state.root.uuid === projectUUID)
                    {
                        this.createApplicationUpdateState(props);
                    }
                    break;
                }

                case 'application-updated':
                    this.updateApplicationRequest.resolve(data);
                    break;

                case 'update-application':
                {
                    const { projectUUID, applicationUUID, ...props } = data;
                    if(this.state.root.uuid === projectUUID)
                    {
                        this.updateApplicationState(applicationUUID, props);
                    }
                    break;
                }

                case 'application-update-failed':
                    this.updateApplicationRequest.reject(data.error);
                    break;

                case 'delete-application':
                {
                    if(this.state.root.uuid === data.projectUUID)
                    {
                        this.deleteApplicationState(data.applicationUUID);
                    }
                    break;
                }

                //--------------------------------------------------------------------------
                // Error

                case 'error':
                {
                    this.state          = this.state || {};
                    this.state.errorNum = data.errorNum;
                    this.refreshState();
                    break;
                }
            }
        });
    }

    //--------------------------------------------------------------------------
    sendRequest = (type, data) =>
    {
        this.context.sendRequest(type, data);
    };

    //--------------------------------------------------------------------------
    getWorkspaceUUIDs = (options = {}) =>
    {
        const {
            includeLibraries = false,
            includeTrash     = false,
            folder           = this.state.root
        } = options;

        const workspaceUUIDs    = [];
        const queue             = [];

        if(folder)
        {
            queue.push(folder);
        }

        if(includeLibraries)
        {
            if(Array.isArray(includeLibraries))
            {
                for(const libraryUUID of includeLibraries)
                {
                    const library = this.state.libraryMap.get(libraryUUID);
                    if(!library)
                    {
                        console.warn(`Cannot find library ${libraryUUID} in getWorkspaceUUIDs`);
                        continue;
                    }
                    queue.push(library);
                }
            }
            else
            {
                queue.push(...this.state.root.libraries);
            }
        }

        if(includeTrash && this.state.root.SystemFolders.Trash)
        {
            queue.push(this.state.root.SystemFolders.Trash);
        }

        let currentFolder       = null;
        while(currentFolder = queue.shift())
        {
            workspaceUUIDs.push(currentFolder.workspaceUUID);
            for(const folder of currentFolder.folders)
            {
                queue.push(folder);
            }
        }

        return workspaceUUIDs;
    };

    //--------------------------------------------------------------------------
    //--------------------------------------------------------------------------
    // Render

    //--------------------------------------------------------------------------
    provideUsersProperties(collaborators)
    {
        for(const {userUUID, ...userProperties} of collaborators)
        {
            UserWrapper.provideUserProperties(userUUID, userProperties);
        }
    }

    //--------------------------------------------------------------------------
    refreshState()
    {
        this.setState(this.state);
    }

    //--------------------------------------------------------------------------
    render = () =>
    {
        if(!this.state)         return <Loader centered />;
        if(this.state.errorNum) return <ErrorPage errorNum={this.state.errorNum} />;

        const currentProject    = this.state.root;

        const projectPage       = this.props.match.params.projectPage || 'content';
        const isLibrary         = projectPage === 'library' ;

        const libraryUUID       = isLibrary && this.props.match.params.uuid1;
        const currentFolderUUID = isLibrary ? this.props.match.params.uuid2 : this.props.match.params.uuid1;
        const sourceFileUUID    = isLibrary ? this.props.match.params.uuid3 : this.props.match.params.uuid2;

        const currentLibrary    = (libraryUUID && this.state.libraryMap.get(libraryUUID)) || null;
        const currentFolder     = currentLibrary
                                ? currentFolderUUID
                                    ? currentLibrary.projectMap.get(currentFolderUUID)
                                    : currentLibrary
                                : currentFolderUUID && this.state.projectMap.get(currentFolderUUID);

        return this.props.children(
        {
            currentProject, currentFolder, currentLibrary, projectPage, sourceFileUUID,
            projectMap      : this.state.projectMap,
            libraryMap      : this.state.libraryMap,
            workspaceMap    : this.state.workspaceMap,
            requests        : this.requests,
            permissions     : this.state.permissions
        });
    }
}

//------------------------------------------------------------------------------
export default ProjectWrapper;
