import React, { useState }              from 'react';
import PropTypes                        from 'prop-types';

import { Button, ButtonIcon
}                                       from '../../designSystem/components';
import getSystemFolderIcon              from '../../utils/systemFolderIcons';

//------------------------------------------------------------------------------
export default function Cell(props)
{
    //------------------------------------------------------------------------------
    const { node, index, isCurrent, style } = props;
    const isRoot       = node.level === 0;
    const { isSystem } = node;
    const isLibrary    = Boolean(node.libraryUUID);

    //------------------------------------------------------------------------------
    const [ focus, setFocus ] = useState(null);

    //------------------------------------------------------------------------------
    const onContextMenu = (e) =>
    {
        setFocus(node.uuid);

        const asset = {
            uuid        : node.uuid,
            assetType   : isLibrary ? 'library' : 'folder',
            name        : node.name,
        };
        const anchor            = 'mouse';
        const eventFrom         = 'FOLDER_TREE';
        const onCloseCallback   = () =>
        {
            setFocus(null);
        };

        props.onContextMenu(e, asset, { anchor, eventFrom, onCloseCallback });
    };

    //------------------------------------------------------------------------------
    // Drag n Drop

    const [ dragOver, setDragOver ] = useState(0);

    //------------------------------------------------------------------------------
    const onDragStart = (e) =>
    {
        const assetType = 'folder';
        props.onDragStart(e, node, assetType);
    };

    //------------------------------------------------------------------------------
    const onDrop = (e) =>
    {
        setDragOver(0);
        if (isDroppable(e))
        {
            props.onDrop(e, node);
        }
    };

    //------------------------------------------------------------------------------
    const onDragEnter = (e) =>
    {
        if (isDroppable(e))
        {
            setDragOver( dragOver + 1 || 1 );
        }
    };

    //------------------------------------------------------------------------------
    const onDragLeave = (e) =>
    {
        if (isDroppable(e))
        {
            setDragOver( dragOver - 1 || 0 );
        }
    };

    //------------------------------------------------------------------------------
    const isDroppable = (e) =>
    {
        return !e.dataTransfer.types.includes(`3dverse/${node.uuid}`)
                || e.dataTransfer.types.includes('Files');
    };

    //------------------------------------------------------------------------------
    const computeIcon = () =>
    {
        if (isLibrary && node.level === 1)
        {
            return 'fal fa-link';
        }

        if(isSystem)
        {
            return getSystemFolderIcon(node);
        }

        if(isRoot)
        {
            return 'fal fa-folder';
        }
    };
    const startIconClassName = computeIcon();

    //------------------------------------------------------------------------------
    return (
        <div
            className='flex items-center'
            style={style}
            onDragOver={(e) => e.preventDefault()}
        >
            <div style={{
                width: (node.level * 1) + 'rem'
            }}></div>

            <div className='w-4 h-full'>
                {node.folders.length > 0 &&
                    <ButtonIcon
                        onClick={() => props.onExpand(node, index)}
                        className='cursor-pointer opacity-80'
                        iconClassName={`fas fa-caret-right transition-transform ${props.isOpen ? 'fa-rotate-90' : ''}`}
                        size='xsmall'
                        color='tertiary'
                        square
                        style={{ height: '100%' }}
                    />
                }
            </div>

            <Button
                value={node.name}

                onClick={() => props.onClick(node)}
                onContextMenu={((!isLibrary && isRoot) || isSystem) ? null : onContextMenu}
                draggable={!(isRoot || isSystem || node.libraryUUID)}
                onDragStart={isSystem ? null : onDragStart}
                onDrop={node.libraryUUID ? null : onDrop}
                onDragEnter={node.libraryUUID ? null : onDragEnter}
                onDragLeave={node.libraryUUID ? null : onDragLeave}
                onDragOver={(e) => e.preventDefault()}
                startIcon={startIconClassName}
                color={(dragOver || isCurrent) ? 'primary' : 'tertiary'}
                size='small'
                contentAlign='left'
                fullWidth
                noPadding
                className={`flex-1 pl-1 ${focus === node.uuid ? 'focus' : ''}`}
            />
        </div>
    );
}

Cell.propTypes = {
    style         : PropTypes.object.isRequired,
    onExpand      : PropTypes.func.isRequired,
    index         : PropTypes.number.isRequired,
    onClick       : PropTypes.func,
    onCheck       : PropTypes.func,
    node          : PropTypes.shape({
        id              : PropTypes.number,
        text            : PropTypes.string,
        level           : PropTypes.number,
        isLeaf          : PropTypes.bool,
        isOpen          : PropTypes.bool,
        isLastNode      : PropTypes.bool,
        isLastBranch    : PropTypes.bool,
        isRoot          : PropTypes.bool
    }).isRequired
};
