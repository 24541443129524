import React, { useEffect, useState, useContext }           from 'react';
import { useTranslation }                                   from 'react-i18next';
import Can                                                  from '../../../utils/permissions';
import { Button, Icon, Divider, Text }                      from '../../../designSystem/components';
import ProjectDeleteModal                                   from './ProjectDeleteModal';
import ProjectNameSection                                   from './ProjectNameSection';
import ProjectShareGroupSection                             from './ProjectShareGroupSection';
import LibraryConsumerSection                               from './LibraryConsumerSection';
import ConfirmationModal                                    from '../../../components/ConfirmationModal';
import parseError                                           from '../../../utils/parseError';
import assetUtils                                           from '../../../utils/assetUtils';
import UserContext                                          from '../../account/userContext';

import { convertProjectToLibraryAPI, grantPublicAccess }    from '../../libraries/api';
import { publishAssetWorkspaces, isWorkspaceEmpty }         from '../api';
import getSystemFolderIcon                                  from '../../../utils/systemFolderIcons';

//------------------------------------------------------------------------------
const publishableAssets     = ['shader', 'renderGraph', 'script', 'animationSequence'];
const maxUndeletableFolders = 5;

//------------------------------------------------------------------------------
function ProjectSettings(props)
{
    //------------------------------------------------------------------------------
    const userData                                  = useContext(UserContext);
    const { t }                                     = useTranslation();
    const { currentProject }                        = props;
    const { isLibrary, isPublic }                   = currentProject;

    //------------------------------------------------------------------------------
    const [ message, setMessage ]                       = useState(null);
    const [ projectDeleteModal, setProjectDeleteModal ] = useState(null);
    const [ pending, setPending ]                       = useState('');
    const [ showMore, setShowMore ]                     = useState(false);
    const hasApplications                               = currentProject.applications.length > 0;

    //------------------------------------------------------------------------------
    const generateProjectPackageURL = () =>
    {
        const workspaceUUIDs = props.requests.getWorkspaceUUIDs();

        const options = {
            attachment : `${currentProject.name}.zip`,
            packagePublicDependencies : isPublic
        };

        return `${apiGatewayURL}/workspace/packageWorkspaces?workspaceUUIDs=${encodeURI(JSON.stringify(workspaceUUIDs))}&options=${encodeURI(JSON.stringify(options))}&token=${apiToken}`;
    };

    //------------------------------------------------------------------------------
    const packageProject = () =>
    {
        window.open(generateProjectPackageURL(), '_blank');
    };

    //------------------------------------------------------------------------------
    const [confirmationModal, setConfirmationModal] = useState(null);

    //------------------------------------------------------------------------------
    const convertProjectToLibrary = async () =>
    {
        setPending('convertToLibrary');
        try
        {
            await convertProjectToLibraryAPI(currentProject.uuid);
            setMessage({ title: 'Great! This project is now a library.', description: 'Share it with others.', color: 'positive' });
        }
        catch(err)
        {
            const error = parseError(err);
            setMessage({ title: 'Error ' + error.errorNum, description: error.message, color: 'negative' });
        }
        setTimeout(() => setMessage(null), 10000);
        setPending('');
    };

    //------------------------------------------------------------------------------
    const exposeLibraryToPublic = async () =>
    {
        await grantPublicAccess(currentProject.uuid);
        setMessage({ title: 'Great! This project is now shared with everyone.', color: 'positive' });
        setTimeout(() => setMessage(null), 10000);
    };

    //------------------------------------------------------------------------------
    const compileAllAssets = async (assetListName) =>
    {
        const workspaceUUIDs = props.requests.getWorkspaceUUIDs();
        setPending(assetListName);

        try
        {
            const assets = await publishAssetWorkspaces(assetListName, workspaceUUIDs);
            setMessage({ title: `${assets.length} ${assetListName} compilation scheduled`, color: 'positive' });
        }
        catch(err)
        {
            const error = parseError(err);
            setMessage({ title: 'Error ' + error.errorNum, description : error.message, color: 'negative' });
        }
        finally
        {
            setTimeout(() => setMessage(null), 10000);
            setPending('');
        }
    };

    //------------------------------------------------------------------------------
    const [undeletableFolders, setUndeletableFolders] = useState(null);

    //------------------------------------------------------------------------------
    const lookForNotEmptyFolder = async () =>
    {
        const workspaceUUIDs        = props.requests.getWorkspaceUUIDs({includeTrash : true});
        const emptyStates           = await isWorkspaceEmpty(workspaceUUIDs);
        const notEmptyWorkspaces    = emptyStates.filter(entry => !entry.isEmpty);
        const folders               = notEmptyWorkspaces.map(({workspaceUUID}) => props.workspaceMap.get(workspaceUUID));
        setUndeletableFolders(folders);
    }

    //------------------------------------------------------------------------------
    useEffect(() => lookForNotEmptyFolder(), []);

    //------------------------------------------------------------------------------
    return (
        <section
            className={`wrapper pt-4 sm:pt-6 pb-10 ${props.className ?? props.className }`}
            style={props.style}
        >
            {message &&
                <div className={`mb-5 px-4 py-3 ${message.color ? `text-color-feedback-${message.color} bg-color-feedback-${message.color}` : 'bg-color-underground' } rounded`}>
                    <Text format='title3' className='mb-1'>
                        {message.title}
                    </Text>
                    <Text format='body3' className='opacity-80'>
                        {message.description}
                    </Text>
                </div>
            }
            <header className='flex justify-between'>
                <Text format='title1'>
                    {t('t:settings')}
                </Text>
            </header>

            <div className='flex flex-col gap-4 max-w-3xl mt-4'>

                {Can(props.permissions, 'updateProject') &&
                    <ProjectNameSection
                        currentProject={currentProject}
                        requests={props.requests}
                    />
                }

                <ProjectShareGroupSection
                    currentProject={currentProject}
                    requests={props.requests}
                    permissions={props.permissions}
                />

                {
                    isLibrary &&
                    <LibraryConsumerSection
                        currentProject={currentProject}
                        requests={props.requests}
                        permissions={props.permissions}
                    />
                }

                <section className='p-4 bg-color-underground border border-color-tertiary-alt rounded'>
                    <header className='mb-2'>
                        <Text format='title2'>
                            {t(`project:export ${isLibrary ? 'library' : 'project'}`)}
                        </Text>
                    </header>
                    <Text format='body3' color='secondary'>
                        {t(`project:export ${isLibrary ? 'library' : 'project'} as ZIP Package`)}
                    </Text>

                    <div className='flex gap-2 mt-3'>
                        <Button
                            value={t(`project:export ${isLibrary ? 'library' : 'project'}`)}
                            onClick={packageProject}
                            startIcon='fal fa-file-archive'
                            size='small'
                        />
                    </div>
                </section>

                {!isLibrary && Can(props.permissions, 'convertToLibrary') &&
                    <section className='p-4 bg-color-underground border border-color-tertiary-alt rounded'>
                        <header className='mb-2'>
                            <Text format='title2'>
                                {t('project:convert your project to a Library')}
                            </Text>
                        </header>
                        <Text format='body3' color='secondary' className='mb-1'>
                            {t('project:library presentation sentence')}
                        </Text>
                        {hasApplications &&
                            <Text format='body3' color='warning'>
                                {t('project:delete app sentence')}
                            </Text>
                        }
                        <Button
                            value={t('project:convert to library')}
                            onClick={() => setConfirmationModal(
                            {
                                titleHTML   : t('project:convert project to library', {projectName : currentProject.name}),
                                onSubmit    : convertProjectToLibrary,
                                submitText  : t('project:convert to library')
                            })}
                            loader={pending === 'convertToLibrary'}
                            disabled={Boolean(pending) || hasApplications}
                            startIcon='fal fa-link'
                            className='mt-3'
                            size='small'
                        />
                    </section>
                }

                {isLibrary && !isPublic && Can(userData.account.permissions, 'grantPublicAccess') &&
                    <section className='p-4 bg-color-underground border border-color-tertiary-alt rounded'>
                        <header className='mb-2'>
                            <Text format='title2'>
                                {t('project:make your library public')}
                            </Text>
                        </header>
                        <Text format='body3' color='secondary' className='mb-1'>
                            {t('project:library expose sentence')}
                        </Text>

                        <Button
                            value={t('project:make your library public')}
                            onClick={() => setConfirmationModal(
                            {
                                titleHTML   : t('project:make library public', {projectName : currentProject.name}),
                                onSubmit    : exposeLibraryToPublic,
                                submitText  : t('project:make your library public')
                            })}
                            startIcon='fas fa-globe-americas'
                            className='mt-3'
                            size='small'
                        />
                    </section>
                }

                {Can(props.permissions, 'createAsset') && Can(userData.account.permissions, 'scheduleCompileTask') && publishableAssets.length > 0 &&
                    <section className='p-4 bg-color-underground border border-color-tertiary-alt rounded'>
                        <header className='mb-3'>
                            <Text format='title2'>
                                Compilation
                            </Text>
                        </header>
                        <div className='grid grid-cols-2 gap-4'>
                            {publishableAssets.map(assetType =>
                            {
                                const assetListName = assetUtils.getAssetListNameFromAssetType(assetType);
                                return (
                                    <div key={assetType} style={{ flex: 1 }}>
                                        <Text format='title3' className='mb-1 capitalize'>
                                            {assetListName}
                                        </Text>
                                        <Text format='body3' color='secondary' className='mb-1'>
                                            Schedule a compile task on all {assetListName} of the project.
                                        </Text>
                                        <Button
                                            value={'Compile all ' + assetListName}
                                            onClick={() => compileAllAssets(assetListName)}
                                            startIconProps={{ name: 'asset', assetType }}
                                            className='mt-2'
                                            size='small'
                                            loader={pending === assetListName}
                                            disabled={Boolean(pending)}
                                        />
                                    </div>
                                );
                            }
                            )}
                        </div>
                    </section>
                }

                <Divider horizontal margin={1.5} />

                {Can(props.permissions, 'leaveProject') &&
                    <section className='p-4 bg-color-underground border border-color-tertiary-alt rounded'>
                        <header className='mb-2'>
                            <Text format='title2' color='warning'>
                                {t('project:leave this project')}
                            </Text>
                        </header>
                        <Text format='body3' color='secondary' className='mb-4'>
                            {t('project:leave project sentence', {projectName : currentProject.name})}
                        </Text>
                        <Button
                            value={t('project:leave project')}
                            onClick={() => setConfirmationModal(
                                {
                                    titleHTML   : t('project:leave project name', {projectName : currentProject.name}),
                                    onSubmit    : props.requests.leaveProject,
                                    submitText  : t('project:leave this project')
                                })}
                            size='small'
                            startIcon='fal fa-door-closed'
                            coor='warning'
                        />
                    </section>
                }

                {Can(props.permissions, 'deleteProject') && undeletableFolders &&
                   <section className='p-4 bg-color-underground border border-color-tertiary-alt rounded'>
                        <header className='mb-2'>
                            <Text format='title2' color='warning'>
                                {isLibrary ? t('project:delete this library') : t('project:delete this project')}
                            </Text>
                        </header>
                        {
                            undeletableFolders.length > 0
                            ?
                                <div className='mb-2'>
                                    <Text format='body3' color='primary'>
                                        {t('project:cannot delete project')}
                                    </Text>
                                    <ul className='m-1'>
                                    {
                                        undeletableFolders.slice(0, showMore ? Infinity : maxUndeletableFolders).map(folder =>
                                            <li
                                                key={folder.uuid}
                                                className='flex items-center gap-1 cursor-pointer'
                                                onClick={() =>
                                                    props.goTo({ folderUUID : folder.uuid, libraryUUID : null, sourceFileUUID : null })
                                                }
                                            >
                                                <Icon className={folder.isSystem ? getSystemFolderIcon(folder): 'fas fa-folder'} size='xsmall'/>
                                                <Text format='body4' color='primary'>
                                                    {folder.name}
                                                </Text>
                                            </li>
                                        )
                                    }
                                    {undeletableFolders.length > maxUndeletableFolders &&
                                        <Button
                                            value={showMore ? 'Show less' : `Show ${undeletableFolders.length - maxUndeletableFolders} more`}
                                            onClick={() => setShowMore(!showMore)}
                                            color='tertiary'
                                            noPadding
                                            size='xsmall'
                                        />
                                    }
                                    </ul>

                                </div>
                            :
                                <Text format='body3' color='secondary' className='mb-4'>
                                    <span dangerouslySetInnerHTML={
                                        {__html: t('project:delete project sentence', {projectName : currentProject.name})}
                                    }/>
                                </Text>
                        }
                        <Button
                            value={t('t:delete')}
                            onClick={() => setProjectDeleteModal(true)}
                            size='small'
                            startIcon='fal fa-trash'
                            coor='warning'
                            disabled={!undeletableFolders || undeletableFolders.length > 0 || Boolean(pending)}
                        />
                        {projectDeleteModal &&
                            <ProjectDeleteModal
                                open
                                onClose={() => setProjectDeleteModal(null)}
                                deleteProject={props.requests.deleteProject}
                                currentProject={currentProject}
                            />
                        }
                    </section>
                }

                {confirmationModal &&
                    <ConfirmationModal
                        open
                        onClose={() => setConfirmationModal(null)}
                        {...confirmationModal}
                    />
                }
            </div>
        </section>
    );
}

//------------------------------------------------------------------------------
export default ProjectSettings;
