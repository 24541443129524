//------------------------------------------------------------------------------
export default async function CallAPI(url, method, body, parseJson = true)
{
	const requestOptions = {
		method	: method,
		body	: JSON.stringify(body),
		headers	: {
			'Content-Type': 'application/json'
		},
	};

	const response   	= await fetch(basename + 'innerAPI' + url, requestOptions);

	const contentType 	= response.headers.get("content-type");
	const isJSON		= parseJson && contentType?.indexOf("application/json") !== -1;
	let data			= null;

	try
	{
		if (isJSON)
		{
			data = await response.json();
		}
		else
		{
			data = await response.text();
		}
	}
	catch
	{
		data = null;
	}

	if(response.status !== 200)
	{
		let error = data;
		while(error && error.error) error = error.error;
		throw error;
	}
	else
	{
		return data;
	}
}
