import React, { useState, useEffect }   from 'react';
import MainLayout                       from '../../../components/Layout';
import { Link, Text }                   from '../../../designSystem/components';


//------------------------------------------------------------------------------
function ErrorPage(props)
{
    //------------------------------------------------------------------------------
    const [ errorMessage, setErrorMessage ] = useState(null);

    //------------------------------------------------------------------------------
    useEffect(() =>
    {
        switch (props.errorNum) 
        {
        case 6: {
            setErrorMessage('No project here.');
            break;
        }
        case 1003: {
            setErrorMessage('This project doesn\'t exist anymore.');
            break;
        }
        default:
            setErrorMessage('Error');
            break;
        }
    }, []);

    //------------------------------------------------------------------------------
    return (
        <MainLayout>
            <section className='absolute top-0 flex flex-col items-center justify-center gap-4 w-full h-full'>

                <Text format='title1'>
                    {errorMessage}
                </Text>

                <Link to='/' className='link'>
                    Go back to home
                </Link>

            </section>
        </MainLayout>
    );
}

export default ErrorPage;
