import CallAPI from '../../utils/api';

//------------------------------------------------------------------------------
export async function changeEmailAPI(userUUID, email)
{
    return await CallAPI('/user/changeEmail', 'POST', { userUUID, email });
}

//------------------------------------------------------------------------------
export async function changeProfileAPI(userUUID, profileProps)
{
    return await CallAPI('/user/changeProfile', 'POST', { userUUID, ...profileProps });
}

//------------------------------------------------------------------------------
export async function changePasswordAPI(userUUID, currentPassword, newPassword)
{
    return await CallAPI('/user/changePassword', 'POST', { userUUID, currentPassword, newPassword });
}

//------------------------------------------------------------------------------
export async function statsAPI()
{
    return await CallAPI('/user/stats', 'GET');
}

//------------------------------------------------------------------------------
export async function postAvatarAPI(body)
{
    return await CallAPI('/user/postAvatar', 'POST', body);
}

//------------------------------------------------------------------------------
export async function getAccountUsers()
{
    return await CallAPI('/account/getAccountUsers', 'GET');
}

//------------------------------------------------------------------------------
export async function revokeAccessToken(provider)
{
    return await CallAPI('/account/revokeAccessToken', 'POST', {provider});
}
