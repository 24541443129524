import styled, { css }                  from 'styled-components';


export const TileApplicationInlineStyled = styled.button`
`;

export const TileApplicationStyled = styled.button`
    min-width: 12.5rem;
    min-height: 11rem;
    border: 2px solid var(--color-bg-ground);

    ${props => props.size === 'large' && css`
        aspect-ratio: 1.5;
    `}

    ${props => props.$isFeatured && css`
        aspect-ratio: 2.5;
    `}

    &:hover,
    &:focus {
        border-color: var(--color-accent);
    }

    .info {
        position: relative;

        &:before {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 90%;
            background: linear-gradient(0deg,
                hsla(var(--color-bg-underground-hsl), 0.95) 0%,
                hsla(var(--color-bg-underground-hsl), 0.85) 22%,
                hsla(var(--color-bg-underground-hsl), 0.67) 33%,
                hsla(var(--color-bg-underground-hsl), 0.382) 47%,
                hsla(var(--color-bg-underground-hsl), 0.278) 56.5%,
                hsla(var(--color-bg-underground-hsl), 0.194) 65%,
                hsla(var(--color-bg-underground-hsl), 0.126) 73%,
                hsla(var(--color-bg-underground-hsl), 0.075) 80.2%,
                hsla(var(--color-bg-underground-hsl), 0.042) 86.1%,
                hsla(var(--color-bg-underground-hsl), 0.021) 91%,
                hsla(var(--color-bg-underground-hsl), 0.008) 95.2%,
                hsla(var(--color-bg-underground-hsl), 0.002) 98.2%,
                hsla(var(--color-bg-underground-hsl), 0) 100%
            );
        }
    }
`;