import React, { useContext, useState, useEffect }   from 'react';
import { useTranslation }                           from 'react-i18next';
import { Avatar, Button, ButtonGroup, ControlSearch, Popover, Text
}                                                   from '../../../designSystem/components';
import TileProject                                  from '../../../components/TileProject';
import MainLayout                                   from '../../../components/Layout';
import UserContext                                  from '../../account/userContext';
import UserListPopover                              from '../UserListPopover';
import NewProjectModal                              from '../NewProjectModal';
import { SectionStyled }                            from './style';
import ProjectContext                               from '../../projects/ProjectContext';


//------------------------------------------------------------------------------
function ProjectListPage(props)
{
    //------------------------------------------------------------------------------
    const { t }                 = useTranslation();
    const userData              = useContext(UserContext);
    const projectContext        = useContext(ProjectContext);

    //------------------------------------------------------------------------------
    const PROJECT_PAGES = ['PROJECT', 'LIBRARY'];

    //------------------------------------------------------------------------------
    const SORT_BY_LIST = [
        {
            id  :'CREATION',
            name: t('t:creation date')
        },
        {
            id  :'NAME',
            name: t('t:name')
        },
        {
            id  :'OWNER',
            name: t('t:owner')
        }
    ];

    //------------------------------------------------------------------------------
    const FILTER_PROJECT_TYPE = [
        {
            id  :'ALL',
            name: t('t:all')
        },
        {
            id  :'APPLICATION',
            name: t('project:application')
        },
        {
            id  :'LIBRARY',
            name: t('project:library')
        }
    ];

    //------------------------------------------------------------------------------
    useEffect(() =>
    {
        const { search }                = props.location;
        const query                     = new URLSearchParams(search);
        let projectTypeFilterDefault    = query.get('projectType')?.toUpperCase();
        projectTypeFilterDefault        = PROJECT_PAGES.includes(projectTypeFilterDefault) ? projectTypeFilterDefault : PROJECT_PAGES[0];
    }, [props.location]);

    //------------------------------------------------------------------------------
    useEffect(() =>
    {
        updateFinalList();

        // Init owner list
        if (projectContext.projects?.length > 0)
        {
            setProjectFilteredList(projectContext.projects);

            // List owner
            let _ownerList = projectContext.projects.map(project => (
            {
                uuid        : project.group.creator.userUUID,
                username    : project.group.creator.username,
            }));

            // Remove duplicates
            _ownerList = _ownerList.filter((obj, pos, arr) =>
            {
                return arr.map(mapObj => mapObj['uuid']).indexOf(obj['uuid']) === pos;
            });

            setOwnerList(_ownerList);
        }
    }, [projectContext.projects, updateFinalList]);


    //------------------------------------------------------------------------------
    // New Project/Library Modal

    const [ newProjectModal, setNewProjectModal ]           = useState(null);


    //------------------------------------------------------------------------------
    // Search and Filters

    //------------------------------------------------------------------------------
    // List
    const [ projectFilteredList, setProjectFilteredList ]   = useState([]);
    const [ projectFinalList, setProjectFinalList ]         = useState([]);

    //------------------------------------------------------------------------------
    // Search
    const [ searchString, setSearchString ]                 = useState('');

    //------------------------------------------------------------------------------
    // Sort by
    const [ sortBy, setSortBy ]                             = useState(SORT_BY_LIST[0].id);
    const [ sortByPopover, setSortByPopover ]               = useState(null);

    const toggleSortByPopover = (event) =>
    {
        setSortByPopover(sortByPopover ? null : event.currentTarget);
    }
;
    //------------------------------------------------------------------------------
    // Filter by Project Type
    const [ projectTypeFilter, setProjectTypeFilter ]       = useState(FILTER_PROJECT_TYPE[0].id);

    //------------------------------------------------------------------------------
    // Filter by Owner
    const [ ownerList, setOwnerList ]                       = useState([]);
    const [ ownerFilter, setOwnerFilter ]                   = useState(null);
    const [ ownerFilterPopover, setOwnerFilterPopover ]     = useState(null);

    //------------------------------------------------------------------------------
    useEffect(() =>
    {
        if (!projectContext.projects)
            return;

        const _projectFilteredList = projectContext.projects.filter(p =>
        {
            return (searchString ? p.name.toLowerCase().includes(searchString.toLowerCase()) : true)
                    && (ownerFilter ? p.group.creator.userUUID === ownerFilter.uuid : true)
                    && (projectTypeFilter === 'APPLICATION' ? !p.isLibrary : true)
                    && (projectTypeFilter === 'LIBRARY' ? p.isLibrary : true);
        });
        setProjectFilteredList(_projectFilteredList);

    }, [projectContext.projects, searchString, ownerFilter, projectTypeFilter]);

    //------------------------------------------------------------------------------
    useEffect(() =>
    {
        updateFinalList();
    }, [sortBy, projectFilteredList, updateFinalList]);

    //------------------------------------------------------------------------------
    const updateFinalList = () =>
    {
        if (sortBy === 'OWNER')
        {
            const _projectFilteredList = ownerList.sort((a,b) => (
                a.username.toLowerCase() > b.username.toLowerCase() ? 1 : -1
            )).map(owner => (
                {
                    ...owner,
                    name        : owner.username,
                    displayName : true,
                    avatar      : true,
                    userUUID    : owner.uuid,
                    list        : projectFilteredList.filter(project => project.group.creator.userUUID === owner.uuid).sort(function(a, b)
                    {
                        return (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1;
                    })
                }
            ));
            setProjectFinalList(_projectFilteredList);
        }
        else
        {
            const _projectFilteredList = [
                {
                    name        : 'Group',
                    displayName : false,
                    list        : projectFilteredList.sort(function(a, b)
                    {
                        switch(sortBy)
                        {
                        case 'CREATION':
                            return b.creationTimestamp - a.creationTimestamp;

                        case 'NAME':
                            return (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1;
                        }
                    }
                    )
                }
            ];
            setProjectFinalList(_projectFilteredList);
        }
    };

    //------------------------------------------------------------------------------
    const toggleOwnerListPopover = (event) =>
    {
        setOwnerFilterPopover(ownerFilterPopover ? false : event.currentTarget);
    };

    //------------------------------------------------------------------------------
    const closeOwnerListPopover = () =>
    {
        setOwnerFilterPopover(false);
    };


    //------------------------------------------------------------------------------
    return (
        <MainLayout projects={projectContext.projects} theme={props.theme} setTheme={props.setTheme}>

            <SectionStyled>
                <header className='flex justify-between wrapper pt-5'>
                    <Text format='title1' className='mb-3'>
                        {t('project:all projects')}
                    </Text>
                    <Button
                        value={t('project:new project')}
                        onClick={() => setNewProjectModal(true)}
                        startIcon='fal fa-plus'
                        color='primary'
                        className='ml-4'
                    />
                    {newProjectModal &&
                        <NewProjectModal
                            open
                            onClose={() => setNewProjectModal(null)}
                        />
                    }
                </header>

                <div className='flex justify-between items-end gap-3 wrapper pb-3 border-b border-color-tertiary'>
                    <div className='flex-1 max-w-3xl flex items-end gap-4'>
                        <ControlSearch
                            value={searchString}
                            onChange={e => setSearchString(e.target.value)}
                            placeholder={t('project:search projects')}
                            className='flex-1 hidden md:block'
                            size='small'
                        />

                        <ButtonGroup label='Type' size='small'>
                            {FILTER_PROJECT_TYPE.map(projectType =>
                                <Button
                                    key={projectType.id}
                                    value={projectType.name}
                                    onClick={e => setProjectTypeFilter(projectType.id)}
                                    size='small'
                                    color={projectTypeFilter === projectType.id ? 'primary' : undefined}
                                />
                            )}
                        </ButtonGroup>

                        <Button
                            label={
                                <p className='flex items-center justify-between gap-2'>
                                    {t('t:owned by')}
                                    {(!ownerFilter || (ownerFilter?.uuid !== userData.uuid)) &&
                                        <Button
                                            value={t('t:me')}
                                            onClick={() => setOwnerFilter(
                                                {
                                                    username: userData.username,
                                                    uuid    : userData.uuid
                                                }
                                            )}
                                            size='xsmall'
                                            color='tertiary'
                                            className='link'
                                        />
                                    }
                                </p>
                            }
                            onClick={toggleOwnerListPopover}
                            selected={Boolean(ownerFilter)}
                            labelClassName='min-w-max'
                            contentAlign='left'
                            size='small'
                        >
                            {ownerFilter ?
                                <span className='flex items-center gap-2'>
                                    <Avatar
                                        {...ownerFilter}
                                        size='small'
                                        circleStyle={userData.uuid === ownerFilter.uuid}
                                        color='var(--color-accent)'
                                    />
                                    <span className='username'>
                                        {ownerFilter.username}
                                    </span>
                                </span>
                                :
                                <span className='text-color-neutral-secondary'>
                                    {t('t:all')}
                                </span>
                            }
                        </Button>

                        {ownerFilterPopover &&
                            <UserListPopover
                                open
                                anchorEl={ownerFilterPopover}
                                onClose={closeOwnerListPopover}
                                userList={ownerList}
                                currentUser={ownerFilter}
                                clientUserUUID={userData.uuid}
                                onClickOnUser={(owner) =>
                                {
                                    setOwnerFilter(owner);
                                    closeOwnerListPopover();
                                }}
                                onRemoveActiveUser={() =>
                                {
                                    setOwnerFilter(null);
                                    closeOwnerListPopover();
                                }}
                                removeActiveUserMessage={t('t:clear filter')}
                                searchPlaceholderMessage={t('t:search owner')}
                                emptyMessage={t('t:no owner found')}
                            />
                        }
                    </div>

                    <Button
                        value={SORT_BY_LIST.find(s => s.id === sortBy)?.name}
                        onClick={toggleSortByPopover}
                        endIcon='fal fa-arrow-down'
                        size='small'
                        color='tertiary'
                    />
                    {sortByPopover &&
                        <Popover
                            open
                            onClose={() => setSortByPopover(null)}
                            anchorEl={sortByPopover}
                        >
                            <div>
                                {SORT_BY_LIST.map(_sortBy =>
                                    <Button
                                        key={_sortBy.id}
                                        value={_sortBy.name}
                                        onClick={e => { setSortBy(_sortBy.id), setSortByPopover(null); }}
                                        size='small'
                                        contentAlign='left'
                                        fullWidth
                                        color={sortBy === _sortBy.id ? 'primary' : undefined}
                                    />
                                )}
                            </div>
                        </Popover>
                    }
                </div>

                <div className='wrapper pt-3 pb-6 overflow-y-auto'>
                    {projectFinalList.length > 0 ?
                        <div className='flex flex-col gap-6'>
                            {projectFinalList.map((filterGroup, index) =>
                                <div key={index}>
                                    {filterGroup.displayName &&
                                        <div className='flex items-center gap-2 mb-3'>
                                            {filterGroup.avatar &&
                                                <Avatar
                                                    uuid={filterGroup.userUUID}
                                                    username={filterGroup.name}
                                                    circleStyle={userData.uuid === filterGroup.userUUID}
                                                    color='var(--color-accent)'
                                                    size='small'
                                                    reversed
                                                />
                                            }
                                            <Text format='body3' className='uppercase'>
                                                {filterGroup.name}
                                            </Text>
                                        </div>
                                    }
                                    {filterGroup.list.length > 0 ?
                                        <ul className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-2'>
                                            {filterGroup.list.map(project =>
                                                <li key={project.uuid}>
                                                    <TileProject
                                                        {...project}
                                                        to
                                                        highlightString={searchString}
                                                    />
                                                </li>
                                            )}
                                        </ul>
                                        :
                                        <Text format='body3' color='secondary'>
                                            {t('project:no projects match your filter')}
                                        </Text>
                                    }
                                </div>
                            )}
                        </div>
                        :
                        <Text format='body3' color='secondary'>
                            {t('project:no projects match your filter')}
                        </Text>
                    }
                </div>
            </SectionStyled>

        </MainLayout>
    );
}

export default ProjectListPage;
