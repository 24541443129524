import React, { Component }         from 'react';
import PropTypes                    from 'prop-types';
import { FixedSizeList as List }    from 'react-window';
import Cell                         from './Cell';

import { Divider }                   from '../../designSystem/components';


//------------------------------------------------------------------------------
export default class FolderTree extends Component
{
    //------------------------------------------------------------------------------
    state = {
        openedFolderUUID    : new Set(),
        flattenedFolders    : new Array()
    };

    //------------------------------------------------------------------------------
    componentDidMount()
    {
        if(!this.props.items)
        {
            return;
        }

        for(const item of this.props.items)
        {
            if(!item.divider)
            {
                this.state.openedFolderUUID.add(item.uuid);
            }
        }

        this.openParentFolders(this.props.currentFolder);

        if(this.props.items)
        {
            const {flattenedFolders, hash} = this.flattenFolders();
            this.setState({flattenedFolders, hash});
        }
    }

    //------------------------------------------------------------------------------
    componentDidUpdate(prevProps)
    {
        if(this.props.items !== prevProps.items)
        {
            for(const item of this.props.items)
            {
                if(!item.divider)
                {
                    this.state.openedFolderUUID.add(item.uuid);
                }
            }
        }

        if(this.props.currentFolder !== prevProps.currentFolder)
        {
            this.openParentFolders(this.props.currentFolder);
        }

        const {flattenedFolders, hash} = this.flattenFolders();

        if(this.state.hash !== hash || this.state.flattenedFolders.length !== flattenedFolders.length)
        {
            this.setState({flattenedFolders, hash});
        }
    }

    //------------------------------------------------------------------------------
    cellRenderer = ({ index, style }) =>
    {
        const { currentFolder, currentLibrary, isSearching } = this.props;
        const node = this.state.flattenedFolders[index];
        const isOpen = this.state.openedFolderUUID.has(node.uuid);

        if (!node)
        {
            return null;
        }

        if(node.divider)
        {
            return (
                <Divider
                    horizontal
                    style={{...style, height : '1px', marginTop : '12px'}}
                />
            );
        }

        const isCurrent = !isSearching &&
                        (
                            (currentFolder ? currentFolder.uuid === node.uuid :
                                (this.props.projectPage === 'content' ? node.uuid === this.props.projectUUID : node.uuid === this.props.projectPage)
                            )
                            || currentLibrary?.uuid === node.uuid
                        );

        return (
            <Cell
                key={node.uuid}
                index={index}
                node={node}
                isCurrent={isCurrent}
                isOpen={isOpen}

                onClick={this.onClick}
                onExpand={this.toggleExpand}
                onContextMenu={this.props.onContextMenu}
                onDragStart={this.props.onDragStart}
                onDrop={this.props.onDrop}

                style={style}
            />
        );
    };

    //------------------------------------------------------------------------------
    onClick = (folder) =>
    {
        const folderUUID = folder.level === 0 && !folder.isSystem ? null : folder.uuid;

        if(folder.onClick)
        {
            folder.onClick();
        }
        else
        {
            this.props.goTo({ libraryUUID : folder.libraryUUID, folderUUID, sourceFileUUID : null });
        }

        if (this.props.isSearching)
        {
            this.props.clearSearch();
        }
    }

    //------------------------------------------------------------------------------
    toggleExpand = (node, index) =>
    {
        const isOpen = this.state.openedFolderUUID.has(node.uuid);
        if (isOpen)
        {
            this.collapseNode(node, index);
            this.state.openedFolderUUID.delete(node.uuid);
        }
        else
        {
            this.expandNode(node, index);
            this.state.openedFolderUUID.add(node.uuid);
        }

        this.forceUpdate();
    };

    //------------------------------------------------------------------------------
    expandNode(currentNode, index)
    {
        return this.state.flattenedFolders.splice(
            index + 1,
            0,
            ...currentNode.folders
        );
    }

    //------------------------------------------------------------------------------
    collapseNode(currentNode, index)
    {
        const nodes = this.state.flattenedFolders;
        let nextNodeIndex = nodes.length;

        for (let i = index + 1; i < nodes.length; i++)
        {
            if (nodes[i].level <= currentNode.level)
            {
                nextNodeIndex = i - 1;
                break;
            }
        }

        nodes.splice(index + 1, nextNodeIndex - index);
    }

    //------------------------------------------------------------------------------
    openParentFolders(folder)
    {
        let folderPath = null;
        while(folder && (folderPath = folder.parentPath))
        {
            const library   = folder.libraryUUID && this.props.libraryMap.get(folder.libraryUUID)
            folder          = library
                            ? library.projectMap.get(folderPath)
                            : this.props.projectMap.get(folderPath);
            if(!folder)
            {
                console.warn('Cannot find folder '+ folderPath +' in openParentFolders');
                return;
            }

            this.state.openedFolderUUID.add(folder.uuid);
        }
    }

    //------------------------------------------------------------------------------
    flattenFolders()
    {
        const flattenedFolders = [];
        for(const folder of this.props.items)
        {
            this.flattenSub(folder, flattenedFolders);
        }

        const hash = flattenedFolders
                    .filter(f => !f.divider)
                    .reduce((acc, folder) => ((acc << 5) - acc) + this.miniHash(folder.uuid), 0);

        return {flattenedFolders, hash};
    }

    //------------------------------------------------------------------------------
    miniHash(uuid)
    {
        let hash = 0;
        for (let i = 0; i < uuid.length && i < 5; i++)
        {
            hash = ((hash << 5) - hash) + uuid.charCodeAt(i);
        }
        return hash;
    }

    //------------------------------------------------------------------------------
    flattenSub(currentFolder, flattenedFolders, level)
    {
        flattenedFolders.push(currentFolder);

        if(this.state.openedFolderUUID.has(currentFolder.uuid) && !currentFolder.divider)
        {
            for(const folder of currentFolder.folders)
            {
                this.flattenSub(folder, flattenedFolders);
            }
        }
    }

    //------------------------------------------------------------------------------
    render()
    {
        const { width, height, rowHeight } = this.props;
        const { flattenedFolders } = this.state;

        return (
            <List
                // width={width}
                height={height}
                itemSize={24}
                itemCount={flattenedFolders.length}
                itemData={flattenedFolders}
                className={this.props.className + ' child-relative'}
                style={{ height: '-webkit-fill-available' }}
            >
                {this.cellRenderer}
            </List>
        );
    }
}
