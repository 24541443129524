//------------------------------------------------------------------------------
import React                        from 'react';
import { TileApplicationStyled }    from './style';

//------------------------------------------------------------------------------
import { Link }                     from '../../designSystem/components';

//------------------------------------------------------------------------------
export default function TileButton(props) 
{
    //------------------------------------------------------------------------------
    return (
        <TileApplicationStyled
            as={props.to ? Link : 'button'}
            to={props.to}
            onClick={props.onClick}
            className={`flex flex-col gap-5 h-full bg-cover bg-center rounded-xs text-left transition transition-colors overflow-hidden ${props.className ?? null}`}
            size={props.size}
        >
        {
            props.children
        }
        </TileApplicationStyled>
    );
}
