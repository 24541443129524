import React, { Fragment, useEffect, useRef, useState
}                                                           from 'react';
import { useTranslation }                                   from 'react-i18next';
import { Avatar, Button, ControlSearch, Icon, Loader, Popover, Text
}                                                           from '../../../designSystem/components';
import CallAPI                                              from '../../../utils/api';


//--------------------------------------------------------------------------
function UserListPopover(props)
{
    //--------------------------------------------------------------------------
    const { userList, hasSearchAPI = false }            = props;

    //--------------------------------------------------------------------------
    const { t }                                         = useTranslation();

    //--------------------------------------------------------------------------
    const controlSearchRef                              = useRef();
    const [ searchString, setSearchString ]             = useState('');
    const [ userListFiltered, setUserListFiltered ]     = useState([]);

    //--------------------------------------------------------------------------
    useEffect(() =>
    {
        if (hasSearchAPI || !userList) return;

        setUserListFiltered(userList);
    }, [userList]);

    //--------------------------------------------------------------------------
    const updateSearchResults = async (event) =>
    {
        // Update search string
        const _searchString = event.target.value;
        setSearchString(_searchString);

        //--------------------------------------------------------------------------
        // Option 1: with Search API
        if (hasSearchAPI)
        {
            if (!_searchString)
            {
                setUserListFiltered([]);
                return;
            }

            const searchResult = await CallAPI('/user/search', 'POST', { searchString: _searchString });
            const usersFound   = Object.keys(searchResult)
                .map(userUUID =>
                    (
                        {
                            uuid : userUUID,
                            username: searchResult[userUUID].username,
                            isAlreadyActive: (userUUID === props.clientUserUUID) || (props.activeUserUUIDs.includes(userUUID)) || false
                        }
                    ))

            const _usersFound = props.hideMyself ? usersFound.filter(u => u.uuid !== props.clientUserUUID) : usersFound;
            setUserListFiltered(_usersFound);
        }
        //--------------------------------------------------------------------------
        // Option 2: with userList passed as props
        else
        {
            // Update user list
            const _userListFiltered = _searchString ?
                userList.filter((user) => user.username.toLowerCase().includes(_searchString.toLowerCase()) )
                :
                userList;
            setUserListFiltered(_userListFiltered);
        }

        // Put focus on first user
        setActiveButtonIndex(0);
    };

    //------------------------------------------------------------------------------
    // Autofocus on search
    // TODO: replace timeout by:
    // if(controlSearchRef && controlSearchRef.current) { controlSearchRef.current.focus(); }
    let timeout;
    useEffect(() =>
    {
        event.stopPropagation();
        timeout = setTimeout(() =>
        {
            controlSearchRef.current.focus();
        }, 100);

        return () =>
        {
            clearTimeout(timeout);
        };
    }, []);

    //------------------------------------------------------------------------------
    const [ activeButtonIndex, setActiveButtonIndex]    = useState(null);

    //------------------------------------------------------------------------------
    useEffect(() =>
    {
        if(!userListFiltered) return;

        setActiveButtonIndex(0);
    }, [userListFiltered]);

    //------------------------------------------------------------------------------
    useEffect(() =>
    {
        const onKeydown = (event) =>
        {
            switch(event.keyCode)
            {
            case 13 : { // Enter
                event.preventDefault();
                if(userListFiltered[activeButtonIndex])
                {
                    props.onClickOnUser(userListFiltered[activeButtonIndex]);
                }
                break;
            }
            case 38 : { // Up
                event.preventDefault();
                setActiveButtonIndex( (activeButtonIndex > 0) ? activeButtonIndex-1 : userListFiltered.length-1 );
                break;
            }
            case 40 : { // Down
                event.preventDefault();
                setActiveButtonIndex( (activeButtonIndex < userListFiltered.length-1) ? activeButtonIndex+1 : 0 );
                break;
            }
            }
        };
        window.addEventListener('keydown', onKeydown);

        return () =>
        {
            window.removeEventListener('keydown', onKeydown);
        };
    }, [userListFiltered, activeButtonIndex, setActiveButtonIndex]);


    //--------------------------------------------------------------------------
    return (
        <Popover
            open={props.open}
            anchorEl={props.anchorEl}
            onClose={props.onClose}
            anchorPosition={props.anchorPosition}
            anchorOrigin={props.anchorOrigin}
            transformOrigin={props.transformOrigin}
        >
            <div>
                <ControlSearch
                    inputRef={controlSearchRef}
                    value={searchString}
                    onChange={updateSearchResults}
                    placeholder={props.searchPlaceholderMessage || t('t:search owner')}
                    size='small'
                    autoFocus
                    color='tertiary'
                    className='border-b border-color-tertiary'
                />

                {Boolean(props.currentUser) &&
                    <Button
                        onClick={props.onRemoveActiveUser}
                        value={props.removeActiveUserMessage || t('t:clear filter')}
                        color='tertiary'
                        size='small'
                        contentAlign='left'
                        fullWidth
                        square
                        className='border-b border-color-tertiary'
                        startIcon='fal fa-times'
                    />
                }

                {userListFiltered ?
                    <Fragment>
                        {userListFiltered.length > 0 ?
                            <ul className='list py-1' style={{maxHeight: "205px"}}>
                                {userListFiltered.map((user, index) => (
                                    <li key={user.uuid}>
                                        <Button
                                            onClick={() => props.onClickOnUser(user)}
                                            color={(props.currentUser && (props.currentUser.uuid === user.uuid)) ? 'primary' : 'tertiary'}
                                            size='small'
                                            contentAlign='left'
                                            fullWidth
                                            valueClassName='w-full justify-between'
                                            className={activeButtonIndex === index ? 'focus' : ''}
                                        >
                                            <span className='flex'>
                                                <Avatar
                                                    {...user}
                                                    circleStyle={user.uuid === props.clientUserUUID}
                                                    color='var(--color-accent)'
                                                    size='small'
                                                    className='mr-2'
                                                />
                                                <Text format='body3' className='username'>
                                                    {user.username}
                                                    {user.uuid === props.clientUserUUID &&
                                                        <span className='ml-2 text-xs opacity-50'>
                                                            ({t('t:me')})
                                                        </span>
                                                    }
                                                </Text>
                                            </span>
                                            {user.isAlreadyActive &&
                                                <Icon className='fal fa-check' size='xsmall' color='success' />
                                            }
                                        </Button>
                                    </li>
                                ))}
                            </ul>
                            :
                            <Text
                                format='body4'
                                color='secondary'
                                align='center'
                                className='py-2'
                            >
                                {props.emptyMessage || t('t:no owner found')}
                            </Text>
                        }
                    </Fragment>
                    :
                    <Loader />
                }

            </div>
        </Popover>
    );
}

export default UserListPopover;
