//------------------------------------------------------------------------------
import React                                                from 'react';

//------------------------------------------------------------------------------
import Deferred                                             from '../../utils/deferred';
import NotificationContext                                  from '../notifications/notificationContext';
import { getUsersAPI }                                      from './api';
import { getUserStateOfProject, mergeUserStateOfProject }   from './functions';

//------------------------------------------------------------------------------
const ProjectContext = React.createContext();

//------------------------------------------------------------------------------
export class ProjectProvider extends React.Component
{
    //--------------------------------------------------------------------------
    static contextType = NotificationContext;

    //--------------------------------------------------------------------------
    componentDidMount()
    {
        this.connect();
    }

    //--------------------------------------------------------------------------
    componentWillUnmount()
    {
        this.disconnect();
    }

    //--------------------------------------------------------------------------
    connect = (reconnect = false) =>
    {
        this.socket         = this.connectToServer(reconnect);
        this.socket.onclose = () =>
        {
            if(this.connectedOnce)
            {
                console.log('Disconnected from project socket, reconnecting...');
                this.connectedPromise = new Deferred();
                this.setState({ connected : false });
                this.reconnectTimeout = setTimeout(
                    () =>
                    {
                        this.reconnectTimeout = null;
                        this.connect(true);
                    },
                    1000
                );
            }
            else
            {
                console.error('Connection to project endpoint failed');
            }
        };
    };

    //--------------------------------------------------------------------------
    disconnect = () =>
    {
        if(this.socket)
        {
            this.socket.onclose = null;
            this.socket.close();
            if(this.reconnectTimeout)
            {
                clearTimeout(this.reconnectTimeout);
            }
        }
    };

    //--------------------------------------------------------------------------
    connectToServer = (reconnect) =>
    {
        const protocol      = location.protocol === 'https:' ? 'wss' : 'ws';
        const websocketURL  = `${protocol}://${window.location.host}/innerAPI/project/register`;
        const socket        = new WebSocket(websocketURL);

        socket.onopen      = () =>
        {
            console.log('Connected to project socket');
            this.connectedOnce = true;

            // if we were disconnected, resubscribe to the same project.
            if(reconnect && this.state.currentProjectUUID)
            {
                this.subscribeToProject(this.state.currentProjectUUID);
            }
        };

        socket.onerror      = (error) =>
        {
            console.log('project socket error:', error);
        };

        socket.onmessage    = async (event) =>
        {
            const { eventType, data, emitter } = JSON.parse(event.data);
            console.log('onmessage', eventType, data, emitter, this);

            switch(eventType)
            {
                //--------------------------------------------------------------
                case 'refresh-token':
                    this.props.setToken(data.token);
                    console.log('API token refreshed');
                    break;

                //--------------------------------------------------------------
                case 'project-list':
                    this.updateProjectList(data);
                    this.connectedPromise.resolve();
                    break;

                //--------------------------------------------------------------
                case 'project-created':
                    this.projectCreated.resolve(data);
                    break;

                case 'project-updated':
                case 'main-asset-setted':
                    data.projectUUID = this.state.currentProjectUUID;
                case 'update-project':
                case 'set-main-asset':
                    this.updateProjectUpdateState(data);
                    break;

                case 'delete-project':
                    this.updateProjectDeleteState(data);
                    break;

                //--------------------------------------------------------------
                // Snoop project info to update user lookup table.
                case 'project-info':
                    this.updateUserLookupTable(data);
                    break;

                //--------------------------------------------------------------
                case 'application-created':
                    data.projectUUID = this.state.currentProjectUUID;
                case 'create-application':
                    this.createApplicationUpdateState(data);
                    break;

                case 'application-updated':
                    data.projectUUID = this.state.currentProjectUUID;
                case 'update-application':
                    this.updateApplicationState(data);
                    break;

                case 'application-deleted':
                    data.projectUUID = this.state.currentProjectUUID;
                case 'delete-application':
                    this.deleteApplicationState(data);
                    break;

                //--------------------------------------------------------------
                case 'user-access':
                {
                    const { eventType, data : eventData , projectUUID } = data;
                    switch(eventType)
                    {
                        case 'create-project':
                        case 'grant-access':
                            this.onProjectJoined(eventData.project, emitter);
                            break;

                        case 'revoke-access':
                        case 'leave-project':
                            this.onProjectLeft(projectUUID);
                            break;
                    };
                }
                break;
            };

            if(this.state.eventListener)
            {
                this.state.eventListener(eventType, data, emitter);
            }
        };

        return socket;
    };

    //--------------------------------------------------------------------------
    updateProjectList(data)
    {
        const projects = data.map(p =>
        {
            const userState = getUserStateOfProject(p.uuid);
            return {...p, ...userState};
        })
        .sort((a, b) =>
        {
            const aLastOpen = a.lastOpen || null;
            const bLastOpen = b.lastOpen || null;

            if(!aLastOpen && !bLastOpen)
            {
                return b.creationTimestamp - a.creationTimestamp;
            }

            if(aLastOpen && bLastOpen)
            {
                return b.lastOpen - a.lastOpen;
            }

            return bLastOpen ? 1 : -1;
        });

        this.setState({ projects, connected : true });
    }

    //--------------------------------------------------------------------------
    updateProjectUpdateState = ({ projectUUID, ...updateData }) =>
    {
        const projects  = this.state.projects.map(project =>
        {
            if (project.uuid === projectUUID)
            {
                return {...project, ...updateData};
            }
            return project;
        });
        this.setState({ projects });
    };

    //--------------------------------------------------------------------------
    updateProjectDeleteState = ({ projectUUID }) =>
    {
        const projects = this.state.projects.filter(project =>
        {
            return project.uuid !== projectUUID;
        });
        this.setState({ projects });
    };

    //--------------------------------------------------------------------------
    createApplicationUpdateState = ({ projectUUID, ...applicationData }) =>
    {
        // Add application state in projects global list state.
        const project = this.state.projects.find(p => p.uuid === projectUUID);
        if(!project)
        {
            console.warn(`Cannot find project ${projectUUID} in projects list`);
            return;
        }

        project.applications.push(applicationData);
        this.refreshState();
    }

    //--------------------------------------------------------------------------
    updateApplicationState = ({ projectUUID, applicationUUID, ...updateData }) =>
    {
        // Update application state in projects global list state.
        const project = this.state.projects.find(p => p.uuid === projectUUID);
        if(!project)
        {
            console.warn(`Cannot find project ${projectUUID} in projects list`);
            return;
        }

        const applicationReference = project.applications.find(app => app.uuid === applicationUUID);
        if(!applicationReference)
        {
            console.warn(`Cannot find application ${applicationUUID} in project ${projectUUID}`);
            return;
        }

        for(const propertyName in updateData)
        {
            applicationReference[propertyName]  = updateData[propertyName];
        }

        this.refreshState();
    }

    //--------------------------------------------------------------------------
    deleteApplicationState = ({projectUUID, applicationUUID}) =>
    {
        const project = this.state.projects.find(p => p.uuid === projectUUID);
        if(!project)
        {
            console.warn(`Cannot find project ${projectUUID} in projects list`);
            return;
        }

        const applicationIndex = project.applications.findIndex(app => app.uuid === applicationUUID);
        if(applicationIndex < 0)
        {
            console.warn(`Cannot find application ${applicationUUID} in project ${projectUUID}`);
            return;
        }

        project.applications.splice(applicationIndex, 1);
        this.refreshState();
    }

    //--------------------------------------------------------------------------
    sendRequest = async (type, data) =>
    {
        await this.connectedPromise.promise;
        console.debug(type, data);
        this.socket.send(JSON.stringify({ type, data }));
    };

    //--------------------------------------------------------------------------
    subscribeToProject = (projectUUID) =>
    {
        this.sendRequest('register-to-project', { projectUUID });

        mergeUserStateOfProject(projectUUID, {lastOpen : new Date().getTime()});

        // Push current project in top of the list
        const projects = Array.from(this.state.projects).sort((a, b) =>
        {
            if(a.uuid === projectUUID)
            {
                return -1;
            }
            if (b.uuid === projectUUID)
            {
                return 1;
            }

            return 0;
        });

        this.setState({projects, currentProjectUUID : projectUUID});
    };

    //--------------------------------------------------------------------------
    unsubcribeFromCurrentProject = () =>
    {
        this.sendRequest('unregister-from-project', {});
        this.setState({currentProjectUUID : null});
    };

    //--------------------------------------------------------------------------
    registerToEvents = eventListener =>
    {
        this.setState({eventListener});
    };

    //--------------------------------------------------------------------------
    createProject = async (projectType, projectName) =>
    {
        const isLibrary     = projectType === 'LIBRARY';
        this.projectCreated = new Deferred();

        this.sendRequest('create-project', { name : projectName, description : '', isLibrary });
        const project = await this.projectCreated.promise;

        const newProject = {
            ...project,
            isLibrary,
            group : {
                creator : {
                    userUUID    : this.props.user.uuid,
                    username    : this.props.user.username
                }
            }
        };

        this.setState({projects : [newProject, ...this.state.projects]});
        return project;
    };

    //--------------------------------------------------------------------------
    onProjectJoined = (project, emitter) =>
    {
        const newProject = {
            ...project,
            group : {
                // Not quite right, but it's the best we can do for now
                creator : emitter
            }
        };

        this.setState({projects : [newProject, ...this.state.projects]});
    };

    //--------------------------------------------------------------------------
    onProjectLeft = (projectUUID) =>
    {
        const projects = this.state.projects.filter(project =>
        {
            return project.uuid !== projectUUID;
        });
        this.setState({projects});
    };

    //--------------------------------------------------------------------------
    updateUserLookupTable = (data) =>
    {
        const registerMember = member =>
        {
            this.state.userMap.set(
                member.userUUID,
                {
                    username : member.username,
                    uuid     : member.userUUID
                }
            );
        };

        data.group.members.forEach(registerMember);
        if(data.consumerGroup)
        {
            data.consumerGroup.members.forEach(registerMember);
        }
    }

    //--------------------------------------------------------------------------
    resolveUsernames = async (_userUUIDs) =>
    {
        const userUUIDs         = Array.from(new Set(_userUUIDs));
        const missingUserUUIDs  = userUUIDs.filter(userUUID => !this.state.userMap.has(userUUID));

        if(missingUserUUIDs.length > 0)
        {
            const resolvedUsernames = await getUsersAPI(missingUserUUIDs);

            for(const userUUID of missingUserUUIDs)
            {
                const user = {
                    uuid        : userUUID,
                    username    : resolvedUsernames[userUUID].username
                };

                this.state.userMap.set(userUUID, user);
            }
        }

        return userUUIDs.map((user) => this.state.userMap.get(user.uuid));
    }

    //--------------------------------------------------------------------------
    connectedPromise    = new Deferred();
    state               = {
        projects                            : [],
        connected                           : false,
        currentProjectUUID                  : null,
        eventListener                       : null,
        userMap                             : new Map(),
        createProject                       : this.createProject,
        registerToEvents                    : this.registerToEvents,
        sendRequest                         : this.sendRequest,
        subscribeToProject                  : this.subscribeToProject,
        unsubcribeFromCurrentProject        : this.unsubcribeFromCurrentProject,
        resolveUsernames                    : this.resolveUsernames,
        subscribeToWorkspace                : this.context.subscribeToWorkspace,
        subscribeToWorkspaces               : this.context.subscribeToWorkspaces,
        unsubscribeFromWatchedWorkspaces    : this.context.unsubscribeFromWatchedWorkspaces,
        watchWorkspaceTasks                 : this.context.watchWorkspaceTasks
    };

    //--------------------------------------------------------------------------
    refreshState()
    {
        this.setState(this.state);
    }

    //--------------------------------------------------------------------------
    render = () =>
    {
        return (
            <ProjectContext.Provider value={this.state}>
                {this.props.children}
            </ProjectContext.Provider>
        );
    };
}

//------------------------------------------------------------------------------
export default ProjectContext;
export const ProjectConsumer = ProjectContext.Consumer;
