import React, { useEffect, useState }       from 'react';
import { useSnackbar }                      from 'notistack';
import { useTranslation }                   from 'react-i18next';
import moment                               from 'moment';
import momentDuration                       from 'moment-duration-format';
import InputAdornment                       from '@material-ui/core/InputAdornment';

import { Avatar, Button, ButtonIcon, ControlSelect, ControlSelectItem, ControlText, Divider, Link, ProgressBar, Text
}                                           from '../../../designSystem/components';
import { getTheme }                         from '../../../designSystem/themes';
import { plural as p, formatFileSize }      from '../../../designSystem/utils';
import i18n                                 from '../../../i18n';
import TileConnectedApp                     from '../TileConnectedApp';
import ProfileAvatarEdit                    from '../EditAvatar';
import {
    changePasswordAPI,
    changeEmailAPI,
    statsAPI,
    postAvatarAPI,
    revokeAccessToken,
    changeProfileAPI
}                                           from '../api';


momentDuration(moment);

//------------------------------------------------------------------------------
const CONNECTED_APPS = [
    {
        categoryName: 'Git',
        list: [
            {
                name            : 'GitLab',
                id              : 'gitlab',
                description     : 'Synchronize your modules and your applications source code from GitLab.',
                image           : 'logo/gitlab.svg',
                label           : 'GitlabAccount',
                //externalAppsLink: 'https://gitlab.com/-/profile/applications'
            },
            {
                name            : 'GitHub',
                id              : 'github',
                description     : 'Synchronize your modules and your applications source code from GitHub.',
                image           : 'logo/github-light.png',
                imageClassName  : 'p-3',
                label           : 'GithubAccount',
                //externalAppsLink: 'https://github.com/settings/applications'
            }
        ]
    },
    {
        categoryName: 'Import',
        list        : [
            {
                name          : 'Box',
                id            : 'box',
                description   : 'Upload file from your Box account.',
                image         : 'logo/box.svg',
                imageClassName: 'p-2',
                label         : 'BoxAccount',
                //externalAppsLink: 'https://app.box.com/services/browse/my'
            },
            showNotReadyFeatures && {
                name          : 'Dropbox',
                id            : 'dropbox',
                description   : 'Upload file from your Dropbox account.',
                image         : 'logo/dropbox.svg',
                imageClassName: 'p-3',
                //externalAppsLink: ''
            }
        ]
    }
];

if(showNotReadyFeatures)
{
    CONNECTED_APPS.push(
        {
            categoryName: 'Sign In',
            list: [
                {
                    name            : 'Google',
                    id              : 'google',
                    description     : 'Sign in with your Google account.',
                    image           : 'logo/google.svg',
                    imageClassName  : 'p-3',
                    label           : 'GoogleAccount',
                //externalAppsLink: 'https://myaccount.google.com/permissions'
                }
            ]
        });
}

//------------------------------------------------------------------------------
function Profile(props)
{
    //------------------------------------------------------------------------------
    const { user, setUser }     = props;
    const { enqueueSnackbar }   = useSnackbar();
    const { t }                 = useTranslation();

    //------------------------------------------------------------------------------
    const isStorageUnlimited    = user.accountPlan && (user.accountPlan.maxStorageSizeInMB === -1);

    //------------------------------------------------------------------------------
    const [conversionCount, setConversionCount]             = useState(0);
    const [totalSessionDuration, setTotalSessionDuration]   = useState(0);


    //------------------------------------------------------------------------------
    // Profile Info

    //------------------------------------------------------------------------------
    const [profile, setProfile] = useState({
        username        : user.username,
        email           : user.email,
        firstname       : user.firstname,
        lastname        : user.lastname,
        language        : 'en',
        initialEmail    : user.email
    });

    //------------------------------------------------------------------------------
    // Stats

    //------------------------------------------------------------------------------
    useEffect(() =>
    {
        statsAPI().then(stats =>
        {
            setConversionCount(stats.conversionCount || 0);
            setTotalSessionDuration(stats.totalSessionDuration || 0);
        });
    }, []);

    //------------------------------------------------------------------------------
    // Language

    //------------------------------------------------------------------------------
    const changeLanguage = (language) =>
    {
        setProfile({ ...profile, language });
        i18n.changeLanguage(language);
    };

    //------------------------------------------------------------------------------
    // Password

    //------------------------------------------------------------------------------
    const [changePassword, setChangePassword] = useState({
        isVisible: false,
        currentPassword: '',
        newPassword: '',
        showPassword: false,
    });

    //------------------------------------------------------------------------------
    const changeEmail = async (email) =>
    {
        if(profile.initialEmail === email)
        {
            return;
        }

        try
        {
            await changeEmailAPI(user.uuid, email);
            enqueueSnackbar('Email updated', { variant: 'success' });
            setProfile({ ...profile, initialEmail : email });
        }
        catch(error)
        {
            enqueueSnackbar('Error: ' + error.message, { variant: 'error' });
        }
    };

    //------------------------------------------------------------------------------
    const submitProfile = async ( { firstname, lastname }) =>
    {
        const profileProps = {};

        if(firstname && firstname !== user.firstname)
        {
            profileProps.firstname = firstname;
        }

        if(lastname && lastname !== user.lastname)
        {
            profileProps.lastname = lastname;
        }

        if(Object.keys(profileProps).length === 0)
        {
            return;
        }

        try
        {
            setProfile({ ...profile, initialEmail : email });
            await changeProfileAPI(user.uuid, profileProps);
            enqueueSnackbar('Profile updated', { variant: 'success' });

            setUser({ ...user, ...profileProps });
        }
        catch(error)
        {
            enqueueSnackbar('Error: ' + error.message, { variant: 'error' });
        }
    };

    //------------------------------------------------------------------------------
    const submitNewPassword = async (event) =>
    {
        event.preventDefault();
        changePasswordAPI(user.uuid, changePassword.currentPassword, changePassword.newPassword)
            .then(() =>
            {
                enqueueSnackbar('Perfect! Password changed.', { variant: 'success' });
            }).catch(err =>
            {
                console.error('err', err);
                if (err.errorNum === 2002)
                {
                    enqueueSnackbar('Invalid credentials supplied', { variant: 'warning' });
                }
                else
                {
                    enqueueSnackbar(err.message, { variant: 'warning' });
                }
            });
        setChangePassword({});
    };

    //------------------------------------------------------------------------------
    const handleClickShowPassword = () =>
    {
        setChangePassword({ ...changePassword, showPassword: !changePassword.showPassword });
    };

    //------------------------------------------------------------------------------
    const handleMouseDownPassword = (event) =>
    {
        event.preventDefault();
    };


    //------------------------------------------------------------------------------
    // Theme

    //------------------------------------------------------------------------------
    const toggleTheme = (themeName) =>
    {
        const newTheme = (themeName === 'dark') ? 'dark' : 'light';
        props.setTheme(getTheme(newTheme));
    };


    //------------------------------------------------------------------------------
    // Connected App

    //------------------------------------------------------------------------------
    const connectApp = (appId) =>
    {
        window.location = basename + 'connectApp/' + appId + `?redirectURI=account/profile?provider=${appId}`;
    };

    //------------------------------------------------------------------------------
    const disconnectApp = async (appId) =>
    {
        await revokeAccessToken(appId);
        window.location = basename + 'account/profile';
    };


    //------------------------------------------------------------------------------
    // Avatar

    //------------------------------------------------------------------------------
    const [editAvatar, setEditAvatar] = useState(false);

    //------------------------------------------------------------------------------
    const saveAvatar = async (preview) =>
    {
        setEditAvatar(false);
        const image = preview.replace(/^data:image\/(png|jpg|jpeg);base64,/, '');
        await postAvatarAPI({ image });
        refreshAvatar();
    };

    //------------------------------------------------------------------------------
    const refreshAvatar = () =>
    {
        se({
            ...user,
            refreshAvatar: Math.floor(Math.random() * 1000) + 1
        });
    };

    //------------------------------------------------------------------------------
    return (
        <section className='pb-8'>

            <header className='flex items-end justify-between w-full mb-3'>
                <div className='flex gap-4'>
                    <button onClick={() => setEditAvatar(true)}>
                        <Avatar
                            {...user}
                            size='large'
                            style={{
                                width: '3rem',
                                height: '3rem'
                            }}
                            circleStyled
                        />
                    </button>

                    {editAvatar &&
                        <ProfileAvatarEdit
                            open
                            onClose={() => setEditAvatar(false)}
                            saveAvatar={saveAvatar}
                        />
                    }

                    <div className='flex flex-col gap-1'>
                        <Text format='title1'>
                            {user.username}
                        </Text>
                        <div className='flex gap-4'>
                            <Text format='body3' color='secondary'>
                                {user.plan.name} Plan
                            </Text>
                            {false &&
                                <Link to='/plans'>
                                    {t('account:change plan')}
                                </Link>
                            }
                        </div>
                    </div>
                </div>
            </header>

            <Divider horizontal className='my-5' />

            <section>
                {/* <div className='flex gap-4 mb-2'>
                    <p>
                        <b>{formatFileSize(user.consumption.storageSizeInMB * 1024 * 1024)}</b> storage used{isStorageUnlimited ? '' : ` on ${formatFileSize(user.accountPlan.maxStorageSizeInMB * 1024 * 1024)}`}
                    </p>
                    {!isStorageUnlimited &&
                        <ProgressBar progress={user.consumption.storageSizeInMB / user.accountPlan.maxStorageSizeInMB} />
                    }
                </div> */}
                <div className='flex justify-between gap-4'>
                    <p><b>{conversionCount}</b> Conversion{p(conversionCount)}</p>
                    {/* TODO: <p><b>{props.countOwnedworkspaces}</b> Workspace{p(props.countOwnedworkspaces)}</p> */}
                    {/* <p><b>{props.sceneCountTotal}</b> Scene{p(props.sceneCountTotal)}</p> */}
                    <p title={moment.duration(totalSessionDuration, 'seconds').format(' d [days], h [hours]')}>
                        <b>{moment.duration(totalSessionDuration, 'seconds').format('h')}</b> Hour{p(totalSessionDuration)} of session
                    </p>
                </div>
            </section>

            <Divider horizontal className='my-5' />

            <section>
                <Text format='title2' className='mb-3'>
                    {t('account:personal informations')}
                </Text>

                <div className='flex flex-col gap-4'>

                    <ControlText
                        id='email'
                        value={profile.email}
                        onChange={(event, value) => setProfile({ ...profile, email: value })}
                        onBlur={(event, value) => changeEmail(value)}
                        label={t('account:Email')}
                        placeholder={t('account:Email')}
                        size='small'
                    />
                    <div className='flex gap-4'>
                        <ControlText
                            id='first-name'
                            value={profile.firstname}
                            onChange={(event, value) => setProfile({ ...profile, firstname: value })}
                            onBlur={(event, value) => submitProfile({ firstname: value })}
                            label={t('account:First name')}
                            placeholder={t('account:First name')}
                            className='flex-1'
                            size='small'
                        />

                        <ControlText
                            id='last-name'
                            value={profile.lastname}
                            onChange={(event, value) => setProfile({ ...profile, lastname: value })}
                            onBlur={(event, value) => submitProfile({ lastname: value })}
                            label={t('account:Last name')}
                            placeholder={t('account:Last name')}
                            className='flex-1'
                            size='small'
                        />
                    </div>

                    <div>
                        {!changePassword.isVisible ?
                            <div>
                                <Button
                                    onClick={() => setChangePassword({ ...changePassword, isVisible: true })}
                                    value={t('account:change password')}
                                    color='tertiary'
                                    size='small'
                                />
                            </div>
                            :
                            <form onSubmit={submitNewPassword}>
                                <ControlText
                                    id='current-password'
                                    name='current-password'
                                    value={changePassword.currentPassword}
                                    onChange={event => setChangePassword({ ...changePassword, currentPassword: event.target.value })}
                                    label={t('account:Old password')}
                                    type='password'
                                    required
                                    size='small'
                                />

                                <ControlText
                                    id='new-password'
                                    name='new-password'
                                    value={changePassword.newPassword}
                                    onChange={event => setChangePassword({ ...changePassword, newPassword: event.target.value })}
                                    label={t('account:new password')}
                                    type={changePassword.showPassword ? 'text' : 'password'}
                                    required
                                    size='small'
                                    inputProps={{
                                        autoComplete: 'current-password'
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position='end'>
                                                <ButtonIcon
                                                    className={changePassword.showPassword ? 'fal fa-eye' : 'fal fa-eye-slash'}
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                />
                                            </InputAdornment>
                                        ),
                                    }}
                                />

                                <Button
                                    type='submit'
                                    value={t('account:change password')}
                                    className='mt-3'
                                    size='small'
                                />
                            </form>
                        }
                    </div>

                    <ControlSelect
                        id='language'
                        value={localStorage.getItem('i18nextLng') || 'en'}
                        onChange={event => changeLanguage(event.target.value)}
                        label='Language'
                        size='small'
                    >
                        <ControlSelectItem value='en'>English</ControlSelectItem>
                        <ControlSelectItem value='fr'>Français</ControlSelectItem>
                    </ControlSelect>

                    {props.theme &&
                        <ControlSelect
                            value={props.theme.name}
                            onChange={event => toggleTheme(event.target.value)}
                            label='Theme'
                            size='small'
                        >
                            <ControlSelectItem value='dark'>Dark</ControlSelectItem>
                            <ControlSelectItem value='light'>Light</ControlSelectItem>
                        </ControlSelect>
                    }
                </div>
            </section>

            <Divider horizontal className='my-5' />

            <section>
                <Text format='title2' className='mb-3'>
                    Connected Apps
                </Text>

                <div className='flex flex-col gap-5'>
                    {CONNECTED_APPS.map((category, index) =>
                        <div key={index}>
                            <Text format='title3' className='mb-1'>
                                {category.categoryName}
                            </Text>
                            <ul className='flex flex-col gap-1px'>
                                {category.list.map(connectedApp =>
                                    connectedApp && <li key={connectedApp.id}>
                                        <TileConnectedApp
                                            {...connectedApp}
                                            isConnected={Boolean(user.oauthAccounts.find(a => a.label === connectedApp.label))}
                                            onClick={() => connectApp(connectedApp.id)}
                                            onClickConnected={() => disconnectApp(connectedApp.id)}
                                        />
                                    </li>
                                )}
                            </ul>
                        </div>
                    )}
                </div>
            </section>

        </section>
    );
}

export default Profile;
