//------------------------------------------------------------------------------
import React, { Fragment, useState }    from 'react';
import { Button, Text, Icon }           from '../../designSystem/components';


//------------------------------------------------------------------------------
function Breadcrumb(props) 
{
    //------------------------------------------------------------------------------
    const { currentProject, currentFolder, currentLibrary } = props;
    const isLibrary     = Boolean(currentLibrary || currentProject.isLibrary);
    const projectMap    = currentLibrary ? currentLibrary.projectMap : props.projectMap;
    const pathElements  = currentFolder && (!isLibrary || currentLibrary?.uuid !== currentFolder.uuid) 
                        ? currentFolder.path.slice(1, -1).split('/').filter(p => Boolean(p))
                        : null;

    //------------------------------------------------------------------------------  
    const [ dragOverButtons, setDragOverButtons ]       = useState([]);
    const [ activeButtonIndex, setActiveButtonIndex ]   = useState(null);
    
    //------------------------------------------------------------------------------  
    const onContextMenu = (e, index, folder) =>
    {
        setActiveButtonIndex(index);

        props.openAssetContextMenu(
            e,
            {
                assetType       : 'folder',
                uuid            : folder.uuid,
                name            : folder.name,
                isSystem        : folder.isSystem
            },
            {
                onCloseCallback : () => setActiveButtonIndex(null),
                eventFrom       : 'BREADCRUMB'
            }
        );
    };
    
    //------------------------------------------------------------------------------  
    const onDrop = (e, folder) =>
    {
        setDragOverButtons([]);
        if (isDroppable(e, folder.uuid))
        {
            props.onDrop(e, folder);
        }
    };

    //------------------------------------------------------------------------------  
    const onDragEnter = (e, indexEl, folderUUID) => 
    {
        if (isDroppable(e, folderUUID))
        {
            const _dragOverButtons = Array.from(dragOverButtons) || [];
            _dragOverButtons[indexEl] = (_dragOverButtons[indexEl] + 1) || 1;
            setDragOverButtons(_dragOverButtons);
        }
    };

    //------------------------------------------------------------------------------  
    const onDragLeave = (e, indexEl, folderUUID) => 
    {
        if (isDroppable(e, folderUUID))
        {
            const _dragOverButtons = Array.from(dragOverButtons) || [];
            _dragOverButtons[indexEl] = (_dragOverButtons[indexEl] - 1) || 0;
            setDragOverButtons(_dragOverButtons);
        }
    };

    //------------------------------------------------------------------------------  
    const isDroppable = (e, folderUUID) =>
    {
        return (
                (e.dataTransfer.types.includes('3dverse/folder') || e.dataTransfer.types.includes('3dverse/asset') || e.dataTransfer.types.includes('3dverse/multiple')) 
                && !e.dataTransfer.types.includes(`3dverse/${folderUUID}`)
            )
            || e.dataTransfer.types.includes('Files');
    };

    //------------------------------------------------------------------------------
    const computeIcon = (node) =>
    {
        if(node.name === 'Public')
        {
            return 'fal fa-globe';
        }
        else if (node.name === 'Private')
        {
            return 'fal fa-lock';
        }
        else if (node.name === 'Trash')
        {
            return 'fal fa-trash-alt';
        }
    };


    //------------------------------------------------------------------------------  
    return (
        <div
            className={`flex items-center gap-1 ${props.className ?? ''}`}
            style={props.style}
        >
            <Button
                value={currentLibrary ? currentLibrary.name : currentProject.name}
                onClick={() => props.goTo({ folderUUID: null, sourceFileUUID : null })}
                onDrop={isLibrary ? null : (e) => onDrop(e, currentProject)}
                onDragEnter={isLibrary ? null : (e) => onDragEnter(e, 0, currentProject.uuid)}
                onDragLeave={isLibrary ? null : (e) => onDragLeave(e, 0, currentProject.uuid)}
                onDragOver={isLibrary ? null : (e) => e.preventDefault()}

                startIcon={isLibrary ? 'fal fa-link' : 'fal fa-folder'}
                color={dragOverButtons[0] ? 'primary' : 'tertiary'}
                contentAlign='left'
                size='small'
            />

            {pathElements?.map((pathName, _index) =>
            {
                const index         = _index + 1;
                const currentPath   = pathElements.slice(0, index).join('/');
                const folder        = projectMap.get('/' + currentPath + '/');

                if(!folder)
                {
                    console.warn('Cannot find folder with path:', {pathName, currentPath, projectMap});
                    return;
                }

                return (
                    <Fragment key={index}>
                        <span className='opacity-25'>/</span>

                        <Button
                            value={pathName}
                            onClick={() => props.goTo({ folderUUID : folder.uuid, libraryUUID : folder.libraryUUID, sourceFileUUID : null })}
                            onContextMenu={(e) => onContextMenu(e, index, folder)}
                            onDrop={(e) => onDrop(e, folder)}
                            onDragEnter={(e) => onDragEnter(e, index, folder.uuid)}
                            onDragLeave={(e) => onDragLeave(e, index, folder.uuid)}
                            onDragOver={(e) => e.preventDefault()}
                            
                            startIcon={folder.isSystem ? computeIcon(folder) : null}
                            className={(activeButtonIndex === index) ? 'focus' : ''}
                            color={dragOverButtons[index] ? 'primary' : 'tertiary'}
                            formatBold={_index == pathElements.length -1 && !props.sourceFile}
                            size='small'
                            contentAlign='left'
                        />
                    </Fragment>
                );
            })}

            {
                props.sourceFile && !props.sourceFile.loading &&
                <Fragment>
                    <span className='opacity-25'>/</span>
                    <div className='inline-flex items-center'>
                        <Icon className={'fal fa-file'}/>
                        <Text className='font-bold' size='small' contentAlign='left'>
                            {props.sourceFile.name}
                        </Text>
                    </div>
                </Fragment>
            }
        </div>
    );
}

export default Breadcrumb;
