import React, { Fragment, useRef, useState }        from 'react';
import { useSnackbar }                              from 'notistack';
import { Button, ControlAdornment, Divider, Icon, Popover, ShortcutHint
}                                                   from '../../../../designSystem/components';
import UploadContext                                from '../../../uploadAsset/uploadContext';
import { uploadFiles }                              from '../../../uploadAsset/functions';
import { extensions }                               from '../../../uploadAsset/supportedFiles';
import DropboxChooser                               from '../../../uploadAsset/DropboxChooser';
import Can                                          from '../../../../utils/permissions';
import CreatePopover                                from './CreatePopover';
import { BoxFilePicker }                            from './BoxFilePicker';
import { getFileExtension }                         from '../../../../utils/textTools';


//------------------------------------------------------------------------------
function AddAssetPopover(props)
{
    //------------------------------------------------------------------------------
    const { wrapperProps = {}, user }   = props;

    //------------------------------------------------------------------------------
    const permissions       = user.account.permissions;
    const anchorReference   = props.anchorPosition ? 'anchorPosition' : props.anchorReference;

    //------------------------------------------------------------------------------
    const [ createPopover, setCreatePopover ]   = useState(null);
    const createPopoverHandleHoverRef           = useRef(null);

    //------------------------------------------------------------------------------
    const openCreatePopover = (e) =>
    {
        createPopoverHandleHoverRef.current = true;
        setCreatePopover(e.currentTarget);
    };

    //------------------------------------------------------------------------------
    const closeCreatePopover = () =>
    {
        createPopoverHandleHoverRef.current = false;

        setTimeout(() =>
        {
            if(!createPopoverHandleHoverRef.current) setCreatePopover(null);
        }, 350);
    };

    //------------------------------------------------------------------------------
    const buttonProps = {
        color           : 'secondary',
        size            : 'small',
        contentAlign    : 'left',
        square          : true,
        style           : { height: '1.25rem' },
        ...props.buttonProps,
    };


    //------------------------------------------------------------------------------
    const computeAndUploadFiles = (e, options) =>
    {
        const files = Array.from(e.currentTarget.files).map(file =>
        {
            const { filename, extension } = getFileExtension(file.name);
            return {
                name        : filename,
                extension   : extension,
                handle      : file
            };
        });

        preUploadFiles(files, options);
    };

    //------------------------------------------------------------------------------
    const preUploadFiles = (files, _options) =>
    {
        props.onClose();

        const options = {
            isAutoConvert   : true,
            isVolume        : false,
            isPackage       : false,
            onUploadDone    : props.onUploadDone,
            onUploadProgress: props.onUploadProgress,
            onUploadError   : props.onUploadError,
            ..._options
        };

        props.setUploadAssetModal({
            workspaceUUID: props.workingFolder.workspaceUUID,
            files,
            options,
        })
    };

    //------------------------------------------------------------------------------
    return (
        <Popover
            open={props.open}
            anchorEl={props.anchorEl}
            anchorPosition={props.anchorPosition}
            anchorReference={anchorReference}
            onClose={props.onClose}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
        >
            <section
                {...wrapperProps}
                className={`flex flex-col gap-1px ${wrapperProps.className ? wrapperProps.className : ''}`}
                style={{
                    minWidth: '10rem',
                    overflowY: 'hidden',
                    ...wrapperProps.style
                }}
            >

                <Button
                    value='New Folder'
                    onClick={() =>
                    {
                        props.addPendingFolder();
                        props.onClose();
                    }}
                    {...buttonProps}
                    startIcon='fal fa-folder'
                    InputProps={{
                        endAdornment: (
                            <ControlAdornment position='end'>
                                <ShortcutHint control={true} shift={true} character='N' reversed />
                            </ControlAdornment>
                        ),
                    }}
                />

                <Button
                    value='New Asset'
                    onMouseEnter={openCreatePopover}
                    onMouseLeave={closeCreatePopover}
                    startIcon='fal fa-plus-square'
                    InputProps={{
                        endAdornment: (
                            <Icon className='fal fa-chevron-right' size='xsmall' />
                        ),
                    }}
                    {...buttonProps}
                >
                    {createPopover &&
                        <CreatePopover
                            open
                            placement='right-start'
                            anchorEl={createPopover}
                            wrapperProps={wrapperProps}
                            buttonProps={buttonProps}
                            workingFolder={props.workingFolder}
                            createAssetCallback={props.createAssetCallback}
                            onClose={() => setCreatePopover(null)}
                            onCloseMainPopover={() => props.onClose(null)}
                            requests={props.requests}
                            hideBackdrop
                        />
                    }
                </Button>

                <Divider horizontal />

                <Button
                    value='Import'
                    htmlFor='import'
                    as='label'
                    startIcon='fal fa-file-import'
                    {...buttonProps}
                />
                <input
                    id='import'
                    type='file'
                    onChange={computeAndUploadFiles}
                    multiple
                    accept={extensions}
                    className='hidden'
                />

                <DropboxChooser
                    appKey={'uemqz1y2wxz8udq'}
                    folderselect={true}
                    multiselect={true}
                    extensions={extensions}
                    success={(dropboxFiles) =>
                    {
                        const files = dropboxFiles.map(file =>
                        {
                            const { filename, extension } = getFileExtension(file.name);

                            return {
                                link        : file.link,
                                name        : filename,
                                extension   : extension,
                                size        : file.bytes,
                                isDir       : file.isDir
                            };
                        });

                        preUploadFiles(files);
                    }}
                >
                    <Button
                        value='Import from Dropbox'
                        startIcon='fab fa-dropbox'
                        fullWidth
                        {...buttonProps}
                    />
                </DropboxChooser>

                {Boolean(user.oauthAccounts.find(a => a.label === 'BoxAccount')) &&
                    <BoxFilePicker
                        multipleSelection={true}
                        extensions={extensions}
                        success={preUploadFiles}
                    >
                        <Button
                            value='Import from Box'
                            startIcon='fas fa-box-alt'
                            fullWidth
                            {...buttonProps}
                        />
                    </BoxFilePicker>
                }

                <Divider horizontal />

                <Button
                    value='Import Volume'
                    htmlFor='importVolume'
                    as='label'
                    startIcon='fal fa-layer-group'
                    {...buttonProps}
                />
                <input
                    id='importVolume'
                    type='file'
                    onChange={(e) => computeAndUploadFiles(e, { isVolume: true })}
                    multiple
                    className='hidden'
                />

                {
                    Can(permissions, 'importPackagedAsset') &&
                    <Fragment>
                        <Button
                            value='Import Package'
                            htmlFor='importPackage'
                            as='label'
                            startIcon='fal fa-box'
                            {...buttonProps}
                        />
                        <input
                            id='importPackage'
                            type='file'
                            onChange={(e) => computeAndUploadFiles(e, { isPackage: true })}
                            multiple
                            accept='.zip'
                            className='hidden'
                        />
                    </Fragment>
                }

                <Divider horizontal />

                <Button
                    value='Link new Libraries'
                    onClick={() => {
                        props.toggleSelectLibrariesModal();
                        props.onClose();
                    }}
                    startIcon='fal fa-link'
                    {...buttonProps}
                />
            </section>
        </Popover>
    );
}

export default AddAssetPopover;
