import styled       from 'styled-components';
import MonacoEditor from '@monaco-editor/react';


export const MonacoEditorStyled = styled(MonacoEditor)`
    .monaco-editor,
    .monaco-editor .margin,
    .monaco-editor-background,
    .monaco-editor .inputarea.ime-input {
        background-color: var(--color-bg-underground);
    }

    .monaco-editor .cursors-layer .cursor {
        background-color: var(--color-accent);
    }

    .monaco-editor .view-overlays .current-line {
        border-color: var(--color-bg-overground);
    }
`;