//------------------------------------------------------------------------------
import React                    from 'react';

//------------------------------------------------------------------------------
import Plugin                   from '../../../../../plugins';
import assetUtils               from '../../../../../utils/assetUtils';
import { getAssetDescription }  from '../../../api';

//------------------------------------------------------------------------------
export default function MaterialEditor(props)
{
    const assetLabel = assetUtils.getAssetLabelFromAssetType(props.assetType);

    return (
        <Plugin
            pluginName='Material Editor'
            version='master'
            component={assetLabel+'Editor'}
            getAssetDescription={getAssetDescription}
            {...props}
        />
    );
}
