//------------------------------------------------------------------------------
import React, { Fragment }                        from 'react';
import { withSnackbar }             from 'notistack';

//------------------------------------------------------------------------------
import {
    Stack,
    Button,
    ButtonIcon,
    Text,
    ControlNumber,
    ControlCheckbox,
    ControlSelect,
    ControlSelectItem,
}                                   from '../../../../../designSystem/components';
import { Widgets, AssetWatcher }    from '../../../../../designSystem/widgets';

//------------------------------------------------------------------------------
import Timeline                     from './Timeline';
import { TimelineEvent }            from './style.js';

//------------------------------------------------------------------------------
import { getAssetDescription }      from '../../../api';
import { searchAssetsAPI }          from '../../../../projects/api';
import Utils                        from '../../../../../Utils';

//------------------------------------------------------------------------------
const attributes    = [
    {
        name    : 'skeletonRef',
        type    : 'skeleton_ref',
        widget  : 'skeleton-selector',
        default : Utils.invalidUUID
    }
];

//------------------------------------------------------------------------------
const EventColors = [
    "#4d9de0","#e15554","#e1bc29","#3bb273","#7768ae","#ceb992","#73937e","#585563","#5b2e48","#471323",
    "#9ee493","#daf7dc","#abc8c0","#70566d","#42273b","#086788","#07a0c3","#f0c808","#fff1d0","#dd1c1a"
];

//------------------------------------------------------------------------------
class AnimationEditor extends React.Component
{
    //--------------------------------------------------------------------------
    state = {
        eventMaps : [],
        eventMapDescriptions : {},
        selectedEventOccurence : -1,
        timelineEventOccurences : [],
    };

    //--------------------------------------------------------------------------
    componentDidMount()
    {
        this.retrieveEventMaps();
        this.refreshTimelineEventOccurences();
    }

    //--------------------------------------------------------------------------
    componentDidUpdate(prevProps, prevState)
    {
        if(
            this.props.assetDescription !== prevProps.assetDescription
            || this.props.assetDescription.animationEventTrack !== prevProps.assetDescription.animationEventTrack
            || this.state.selectedEventOccurence !== prevState.selectedEventOccurence
        )
        {
            this.refreshTimelineEventOccurences();
        }
    }

    //--------------------------------------------------------------------------
    render()
    {
        const { eventNames = [] } = this.props.assetDescription.animationEventTrack || {};

        return (
            <Stack vertical className='h-full' id='root' onMouseDown={(e) => e.target.id === 'root' && this.setState({selectedEventOccurence : -1})}>
                <Stack vertical className='w-100 pt-2 overflow-y-auto'>
                {
                    attributes.map(attribute =>
                    {
                        const widgetProps = {
                            hasLabel                : true,
                            key                     : attribute.name,
                            attribute               : attribute,
                            originalAttributeValue  : null,
                            attributeValue          : this.props.assetDescription[attribute.name] || attribute.default,
                            onChange                : (event, attributeValue) => this.onValueUpdated(attribute.name, attribute.type, attributeValue, false),
                            onSubmit                : (event, attributeValue) => this.onValueUpdated(attribute.name, attribute.type, attributeValue, true),
                            apiGatewayURL           : apiGatewayURL,
                            apiToken                : apiToken,
                        };

                        return (
                            <Widgets {...widgetProps}/>
                        );
                    })
                }
                </Stack>

                <div className='grid gap-2' style={{ gridTemplateColumns: '40px max-content max-content max-content' }}>
                    <Text format='body3' color='primary' className='col-start-2'>
                        Event Maps
                    </Text>

                    <Text format='body3' color='primary' className='col-start-3 col-span-2'>
                        Event Names
                    </Text>
                    {
                        eventNames.map((eventKey, index) =>
                        {
                            const [eventMapUUID, eventName] = eventKey.split('/');

                            return (
                                <Fragment key={index}>
                                    <div className='flex justify-center items-center'>
                                        <TimelineEvent
                                            shape='circle'
                                            style={{ backgroundColor : EventColors[index % EventColors.length] }}
                                        />
                                    </div>

                                    <ControlSelect
                                        value={eventMapUUID}
                                        className='flex-1'
                                        onChange={(e, value) => this.setEventMap(index, value)}
                                    >
                                        {this.state.eventMaps.map((eventMap) =>
                                            <ControlSelectItem
                                                key={eventMap.uuid}
                                                value={eventMap.uuid}
                                            >
                                                <div className='flex justify-between items-center w-full gap-1'>
                                                    <Text format='body3' color='primary'>
                                                        {eventMap.name}
                                                    </Text>

                                                    <Text format='body4' color='secondary'>
                                                        {eventMap.path}
                                                    </Text>
                                                </div>
                                            </ControlSelectItem>
                                        )}
                                    </ControlSelect>

                                    <AssetWatcher
                                        assetUUID={eventMapUUID}
                                        assetType='eventMap'
                                        apiGatewayURL={apiGatewayURL}
                                        apiToken={apiToken}
                                        onDescriptionResolved={(assetDescription) => this.onEventMapResolved(eventMapUUID, assetDescription)}
                                    >
                                        {({description}) =>
                                        {
                                            const events = description.events ? Object.keys(description.events) : null;

                                            return (
                                                <ControlSelect
                                                    value={eventName}
                                                    className='flex-1'
                                                    onChange={(e, value) => this.setEventName(index, value)}
                                                >
                                                    {events && events.map((event) =>
                                                        <ControlSelectItem
                                                            key={event}
                                                            value={event}
                                                        >
                                                            <Text format='body3' color='primary'>
                                                                {event}
                                                            </Text>
                                                        </ControlSelectItem>
                                                    )}
                                                </ControlSelect>
                                            );
                                        }}
                                    </AssetWatcher>

                                    <div className='flex justify-center items-center'>
                                        <ButtonIcon
                                            onClick={() => this.deleteEvent(index)}
                                            className='fal fa-trash '
                                            size='small'
                                            tooltipTitle='Delete'
                                        />
                                    </div>
                                </Fragment>
                            );
                        })
                    }
                    <Button
                        value='Add Event'
                        onClick={this.addEvent}
                        startIcon='fas fa-bolt'
                        disabled={this.state.eventMaps.length === 0}
                        className='mt-2 col-start-2'
                        size='small'
                        color='secondary'
                    />
                </div>

                <Timeline
                    className='w-11/12 m-4'
                    elements={this.state.timelineEventOccurences}
                    onElementClicked={(event, element, index) => this.onEventOccurenceClicked(event, element, index)}
                    onElementMoved={(position, isMovementCompleted, element, index) => this.onEventOccurenceMoved(position, isMovementCompleted, element, index)}
                    onElementCreated={(position) => this.createEventOccurence(position)}
                />
            </Stack>
        );
    }

    //--------------------------------------------------------------------------
    async retrieveEventMaps()
    {
        const workspaceUUIDs = this.props.getWorkspaceUUIDs();
        const { eventMaps = [] } = await searchAssetsAPI(workspaceUUIDs, '', 0, 200, ['eventMaps'], false);

        this.setState(
        {
            eventMaps : eventMaps.sort((a, b) => a.name.localeCompare(b.name)).map(eventMap => (
            {
                name : eventMap.name,
                uuid : eventMap.uuid,
                path : this.props.workspaceMap.has(eventMap.workspaceUUID)
                        ? this.props.workspaceMap.get(eventMap.workspaceUUID).path
                        : ''
            }))
        });
    }

    //--------------------------------------------------------------------------
    onEventMapResolved(eventMapUUID, assetDescription)
    {
        this.setState(
        {
            eventMapDescriptions : {
                ...this.state.eventMapDescriptions,
                [eventMapUUID] : assetDescription
            }
        });
    }

    //--------------------------------------------------------------------------
    onEventOccurenceClicked(event, element, index)
    {
        event.stopPropagation();
        this.setState({ selectedEventOccurence : index });
    }

    //--------------------------------------------------------------------------
    refreshTimelineEventOccurences()
    {
        const { eventNames = [], eventTimeline = [] } = this.props.assetDescription.animationEventTrack || {};

        this.setState(
        {
            timelineEventOccurences : eventTimeline.map(
                (occurence, index) =>
                {
                    const isOpen    = index === this.state.selectedEventOccurence;
                    const element   = {
                        color    : EventColors[occurence.eventIndex % EventColors.length],
                        position : occurence.triggerTime,
                        shape    : occurence.triggerGlobally ? 'square' : 'circle',
                    };

                    if(isOpen)
                    {
                        element.children = (
                            <div className="flex flex-col gap-2">
                                <ControlSelect
                                    label='Event'
                                    value={occurence.eventIndex}
                                    onChange={(e, value) => this.onEventOccurenceChanged('eventIndex', value, index)}
                                >
                                    {eventNames.map((eventKey, index) =>
                                    {
                                        const [eventMapUUID, eventName] = eventKey.split('/');
                                        const eventMapDescription = this.state.eventMapDescriptions[eventMapUUID] || {name : ''};

                                        return (
                                            <ControlSelectItem key={index} value={index}>
                                                <div className='flex justify-between items-center w-full gap-2'>
                                                    <TimelineEvent
                                                        shape='circle'
                                                        style={{ backgroundColor : EventColors[index % EventColors.length] }}
                                                    />

                                                    <Text format='body4' color='secondary'>
                                                        {eventMapDescription.name}
                                                    </Text>

                                                    <Text format='body3' color='primary'>
                                                        {eventName}
                                                    </Text>
                                                </div>
                                            </ControlSelectItem>
                                        );
                                    })}
                                </ControlSelect>

                                <ControlNumber
                                    label='Trigger time'
                                    value={occurence.triggerTime}
                                    onChange={(e, value) => this.onEventOccurenceChanged('triggerTime', value, index, false)}
                                    onBlur={(e, value) => this.onEventOccurenceChanged('triggerTime', value, index, true)}
                                    min={0}
                                    max={1.0}
                                />

                                <ControlCheckbox
                                    label='Trigger globally'
                                    value={occurence.triggerGlobally}
                                    onChange={(e, value) => this.onEventOccurenceChanged('triggerGlobally', value, index, true)}
                                />

                                <Button
                                    value='Delete event'
                                    onClick={(e) => this.deleteEventOccurence(index)}
                                    startIcon='fas fa-trash'
                                    size='small'
                                    color='secondary'
                                />
                            </div>
                        );
                    }

                    return element;
                }
            )
        });
    }

    //--------------------------------------------------------------------------
    addEvent = () =>
    {
        const { eventNames = [], eventTimeline = [] } = this.props.assetDescription.animationEventTrack || {};
        const newEventName = this.state.eventMaps[0].uuid + '/';

        this.onValueUpdated(
            'animationEventTrack',
            'object',
            {
                eventNames : [...eventNames, newEventName],
                eventTimeline
            },
            true
        );
    }

    //--------------------------------------------------------------------------
    deleteEvent = (index) =>
    {
        const { eventNames = [], eventTimeline = [] } = this.props.assetDescription.animationEventTrack || {};
        const newEventNames = Array.from(eventNames);
        newEventNames.splice(index, 1);

        const newEventTimeline = [];

        for(const occurence of eventTimeline)
        {
            if(occurence.eventIndex !== index)
            {
                newEventTimeline.push({
                    ...occurence,
                    eventIndex : occurence.eventIndex > index ? occurence.eventIndex - 1 : occurence.eventIndex
                });
            }
        }

        this.onValueUpdated(
            'animationEventTrack',
            'object',
            {
                eventNames      : newEventNames,
                eventTimeline   : newEventTimeline
            },
            true
        );
    }

    //--------------------------------------------------------------------------
    createEventOccurence = (position) =>
    {
        const { eventNames = [], eventTimeline = [] } = this.props.assetDescription.animationEventTrack || {};

        const newElement = {
            eventIndex : 0,
            triggerTime : position,
            triggerGlobally : false
        };

        const newTimeline      = [...eventTimeline, newElement].sort((a, b) => a.triggerTime - b.triggerTime);
        const newIndex         = newTimeline.indexOf(newElement);

        this.setState(
            {
                selectedEventOccurence : newIndex,
            },
            () =>
            {
                this.onValueUpdated(
                    'animationEventTrack',
                    'object',
                    { eventNames, eventTimeline : newTimeline },
                    true
                );
            }
        );
    }

    //--------------------------------------------------------------------------
    deleteEventOccurence = (index) =>
    {
        const { eventNames = [], eventTimeline = [] } = this.props.assetDescription.animationEventTrack || {};

        const newTimeline = [...eventTimeline];
        newTimeline.splice(index, 1);

        this.setState(
            {
                selectedEventOccurence : -1,
            },
            () =>
            {
                this.onValueUpdated(
                    'animationEventTrack',
                    'object',
                    { eventNames, eventTimeline : newTimeline },
                    true
                );
            }
        );
    }

    //--------------------------------------------------------------------------
    setEventMap = (index, value) =>
    {
        const { eventNames = [], eventTimeline = [] } = this.props.assetDescription.animationEventTrack || {};
        const newEventNames     = Array.from(eventNames)
        newEventNames[index]    = value + '/';

        this.onValueUpdated(
            'animationEventTrack',
            'object',
            { eventNames : newEventNames, eventTimeline },
            true
        );
    }

    //--------------------------------------------------------------------------
    setEventName = (index, value) =>
    {
        const { eventNames = [], eventTimeline = [] } = this.props.assetDescription.animationEventTrack || {};
        const [ eventMapUUID ]  = eventNames[index].split('/');
        const newEventNames     = Array.from(eventNames)
        newEventNames[index]    = eventMapUUID + '/' + value;

        this.onValueUpdated(
            'animationEventTrack',
            'object',
            { eventNames : newEventNames, eventTimeline },
            true
        );
    }

    //--------------------------------------------------------------------------
    onEventOccurenceMoved = (position, isMovementCompleted, element, index) =>
    {
        const { eventTimeline = [] }        = this.props.assetDescription.animationEventTrack;
        eventTimeline[index].triggerTime    = position;

        this.refreshTimelineEventOccurences();
        if(isMovementCompleted)
        {
            this.onEventOccurenceChanged('triggerTime', position, index, isMovementCompleted);
        }
    }

    //--------------------------------------------------------------------------
    onEventOccurenceChanged = (attributeName, value, index, doCommit = true) =>
    {
        const { eventNames = [], eventTimeline = [] } = this.props.assetDescription.animationEventTrack || {};
        eventTimeline[index][attributeName] = value;

        if(!doCommit)
        {
            this.setState({ timelineEventOccurences : this.state.timelineEventOccurences });
            return;
        }

        const element     = this.state.selectedEventOccurence !== -1 ? eventTimeline[this.state.selectedEventOccurence] : null;
        const newTimeline = Array.from(eventTimeline).sort((a, b) => a.triggerTime - b.triggerTime);
        const newIndex    = element ? newTimeline.indexOf(element) : -1;

        this.setState(
            {
                selectedEventOccurence : newIndex,
            },
            () =>
            {
                this.onValueUpdated(
                    'animationEventTrack',
                    'object',
                    { eventNames, eventTimeline : newTimeline },
                    true
                );
            }
        );
    }

    //--------------------------------------------------------------------------
    async onValueUpdated(attributeName, attributeType, value, doCommit)
    {
        if(attributeType === 'skeleton_ref' && value !== Utils.invalidUUID)
        {
            try
            {
                const skeletonDescription = await getAssetDescription('skeleton', value);
                if(this.props.assetDescription.channels?.length > skeletonDescription.bones?.length)
                {
                    this.props.enqueueSnackbar("Skeleton bone count cannot be inferior to the animation channel count", { variant: 'error', autoHideDuration : 6000 });
                    return;
                }
            }
            catch(error)
            {
                console.error(error);
                this.props.enqueueSnackbar("Failed to fetch skeleton description", { variant: 'error', autoHideDuration : 6000 });
                return;
            }
        }

        const newDescription = {...this.props.assetDescription};

        if(value === Utils.invalidUUID)
        {
            delete newDescription[attributeName];
        }
        else
        {
            newDescription[attributeName] = value;
        }

        this.props.onAssetUpdated(newDescription, doCommit);
    }
}

//------------------------------------------------------------------------------
export default withSnackbar(AnimationEditor);
