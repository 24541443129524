import React from 'react';
import { MonacoEditorStyled } from './style';

//------------------------------------------------------------------------------
function Editor(props) 
{
    //------------------------------------------------------------------------------  
    return (
        <MonacoEditorStyled
            theme='vs-dark'
            {...props}
        />
    );
}

export default Editor;
