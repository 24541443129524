//------------------------------------------------------------------------------
import React, { useState }          from 'react';
import { useSnackbar }              from 'notistack';

//------------------------------------------------------------------------------
import { Button, Icon, Text }       from '../../../../designSystem/components';
import ConfirmationModal            from '../../../../components/ConfirmationModal';
import ContentModal                 from '../../../../components/ContentModal';

//------------------------------------------------------------------------------
import ApplicationTemplateTile               from '../ApplicationTemplateTile';
import ApplicationTemplateSettings           from '../ApplicationTemplateSettings';
import { buildApplicationTemplateApps }      from '../api';

//------------------------------------------------------------------------------
export default function ApplicationTemplateSection(props)
{
    //------------------------------------------------------------------------------
    const { enqueueSnackbar }                       = useSnackbar();
    const { applicationTemplates = [] }             = props.currentProject;

    //------------------------------------------------------------------------------
    const [pending, setPending]                     = useState('');
    const [confirmationModal, setConfirmationModal] = useState(null);
    const [contentModal, setContentModal]           = useState(null);

    //------------------------------------------------------------------------------
    async function createApplicationTemplate(applicationTemplateData)
    {
        setPending('link');
        try
        {
            await props.requests.createApplicationTemplate(applicationTemplateData);
            enqueueSnackbar('Application templated added', { variant: 'success' });
        }
        catch(error)
        {
            enqueueSnackbar(error.message, { variant: 'error' });
        }
        setContentModal(null);
        setPending('');
    }

    //------------------------------------------------------------------------------
    async function updateApplicationTemplate(applicationTemplate)
    {
        props.requests.updateApplicationTemplate(applicationTemplate);
        setContentModal(null);
    }

    //------------------------------------------------------------------------------
    async function deleteApplicationTemplate(applicationTemplate)
    {
        props.requests.deleteApplicationTemplate(applicationTemplate.uuid);
        setConfirmationModal(null);
    }

    //------------------------------------------------------------------------------
    function createNewApplicationTemplate()
    {
        setContentModal(
            {
                titleHTML       : 'Add a new template',
                headerClassName : 'mb-4',
                children        :  (
                    <ApplicationTemplateSettings
                        onSubmit={createApplicationTemplate}
                        onClose={() => setContentModal(null)}
                        submitText='Add template'
                        currentApplicationTemplate={{}}
                        user={props.user}
                    />
                )
            });
    }

    //------------------------------------------------------------------------------
    function onClickApplicationTemplate(applicationTemplate)
    {
        setContentModal(
            {
                titleHTML       : 'Edit template',
                headerClassName : 'mb-4',
                children        :  (
                    <ApplicationTemplateSettings
                        onSubmit={updateApplicationTemplate}
                        onClose={() => setContentModal(null)}
                        submitText='Update template'
                        currentApplicationTemplate={applicationTemplate}
                        user={props.user}
                    />
                )
            });
    }

    //------------------------------------------------------------------------------
    async function publishApplicationTemplate(event, applicationTemplate)
    {
        event.stopPropagation();
        setPending(applicationTemplate.uuid);
        try
        {
            await buildApplicationTemplateApps(props.currentProject.uuid, applicationTemplate.uuid, applicationTemplate.provider);
            enqueueSnackbar('Applications published', { variant: 'success' });
        }
        catch(error)
        {
            enqueueSnackbar(error.message, { variant: 'error' });
        }
        setPending(false);
    }

    let content;
    if(applicationTemplates.length > 0)
    {
        content = (
            <ul className='flex flex-col gap-1px'>
                {applicationTemplates.map(applicationTemplate =>
                    <ApplicationTemplateTile
                        key={applicationTemplate.uuid}
                        {...applicationTemplate}
                        image={`logo/${applicationTemplate.provider.replace('-oauth', '').toLowerCase()}.svg`}
                        hasApps={applicationTemplate.linkedApplicationUUIDs.length > 0}
                        onClick={() => onClickApplicationTemplate(applicationTemplate)}
                        onPublish={(e) => publishApplicationTemplate(e, applicationTemplate)}
                        pending={pending}
                        onDelete={() => setConfirmationModal(
                            {
                                titleHTML   : `Delete the template <b>${applicationTemplate.gitPath}</b> ?`,
                                onSubmit    : () => deleteApplicationTemplate(applicationTemplate),
                                submitText  : 'Delete'
                            })}
                    />
                )}
            </ul>
        );
    }
    else
    {
        content = (
            <ApplicationTemplateSettings
                onSubmit={createApplicationTemplate}
                submitText='Add template'
                currentApplicationTemplate={{}}
                user={props.user}
                pending={pending === 'link'}
            />
        );
    }

    return (
        <div>
            <header className='flex justify-between mb-4 items-center'>
                <Text format='title2'>
                    <Icon className='fab fa-git-alt mr-3' size="xxlarge" fixedSize={false} color='secondary' />
                    Application Templates
                </Text>
                {
                    applicationTemplates.length > 0 &&
                    <Button
                        startIcon='fal fa-plus'
                        value='Create new template'
                        size='small'
                        onClick={createNewApplicationTemplate}
                    />
                }
            </header>

            {content}

            {contentModal &&
                <ContentModal open {...contentModal}/>
            }

            {confirmationModal &&
                <ConfirmationModal
                    open
                    onClose={() => setConfirmationModal(null)}
                    {...confirmationModal}
                />
            }
        </div>
    );
}