import React            from 'react';
import { plural as p }  from '../../designSystem/utils';
import { Button }       from '../../designSystem/components';

//------------------------------------------------------------------------------
const ASSET_COUNT_THRESHOLD = 10;

//------------------------------------------------------------------------------
function SourceFileAssetTag(props)
{
    const { sourceFileUUID, sourceFileAssetCount, assetType, ...otherProps } = props;

    // To remove when the api would return the exact asset count
    const value = sourceFileAssetCount > ASSET_COUNT_THRESHOLD
        ? ASSET_COUNT_THRESHOLD + '+ assets'
        : sourceFileAssetCount + ' asset' + p(sourceFileAssetCount);

    //------------------------------------------------------------------------------
    return (
        <Button
            as='div'
            value={value}
            onClick={(e) =>
            {
                e.stopPropagation();
                props.goTo({ sourceFileUUID, assetDetails: { uuid: sourceFileUUID, assetType, isSourceFile: true } });
            }}
            tooltipTitle='Browse assets from this source file'
            title=''
            startIcon='fas fa-caret-right'
            startIconProps={{
                color: 'secondary',
                fixedSize: false,
            }}
            valueClassName='gap-1'
            size={props.size || 'xsmall'}
            color='secondary'
            textColor='secondary'
            reversed
            {...otherProps}
        />
    );
}

export default SourceFileAssetTag;
