
//------------------------------------------------------------------------------
import { createGlobalStyle }    from 'styled-components';
import reset                    from 'styled-reset';

//------------------------------------------------------------------------------
import { GlobalStyle }          from '../designSystem/global';

//------------------------------------------------------------------------------
// To use this Global Style, don't forget to add a font link in the main html:
// <link href='https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;500;600&display=swap' rel='stylesheet' />

const AppGlobalStyle = createGlobalStyle`

    ${reset}

    .layout-wrapper {
        margin: auto;
        width: 100%;
        max-width: 100rem;
        padding-inline: var(--spacing-4);

        @media ${props => props.theme.breakpoints.mobileL} {
            padding-inline: var(--spacing-3);
        }
    }

    .wrapper {
        padding-inline: var(--spacing-6);

        @media ${props => props.theme.breakpoints.mobileL} {
            padding-inline: var(--spacing-4);
        }
    }

`;

//------------------------------------------------------------------------------
export {
    AppGlobalStyle,
    GlobalStyle
};