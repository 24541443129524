import React, { useContext, useEffect }   from 'react';
import TableCell                          from '@material-ui/core/TableCell';
import TableRow                           from '@material-ui/core/TableRow';
import { makeStyles }                     from '@material-ui/core/styles';
import moment                             from 'moment';

import {
    DeletedIcon,
    getStatusIcon,
    getStatusText
}                                         from '../status';
import { getSorting, stableSort }         from '../sort';
import Table                              from '../Table';
import UserContext                        from '../../../../account/userContext';
import ProjectContext                     from '../../../ProjectContext';
import { Link, ProgressBar, Avatar }      from '../../../../../designSystem/components';


//------------------------------------------------------------------------------
const useStyles = makeStyles(() => ({
    deleted: {
        background: '#f9f9f9',
    },

    deleteRow : {
        color: 'grey'
    }
}));


//------------------------------------------------------------------------------
const headCells = [
    { id: 'creationTimestamp',  align: 'left',  padding: 'checkbox', sortable : true,  label: 'Age' },
    { id: 'name',               align: 'left',  padding: 'none',     sortable : true,  label: 'Name' },
    { id: 'itemCount',          align: 'left',  padding: 'normal',   sortable : true,  label: 'Items' },
    { id: 'progress',           align: 'left',  padding: 'normal',   sortable : true,  label: 'Completion' },
    { id: 'status',             align: 'left',  padding: 'normal',   sortable : true,  label: 'Status' },
    { id: 'userUUID',           align: 'left',  padding: 'normal',   sortable : true,  label: 'Creator' },
];

//------------------------------------------------------------------------------
export default function TaskTable(props)
{
    const { page, setPage, order, setOrder, orderBy, setOrderBy, rowsPerPage, setRowsPerPage } = props;

    const appStyle          = useStyles();
    const userData          = useContext(UserContext);
    const projectContext    = useContext(ProjectContext);

    const [, updateState]   = React.useState();
    const forceUpdate       = React.useCallback(() => updateState({}), [])

    const displayData = () =>
    {
        const isDesc        = order === 'desc';

        // Since creation timestamp is considered as 'age' reverse the sort order.
        const computedOrder = (orderBy === 'creationTimestamp' ? !isDesc : isDesc) ? 'desc' : 'asc';

        const sort          = stableSort(props.tasks, getSorting(computedOrder, orderBy));
        return sort.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    };

    useEffect(() =>
    {
        projectContext.resolveUsernames(props.tasks.map(task => task.userUUID)).then(
            () => forceUpdate()
        );
    }, [props.tasks])

    return (
        <Table
            title={props.title}
            headCells={headCells}
            order={order}
            setOrder={setOrder}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            rowCount={props.tasks.length}
        >
            { displayData().map((task) =>
            {
                const isDeleted = task.deleted === 1 && task.status !== 'rejected';
                const cellClass = isDeleted ? appStyle.deleteRow : null;

                return (
                    <TableRow tabIndex={-1} key={task.uuid} className={isDeleted ? appStyle.deleted : null}>
                        <TableCell className='p-0'>
                            <time className='text-color-neutral-secondary'>
                                {moment(task.creationTimestamp * 1000).fromNow()}
                            </time>
                        </TableCell>
                        <TableCell component='th' id={task.uuid} scope='row' padding='none' className={cellClass}>
                            {
                                isDeleted
                                    ?   task.name
                                    :   <Link to={`/p/${props.projectUUID}/conversions/${task.uuid}/${window.location.search}`}>
                                        {task.name}
                                    </Link>
                            }
                        </TableCell>
                        <TableCell align='left' className={cellClass}>{task.itemCount}</TableCell>
                        <TableCell align='left'>
                            {Number(task.progress) !== 1 &&
                                <ProgressBar progress={task.progress || 0} disabled={isDeleted} size='small' backgroundColor='light' />
                            }
                        </TableCell>
                        <TableCell align='left' className={cellClass}>
                            {isDeleted ? <DeletedIcon /> : getStatusIcon(task.status)}
                            {isDeleted ? 'Deleted' : getStatusText(task.status)}
                        </TableCell>
                        <TableCell>
                            <Avatar
                                uuid={task.userUUID}
                                username={projectContext.userMap.get(task.userUUID)?.username}
                                size='small'
                                circleStyle={userData.uuid === task.userUUID}
                                color='var(--color-accent)'
                                backgroundColor='transparent'
                                reversed
                                displayName
                                className='w-full'
                            />
                        </TableCell>
                    </TableRow>
                );
            })}
        </Table>
    );
}
