//------------------------------------------------------------------------------
import React                from 'react';

//------------------------------------------------------------------------------
import DevelopmentPlugin    from './DevelopmentPlugin';
import PluginContainer      from './PluginContainer';

//------------------------------------------------------------------------------
export default function Plugin(props)
{
    //--------------------------------------------------------------------------
    const urlParams     = new URLSearchParams(window.location.search);
    const devPlugin     = urlParams.get('devPlugin');
    if(!devPlugin)
    {
        return <PluginContainer {...props}/>
    }

    //--------------------------------------------------------------------------
    return (
        <DevelopmentPlugin>
            {({url}) =>
            (
                <PluginContainer {...props} url={url} />
            )}
        </DevelopmentPlugin>
    );
}