//------------------------------------------------------------------------------
import React            from 'react';
import urlEncodeParams  from '../../../utils/urlEncodeParams';

//------------------------------------------------------------------------------
const SoundPlayer = (props) =>
{
    return (
        <figure>
            <audio key={props.assetUUID} controls className='w-full p-4'>
                <source src={`${apiGatewayURL}/asset/export/sound/${props.assetUUID}?${urlEncodeParams({ token: apiToken })}`} />
                    Your browser does not support the audio element.
            </audio>
        </figure>
    );
};

export default SoundPlayer;