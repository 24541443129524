import React                            from 'react';
import { Button, Link, Logo }           from '../../designSystem/components';

//------------------------------------------------------------------------------
const LoginLayout = (props) =>
{
    return (
        <div className='min-h-screen flex flex-col justify-center items-center px-4 py-6 bg-color-underground'>
            <Link to='/' className='mb-4 flex flex-col items-center justify-center gap-1'>
                <Logo height={1.5} />
                <span className='text-lg text-color-neutral-secondary'>LABS</span>
            </Link>
            {props.children}
        </div>
    );
};

export default LoginLayout;


//------------------------------------------------------------------------------
export const Box = (props) =>
{
    return (
        <div
            className={`w-full mt-2 p-6 bg-color-ground border border-color-tertiary rounded-md ${props.className ? props.className : ''}`}
            style={{
                maxWidth: '31rem',
                ...props.style
            }}
        >
            {props.children}
        </div>
    );
};

//------------------------------------------------------------------------------
export const SignInUpSwitch = ({ currentPage, history }) =>
{
    return (
        <div className='flex mt-6 mb-3'>
            <Button
                value='Sign In'
                onClick={() => history.push('/signin')}
                color={currentPage === 'signin' ? 'primary' : 'secondary'}
            />
            {/* <span className='text-color-neutral-tertiary opacity-60'>|</span> */}
            <Button
                value='Sign Up'
                onClick={() => history.push('/signup')}
                color={currentPage === 'signup' ? 'primary' : 'secondary'}
            />
        </div>
    );
};