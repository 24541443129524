import React, { Fragment, useContext }      from 'react';

import { Link, Popover, Text }              from '../../../designSystem/components';
import { plural as p }                      from '../../../designSystem/utils';
import UserContext                          from '../../account/userContext';
import SessionTile                          from '../SessionTile';
import { SessionsListStyled }               from './style';


//------------------------------------------------------------------------------
function CurrentSessionsPopover(props) 
{
    //------------------------------------------------------------------------------
    const { sessions }  = props;
    const user          = useContext(UserContext);

    //------------------------------------------------------------------------------
    // Define Sessions List
    
    const sessionsList = Object.keys(sessions).map(projectUUID => (
        {
            project     : props.projects.find(project => project.uuid === projectUUID),
            scenes      : Object.keys(sessions[projectUUID]).map(sceneUUID => (
                {
                    uuid: sceneUUID,
                    sceneName: sessions[projectUUID][sceneUUID].sceneName,
                    sessions: Object.keys(sessions[projectUUID][sceneUUID].sessions).map(sessionUUID => 
                    {
                        let session = sessions[projectUUID][sceneUUID].sessions[sessionUUID];
                        session.uuid = sessionUUID;
                        return session;
                    })
                }
            ))
        }
    ));
  
    //------------------------------------------------------------------------------
    return (
        <Popover
            id='current-sessions'
            open={props.open}
            anchorEl={props.anchorEl}
            onClose={props.onClose}
        >
            <SessionsListStyled>
                {sessionsList.map((item, index) => (
                    <li key={index}>
                        
                        {item.project &&
                            <Text format='body4' color='secondary' className='mb-1'>
                                {item.scenes.length} session{p(item.scenes.length)} in project
                                {' '}
                                <Link to={'/p/' + item.project.uuid} className='link'>
                                    <b>{item.project.name}</b>
                                </Link>
                            </Text>
                        }

                        <ul className='list'>
                            {item.scenes.map(scene => (
                                <Fragment key={scene.uuid}>

                                    {scene.sessions.map(session => (
                                        <li key={session.uuid}>
                                            <SessionTile 
                                                {...session}
                                                sceneUUID={scene.uuid}
                                                sceneName={scene.sceneName}
                                                sceneInformation
                                                linkTarget={user.openSceneLinkTarget}
                                                reversed
                                            />
                                        </li>
                                    ))}
                  
                                </Fragment>
                            ))}
                        </ul>
                    </li>
                ))}
        
            </SessionsListStyled>
        </Popover>
    );
}

//------------------------------------------------------------------------------
export default CurrentSessionsPopover;
