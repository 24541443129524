//------------------------------------------------------------------------------
import CallAPI                          from '../../utils/api';
import { plural as p, readablizeBytes } from '../../designSystem/utils';
import { extensions }                   from './supportedFiles';

//------------------------------------------------------------------------------
export async function uploadFiles(workspaceUUID, uploadList, { isAutoConvert, isVolume, onUploadProgress, onUploadDone, onUploadError, materialLUT, skeletonUUID })
{
    const formData = new FormData();
    formData.append('workspaceUUID', workspaceUUID);
    formData.append('task-name', uploadList[0].name);
    formData.append('convert', isAutoConvert);
    formData.append('isVolume', isVolume);

    const filesToUpload = uploadList
        .filter(file => file.hasOwnProperty('handle'))
        .map(file => file.handle);

    filesToUpload.forEach(file =>
    {
        formData.append('file_input[]', file);
    });

    const boxFiles = uploadList.filter(file => file.provider === 'Box');
    if(boxFiles.length > 0)
    {
        const fileIDs   = boxFiles.map(file => file.id);
        const fileURLs  = await CallAPI('/account/generateDownloadURLs', 'POST', { fileIDs });
        for(const index in fileURLs)
        {
            boxFiles[index].link = fileURLs[index];
        }
    }

    const linkFiles = uploadList
        .filter(file => file.hasOwnProperty('link'))
        .map(file => (
            {
                name    : file.name + (file.extension ? '.' + file.extension : ''),
                size    : file.size,
                isDir   : file.isDir,
                url     : file.link
            }
        ));

    formData.append('linkFiles', JSON.stringify(linkFiles));

    if(materialLUT)
    {
        formData.append('materialLUT', JSON.stringify(materialLUT));
    }

    if(skeletonUUID)
    {
        formData.append('skeletonUUID', skeletonUUID);
    }

    try
    {
        const HTTP      = new XMLHttpRequest();
        const UploadURL = `${apiGatewayURL}/upload/submit`;

        // Handle http response
        HTTP.onreadystatechange = function onUploadStateChanged()
        {
            switch (this.readyState)
            {
            // Request finished and response is ready:
            case 4: {
                let response = this.response;
                try
                {
                    response = JSON.parse(response);
                }
                catch(e)
                {
                    console.error('uploadFile unexpected non JSON response:', response);
                }

                switch(this.status)
                {
                // OK
                case 301:
                case 302:
                case 200:
                    onUploadDone(uploadList, response, isAutoConvert);
                    break;

                    // Payload too large
                    // This error could be returned by any web server proxying to the service
                case 413:
                    onUploadError('Your file size is too big');
                    break;

                    // Any other api error
                case 500:
                default:
                    onUploadError('Upload Failed', response);
                    break;
                }
                break;
            }
            default:
                break;
            }
        };

        HTTP.open('POST', UploadURL, true);

        // Update progress bar
        HTTP.upload.onprogress = function (e)
        {
            if (!e.lengthComputable)
            {
                return;
            }

            const bytesUploaded = e.loaded;
            const bytesToUpload = e.total;
            onUploadProgress(bytesUploaded, bytesToUpload);
        };

        HTTP.setRequestHeader('x-api-token', apiToken);
        HTTP.send(formData);

    }
    catch (error)
    {
        onUploadError('UUnexpected Error', error);
    }
    return false;
}

//------------------------------------------------------------------------------
export function checkUploadFiles(files, user)
{
    const { authorizedFileTypes, maxUploadSizeInMB, maxStorageSizeInMB, maxMonthlyConversions } = user.accountPlan;
    const { storageSizeInMB, monthlyConversions } = user.consumption;

    const errors                        = [];
    const warnings                      = [];
    const unsupportedExtensions         = new Set();
    const unsupportedExtensionsFileName = [];
    const unauthorizedExtensions        = new Set();
    const unauthorizedExtensionsFileName = [];
    let displayUpgradeAction            = false;

    // Catch max monthly conversions
    if(maxMonthlyConversions !== -1 && monthlyConversions >= maxMonthlyConversions)
    {
        errors.push({
            title: 'You reached the maximum number of uploads allowed in your plan.'
        });

        displayUpgradeAction = true;
    }

    for (const file of files)
    {
        // Catch supported extensions
        if (file.extension && !extensions.includes('.'+file.extension))
        {
            unsupportedExtensions.add(file.extension);
            unsupportedExtensionsFileName.push(file.name+'.'+file.extension);
            file.status = 'rejected';
        }

        // Catch authorized extensions
        if ((authorizedFileTypes.length > 0 && !authorizedFileTypes.includes('.'+file.extension)))
        {
            unauthorizedExtensions.add(file.extension);
            unauthorizedExtensionsFileName.push(file.name+'.'+file.extension);
            file.status = 'rejected';
        }

        if(!file.hasOwnProperty('isChecked'))
        {
            file.isChecked = file.status !== 'rejected';
        }

    }

    if(unsupportedExtensions.size > 0)
    {
        warnings.push({
            id      : 'unsupportedExtensions',
            title   : `Extension${p(unsupportedExtensions.size)} ${Array.from(unsupportedExtensions).join(', ').toUpperCase()} ${unsupportedExtensions.size > 1 ? 'are' : 'is'} not supported: ${unsupportedExtensionsFileName.join(', ')}.`
        });
    }

    if(unauthorizedExtensions.size > 0)
    {
        warnings.push({
            id      : 'unauthorizedExtensions',
            title   : `Extension${p(unauthorizedExtensions.size)} ${Array.from(unauthorizedExtensions).join(', ').toUpperCase()} ${unauthorizedExtensions.size > 1 ? 'are' : 'is'} not authorized: ${unauthorizedExtensionsFileName.join(', ')}.`
        });
    }


    const totalUploadSize           = files.filter(f => f.isChecked).reduce((acc, file) => acc + file.size, 0);
    const totalUploadSizeInMB       = totalUploadSize / 1024 / 1024;

    // Catch upload size
    const isMaxUploadLimitExceed    = maxUploadSizeInMB > 0 && totalUploadSizeInMB > maxUploadSizeInMB;

    let maxUploadLimit = null;
    if(isMaxUploadLimitExceed)
    {
        maxUploadLimit = {
            // title   : `This upload of ${readablizeBytes(totalUploadSize)} exceeds ${readablizeBytes(maxUploadSizeInMB, 'MB')}, the maximum authorized by your plan.`,
            title: `Max upload limit: ${readablizeBytes(totalUploadSize)} / ${readablizeBytes(maxUploadSizeInMB, 'MB')}`,
        };
    }

    // Catch consumption limit
    const isMaxStorageLimitExceed   = maxStorageSizeInMB > 0 && (storageSizeInMB + totalUploadSizeInMB) > maxStorageSizeInMB;

    let maxStorageLimit = null;
    if(isMaxStorageLimitExceed)
    {
        maxStorageLimit = {
            // title   : `Your storage of ${readablizeBytes((storageSizeInMB + totalUploadSizeInMB), 'MB')}MB will exceed ${readablizeBytes(maxStorageSizeInMB, 'MB')}, the maximum authorized by your plan.`,
            title   : `Max storage limit: ${readablizeBytes((storageSizeInMB + totalUploadSizeInMB), 'MB')} / ${readablizeBytes(maxStorageSizeInMB, 'MB')}`,
        };
    }

    return { errors, warnings, displayUpgradeAction, maxStorageLimit, maxUploadLimit, files };
}
