import React, { Fragment, useContext, useEffect, useState
}                                                   from 'react';
import { useTranslation }                           from 'react-i18next';

import { ControlSelect, ControlSelectItem, Loader, Popover, Text
}                                                   from '../../../designSystem/components';
import UserContext                                  from '../../account/userContext';
import { getAllAvailableClustersAPI, removePreferredClusterAPI, updatePreferredClusterAPI
}                                                   from '../api';


//------------------------------------------------------------------------------
function ChangeClusterPopover(props)
{
    //------------------------------------------------------------------------------
    const { t }     = useTranslation();
    const userData  = useContext(UserContext);

    //------------------------------------------------------------------------------
    const [clusters, setClusters]               = useState(null);
    const [selectedCluster, setSelectedCluster] = useState(userData.preferredClusterUUID);

    //------------------------------------------------------------------------------
    useEffect(() =>
    {
        async function fetchClusters()
        {
            const _clusters = await getAllAvailableClustersAPI();
            setClusters(_clusters.sort((a, b) => a.uuid.localeCompare(b.uuid)));
        }

        fetchClusters();
    }, []);

    //------------------------------------------------------------------------------
    function changeCluster(event)
    {
        event.preventDefault();
        const value = event.target.value;
        setSelectedCluster(value);

        if (value === 'none')
        {
            userData.setPreferredCluster(null);
            removePreferredClusterAPI();
        }
        else
        {
            userData.setPreferredCluster(value);
            updatePreferredClusterAPI(value);
        }
    }

    //------------------------------------------------------------------------------
    return (
        <Popover
            id='change-cluster'
            open={props.open}
            anchorEl={props.anchorEl}
            onClose={props.onClose}

        >
            <div
                className='px-4 py-3 relative'
                style={{ minWidth: '15rem', minHeight: '4rem' }}
            >
                {clusters ?
                    <Fragment>
                        {clusters.length > 0 ?
                            <ControlSelect
                                label={t('account:your cluster hint')}
                                onChange={changeCluster}
                                value={selectedCluster || 'none'}
                                margin='none'
                                size='small'
                                fullWidth
                            >
                                {clusters.map( cluster => (
                                    <ControlSelectItem key={cluster.uuid} value={cluster.uuid}>
                                        {cluster.uuid}
                                    </ControlSelectItem>
                                ))}
                                <ControlSelectItem value='none'>
                                    {t('account:no preferred cluster')}
                                </ControlSelectItem>
                            </ControlSelect>
                            :
                            <Text format='body3' color='secondary'>
                                {t('account:no cluster available')}
                            </Text>
                        }
                    </Fragment>
                    :
                    <Loader centered />
                }
            </div>
        </Popover>
    );
}

export default ChangeClusterPopover;
