//------------------------------------------------------------------------------
import React, { useState, useRef }                  from 'react';

//------------------------------------------------------------------------------
import { Button, Popper, Icon }                     from '../../../../designSystem/components';
import assetUtils                                   from '../../../../utils/assetUtils';
import { createAssetAPI }                           from '../../../assets/api';
import MaterialPopover                              from './MaterialPopover';

//------------------------------------------------------------------------------
function CreatePopover(props)
{
    //--------------------------------------------------------------------------
    const { wrapperProps = {}, workingFolder } = props;

    //--------------------------------------------------------------------------
    const assetList = assetUtils.creatableAssets;

    //--------------------------------------------------------------------------
    const buttonProps = {
        color           : 'primary',
        size            : 'small',
        contentAlign    : 'left',
        square          : true,
        style           : { height: '1.25rem' },
        ...props.buttonProps,
    };

    //--------------------------------------------------------------------------
    const [ materialPopover, setMaterialPopover ]   = useState(null);
    const materialPopoverHandleHoverRef             = useRef(null);

    //--------------------------------------------------------------------------
    const openMaterialPopover = (e) =>
    {
        materialPopoverHandleHoverRef.current = true;
        setMaterialPopover(e.currentTarget);
    };

    //--------------------------------------------------------------------------
    const closeMaterialPopover = () =>
    {
        materialPopoverHandleHoverRef.current = false;

        setTimeout(() =>
        {
            if(!materialPopoverHandleHoverRef.current) setMaterialPopover(null);
        }, 350);
    };

    //--------------------------------------------------------------------------
    return (
        <Popper
            open={props.open}
            anchorEl={props.anchorEl}
            placement={props.placement}
            onClose={props.onClose}
            style={{ zIndex: '20000' }}
        >
            <section
                {...wrapperProps}
                className={`flex flex-col gap-1px bg-color-underground mx-1 shadow-md ${wrapperProps.className ? wrapperProps.className : ''}`}
                style={{
                    minWidth: '10rem',
                    ...wrapperProps.style
                }}
            >
                {assetList.map(assetType =>

                    assetType === 'material'
                    ? <Button
                        key={assetType}
                        value={assetUtils.getAssetLabelFromAssetType(assetType)}
                        onMouseEnter={openMaterialPopover}
                        onMouseLeave={closeMaterialPopover}
                        startIconProps={{ name: 'asset', assetType }}
                        InputProps={{
                            endAdornment: (
                                <Icon className='fal fa-chevron-right' size='xsmall'/>
                            ),
                        }}
                        {...buttonProps}
                    >
                        {materialPopover &&
                            <MaterialPopover
                                open
                                placement='right-start'
                                anchorEl={materialPopover}
                                wrapperProps={wrapperProps}
                                buttonProps={buttonProps}
                                workingFolder={props.workingFolder}
                                createAssetCallback={props.createAssetCallback}
                                onClose={() => setMaterialPopover(null)}
                                onCloseMainPopover={() => {props.onClose(null); props.onCloseMainPopover();} }
                                requests={props.requests}
                                hideBackdrop
                            />
                        }
                    </Button>

                    : <Button
                        key={assetType}
                        value={assetUtils.getAssetLabelFromAssetType(assetType)}
                        onClick={async () =>
                        {
                            props.onClose();
                            props.onCloseMainPopover();
                            const { uuid } = await createAssetAPI(workingFolder.workspaceUUID, assetType, '');
                            props.createAssetCallback({ uuid, assetType });
                        }}
                        startIconProps={{
                            name:'asset',
                            assetType
                        }}
                        {...buttonProps}
                    />
                )}
            </section>
        </Popper>
    );
}

export default CreatePopover;
