//------------------------------------------------------------------------------
import React                from 'react';
//------------------------------------------------------------------------------
import { Loader }           from '../../designSystem/components';
//------------------------------------------------------------------------------
import { getSourceFile }    from './api';

//------------------------------------------------------------------------------
export default class AssetWrapper extends React.Component
{
    //--------------------------------------------------------------------------
    constructor()
    {
        super();

        this.callstack  = [];
        this.state      = { updateTrigger : 0 };
        this.requests   = {
            updateAsset : this.updateAsset,
            undo        : this.undo,
            redo        : this.redo,
        };
    }

    //--------------------------------------------------------------------------
    componentDidMount()
    {
        this.onAssetChanged();
    }

    //--------------------------------------------------------------------------
    componentWillUnmount()
    {
        this.disconnect();
    }

    //--------------------------------------------------------------------------
    componentDidUpdate(prevProps)
    {
        if (prevProps.assetType !== this.props.assetType || prevProps.assetUUID !== this.props.assetUUID)
        {
            this.onAssetChanged();
        }
    }

    //--------------------------------------------------------------------------
    async onAssetChanged()
    {
        if(!this.props.isSourceFile)
        {
            this.connect(this.props.assetType, this.props.assetUUID, null);
            return;
        }

        let sourceFileInfo = this.props.assetInfo;
        if(!sourceFileInfo)
        {
            const { assets, ...sourceFile } = await getSourceFile(this.props.assetUUID);
            
            sourceFile.assetCount   = assets.length;
            sourceFile.mainAsset    = assets.find(a => a.isMainAsset);
            sourceFileInfo          = sourceFile;
        }

        if(sourceFileInfo.mainAsset)
        {
            this.connect(this.props.assetType, sourceFileInfo.mainAsset.uuid, sourceFileInfo);
        }
        else
        {
            this.setState(
            {
                asset               : null,
                assetDescription    : null,
                undoRedo            : null,
                canEdit             : true, // TODO
                sourceFile          : sourceFileInfo,
                updateTrigger       : this.state.updateTrigger + 1,
            });
        }
    }

    //--------------------------------------------------------------------------
    connect(assetType, assetUUID, sourceFileState)
    {
        this.disconnect();

        const webSocketURL      = `${this.props.apiGatewayURL}/asset/edit?token=${this.props.apiToken}`.replace('http', 'ws');
        this.socket				= new WebSocket(`${webSocketURL}&assetType=${assetType}&assetUUID=${assetUUID}`);
        this.socket.binaryType	= 'arraybuffer';

        this.socket.onmessage   = (message) =>
        {
            const payload = JSON.parse(message.data);

            if(payload.type === 'error')
            {
                console.error(payload.data);
            }
            else
            {
                console.log(payload);
            }

            switch(payload.type)
            {
                case 'connect-confirmation':
                    this.setState(
                    {
                        ...payload.data,
                        asset           : { uuid : assetUUID, assetType, name : payload.data.description.name },
                        sourceFile      : sourceFileState,
                        updateTrigger   : this.state.updateTrigger + 1
                    });
                    break;

                case 'update-asset':
                {
                    const assetDescription = {
                        ...payload.data,
                        name : payload.data.hasOwnProperty('name')
                                ? payload.data.name
                                : this.state.asset.name,
                        uuid : this.state.asset.uuid
                    };

                    const state = {
                        description     : assetDescription,
                        updateTrigger   : this.state.updateTrigger + 1
                    };

                    if(payload.data.name)
                    {
                        state.asset         = this.state.asset;
                        state.asset.name    = payload.data.name;
                    }

                    this.setState(state);
                    break;
                }

                case 'next-undo-redo':
                {
                    this.setState({undoRedo : payload.data});
                    break;
                }
            }
        };
    }

    //--------------------------------------------------------------------------
    disconnect()
    {
        if(this.socket)
        {
            this.socket.close();
            this.callstack  = [];
            this.socket     = null;
        }
    }

    //--------------------------------------------------------------------------
    updateAsset = (description, doCommit, updateState = true) =>
    {
        if(updateState)
        {
            this.setState(
            {
                description     : description,
                updateTrigger   : this.state.updateTrigger + 1
            });
        }

        this.sendRequest('update-asset',
        {
            description : description,
            doCommit    : doCommit
        });
    }

    //--------------------------------------------------------------------------
    undo = () =>
    {
        this.sendRequest('undo');
    }

    //--------------------------------------------------------------------------
    redo = () =>
    {
        this.sendRequest('redo');
    }

    //--------------------------------------------------------------------------
    sendRequest(type, data)
    {
        if(this.socket)
        {
            this.socket.send(JSON.stringify(
            {
                type : type,
                data : data
            }));
        }
    }

    //--------------------------------------------------------------------------
    sendRequestAsync(type, data)
    {
        return new Promise( (resolve) =>
        {
            this.sendRequest(type, data);

            this.callstack.push((result) =>
            {
                resolve(result);
            });
        });
    }

    //--------------------------------------------------------------------------
    handleRequestResponse(data)
    {
        const callback = this.callstack.shift();
        callback(data);
    }

    //--------------------------------------------------------------------------
    render = () =>
    {
        if(!this.state.asset && !this.state.sourceFile)
        {
            return <Loader centered/>;
        }

        return this.props.children(
        {
            assetRequests       : this.requests,
            canEdit             : this.state.canEdit,
            assetDescription    : this.state.description,
            undoRedo            : this.state.undoRedo,
            asset               : this.state.asset,
            sourceFile          : this.state.sourceFile,
            updateTrigger       : this.state.updateTrigger
        });
    }
}
