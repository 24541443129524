import styled, { css } from 'styled-components';

//------------------------------------------------------------------------------
export const AvatarEditStyled = styled.div`
    margin: 2rem 0;

    label {
        width: 100%;
        color: inherit !important;
        font-family: unset !important;
        font-size: var(--font-size-sm) !important;
        font-weight: 400 !important;
    }

    > div > div {
        border: 1px dashed var(--color-border-secondary) !important;
        transition: border-color .35s, background-color .35s !important;

        &:hover, 
        &:focus {
            background-color: hsla(var(--color-bg-overground-hsl), .2) !important;
        }

        ${props => props.hasPreview && css`
            border-color: transparent !important;
            background-color: hsla(var(--color-bg-overground-hsl), .2) !important;
        `}
    }
`;
