import React, { Fragment, useContext, useEffect, useState
}                                                   from 'react';
import { ThemeContext }                             from 'styled-components';

import SourceFileAssetTag                           from '../../components/SourceFileAssetTag';
import { Button, ControlText, Icon, Link, Popover, Text, Tooltip
}                                                   from '../../designSystem/components';
import SessionTile                                  from '../../apps/session/SessionTile';
import { TileStyled }                               from './style';


//------------------------------------------------------------------------------
function TileAsset(props)
{
    //------------------------------------------------------------------------------
    const { uuid, assetType, isActive, displayMode, sessions, mainAsset, isSourceFile, sourceFileAssetCount } = props;

    //------------------------------------------------------------------------------
    const theme = useContext(ThemeContext);

    const [ joinSessionsPopover, setJoinSessionsPopover ] = useState(null);

    //------------------------------------------------------------------------------
    const assetUUID     = mainAsset ? mainAsset.uuid : uuid;

    //------------------------------------------------------------------------------
    const hasThumbnail  = Boolean(assetType === 'scene' || assetType === 'texture');
    const isFolder      = assetType === 'folder';

    //------------------------------------------------------------------------------
    const nodeName = () =>
    {
        if (props.highlightString)
        {
            const regEx = new RegExp(props.highlightString, 'ig');
            const name = props.name.replace(regEx, '<mark>$&</mark>');
            return <span dangerouslySetInnerHTML={{ __html: name }} />;
        }
        else
        {
            return props.name || 'No name';
        }
    };


    //------------------------------------------------------------------------------
    // Define Name Mode

    //------------------------------------------------------------------------------
    const [ defineName, setDefineName ] = useState(props.name || '');

    //------------------------------------------------------------------------------
    const onSubmitName = () =>
    {
        if (isFolder)
        {
            if(props.createFolderMode)
            {
                props.createFolder(defineName);
            }
            else
            {
                props.renameFolder({ folderUUID: uuid, name: defineName });
            }
        }
        else
        {
            props.renameAsset(assetType, uuid, isSourceFile, defineName, props.name);
        }
        props.closeRenameMode();
    };

    //------------------------------------------------------------------------------
    const abortChanges = () =>
    {
        if (isFolder && props.createFolderMode)
        {
            props.closePendingFolder();
        }
        else
        {
            props.closeRenameMode();
        }
    };

    //------------------------------------------------------------------------------
    useEffect(() =>
    {
        if( !(props.createFolderMode || props.isTileInRenameMode) )
            return;

        function escFunction(event)
        {
            if(props.createFolderMode || props.isTileInRenameMode)
            {
                switch(event.keyCode)
                {
                case 13:
                    // Enter
                    event.stopPropagation();
                    event.preventDefault();
                    onSubmitName();
                    break;
                case 27:
                    // Escape
                    event.stopPropagation();
                    event.preventDefault();
                    abortChanges();
                    break;
                default:
                    break;
                }
            }
        }
        window.addEventListener('keydown', escFunction);

        return () =>
        {
            window.removeEventListener('keydown', escFunction);
        };

    }, [props.createFolderMode, props.isTileInRenameMode, defineName]);

    //------------------------------------------------------------------------------
    // Drag n Drop

    const [ dragOver, setDragOver ] = useState(0);

    //------------------------------------------------------------------------------
    const onDrop = (e, folder) =>
    {
        setDragOver(0);
        if (isDroppable(e))
        {
            props.onDrop(e, folder);
        }
    };

    //------------------------------------------------------------------------------
    const onDragEnter = (e) =>
    {
        e.stopPropagation();
        props.onDragLeaveInFiller(e);

        if (isDroppable(e))
        {
            setDragOver( dragOver + 1 );
        }
    };

    //------------------------------------------------------------------------------
    const onDragLeave = (e) =>
    {
        e.stopPropagation();
        props.onDragEnterInFiller(e);

        if (isDroppable(e))
        {
            setDragOver( dragOver - 1 );
        }
    };

    //------------------------------------------------------------------------------
    const isDroppable = (e) =>
    {
        return (
                    (e.dataTransfer.types.includes('3dverse/folder') || e.dataTransfer.types.includes('3dverse/asset') || e.dataTransfer.types.includes('3dverse/multiple'))
                    && !e.dataTransfer.types.includes(`3dverse/${uuid}`)
                )
                || e.dataTransfer.types.includes('Files');
    };

    //------------------------------------------------------------------------------
    return (
        <div className={`relative h-full ${props.className ? props.className : ''}`}>
            <TileStyled
                as={props.isLink ? Link : Button}
                onClick={(!props.createFolderMode && !props.isTileInRenameMode) ? props.onClick : undefined}
                onDoubleClick={props.onDoubleClick}
                onContextMenu={props.onContextMenu}

                draggable={!(props.createFolderMode || props.isTileInRenameMode) && props.draggable}
                onDragStart={props.onDragStart}
                onDrop={props.droppable ? onDrop : null}
                onDragEnter={props.droppable ? onDragEnter : null}
                onDragLeave={props.droppable ? onDragLeave : null}
                onDragOver={(e) => e.preventDefault()}

                to={props.to}
                isActive={isActive}
                displayMode={displayMode}
                outline={props.createFolderMode || props.isTileInRenameMode}

                color={dragOver || (isFolder && isActive) ? 'primary' : 'secondary'}
                size='small'
                contentAlign='left'
                noPadding
                style={{
                    height: '100%',
                    '--asset-color' : theme.assets[assetType]?.color,
                    ...props.style
                }}
                isFolder={isFolder}
                className={`
                    relative w-full overflow-hidden
                    ${props.isSelected ? 'focus' : ''}
                `}
                valueClassName='w-full'
                title={props.name}
            >
                <span
                    className={`w-full h-full ${(hasThumbnail && displayMode !== 'LIST') ? 'flex flex-col' : 'flex'}`}
                >
                    {hasThumbnail &&
                        <span
                            className={`overflow-hidden bg-cover bg-center bg-color-underground ${displayMode !== 'LIST' ? 'w-full h-11' : 'absolute w-6 h-5'}`}
                            style={props.hasThumbnail ? {
                                backgroundImage: `url(${apiGatewayURL + '/asset/thumbnail?assetUUID=' + assetUUID + '&size=medium&token=' + apiToken})`
                            } : {}}
                        />
                    }
                    <span className={`flex items-center w-full py-1 ${hasThumbnail ? 'gap-1 px-1' : 'gap-2 px-2'} ${hasThumbnail && (displayMode !== 'LIST' ? '' : 'pl-7')}`}>
                        {props.fromLibrary &&
                            <Icon className='fal fa-link' size='xsmall'/>
                        }
                        {isFolder ?
                            <Icon className='fas fa-folder' size='xsmall'/>
                        :
                            <Icon
                                name='asset'
                                assetType={assetType}
                                size='xsmall'
                                htmlColor={props.isActive && !(props.createFolderMode || props.isTileInRenameMode) ? 'var(--color-bg-ground)' : undefined}
                                color={(props.isActive || props.createFolderMode || props.isTileInRenameMode) ? undefined : 'secondary'}
                            />
                        }
                        {props.createFolderMode || props.isTileInRenameMode ?
                            <ControlText
                                value={defineName}
                                onChange={(_, name) => setDefineName(name)}
                                onBlur={onSubmitName}
                                onFocus={e => e.currentTarget.select()}
                                autoFocus
                                placeholder={props.name || `New ${assetType}`}
                                format='body4'
                                size='xsmall'
                                fullWidth
                                noColor
                                noPadding
                                caretColor={props.isActive ? 'var(--color-content-neutral-primary)' : 'var(--asset-color)'}
                            />
                            :
                            <div className='flex items-baseline justify-between flex-wrap w-full'>
                                <div className='flex items-center gap-2'>
                                    <Text format='body4' className={`flex-1 text-ellipsis ${!Boolean(props.name) ? 'italic' : ''} `} as='span'>
                                        {nodeName()}
                                    </Text>
                                    {props.label &&
                                        <Text format='body4' color='secondary' as='span'>
                                            {props.label}
                                        </Text>
                                    }
                                </div>
                                {props.isSourceFile && sourceFileAssetCount > 1 &&
                                    <SourceFileAssetTag
                                        sourceFileUUID={uuid}
                                        sourceFileAssetCount={sourceFileAssetCount}
                                        assetType={assetType}
                                        goTo={props.goTo}
                                    />
                                }
                            </div>
                        }
                        {props.children}
                    </span>
                </span>
            </TileStyled>

            {sessions &&
                <div className='absolute bottom-1/3 left-2'>
                    {sessions.length === 1 ?
                        <Tooltip title='Join session'>
                            <SessionTile
                                {...sessions[0]}
                                sceneUUID={assetUUID}
                                showCountry={false}
                                size='small'
                            />
                        </Tooltip>
                        :
                        <Button
                            value={sessions.length + ' sessions'}
                            onClick={(e) => setJoinSessionsPopover(e.currentTarget)}
                            color={joinSessionsPopover ? 'primary' : undefined}
                            size='xsmall'
                            round
                        />
                    }
                </div>
            }

            {joinSessionsPopover &&
                <Popover
                    open
                    anchorEl={joinSessionsPopover}
                    onClose={() => setJoinSessionsPopover(null)}
                >
                    <ul className='flex flex-col gap-1 p-2'>
                        {sessions.map(s =>
                            <li key={s.uuid}>
                                <SessionTile
                                    {...s}
                                    sceneUUID={assetUUID}
                                />
                            </li>
                        )}
                    </ul>
                </Popover>
            }
        </div>
    );
}

export default TileAsset;
