import React, { useEffect, useState }               from 'react';
import { useTranslation }                           from 'react-i18next';
import { ControlTextToggle
}                                                   from '../../../../designSystem/components';


//------------------------------------------------------------------------------
function ProjectNameSection(props) 
{
    //------------------------------------------------------------------------------
    const { t }                                     = useTranslation();
    const { currentProject }                        = props;
    const { isLibrary }                             = currentProject;
    const projectNameLabel                          = isLibrary ? t('project:library name') : t('project:project name');

    //------------------------------------------------------------------------------
    const [ projectInfo, setProjectInfo ]           = useState();

    //------------------------------------------------------------------------------
    useEffect(() =>
    {
        setProjectInfo({
            ...projectInfo,
            name        : props.currentProject.name,
            description : props.currentProject.description
        });
    }, [props.currentProject]);

    //------------------------------------------------------------------------------
    const updateProject = async () =>
    {
        if (projectInfo.name === '')
        {
            setProjectInfo({
                ...projectInfo,
                name        : props.currentProject.name,
            });
            return;
        }
        
        const { name, description } = projectInfo;
        props.requests.updateProject({ name, description });
    };

    //------------------------------------------------------------------------------
    return (
        <section className='p-4 bg-color-underground border border-color-tertiary-alt rounded'>
            
            {projectInfo &&
                <div className='flex flex-col gap-3'>
                    <ControlTextToggle
                        value={projectInfo.name}
                        onChange={(e, name) => setProjectInfo({ ...projectInfo, name })}
                        onSubmit={updateProject}
                        placeholder={projectNameLabel}
                        format='title2'
                        // label={projectNameLabel}
                        fullWidth
                    />

                    <ControlTextToggle
                        value={projectInfo.description}
                        onChange={(e, description) => setProjectInfo({ ...projectInfo, description })}
                        onSubmit={updateProject}
                        placeholder='Description'
                        format='body3'
                        // label='Description'
                        fullWidth
                    />   
                </div>
            }

        </section>
    );
}

//------------------------------------------------------------------------------
export default ProjectNameSection;
