import React, { useState }              from 'react';
import { useTranslation }               from 'react-i18next';
import CheckCircleIcon                  from '@material-ui/icons/CheckCircle';
import { Button, ControlText }          from '../../../designSystem/components';
import Layout, { Box }                  from '../LoginLayout';
import { sendResetPasswordEmailAPI }    from '../api';


//------------------------------------------------------------------------------
function ForgotPasswordForm()
{
    //------------------------------------------------------------------------------
    const { t } = useTranslation();

    //------------------------------------------------------------------------------
    const [values, setValues] = useState({
        email: {
            value: '',
            error: false,
            errorMessage: null
        },
        globalError: false,
        submitted: false,
        sendSuccess: null
    });

    //------------------------------------------------------------------------------
    const handleChange = prop => event =>
    {
        setValues({ ...values,
            [prop]: {
                value: event.target.value,
                error: false
            }
        });
    };

    //------------------------------------------------------------------------------
    const handleSubmit = async event =>
    {
        event.preventDefault();
        setValues({ ...values, submitted: true });

        try
        {
            const userData = {
                email: values.email.value,
            };
            const user = await sendResetPasswordEmailAPI(userData);
            setValues({ ...values, sendSuccess: user });
        }
        catch(error)
        {
            console.error(error);
            switch (error.errorNum)
            {
            case 2001: {
                setValues({ ...values,
                    email: {
                        value: values.email.value,
                        errorMessage: 'There is no user with that email. Please check it.',
                        error: true
                    },
                    submitted: false
                });
                break;
            }
            default:
                break;
            }
        }
    };

    //------------------------------------------------------------------------------
    return (
        <Layout>
            <Box>
                <form onSubmit={handleSubmit} className='relative'>

                    <h1 className='text-title1 pb-3'>
                        Forgot your password?
                    </h1>

                    {values.sendSuccess ?
                        <div>
                            <CheckCircleIcon fontSize='large' style={{ position: 'absolute', right: '-2rem', top: '-1rem', color: '#225226' }} />
                            <p style={{ color: '#225226' }} className='text-title2 pb-2'>
                                Reset password email has been sent to {values.sendSuccess.username} at <u>{values.sendSuccess.email}</u>.
                            </p>
                            <p className='text-sm text-color-neutral-secondary'>
                                Please check your inbox and spams.
                            </p>
                        </div>
                        :
                        <div>
                            {Boolean(values.globalError) &&
                                <p className='text-color-feedback-warning pt-2'>
                                    {values.globalError}
                                </p>
                            }

                            <ControlText
                                value={values?.email.value}
                                onChange={handleChange('email')}
                                label={t('account:Email')}
                                type='email'
                                id='signup-email'
                                autoFocus
                                required
                                error={values?.email.error}
                                helperText={values?.email.errorMessage}
                            />

                            <Button
                                value={t('account:Send a new password')}
                                type='submit'
                                color='primary'
                                size='large'
                                className='mt-4'
                                disabled={values.submitted}
                            />
                        </div>
                    }

                </form>
            </Box>
        </Layout>
    );
}

export default ForgotPasswordForm;
