//------------------------------------------------------------------------------
import React                                from 'react';
import EventEmitter                         from 'events';

//------------------------------------------------------------------------------
import CallAPI                              from '../../utils/api';
import assetUtils                           from '../../utils/assetUtils';
import parseError                           from '../../utils/parseError';
import { isStatusPending }                  from '../projects/ProjectConversions/Conversions/status';
import { _TO_REMOVE_getSceneDependencies }  from '../assets/api';

//------------------------------------------------------------------------------
const NotificationContext = React.createContext(
{
    notifications : [],
	subscribeToWorkspace : (workspaceUUID) => {},
	subscribeToWorkspaces : (workspaceUUIDs) => {},
});

//------------------------------------------------------------------------------
export class NotificationProvider extends React.Component
{
    //--------------------------------------------------------------------------
    constructor(props)
    {
        super(props);
        this.webSocketURL       = `${props.apiGatewayURL}/user/registerToNotifications?token=${props.apiToken}`.replace("http", "ws");
        this.eventEmitter       = new EventEmitter();

        this.watchedWorkspaces  = new Array();
        this.watchedTasks       = new Array();
    }

    //--------------------------------------------------------------------------
    componentDidMount()
    {
        this.initializeConnectedPromise();
        this.connect();
        this.registerToTaskEvents();
    }

    //--------------------------------------------------------------------------
    componentWillUnmount()
    {
        this.disconnect();
        this.unregisterFromTaskEvents();
    }

    //--------------------------------------------------------------------------
    initializeConnectedPromise()
    {
        this.connectedPromise   = new Promise((resolve) =>
        {
            this.onOpenCallback = resolve;
        });
    }

    //--------------------------------------------------------------------------
    connect = () =>
    {
        this.socket             = new WebSocket(this.webSocketURL);
        this.socket.onopen      = () =>
        {
            console.debug('Connected to notification endpoint');
            this.onOpenCallback();
            this.setState({isConnected : true});

            if(this.watchedWorkspaces.length > 0)
            {
                this.subscribeToMultipleChannels('workspace', this.watchedWorkspaces);
            }

            if(this.watchedTasks.length > 0)
            {
                this.subscribeToMultipleChannels('task', this.watchedTasks);
            }
        };
        this.socket.onclose     = () =>
        {
            // Reset connected promise if we were connected,
            // keep the same promise instance through consecutive retries.
            if(this.state.isConnected)
            {
                this.initializeConnectedPromise();
            }

            this.setState({isConnected : false});
            console.error("Disconnected from notification center, reconnecting...");
            setTimeout(this.connect, 1000);
        };
        this.socket.onmessage   = this.onMessageReceived;
    }

    //--------------------------------------------------------------------------
    disconnect = () =>
    {
        if(this.socket)
        {
            console.log("Disconnecting from notification center");
            this.socket.onclose = () => {};
            this.socket.close();
        }
    }

    //--------------------------------------------------------------------------
    subscribeTo(topic, channel)
    {
        this.socket.send(JSON.stringify(
        {
            type : 'subscribe',
            topic,
            channel
        }));
    }

    //--------------------------------------------------------------------------
    subscribeToMultipleChannels(topic, channels)
    {
        this.socket.send(JSON.stringify(
        {
            type : 'subscribe-to-channels',
            topic,
            channels
        }));
    }

    //--------------------------------------------------------------------------
    unsubscribeFromChannel(topic, channel)
    {
        this.socket.send(JSON.stringify(
        {
            type : 'unsubscribe',
            topic,
            channel
        }));
    }

    //--------------------------------------------------------------------------
    onMessageReceived = (message) =>
    {
        const {eventType, data, doEmit} = JSON.parse(message.data);
        //console.debug({eventType, data, doEmit});

        const notification = this.computeNotification(eventType, data);

        if(doEmit)
        {
            this.eventEmitter.emit(eventType, data);
        }

        if(notification)
        {
            this.setState(
            {
                notifications : [notification, ...this.state.notifications]
            });
        }
    }

    //--------------------------------------------------------------------------
    computeNotification(eventType, data)
    {
        switch(eventType)
        {
            case 'asset-created':
                return {
                    type: 'create',
                    targetType : data.assetType,
                    name : data.name,
                    workspace: {
                      uuid: data.workspaceUUID,
                      name: data.workspaceName
                    }
                };

            case 'asset-renamed':
                return {
                    type: 'rename',
                    targetType : data.assetType,
                    previousName : data.previousName,
                    newName : data.newName,
                    workspace: {
                      uuid: data.workspaceUUID,
                      name: data.workspaceName || data.newName
                    }
                };

            case 'asset-deleted':
                return {
                    type: 'delete',
                    targetType : data.assetType,
                    name : data.name,
                    workspace: {
                      uuid: data.workspaceUUID,
                      name: data.workspaceName
                    }
                };
            default:
                return null;
        }
    }

    //--------------------------------------------------------------------------
	subscribeToWorkspace = async (workspaceUUID) =>
	{
        await this.connectedPromise;

        this.watchedWorkspaces.push(workspaceUUID);
        this.subscribeTo('workspace', workspaceUUID);
	}

    //--------------------------------------------------------------------------
	subscribeToWorkspaces = async (workspaceUUIDs) =>
	{
        await this.connectedPromise;

        this.watchedWorkspaces.push(...workspaceUUIDs);
        this.subscribeToMultipleChannels('workspace', workspaceUUIDs);
	}

    //--------------------------------------------------------------------------
	subscribeToTask = async (taskUUID) =>
	{
        await this.connectedPromise;
        this.subscribeTo('task', taskUUID);
        this.watchedTasks.push(taskUUID);
    }

    //--------------------------------------------------------------------------
    unsubscribeFromWatchedWorkspaces = async () =>
    {
        await this.connectedPromise;

        for(const workspaceUUID of this.watchedWorkspaces)
        {
            this.unsubscribeFromChannel('workspace', workspaceUUID);
        }

        this.watchedWorkspaces.length = 0;
    }

    //--------------------------------------------------------------------------
    dismissNotification = (notificationUUID) =>
    {
        this.socket.send(JSON.stringify(
        {
            type : 'dismiss',
            notificationUUID
        }));

        this.setState(
        {
            notifications : this.state.notifications.filter(notification => notification.notificationUUID !== notificationUUID)
        });
    }

    //--------------------------------------------------------------------------
    emitter = () =>
    {
        return this.eventEmitter;
    }

    //--------------------------------------------------------------------------
    registerToTaskEvents()
    {
        this.eventEmitter.on('task-created',           this.onTaskCreated);
        this.eventEmitter.on('task-restarted',         this.onTaskUpdate);
        this.eventEmitter.on('task-progress',          this.onTaskUpdate);
        this.eventEmitter.on('task-status-changed',    this.onTaskUpdate);
        this.eventEmitter.on('task-done',              this.onTaskUpdate);

        this.eventEmitter.on('item-created',           this.updateItem);
        this.eventEmitter.on('item-progress',          this.updateItem);
        this.eventEmitter.on('item-done',              this.onItemDone);
    }

    //--------------------------------------------------------------------------
    unregisterFromTaskEvents()
    {
        this.eventEmitter.off('task-created',          this.onTaskCreated);
        this.eventEmitter.off('task-restarted',        this.onTaskUpdate);
        this.eventEmitter.off('task-progress',         this.onTaskUpdate);
        this.eventEmitter.off('task-status-changed',   this.onTaskUpdate);
        this.eventEmitter.off('task-done',             this.onTaskUpdate);

        this.eventEmitter.off('item-created',          this.updateItem);
        this.eventEmitter.off('item-progress',         this.updateItem);
        this.eventEmitter.off('item-done',             this.onItemDone);
    }

    //--------------------------------------------------------------------------
    watchWorkspaceTasks = async (workspaceUUIDs) =>
    {
        try
        {
            const tasks             = await CallAPI('/upload/getWorkspacesTasks', 'POST', { workspaceUUIDs });

            const pendingTasks      = [];
            const completedTasks    = [];
            const workspaceMap      = {};

            for(const task of tasks)
            {
                this.formatProgress(task);

                if(isStatusPending(task.status))
                {
                    pendingTasks.push(task);

                    if(!workspaceMap.hasOwnProperty(task.workspaceUUID))
                    {
                        workspaceMap[task.workspaceUUID] = new Array();
                    }
                    workspaceMap[task.workspaceUUID].push(task);
                }
                else
                {
                    completedTasks.push(task);
                }

                this.formatTaskItems(task);
            }

            this.setState(
            {
                tasks : pendingTasks, completedTasks, workspaceMap,
                taskLoading : false, taskError : null
            });

            this.watchedTasks = pendingTasks.map(task => task.uuid);
            this.subscribeToMultipleChannels('task', this.watchedTasks);
        }
        catch(error)
        {
            console.error(error);
            this.setState({ taskLoading : false, taskError : error });
        }
    }

    //--------------------------------------------------------------------------
    updateItem = _item =>
    {
        this.formatProgress(_item);

        this.setState(
        {
            tasks : this.state.tasks.map((task) =>
            {
                if(task.uuid !== _item.taskUUID)
                {
                    return task;
                }

                const item = {
                    ...task.itemMap[_item.uuid],
                    ..._item,
                };

                task.itemMap[item.uuid] = item;
                const index = task.items.findIndex(i => i.uuid === item.uuid);
                if(index !== -1)
                {
                    task.items[index] = item;
                }
                else
                {
                    task.items.push(item);
                }

                return task;
            })
        });
    }

    //--------------------------------------------------------------------------
    onTaskCreated = task =>
    {
        this.subscribeToTask(task.uuid);
        this.formatTaskItems(task);

        this.setState(
        {
            tasks : [task, ...this.state.tasks],
            workspaceMap : {
                ...this.state.workspaceMap,
                [task.workspaceUUID] : [
                    task,
                    ...(this.state.workspaceMap[task.workspaceUUID] || [])
                ]
            }
        });
    }

    //--------------------------------------------------------------------------
    onTaskUpdate = data =>
    {
        this.formatProgress(data);
        if(data.items)
        {
            this.formatTaskItems(data);
        }

        if (data.hasOwnProperty('errorMessage'))
        {
            data.error = parseError(JSON.parse(data.errorMessage));
        }

        const tasks = this.state.tasks.map(task =>
        {
            if(task.uuid === data.uuid)
            {
                for(const propertyName in data)
                {
                    task[propertyName] = data[propertyName];
                }
            }

            return task;
        });
        this.setState({tasks});
    }

    //--------------------------------------------------------------------------
    onItemCreated = data =>
    {
        const item = data;
        this.formatItem(item);
        this.updateItem(item);
    }

    //--------------------------------------------------------------------------
    onItemDone = async data =>
    {
        const item = data;
        this.updateItem(item);

        if(item.status !== 'success')
        {
            return;
        }

        if(ENABLE_SOURCEFILES)
        {
            return;
        }

        // To remove later
        const assets = [];
        if(item.type === "scene" || item.type === "volume")
        {
            const dependencies = await _TO_REMOVE_getSceneDependencies(item.uuid);

            for(const assetListName in dependencies)
            {
                const assetType = assetUtils.getAssetTypeFromAssetListName(assetListName);

                for(const {name, uuid} of dependencies[assetListName])
                {
                    assets.push(
                    {
                        assetType,
                        uuid,
                        name            : name,
                        workspaceUUID   : item.workspaceUUID
                    });
                }
            }
        }

        if(assets.length > 0)
        {
            this.eventEmitter.emit('asset-created', ...assets);
        }
    }

    //--------------------------------------------------------------------------
    formatTaskItems(task)
    {
        task.itemMap    = task.items || {};
        task.items      = new Array();

        for(const itemUUID in task.itemMap)
        {
            const item = task.itemMap[itemUUID];
            this.formatItem(item);
            this.formatProgress(item);

            task.items.push(item);
        }
    }

    //--------------------------------------------------------------------------
    formatItem(data)
    {
        data.assetType = data.type === 'volume' ? 'scene' : data.type;

        const index = data.name.lastIndexOf('.');
        if(index === -1)
        {
            return;
        }

        data.extension  = data.name.substr(index + 1);
        data.name       = data.name.substr(0, index);
    }

    //--------------------------------------------------------------------------
    formatProgress(data)
    {
        if(data.progress)
        {
            data.progress = Number.parseInt(data.progress) / 100;
        }
    }

    //--------------------------------------------------------------------------
    getTask = async (taskUUID) =>
    {
        let task    = this.state.tasks.find(task => task.uuid === taskUUID);
        if(task)
        {
            return task;
        }

        // Completed task has an empty items array, fetch it when needed.
        task        = this.state.completedTasks.find(task => task.uuid === taskUUID);
        if(!task)
        {
            return null;
        }

        const { items } = await CallAPI('/upload/getTask?taskUUID=' + taskUUID, 'GET');
        task.items      = Object.keys(items).map(key => items[key]);
        return task;
    }

    //--------------------------------------------------------------------------
	state = {
        isConnected                         : false,
        notifications                       : [],
        taskLoading                         : true,
        taskError                           : null,
        tasks                               : [],
        completedTasks                      : [],
        workspaceMap                        : {},

        subscribeToWorkspace                : this.subscribeToWorkspace,
        subscribeToWorkspaces               : this.subscribeToWorkspaces,
        unsubscribeFromWatchedWorkspaces    : this.unsubscribeFromWatchedWorkspaces,
        watchWorkspaceTasks                 : this.watchWorkspaceTasks,
        getTask                             : this.getTask,
		subscribeToTask                     : this.subscribeToTask,
        emitter                             : this.emitter,
        dismissNotification                 : this.dismissNotification,
	};

    //--------------------------------------------------------------------------
    render()
    {
        return (
			<NotificationContext.Provider value={this.state}>
				{this.props.children}
			</NotificationContext.Provider>
        );
    }
}

//------------------------------------------------------------------------------
export default NotificationContext;
export const NotificationConsumer = NotificationContext.Consumer;
