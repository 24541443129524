import React, { useEffect, useState }               from 'react';
import { useTranslation }                           from 'react-i18next';
import { Button, ControlText, Popover, Text
}                                                   from '../../../../designSystem/components';


//------------------------------------------------------------------------------
const RenameAssetPopover = (props) =>
{
    //------------------------------------------------------------------------------
    const { t }                 = useTranslation();

    //------------------------------------------------------------------------------
    const [ name, setName ]     = useState({
        original: '',
        pending : ''
    });

    const [ error, setError ]   = useState(null);

    //------------------------------------------------------------------------------
    useEffect(() =>
    {
        const { name } = props.asset;

        setName({
            original: name,
            pending : name
        });
    }, [props.asset]);

    //------------------------------------------------------------------------------
    const onChangeName = (e, _name) =>
    {
        setName({
            ...name,
            pending: _name
        });
        setError(null);
    };

    //------------------------------------------------------------------------------
    const onSubmitName = (e) =>
    {
        e.preventDefault();

        if (name.pending == '') return;

        if (name.pending === name.original)
        {
            props.onClose();
            return;
        }

        const pendingName = name.pending;
        
        if (props.asset.assetType === 'folder')
        {
            const res = props.renameFolder({
                folderUUID  : props.asset.uuid,
                name        : pendingName,
            });
            if (res)
            {
                setError(res);
                return;
            }
        }
        else
        {
            props.requests.updateAsset({
                assetUUID   : props.asset.uuid,
                name        : pendingName,
            });
        }

        props.onClose();
    };


    //------------------------------------------------------------------------------
    return (
        <Popover
            open={props.open}
            anchorEl={props.anchorEl}
            onClose={props.onClose}
        >
            <form onSubmit={onSubmitName}>
                
                <div className='flex'>

                    <div className='flex flex-col'>
                        <ControlText
                            value={name.pending}
                            onChange={onChangeName}
                            onFocus={e => e.currentTarget.select()}
                            autoFocus
                            placeholder={name.original}
                            square
                            size='small'
                        />

                        {error &&
                            <Text color='warning' format='body4' className='p-2'>
                                {error.message}
                            </Text>
                        }
                    </div>

                    <Button
                        type='submit'
                        value={t('t:rename')}
                        color='primary'
                        size='small'
                        square
                    />
                </div>
            </form>
      
        </Popover>
    );
};

export default RenameAssetPopover;