//------------------------------------------------------------------------------
import React, { useState, useEffect }               from 'react';
import request                                      from 'browser-request';

//------------------------------------------------------------------------------
import { ControlSelect, ControlSelectItem, ListSubheader, Text
}                                                   from '../../../../designSystem/components';
import moduleAPI                                    from './moduleAPI';

//------------------------------------------------------------------------------
export default function Repositories(props)
{
    const { provider, ...providerProps }    = props;
    const [ repositories, setRepositories ] = useState([]);
    const [ errorMessage, setErrorMessage ] = useState('');

    useEffect(() =>
    {
        const update = async () =>
        {
            try
            {
                const repositories  = await listRepositories(provider, providerProps);
                setRepositories(repositories.sort(compare));
                setErrorMessage('');
            }
            catch(error)
            {
                if(typeof error == 'number')
                {
                    setErrorMessage('HTTP error ' + error + ', open console for more details.');
                }
                else
                {
                    setErrorMessage('Error while retrieving repositories, open console for more details.');
                }
                setRepositories([]);
                props.onChange(null);
            }
        };

        if(provider.id.includes('oauth') || providerProps.accessToken)
        {
            update();
        }
        else
        {
            setRepositories([]);
            setErrorMessage('');
            props.onChange(null);
        }

    }, [provider, providerProps.accessToken]);

    const disabled = Boolean(errorMessage) || repositories.length === 0;

    return (
        <div>
            <ControlSelect
                value={props.value || ''}
                onChange={(e, value) =>
                {
                    props.onChange(value);

                    if(props.onRepoSelected)
                    {
                        props.onRepoSelected(repositories.find(r => r.gitPath === value));
                    }
                }}
                label='Repository'
                labelDescription={(!provider.id.includes('oauth') && repositories.length === 0) ? 'Enter git provider information to select a repository.' : undefined}
                labelDirection='horizontal'
                disabled={disabled}
                className={'flex-1 transition' + (disabled ? ' bg-color-overground opacity-50' : ' bg-color-ground')}
                size='small'
                color={errorMessage ? 'negative' : undefined}
            >
                <ListSubheader>
                    Choose a repository
                </ListSubheader>

                {repositories.map((repository) =>
                    <ControlSelectItem
                        key={repository.id}
                        value={repository.gitPath}
                    >
                        <div className='flex justify-between items-center w-full gap-1'>
                            <Text format='body3' color='primary'>
                                {repository.name}
                            </Text>

                            <Text format='body4' color='secondary'>
                                {repository.gitPath}
                            </Text>
                        </div>
                    </ControlSelectItem>
                )}
            </ControlSelect>
            {errorMessage &&
                <Text format='body4' color='negative'>
                    {errorMessage}
                </Text>
            }
        </div>
    );
}

//------------------------------------------------------------------------------
async function listRepositories(provider, providerProps)
{
    switch(provider.id)
    {
        case 'gitlab':
            return gitlabAPI(providerProps.accessToken, false);

        case 'github':
            return githubAPI(providerProps.accessToken);

        case 'gitlab-oauth':
        {
            const accessToken = await moduleAPI.getAccessToken(provider.api);
            return gitlabAPI(accessToken, true);
        }

        case 'github-oauth':
        {
            const accessToken = await moduleAPI.getAccessToken(provider.api);
            return githubAPI(accessToken);
        }

        default:
            throw new Error('Unsupported provider ' + provider.id);
    }
}

//------------------------------------------------------------------------------
function githubAPI(githubAccessToken)
{
    return new Promise((resolve, reject) =>
    {
        const url = 'https://api.github.com/user/repos?per_page=100';
        request(
            {
                method  : 'GET',
                url     : url,
                headers : { 'Authorization': `bearer ${githubAccessToken}` },
                json    : true
            },
            (error, response, body) =>
            {
                if (error)
                {
                    return reject(error);
                }
                else if (response.statusCode !== 200)
                {
                    console.error(body);
                    return reject(response.statusCode);
                }

                const repositories = body.map(repo =>
                {
                    repo.gitPath = repo.full_name;
                    return repo;
                });

                return resolve(repositories);
            }
        );
    });
}

//------------------------------------------------------------------------------
function gitlabAPI(gitlabAccessToken, usingGitlabAccount)
{
    return new Promise((resolve, reject) =>
    {
        const url = basename + 'innerAPI/proxy/gitlab/v4/projects?per_page=100&membership=true&simple=true';
        request(
            {
                method  : 'GET',
                url     : url,
                headers :   usingGitlabAccount
                            ? { 'Authorization': `Bearer ${gitlabAccessToken}` }
                            : { 'PRIVATE-TOKEN': gitlabAccessToken },
                json    : true
            },
            (error, response, body) =>
            {
                if (error)
                {
                    return reject(error);
                }
                else if (response.statusCode !== 200)
                {
                    console.error(body);
                    return reject(response.statusCode);
                }

                const repositories = body.map(repo =>
                {
                    repo.gitPath = repo.path_with_namespace;
                    return repo;
                });

                return resolve(repositories);
            });
    });
}

//------------------------------------------------------------------------------
function compare( a, b )
{
    return a.name.localeCompare(b.name);
}
