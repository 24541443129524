//------------------------------------------------------------------------------
import React, { useEffect } from 'react';

//------------------------------------------------------------------------------
import {
    Text,
    Button,
    ButtonGroup,
    ShortcutHint
}                           from '../../../../designSystem/components';

//------------------------------------------------------------------------------
import MaterialEditor       from './MaterialEditor';
import AnimationEditor      from './AnimationEditor';

//------------------------------------------------------------------------------
export default function AssetEditor(props)
{
    useEffect(() =>
    {
        const keyDownHandler = (event) =>
        {
            // CTRL+Z / CTRL+Y ; Undo / Redo
            if(event.ctrlKey || event.metaKey)
            {
                if(event.keyCode == 90)
                {
                    props.undo();
                    event.preventDefault();
                }
                else if(event.keyCode == 89)
                {
                    props.redo();
                    event.preventDefault();
                }
            }
        };

        window.addEventListener('keydown', keyDownHandler);
        return () => window.removeEventListener('keydown', keyDownHandler);
    }, []);

    return (
        <div className="asset-editor h-full flex flex-col gap-1">
            <ButtonGroup outlined className='ml-auto gap-1'>
                <Button
                    value='Undo'
                    onClick={props.undo}
                    startIcon='fal fa-undo'
                    tooltipTitle={
                        <Text>
                            Revert last changes
                            <ShortcutHint control={true} character='z' />
                        </Text>
                    }
                    color='secondary'
                    disabled={!props.undoRedo.undo}
                    size='small'
                />
                <Button
                    value='Redo'
                    onClick={props.redo}
                    startIcon='fal fa-redo'
                    tooltipTitle={
                        <Text>
                            Restore last changes
                            <ShortcutHint control={true} character='y' />
                        </Text>
                    }
                    color='secondary'
                    disabled={!props.undoRedo.redo}
                    size='small'
                />
            </ButtonGroup>
            {
                props.assetType === 'animation'
                 ? <AnimationEditor {...props}/>
                 : <MaterialEditor {...props}/>
            }
        </div>
    );
}
