//------------------------------------------------------------------------------
import React, { Fragment }  from "react";
import styled               from 'styled-components';
import PluginContainer      from "./PluginContainer";

//------------------------------------------------------------------------------
import {
    Icon,
    ButtonIcon,
    ControlText,
    Tooltip,
    Text,
    Stack
} from '../designSystem/components';

//------------------------------------------------------------------------------
export const MainStackStyled = styled(Stack)`
    width : 100%;
    height: 100%;
    padding: .5rem .5rem;
`;

//------------------------------------------------------------------------------
export default class DevelopmentPlugin extends React.Component
{
    //--------------------------------------------------------------------------
    constructor(props)
    {
        super(props);
        this.state = {
            serverURL   : "http://localhost:4502",
            status      : null,
            isConnected : false
        };
    }

    //--------------------------------------------------------------------------
    render()
    {
        if(this.state.isConnected && !this.state.status)
        {
            return this.props.children({url : this.state.pluginURL});
        }

        return (
            <MainStackStyled horizontal>
            {
                !this.state.isConnected && 
                <Fragment>
                    <ControlText
                        value={this.state.serverURL}
                        onChange={event => this.setState({serverURL : event.target.value})}
                        placeholder="Placeholder"
                        required
                    />
                    
                    <Tooltip title='Connect'>
                        <ButtonIcon
                            onClick={this.connect}
                            iconClassName='fa fa-wifi'
                            color='tertiary'
                            style={{
                                marginLeft: '.5rem'
                            }}
                        />
                    </Tooltip>
                </Fragment>
            }
            {
                this.state.status &&
                <Stack horizontal vCenter>
                    <Icon
                        className={this.state.status.icon}
                        style={{
                            color : this.state.status.color
                        }}
                    />
                    <Text>
                        {this.state.status.text}
                    </Text>
                </Stack>    
            }
            </MainStackStyled>
        )
    }

    //--------------------------------------------------------------------------
    componentDidMount()
    {
        if (location.hostname === 'localhost')
        {
            this.connect();
        }
    }

    //--------------------------------------------------------------------------
    componentWillUnmount()
    {
        if(this.socket)
        {
            this.socket.onclose = () => {};
            this.socket.close();
        }
    }

    //--------------------------------------------------------------------------
    connect = async () =>
    {
        this.setStatus('wait', 'Connecting...', {isConnected : true});

        this.socket         = new WebSocket(this.state.serverURL.replace('http', 'ws') + '/notify');
        this.socket.onopen  = () =>
        {
            const pathname  = document.location.pathname;

            this.socket.send(JSON.stringify(
            {
                host        : document.location.host,
                pathname    : pathname.substr(0, pathname.lastIndexOf("/"))
            }));

            this.setState({status : null, pluginURL : this.getPluginURL()});
            this.socket.onclose     = this.onSocketClosed;
        }

        this.socket.onclose     = this.onConnectionFailed;
        this.socket.onmessage   = this.onMessageReceived;
    }

    //--------------------------------------------------------------------------
    onConnectionFailed = () =>
    {
        this.setStatus('warning', 'Failed to connect', {isConnected : false});
    }

    //--------------------------------------------------------------------------
    onSocketClosed = () =>
    {
        this.setStatus('danger', 'The connection was closed', {isConnected : false});
    }

    //--------------------------------------------------------------------------
    onMessageReceived = (message) =>
    {
        const data = message.data;
        console.log(data);

        if(data == 'build-start')
        {
            this.setStatus('wait', 'Building...');
        }
        else if(data == 'build-end')
        {
            this.setState({status : null, pluginURL : this.getPluginURL()});
        }
        else if(data == 'build-error')
        {
            this.setStatus('danger', 'Build error');
        }
    }

    //--------------------------------------------------------------------------
    getPluginURL()
    {
        return `${this.state.serverURL}/index.js?random=${Math.random()}`;
    }

    //--------------------------------------------------------------------------
    setStatus(type, message, optionalState = {})
    {
        let icon, color;
        switch (type)
        {
            case "success":
                icon    = "fas fa-check";
                color   = "green";
                break;

            case "wait":
                icon    = "fas fa-sync";
                color   = "rgb(92, 124, 250)";
                break;

            case "warning":
                icon    = "fas fa-exclamation-triangle";
                color   = "orange";
                break;

            case "danger":
                icon    = "fas fa-exclamation-circle";
                color   = "red";
                break;
        }

        this.setState(
        {
            status : {icon, color, text : message},
            ...optionalState
        });
    }
}
