import styled from 'styled-components';


export const TileProjectStyled = styled.button`
    &:hover,
    &:focus,
    &.focus {
        background-color: hsla(var(--color-bg-overground-hsl), .6);
    }

    &.active {
        background-color: var(--color-accent);

        &:hover,
        &:focus {
            background-color: hsla(var(--color-bg-accent-hsl), .9);
        }
    }
`;