import React, { useContext }    from 'react';

import Header                   from '../Header';
// import NotificationsCenter  from '../apps/notifications/NotificationsCenter';
// import NotificationContext  from '../apps/notifications/notificationContext';
import ProjectContext           from '../../apps/projects/ProjectContext';

//------------------------------------------------------------------------------

//------------------------------------------------------------------------------
function Connected(props)
{
    const projectContext = useContext(ProjectContext);

    // const [notificationsCenterOpen, setNotificationsCenterOpen] = useState(false);
    // const [hasSeenNewNotifications, setHasSeenNewNotifications] = useState(false);
    // const notificationContext = useContext(NotificationContext);

    // function toggleNavigationCenter()
    // {
    //     setNotificationsCenterOpen(!notificationsCenterOpen);
    //     setHasSeenNewNotifications(true);
    // }

    // function onNotificationDismiss(notificationUUID)
    // {
    //     notificationContext.dismissNotification(notificationUUID);
    // }

    const { className, ...otherProps } = props;

    return (
        <main role='main' className={`bg-color-ground min-h-screen ${className ? className : ''}`}>

            <Header
                {...otherProps}
                projects={projectContext.projects}
                // toggleNavigationCenter={toggleNavigationCenter}
                // isNotificationCenterOpen={notificationsCenterOpen}
                // hasSeenNewNotifications={hasSeenNewNotifications}
            />

            {/* <NotificationsCenter
                open={notificationsCenterOpen}
                toggle={toggleNavigationCenter}
                notificationsList={notificationContext.notifications}
                onDismiss={onNotificationDismiss}
            /> */}

            <div className='mt-8'>
                {props.children}
            </div>

        </main >
    );
}

//------------------------------------------------------------------------------
export default Connected;


//------------------------------------------------------------------------------
export function NotConnected(props)
{
    return (
        <main role='main' className='min-h-screen bg-color-ground overflow-x-hidden'>
            {props.children}
        </main>
    );
}
