import React, { useContext, useEffect, useState }   from 'react';
import { useTranslation }                           from 'react-i18next';

import { Button, Icon, Modal, Text, ControlSelect, ControlSelectItem,
}                                                   from '../../../designSystem/components';
import { plural as p, readablizeBytes }             from '../../../designSystem/utils';
import TileUpload                                   from '../../../components/TileUpload';
import UploadContext                                from '../../uploadAsset/uploadContext';
import { uploadFiles, checkUploadFiles }            from '../../uploadAsset/functions';
import UserContext                                  from '../../account/userContext';
import { searchAssetsAPI } from '../../projects/api';


//------------------------------------------------------------------------------
function UploadAssetModal(props)
{
    //------------------------------------------------------------------------------
    const { t }         = useTranslation();
    const uploadContext = useContext(UploadContext);
    const user          = useContext(UserContext);

    const { workspaceUUID, options, files:initialFiles } = props.uploadAssetModal;

    //------------------------------------------------------------------------------
    const [ files, setFiles]                    = useState([]);
    const [ isLoading, setLoadingState ]        = useState(false);
    const [ materialFolder, setMaterialFolder ] = useState(null);
    const [ skeletonUUID, setSkeletonUUID ]     = useState(null);
    const [ skeletons, setSkeletons ]           = useState(null);
    const [ checkUpload, setCheckUpload]        = useState({
        errors                  : [],
        warnings                : [],
        maxStorageLimit         : null,
        maxUploadLimit          : null,
        displayUpgradeAction    : null
    });
    const [ folders ]                       = useState(
        Array.from(props.projectMap.entries())
            .filter(([id, folder]) => id !== folder.path)
            .map(([id, folder]) => folder)
            .sort((a, b) => a.name.localeCompare(b.name))
    );

    //------------------------------------------------------------------------------
    useEffect(() =>
    {
        if(!initialFiles)
            return;

        const { errors, warnings, maxStorageLimit, maxUploadLimit, files:_files } = checkUploadFiles(initialFiles, user);
        setCheckUpload({ errors, warnings, maxStorageLimit, maxUploadLimit });
        setFiles(_files);

    }, [initialFiles]);

    //------------------------------------------------------------------------------
    const toggleAssetUpload = (index, isChecked) =>
    {
        const newFiles = Array.from(files);
        newFiles[index].isChecked = isChecked;
        const { errors, warnings, maxStorageLimit, maxUploadLimit, files:_files } = checkUploadFiles(newFiles, user);
        setCheckUpload({ errors, warnings, maxStorageLimit, maxUploadLimit });
        setFiles(_files);
    };

    //------------------------------------------------------------------------------
    const triggerUpload = async () =>
    {
        let uploadOptions = options;

        if(materialFolder)
        {
            const { materials = [] } = await searchAssetsAPI(materialFolder.workspaceUUID, '', 0, 200, ['materials'], false);
            const materialLUT = {};

            for(const material of materials)
            {
                materialLUT[material.name] = material.uuid;
            }

            console.info('Using material LUT', materialLUT);
            uploadOptions = {...options, materialLUT};
        }

        if(skeletonUUID)
        {
            uploadOptions = {...uploadOptions, skeletonUUID};
        }

        const finalFiles = files.filter(f => f.isChecked);
        setLoadingState(true);
        await uploadFiles(workspaceUUID, finalFiles, uploadOptions);

        setLoadingState(false);
        props.onClose();
        uploadContext.showProgress(finalFiles);
    };

    //------------------------------------------------------------------------------
    useEffect(() =>
    {
        if(skeletons)
        {
            return;
        }

        if(!files.some(f => f.extension === 'fbx' || f.extension === 'gtfl'))
        {
            return;
        }

        setSkeletons([]);
        const workspaceUUIDs = props.getWorkspaceUUIDs();
        searchAssetsAPI(workspaceUUIDs, '', 0, 200, ['skeletons'], false)
        .then(({ skeletons = [] }) =>
        {
            setSkeletons(
                skeletons
                    .map(skeleton =>
                    ({
                        name : skeleton.name,
                        uuid : skeleton.uuid,
                        path : props.workspaceMap.has(skeleton.workspaceUUID)
                                ? props.workspaceMap.get(skeleton.workspaceUUID).path
                                : ''
                    }))
                    .sort((a, b) => a.name.localeCompare(b.name))
            );
        });

    }, [files]);

    //------------------------------------------------------------------------------
    const { errors, warnings, maxUploadLimit, maxStorageLimit, displayUpgradeAction } = checkUpload;
    const checkedFiles = files.filter(f => f.isChecked);
    const cannotUpload = isLoading || errors.length > 0 || checkedFiles.length === 0 || maxStorageLimit || maxUploadLimit;

    //------------------------------------------------------------------------------
    return (
        <Modal
            open={props.open}
            onClose={props.onClose}
            maxWidth='md'
            fullWidth
        >
            <section className='flex'>
                {files.length > 0 ?
                    <div className='flex-1 flex flex-col w-full'>
                        <header>
                            <Text format='title2' className='mb-4'>
                                Upload {files.length} file{p(files.length)}
                            </Text>
                        </header>
                        {(errors?.length > 0 || warnings?.length > 0) &&
                            <ul>
                                {errors?.length > 0 && errors?.map(error =>
                                    <li key={error.id} className='flex gap-2 mb-2'>
                                        <Icon className='fas fa-exclamation-square -top-1px' size='small' color='negative' />
                                        <p className='text-sm'>
                                            {error.title}
                                        </p>
                                    </li>
                                )}

                                {warnings?.length > 0 && warnings?.map(warning =>
                                    <li key={warning.id} className='flex gap-2 mb-2'>
                                        <Icon className='fas fa-exclamation-triangle -top-1px' size='small' color='warning' />
                                        <p className='text-sm'>
                                            {warning.title}
                                        </p>
                                    </li>
                                )}
                            </ul>
                        }
                        <ul className='grid gap-2 mt-2' style={{ gridTemplateColumns: 'repeat(auto-fit, minmax(180px, 1fr))' }}>
                            {files.map((file, index) =>
                                <li key={index}>
                                    <TileUpload
                                        file={file}
                                        onClick={() => props.goTo({
                                            assetDetails: { uuid: file.uuid, assetType: file.assetType }
                                        })}
                                        displayCheckbox
                                        onCheckboxChange={(e, isChecked) => toggleAssetUpload(index, isChecked)}
                                        isChecked={file.isChecked}
                                        disabled={file.status === 'rejected'}
                                    />
                                </li>
                            )}
                        </ul>
                        {files.some(f => f.extension === 'ifc') &&
                            <div className='mt-2'>
                                <ControlSelect
                                    label='IFC Material Folder'
                                    labelDescription={'Optional, provide IFC materials from a folder'}
                                    labelDirection='horizontal'
                                    value={materialFolder ? materialFolder.uuid : ''}
                                    className='flex-1'
                                    onChange={(e, value) => setMaterialFolder(props.projectMap.get(value))}
                                >
                                    {folders.map((folder) =>
                                        <ControlSelectItem
                                            key={folder.uuid}
                                            value={folder.uuid}
                                        >
                                            <div className='flex justify-between items-center w-full gap-1'>
                                                <Text format='body3' color='primary'>
                                                    {folder.name}
                                                </Text>

                                                <Text format='body4' color='secondary'>
                                                    {folder.path}
                                                </Text>
                                            </div>
                                        </ControlSelectItem>
                                    )}
                                </ControlSelect>
                            </div>
                        }
                        {skeletons &&
                            <div className='mt-2'>
                                <ControlSelect
                                    label='Skeleton'
                                    labelDescription={'Optional, provide an existing skeleton to use for the animations'}
                                    labelDirection='horizontal'
                                    value={skeletonUUID ? skeletonUUID : ''}
                                    className='flex-1'
                                    onChange={(e, value) => setSkeletonUUID(value)}
                                >
                                    {skeletons.map((skeleton) =>
                                        <ControlSelectItem
                                            key={skeleton.uuid}
                                            value={skeleton.uuid}
                                        >
                                            <div className='flex justify-between items-center w-full gap-1'>
                                                <Text format='body3' color='primary'>
                                                    {skeleton.name}
                                                </Text>

                                                <Text format='body4' color='secondary'>
                                                    {skeleton.path}
                                                </Text>
                                            </div>
                                        </ControlSelectItem>
                                    )}
                                </ControlSelect>
                            </div>
                        }
                        <footer className='flex items-center justify-between gap-2 mt-4'>
                            <div className='flex flex-row'>
                                {maxUploadLimit &&
                                    <Text format='body4'>
                                        {maxUploadLimit.title}
                                    </Text>
                                }
                                {maxStorageLimit &&
                                    <Text format='body4'>
                                        {maxStorageLimit.title}
                                    </Text>
                                }
                            </div>
                            <div className='flex gap-2'>
                                <Button
                                    value={t('t:cancel')}
                                    onClick={props.onClose}
                                    color='secondary'
                                />
                                {displayUpgradeAction && showNotReadyFeatures &&
                                    <Button
                                        value='Upgrade your plan'
                                        onClick={() => alert('Not ready feature')}
                                        color='primary'
                                    />
                                }
                                <Button
                                    value={'Upload ' + checkedFiles.length + ' file' + p(checkedFiles.length)}
                                    onClick={triggerUpload}
                                    disabled={cannotUpload}
                                    loader={isLoading}
                                    color={cannotUpload ? 'secondary' : 'primary'}
                                    style={{ minWidth: '10rem' }}
                                />
                            </div>
                        </footer>
                    </div>
                    :
                    <div className='flex-1 flex flex-col items-center justify-center h-100'>
                        <Text format='title2'>
                            Drop your assets
                        </Text>

                        <Button
                            value='Extension list'
                            size='xsmall'
                            className='mt-2 link'
                            color='tertiary'
                        />
                    </div>
                }
            </section>
        </Modal>
    );
}

export default UploadAssetModal;
