//------------------------------------------------------------------------------
import React, { Fragment, useState, useEffect }     from 'react';
import { useSnackbar }                              from 'notistack';

//------------------------------------------------------------------------------
import { Text, Icon, Button, Avatar }               from '../../../designSystem/components';
import moduleAPI                                    from '../AssetDetailsAdvanced/ModuleDeployment/moduleAPI';

//------------------------------------------------------------------------------
export default function ModuleRepository(props)
{
    //------------------------------------------------------------------------------
    const [currentRepository, setRepository]    = useState(null);
    const [loading, setLoading]                 = useState(true);
    const [pending, setPending]                 = useState('');
    const [ownedUser, setOwnerUser]             = useState(null);

    //------------------------------------------------------------------------------
    const { enqueueSnackbar }                   = useSnackbar();

    //------------------------------------------------------------------------------
    useEffect(() =>
    {
        setLoading(true);
        setOwnerUser(null);
        setRepository(null);

        Promise.all([
            moduleAPI.listAvailableRepositories(),
            moduleAPI.getLinkedRepository(props.assetUUID)
        ])
            .then(([r, linkedRepository]) =>
            {
                if(linkedRepository)
                {
                    linkedRepository.hasAccess = r.some(({ uuid }) => linkedRepository.uuid === uuid);
                    setRepository(linkedRepository);
                }
                setLoading(false);
            });
    }, [props.assetUUID]);

    //------------------------------------------------------------------------------
    async function synchronize()
    {
        setPending('synchronize');
        try
        {
            const { lastCommitID } = await moduleAPI.synchronize(currentRepository.uuid);
            setRepository({ ...currentRepository, lastCommitID });
            enqueueSnackbar('Git repository synchronized', { variant: 'success' });
        }
        catch(error)
        {
            enqueueSnackbar(error.message, { variant: 'error' });
        }
        setPending('');
    }

    //------------------------------------------------------------------------------
    if(loading || ownedUser)
    {
        return <Fragment/>;
    }

    //------------------------------------------------------------------------------
    if (!currentRepository)
    {
        return (
            <section className='flex flex-wrap items-center bg-color-underground text-color-neutral-primary px-2 pt-2'>
                <Text format='body2' className='mb-2'>
                    <Icon className='fas fa-exclamation-triangle' size='large'/>
                    This module is not linked to a Git Repository
                </Text>
                {
                    !props.isAdvanced &&
                    <Button
                        value='Link'
                        onClick={props.onOpen}
                        startIcon='fal fa-link'
                        className='ml-4 mb-2'
                        size='small'
                        color='secondary'
                    />
                }
            </section>
        );
    }

    const canSynchronize = currentRepository.hasAccess
        || (currentRepository.provider.includes('gitlab') && props.user.oauthAccounts.some(a => a.label === "GitlabAccount"))
        || (currentRepository.provider.includes('github') && props.user.oauthAccounts.some(a => a.label === "GithubAccount"));

    return (
        <div className='flex flex-col gap-1'>
            <Text format='body3' className='font-bold mb-1'>
                {currentRepository.name}
            </Text>
            <Text format='body4' color='secondary'>
                <a className='hover:underline' href={moduleAPI.getRepositoryURL(currentRepository)} target='_blank' rel='noreferrer'>
                    {currentRepository.gitPath}
                </a>
            </Text>
            <div className='flex gap-3'>
                <Text format='body4' color='secondary'>
                    Branch {currentRepository.branchName}
                </Text>
                <Text format='body4' color='secondary'>
                    <span className='capitalize'>{currentRepository.provider}</span>
                </Text>
            </div>
            {
                currentRepository.lastCommitID &&
                <Text format='body4' color='secondary'>
                    Last Commit:
                    <a className='ml-1 hover:underline' href={moduleAPI.getLastCommitURL(currentRepository)} target='_blank' rel='noreferrer'>
                        {currentRepository.lastCommitID.slice(0, 8)}
                    </a>
                </Text>
            }
            <Text format='body4' color='secondary'>
                Module root directory: {props.assetDescription?.moduleRootDirectory || ''}
            </Text>
            {
                currentRepository.autoPublish &&
                <Text format='body4' color='secondary'>
                    Auto publish enabled
                </Text>
            }
            <div className='flex gap-3'>
                <Text format='body4' color='secondary'>
                    Owned by
                </Text>

                <Avatar
                    uuid={currentRepository.creatorUserUUID}
                    username={currentRepository.creatorUsername}
                    size='small'
                    color='var(--color-accent)'
                    backgroundColor='var(--color-bg-action-secondary)'
                    reversed
                    displayName
                />
            </div>
            {
                canSynchronize &&
                <Button
                    value='Synchronize'
                    onClick={synchronize}
                    disabled={Boolean(pending)}
                    size='small'
                    loader={pending === 'synchronize'}
                />
            }
        </div>
    );
}
