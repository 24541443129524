import React, { Fragment, useEffect, useState, useContext } from 'react';
import Flag                                                 from 'react-country-flag';

import { AvatarGroup, ButtonIcon, Timer, Text }             from '../../../designSystem/components';
import { timeFormat }                                       from '../../../utils/timeFormat';
import UserContext                                          from '../../account/userContext';

import { LinkStyled, SessionTileStyled }                    from './style';
import { closeSession } from '../api';

function SessionTile(props)
{
    //------------------------------------------------------------------------------
    const user = useContext(UserContext);

    //------------------------------------------------------------------------------
    const {
        uuid,
        startedAt,
        ended = false,
        duration,
        sceneUUID,
        sceneName,
        users,
        endpoint,
        sceneInformation,
        showCountry = true,
        showAvailability = true,
        masterUUID
    } = props;

    const isMaster = masterUUID === user.uuid;

    //------------------------------------------------------------------------------
    // Calcul availabilities

    //------------------------------------------------------------------------------
    const [state, setState] = useState({
        isAvailable: true
    });

    //------------------------------------------------------------------------------
    const stopSession = () =>
    {
        closeSession(uuid);
    };

    //------------------------------------------------------------------------------
    useEffect(() =>
    {
        if (props.maxUsers && props.users)
        {
            const slotsAvailable = (props.maxUsers - props.users.length);
            setState({
                isAvailable: true,
                slotsAvailable: slotsAvailable
            });
        }
        else
        {
            //TODO
            // console.log('isAvailable false', props.maxUsers, props.users);
            setState({
                isAvailable: false
            });
        }
    }, [props, props.maxUsers, props.users]);

    //------------------------------------------------------------------------------
    return (
        <SessionTileStyled className={props.className}>

            {sceneInformation && !ended && isMaster &&
                <div className='button-stop pr-1'>
                    <ButtonIcon
                        className='fas fa-times'
                        tooltipTitle='Stop session'
                        color='tertiary'
                        size='small'
                        onClick={stopSession}
                    />
                </div>
            }

            <LinkStyled
                as={(!state.isAvailable || ended) ? 'div' : 'a'}
                href={'/app-launcher/join/default/' + sceneUUID + '/' + uuid}
                target={user.openSceneLinkTarget}
                rel='noopener noreferrer'
                size={props.size}
                reversed={props.reversed}
            >
                {sceneInformation &&
                    <div
                        style={{ backgroundImage: 'url(' + apiGatewayURL + '/asset/thumbnail?assetUUID=' + sceneUUID + '&size=medium&token=' + apiToken + ')' }}
                        className='self-stretch w-9 mr-2 bg-color-ground bg-cover bg-center'
                    />
                }
                <div className='container flex-1 flex flex-col p-2'>

                    {sceneInformation &&
                        <Text format='body3' className='text-ellipsis' as='h1'>
                            {sceneName}
                        </Text>
                    }

                    <div className='flex items-center justify-between gap-2'>
                        <AvatarGroup
                            avatars={users}
                            max={10}
                            onClick={props.onClick}
                            size='small'
                            className='users'
                        />

                        <Text format='body4' color='secondary'>
                            {ended ?
                                <Fragment>
                                    <span>Ended</span> {timeFormat(duration)}
                                </Fragment>
                                :
                                <Timer
                                    startTime={startedAt}
                                    className='text-xxs'
                                />
                            }
                        </Text>
                    </div>

                    {showAvailability && state.slotsAvailable && !ended &&
                        <Text color='secondary' className='text-xxs'>
                            {state.slotsAvailable === 0 ?
                                <span>No more spots available</span>
                                :
                                (state.slotsAvailable < 20 &&
                                    <span>{state.slotsAvailable} more spots available</span>
                                )
                            }
                        </Text>
                    }

                    {showCountry && endpoint && endpoint.country &&
                        <Flag
                            countryCode={endpoint.country}
                            svg
                            alt={'Country flag: ' + endpoint.country}
                            className='flag absolute bottom-1 left-1'
                        />
                    }

                </div>
            </LinkStyled>

        </SessionTileStyled>
    );
}

export default SessionTile;
