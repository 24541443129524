//------------------------------------------------------------------------------
const assetListNames = [
    'scenes',
    'meshes',
    'materials',
    'animations',
    'animationSequences',
    'animationSets',
    'animationGraphs',
    'skeletons',
    'textures1D',
    'textures',
    'textures3D',
    'collisionGeometries',
    'cubemaps',
    'volumeMaterials',
    'shaders',
    'scripts',
    'algorithms',
    'functions',
    'eventMaps',
    'actionMaps',
    'renderGraphs',
    'modules',
    'sounds',
    'pointClouds'
];

const assetLabels =
[
    'Scene',
    'Mesh',
    'Material',
    'Animation',
    'AnimationSequence',
    'AnimationSet',
    'AnimationGraph',
    'Skeleton',
    'Texture1D',
    'Texture',
    'Texture3D',
    'CollisionGeometry',
    'Cubemap',
    'VolumeMaterial',
    'Shader',
    'Script',
    'Algorithm',
    'Function',
    'EventMap',
    'ActionMap',
    'RenderGraph',
    'Module',
    'Sound',
    'PointCloud'
];

const assetTypes =
[
    'scene',
    'mesh',
    'material',
    'animation',
    'animationSequence',
    'animationSet',
    'animationGraph',
    'skeleton',
    'texture1D',
    'texture',
    'texture3D',
    'collisionGeometry',
    'cubemap',
    'volumeMaterial',
    'shader',
    'script',
    'algorithm',
    'function',
    'eventMap',
    'actionMap',
    'renderGraph',
    'module',
    'sound',
    'pointCloud'
];

const assetSubResources =
{
    mip0        : ['texture', 'texture1D', 'texture3D'],
    histogram   : ['texture3D'],
    position    : ['pointCloud'],
    color       : ['pointCloud']
};
for (let level = 0; level < 16; level++)
{
    assetSubResources['mip' + level] = assetSubResources.mip0;
}

const assetDefaultVersion = 'head';

//------------------------------------------------------------------------------
function getOneFromTheOther(searchFor, In, Out)
{
    let index = In.indexOf(searchFor);
    if (index > -1)
    {
        return Out[index];
    }
}

//------------------------------------------------------------------------------
let assetUtils = {};

//------------------------------------------------------------------------------
// Find the asset type
assetUtils.getAssetTypeFromAssetListName = function(assetListName)
{
    return getOneFromTheOther(assetListName, assetListNames, assetTypes);
}
//------------------------------------------------------------------------------
assetUtils.getAssetTypeFromAssetLabel = function(assetLabel)
{
    return getOneFromTheOther(assetLabel, assetLabels, assetTypes);
}

//------------------------------------------------------------------------------
// Find the asset list name
assetUtils.getAssetListNameFromAssetType = function(assetType)
{
    return getOneFromTheOther(assetType, assetTypes, assetListNames);
}
//------------------------------------------------------------------------------
assetUtils.getAssetListNameFromAssetLabel = function(assetLabel)
{
    return getOneFromTheOther(assetLabel, assetLabels, assetListNames);
}

//------------------------------------------------------------------------------
// Find the asset label
assetUtils.getAssetLabelFromAssetListName = function(assetListName)
{
    return getOneFromTheOther(assetListName, assetListNames, assetLabels);
}
//------------------------------------------------------------------------------
assetUtils.getAssetLabelFromAssetType = function(assetType)
{
    return getOneFromTheOther(assetType, assetTypes, assetLabels);
}

//------------------------------------------------------------------------------
assetUtils.tryToAddAssetToList = function(assetUUID, labels, assetLists)
{
    let assetLabel = labels.filter(label => label !== "Asset")[0];
    if (!assetLabels.includes(assetLabel))
    {
        console.error("Unknown label " + assetLabel);
        return;
    }

    let assetListName = assetUtils.getAssetListNameFromAssetLabel(assetLabel);
    if (!assetLists[assetListName])
    {
        assetLists[assetListName] = [];
    }
    else if (assetLists[assetListName].includes(assetUUID))
    {
        return;
    }

    assetLists[assetListName].push(assetUUID);
}


//------------------------------------------------------------------------------
assetUtils.hasPayload = function(assetType)
{
    let withPayload = ['mesh', 'animation', 'animationSequence', 'skeleton', 'texture', 'texture1D', 'texture3D', 'collisionGeometry', 'shader', 'script', 'sound', 'pointCloud'];
    return withPayload.includes(assetType);
}
//------------------------------------------------------------------------------
assetUtils.hasOptionalPayload = function(assetType)
{
    let withPayload = ['shader', 'script'];
    return withPayload.includes(assetType);
}
//------------------------------------------------------------------------------
assetUtils.hasSourceFile = function(assetType)
{
    let withSource = ['scene', 'texture', 'texture1D', 'texture3D', 'sound', 'pointCloud'];
    return withSource.includes(assetType);
}
//------------------------------------------------------------------------------
assetUtils.isAsset = function(type)
{
    return assetTypes.includes(type);
}
//------------------------------------------------------------------------------
assetUtils.hasMips = function(assetType)
{
    return assetUtils.hasSubResource(assetType, 'mip0');
}
//------------------------------------------------------------------------------
assetUtils.hasSinglePayload = function(assetType)
{
    let withPayload = ['mesh', 'animation', 'animationSequence', 'skeleton', 'sound', 'collisionGeometry'];
    return withPayload.includes(assetType);
}
//------------------------------------------------------------------------------
assetUtils.hasHistogram = function(assetType)
{
    return assetUtils.hasSubResource(assetType, 'histogram');
}
//------------------------------------------------------------------------------
assetUtils.hasSettings = function(assetType)
{
    let withSettings = ['scene'];
    return withSettings.includes(assetType);
}
//------------------------------------------------------------------------------
assetUtils.hasGraph = function(assetType)
{
    let withGraph = ['shader', 'script', 'algorithm', 'renderGraph', 'animationGraph'];
    return withGraph.includes(assetType);
}
//------------------------------------------------------------------------------
assetUtils.hasCode = function(assetType)
{
    let withCode = ['shader', 'script', 'algorithm', 'function', 'module', 'animationSequence'];
    return withCode.includes(assetType);
}
//------------------------------------------------------------------------------
assetUtils.hasSubResource = function(assetType, subResource)
{
    const subResourceAssetTypes = assetSubResources[subResource];
    return subResourceAssetTypes.includes(assetType);
}
//------------------------------------------------------------------------------
// Is it used by the renderer at runtime ?
assetUtils.isRuntime = function(assetType)
{
    let nonRuntime = ['module'];
    return !nonRuntime.includes(assetType);
}

//------------------------------------------------------------------------------
assetUtils.computeAssetIdentifier = function(assetUUID, version)
{
    if(version === assetDefaultVersion)
    {
        return assetUUID;
    }

    return assetUUID + "@" + version;
}
//------------------------------------------------------------------------------
assetUtils.parseAssetIdentifier = function(assetIdentifier)
{
    const [assetUUID, version = assetDefaultVersion] = assetIdentifier.split('@');
    return { assetUUID, version };
}

//------------------------------------------------------------------------------
assetUtils.isHeadVersion = function (assetIdentifier)
{
    return !assetIdentifier.includes('@');
}


//------------------------------------------------------------------------------
module.exports =
{
    assetListNames,
    assetTypes,
    assetLabels,
    assetSubResources,
    assetDefaultVersion,
    ...assetUtils
}
