import CallAPI from '../../../../utils/api';

//--------------------------------------------------------------------------
const getLinkedRepository = async (assetUUID) =>
{
    return await CallAPI(`/module/getLinkedRepository?assetUUID=${assetUUID}`, 'GET');
};

//--------------------------------------------------------------------------
const listAvailableRepositories = async () =>
{
    return await CallAPI('/module/listAvailableRepositories', 'GET');
};

//--------------------------------------------------------------------------
const createRepositoryAndLink = async (assetUUID, properties) =>
{
    return await CallAPI('/module/createRepositoryAndLink', 'POST', { assetUUID, properties });
};

//--------------------------------------------------------------------------
const linkModule = async (assetUUID, repositoryUUID) =>
{
    return await CallAPI('/module/link', 'POST', { assetUUID, repositoryUUID });
};

//--------------------------------------------------------------------------
const unlinkModule = async (assetUUID) =>
{
    return await CallAPI('/module/unlink', 'POST', { assetUUID });
};

//--------------------------------------------------------------------------
const updateRepository = async (repositoryUUID, properties) =>
{
    return await CallAPI('/module/updateRepository', 'POST', { repositoryUUID, properties });
};

//--------------------------------------------------------------------------
const deleteRepository = async (repositoryUUID) =>
{
    return await CallAPI('/module/deleteRepository', 'DELETE', { repositoryUUID });
};

//--------------------------------------------------------------------------
const synchronize = async (repositoryUUID) =>
{
    return await CallAPI('/module/synchronize', 'POST', { repositoryUUID });
};

//--------------------------------------------------------------------------
const getAccessToken = async (provider) =>
{
    const { accessToken } = await CallAPI(`/oauth/${provider}/getAccessToken`, 'GET');
    return accessToken;
};

//--------------------------------------------------------------------------
const getRepositoryURL = (repository) =>
{
    if(repository.provider.includes('gitlab'))
    {
        return `https://gitlab.com/${repository.gitPath}/`;
    }
    else if(repository.provider.includes('github'))
    {
        return `https://github.com/${repository.gitPath}`;
    }
}

//--------------------------------------------------------------------------
const getLastCommitURL = (repository) =>
{
    if(repository.provider.includes('gitlab'))
    {
        return `https://gitlab.com/${repository.gitPath}/-/commit/${repository.lastCommitID}`;
    }
    else if(repository.provider.includes('github'))
    {
        return `https://github.com/${repository.gitPath}/commit/${repository.lastCommitID}`;
    }
}

//------------------------------------------------------------------------------
async function getGraphModuleCommitIDs(assetUUID)
{
    return await CallAPI('/module/getGraphModuleCommitIDs?assetUUID=' + assetUUID, 'GET');
}

//--------------------------------------------------------------------------
export default {
    getLinkedRepository,
    listAvailableRepositories,
    createRepositoryAndLink,
    linkModule,
    unlinkModule,
    updateRepository,
    deleteRepository,
    synchronize,
    getAccessToken,
    getRepositoryURL,
    getLastCommitURL,
    getGraphModuleCommitIDs
};
