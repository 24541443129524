import React                    from 'react';
import { useTranslation }       from 'react-i18next';
import { Button, Modal, Text }  from '../../../../designSystem/components';


//------------------------------------------------------------------------------
const DeleteAccountModal = (props) =>
{
    //------------------------------------------------------------------------------
    const { t } = useTranslation();

    //------------------------------------------------------------------------------
    return (
        <Modal
            open={props.open}
            onClose={props.onClose}
        >
            <form onSubmit={props.onDeleteAccount}>

                <Text format='title1' className='mb-4'>
                    {t('account:confirm delete account')}
                </Text>
          
                <div className='flex gap-3'>
                    <Button
                        type='submit'
                        value={t('account:yes delete account')}
                    />
                    <Button
                        value={t('cancel')}
                        onClick={props.onClose}
                        color='secondary'
                    />
                </div>

            </form>
        </Modal>
    );
};

export default DeleteAccountModal;
