//------------------------------------------------------------------------------
import React                    from 'react';
import styled                   from 'styled-components';

//------------------------------------------------------------------------------
import TableCell                from '@material-ui/core/TableCell';
import TableHead                from '@material-ui/core/TableHead';
import TableRow                 from '@material-ui/core/TableRow';
import TableSortLabel           from '@material-ui/core/TableSortLabel';

//------------------------------------------------------------------------------
import PropTypes                from 'prop-types';

//------------------------------------------------------------------------------
const TableHeadStyled = styled(TableHead)`
    .MuiTableCell-head {
        font-size: .8rem;
        font-weight: 500;
        color: var(--color-content-neutral-secondary);
    }
`;

//------------------------------------------------------------------------------
export default function EnhancedTableHead(props)
{
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = property => event =>
    {
        onRequestSort(event, property);
    };

    return (
        <TableHeadStyled>
            <TableRow>
                {props.headCells.map(headCell => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.align}
                        padding={headCell.padding}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        {headCell.sortable ? 
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={order}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <span className={classes.visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </span>
                                ) : null}
                            </TableSortLabel>
                            : <div></div>
                        }
                    </TableCell>
                ))}
            </TableRow>
        </TableHeadStyled>
    );
}

//------------------------------------------------------------------------------
EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired
};