//------------------------------------------------------------------------------
import React, { Fragment, useState }     from 'react';

import {
    Button,
    ControlAdornment,
    ControlSelect,
    ControlSelectItem,
    ControlText,
    ControlCheckbox,
    Icon
}                   from '../../../../designSystem/components';
import Repositories from '../../../assets/AssetDetailsAdvanced/ModuleDeployment/Repositories';

//------------------------------------------------------------------------------
const gitProviders = [
    {
        id          : 'gitlab-oauth',
        icon        : 'fa-gitlab',
        name        : 'GitLab Account',
        api         : 'gitlab',
        disabled    : (user) => user.oauthAccounts.every(a => a.label !== "GitlabAccount"),
        inputs      : []
    },
    {
        id          : 'github-oauth',
        icon        : 'fa-github',
        name        : 'GitHub Account',
        api         : 'github',
        disabled    : (user) => user.oauthAccounts.every(a => a.label !== "GithubAccount"),
        inputs      : []
    }
];

//------------------------------------------------------------------------------
const commonGitProvidersInputDescriptor = [
    {
        type : 'text',
        name : 'branchName',
        title : 'Branch name',
        description : 'Could be a branch name, a tag name or a commit SHA',
    }
];

//------------------------------------------------------------------------------
export default function ApplicationTemplateSettings(props)
{
    const {
        provider : currentProviderID = null,
        gitPath : currentGitPath = null,
        ...inputProps
    } = props.currentApplicationTemplate;

    //------------------------------------------------------------------------------
    const [provider, setProvider]       = useState(currentProviderID ? gitProviders.find(p => p.id === currentProviderID) : gitProviders[0]);
    const [gitPath, setGitPath]         = useState(currentGitPath);
    const [inputValue, setInputValue]   = useState({branchName : 'main', ...inputProps});

    //------------------------------------------------------------------------------
    function renderWidget(inputDesc)
    {
        if(inputDesc.type === 'bool')
        {
            return (
                <ControlCheckbox
                    key={inputDesc.name}
                    value={inputValue[inputDesc.name] || ''}
                    onChange={(e) => setInputValue({ ...inputValue, [inputDesc.name] : e.currentTarget.checked })}
                    label={inputDesc.title}
                    labelDescription={inputDesc.description}
                    labelDirection='horizontal'
                    size='small'
                />
            );
        }

        return (
            <ControlText
                key={inputDesc.name}
                value={inputValue[inputDesc.name] || ''}
                onChange={(e) => setInputValue({ ...inputValue, [inputDesc.name] : e.currentTarget.value })}
                type={inputDesc.type}
                autoComplete='new-password'
                label={inputDesc.title}
                labelDescription={inputDesc.description}
                labelDirection='horizontal'
                placeholder={props.editMode?.previousProviderID === provider.id && inputDesc.type === 'password' ? 'Leave this field blank to keep the same value' : inputDesc.title}
                className='flex-1'
                size='small'
                InputProps={inputDesc.help ? {
                    endAdornment: (
                        <ControlAdornment position='end'>
                            <a href={inputDesc.help} target='_blank' rel='noreferrer' title={inputDesc.title}>
                                <Icon className='fas fa-question-circle' size='small' />
                            </a>
                        </ControlAdornment>
                    )
                } : {}}
            />
        );
    }

    const isSubmittable = Boolean(provider?.id && gitPath && inputValue['branchName']);

    //------------------------------------------------------------------------------
    return (
        <div className='flex flex-col gap-3'>
            <ControlSelect
                value={provider?.id}
                onChange={(e, value) =>
                {
                    const provider = gitProviders.find(p => p.id === value);
                    setProvider(provider);
                }}
                label='Git Provider'
                labelDirection='horizontal'
                className='flex-1'
                size='small'
            >
                {gitProviders.map(gitProvider =>
                {
                    const disabled = typeof gitProvider.disabled === 'function' ? gitProvider.disabled(props.user) : gitProvider.disabled;

                    return (
                        <ControlSelectItem
                            key={gitProvider.id}
                            value={gitProvider.id}
                            disabled={disabled}
                        >
                            <Icon className={`fab ${gitProvider.icon} mr-1`} size='small' />
                            <span>{gitProvider.name}{(disabled ? ' (no account linked)' : '')}</span>
                        </ControlSelectItem>
                    );
                })}
            </ControlSelect>

            { provider &&
                <Fragment>
                    {provider.inputs.map(renderWidget)}

                    <Repositories
                        provider={provider}
                        value={gitPath}
                        onChange={setGitPath}
                        {...inputValue}
                    />
                </Fragment>
            }

            {commonGitProvidersInputDescriptor.map(renderWidget)}

            <div className='flex justify-end mt-4 gap-1'>
                <Button
                    value={props.submitText}
                    onClick={() => props.onSubmit({provider : provider.id, gitPath, ...inputValue})}
                    disabled={!isSubmittable || Boolean(props.pending)}
                    style={{ flex: props.onClose ? '0 calc(33% - var(--spacing-1))' :  '0 calc(65% - var(--spacing-4))' }}
                    loader={props.pending}
                />

                {props.onClose &&
                    <Button
                        value={'Cancel'}
                        onClick={props.onClose}
                        disabled={Boolean(props.pending)}
                        style={{ flex: '0 calc(32% - var(--spacing-4))' }}
                    />
                }
            </div>
        </div>
    );
}
