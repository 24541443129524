//------------------------------------------------------------------------------
import React                        from 'react';
import { useTranslation }           from 'react-i18next';
import { Button, Link, Icon, Text } from '../../../designSystem/components';

//------------------------------------------------------------------------------
import TileApplication              from '../../../components/TileApplication';
import TileButton                   from '../../../components/TileApplication/TileButton';

//------------------------------------------------------------------------------
import ApplicationPanel           from './ApplicationPanel';
import ApplicationTemplateSection from './ApplicationTemplateSection';
import Can     from '../../../utils/permissions';
import DevTool from "./DevTool";

//------------------------------------------------------------------------------
function Applications(props)
{
    //--------------------------------------------------------------------------
    const { t }                     = useTranslation();
    const { currentProject, user }  = props;

    const applicationUUID           = props.match.params.uuid1;
    const canManageApps             = Can(props.permissions, 'manageApplications');

    //--------------------------------------------------------------------------
    if(applicationUUID === 'devTool')
    {
        return (
            <section
                className={props.className}
                style={props.style}
            >
                <DevTool />
            </section>
        );
    }

    //--------------------------------------------------------------------------
    if(applicationUUID)
    {
        return (
            <ApplicationPanel
                canEdit={canManageApps}
                className={props.className}
                style={props.style}
                applicationUUID={applicationUUID}
                currentProject={currentProject}
                requests={props.requests}
            />
        );
    }

    //--------------------------------------------------------------------------
    const canManageTemplates        = Can(props.permissions, 'manageApplicationTemplates');

    return (
        <section
            className={`wrapper pt-4 sm:pt-6 pb-10 ${props.className ?? props.className }`}
            style={props.style}
        >
            <header className='flex justify-between'>
                <Text format='title1'>
                    {t('project:applications')}
                </Text>
            </header>

            <div className='flex flex-col gap-4 mt-4'>
                {
                    canManageTemplates &&
                    <section className='p-4 bg-color-underground border border-color-tertiary-alt rounded'>
                        {
                            user.oauthAccounts.every(a => !['GitlabAccount', 'GithubAccount'].includes(a.label))
                                ? <div className='flex flex-col items-center gap-3 w-full h-full justify-center m-auto max-w-lg'>
                                    <Icon className='fal fa-ban opacity-50' style={{ fontSize : '2.5rem' }} size='large' />
                                    <Text format='title2'>
                                        Git account not found
                                    </Text>
                                    <span className='text-sm text-color-neutral-secondary text-center'>
                                        You must link your 3dverse account to GitLab to deploy your own application code.
                                    </span>
                                    <Link to='/account/profile'>
                                        <Button color='primary'>
                                            Go to profile
                                        </Button>
                                    </Link>
                                </div>
                                : <ApplicationTemplateSection
                                    currentProject={currentProject}
                                    requests={props.requests}
                                    user={user}
                                />
                        }
                    </section>
                }

                <section className='p-4 bg-color-underground border border-color-tertiary-alt rounded'>
                    <header className='mb-4'>
                        <Text format='title2' as='h2'>
                            <Icon className='fad fa-rocket mr-3' size='xlarge' fixedSize={false} color='secondary' />
                            Project Applications
                        </Text>
                    </header>

                    {
                        !canManageApps && currentProject.applications.length === 0 &&
                        <div className='flex flex-col items-center justify-center w-full h-full gap-3 wrapper py-6'>
                            <Icon className='fal fa-empty-set text-color-neutral-tertiary' size='xxlarge' />
                            <Text format='body1' color='tertiary'>
                                No applications found
                            </Text>
                        </div>
                    }

                    <div className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 4xl:grid-cols-4 gap-2'>
                        {currentProject.applications.map(application =>
                            <TileApplication
                                key={application.uuid}
                                to={'./applications/'+application.uuid}
                                {...application}
                                thumbnailUUID={application.thumbnailUUID}
                                className='w-full'
                                size='large'
                            />
                        )}
                        {
                            canManageApps &&
                            <TileButton to='./applications/create' size='large' className='bg-color-ground'>
                                <span className='w-full h-full flex flex-col items-center justify-center'>
                                    <Icon className='fal fa-plus' style={{ fontSize:'2.5rem' }} color='secondary' />
                                    <Text format='body2' className='mt-4'>
                                        Create new application
                                    </Text>
                                </span>
                            </TileButton>
                        }
                    </div>
                </section>
                <section className='p-4 bg-color-underground border border-color-tertiary-alt rounded w-full'>
                    <header className='mb-2'>
                        <Text format='title2'>
                            Open Dev Tool
                        </Text>
                    </header>
                    <Text format='body3' color='secondary' className='mb-4'>
                        Develop your application from your local environment.
                    </Text>
                    <Link to={`./applications/devTool`} className='link'>
                        <Button
                            value='Dev Tool'
                            startIcon='fal fa-laptop-code'
                            size='small'
                        />
                    </Link>
                </section>
            </div>
        </section>
    );
}

//------------------------------------------------------------------------------
export default Applications;
