//------------------------------------------------------------------------------
import {
    itemTypeFileExtensions,
    fileOptExtensions
} from '../../../service-config/commonProperties/supportedFiles.json';

//------------------------------------------------------------------------------
function mapExtensions(extensions)
{
    return extensions.map(extension => '.' + extension);
}

//------------------------------------------------------------------------------
function computeSupportedFiles()
{
    const supportedFiles = [];

    for(const assetType in itemTypeFileExtensions)
    {
        const files = itemTypeFileExtensions[assetType].map(extension =>
        {
            let string = '.' + extension;

            if(!fileOptExtensions.hasOwnProperty(extension))
            {
                return string;
            }
    
            return string + '/' + mapExtensions(fileOptExtensions[extension]).join('/');
        });

        supportedFiles.splice(supportedFiles.length, 0, ...files);
    }

    return supportedFiles.join(' ');
}

//------------------------------------------------------------------------------
function computeExtensionList()
{
    const extensions = ['.zip'];
    for(const assetType in itemTypeFileExtensions)
    {
        extensions.splice(
            extensions.length,
            0,
            ...mapExtensions(itemTypeFileExtensions[assetType])
        );
    }

    for(const fileExtension in fileOptExtensions)
    {
        extensions.splice(
            extensions.length,
            0,
            ...mapExtensions(fileOptExtensions[fileExtension])
        );
    }

    return extensions;
}

//------------------------------------------------------------------------------
export const supportedFiles = computeSupportedFiles();
export const extensions 	= computeExtensionList();