import React, { Component }     from 'react';
import PropTypes                from 'prop-types';
import {
    VariableSizeGrid as Grid,
    shouldComponentUpdate
}                               from 'react-window';

import { Button, Icon, Text }   from '../../../../designSystem/components';
import TileAsset                from '../../../../components/TileAsset';
import { isEditingText }        from '../../../../utils/textTools';


const TILE_WITH_THUMBNAIL_HEIGHT    = 105;
const DEFAULT_HEIGHT                = 24 + 1;
const COLUMN_BREAKPOINT             = 250;
const ASSET_LIST_HEADER_HEIGHT      = 40;

export default class ContentList extends Component
{
    //------------------------------------------------------------------------------
    // TODO: Beware, this could be awful with a tons of items, watch this closely.
    shouldComponentUpdate = shouldComponentUpdate.bind(this);

    state = {
        rowCount            : 0,
        columnCount         : 0,
        columnWidth         : 0,
        navigationActive    : false,
        activeRowIndex      : 0,
        activeColumnIndex   : 0,
        tileMap             : new Map()
    };

    //------------------------------------------------------------------------------
    componentDidMount = () =>
    {
        this.computeDimensions();
        if(this.props.onTileClick)
        {
            window.addEventListener('keydown', this.keyboardNavigation);
        }
    }

    //------------------------------------------------------------------------------
    componentWillUnmount = () =>
    {
        if(this.props.onTileClick)
        {
            window.removeEventListener('keydown', this.keyboardNavigation);
        }
    }

    //------------------------------------------------------------------------------
    componentDidUpdate = (prevProps) =>
    {
        const groupCountHasChanged  = prevProps.groups.length !== this.props.groups.length;
        const groupHasChanged       = groupCountHasChanged || prevProps.groups !== this.props.groups;

        if(groupHasChanged)
        {
            this.grid.resetAfterRowIndex(0);
            this.state.tileMap.clear();
        }

        if(groupCountHasChanged)
        {
            this.grid.scrollTo({scrollLeft : 0, scrollTop : 0});
        }

        if(groupHasChanged
            || prevProps.displayMode        !== this.props.displayMode
            || prevProps.width              !== this.props.width
            || prevProps.paddingLeft        !== this.props.paddingLeft
            || prevProps.paddingRight       !== this.props.paddingRight
            || prevProps.paddingBottom      !== this.props.paddingBottom
        )
        {
            this.computeDimensions();
        }
        else if( prevProps.tileInRenameMode !== this.props.tileInRenameMode
            || prevProps.assetDetailsUUID   !== this.props.assetDetailsUUID
            || prevProps.assetSelection     !== this.props.assetSelection
        )
        {
            this.grid?.forceUpdate();
        }

        if(this.activeAssetUUID !== this.props.assetDetailsUUID && this.props.assetDetailsUUID && this.props.assetDetailsUUID !== prevProps.assetDetailsUUID)
        {
            const coordinates = this.state.tileMap.get(this.props.assetDetailsUUID);
            if(coordinates)
            {
                const { rowIndex, columnIndex } = coordinates;
                this.grid.scrollToItem(coordinates);
                this.setState(
                {
                    navigationActive    : false,
                    activeRowIndex      : rowIndex,
                    activeColumnIndex   : columnIndex
                });
            }
        }
    }

    //------------------------------------------------------------------------------
    computeDimensions = () =>
    {
        const { groups, displayMode } = this.props;

        const width         = this.props.width - this.props.paddingLeft - this.props.paddingRight;

        const columnCount   = displayMode === 'COLUMN' ? Math.floor(width / COLUMN_BREAKPOINT) : 1;
        const columnWidth   = width / columnCount;

        const rowCount      = groups.reduce((accumulator, group) =>
        {
            return accumulator
                + 1 // Asset list name row
                + ( !group.hidden
                  ? Math.ceil(group.count / columnCount)
                  : 0 )
        }, 0);

        this.setState(
        {
            rowCount,
            columnCount,
            columnWidth,
            style : {
                paddingLeft : this.props.paddingLeft,
                display : rowCount > 0 ? 'block' : 'none' // Fix a visual glitch when a folder is empty.
            }
        });
    }

    //------------------------------------------------------------------------------
    keyboardNavigation = (event) =>
    {
        // Prevent weird behavior when editing text.
        if(isEditingText())
        {
            return;
        }

        switch(event.keyCode)
        {
            case 13 : { // Enter
                this.triggerActiveElement(event);
                break;
            }

            case 38 : { // Up
                event.preventDefault();
                this.setState(
                    { navigationActive : true, activeRowIndex : Math.max(this.state.activeRowIndex - 1, 0) },
                    () => this.selectActiveElement(event)
                );
                break;
            }
            case 40 : { // Down
                event.preventDefault();
                this.setState(
                    { navigationActive : true, activeRowIndex : Math.min(this.state.activeRowIndex + 1, this.state.rowCount - 1)},
                    () => this.selectActiveElement(event)
                );
                break;
            }

            case 37 : { // Left
                event.preventDefault();
                const newColumnIndex    = this.state.activeColumnIndex - 1;
                const goToPreviousRow   = newColumnIndex < 0;

                this.setState(
                    {
                        navigationActive    : true,
                        activeColumnIndex   : goToPreviousRow ? this.state.columnCount - 1 : newColumnIndex,
                        activeRowIndex      : goToPreviousRow ? Math.max(this.state.activeRowIndex - 1, 0) : this.state.activeRowIndex
                    },
                    () => this.selectActiveElement(event)
                );
                break;
            }
            case 39 : { // Right
                event.preventDefault();
                const newColumnIndex    = this.state.activeColumnIndex + 1;
                const goToNextRow       = newColumnIndex > this.state.columnCount - 1;
                this.setState(
                    {
                        navigationActive    : true,
                        activeColumnIndex   : goToNextRow ? 0 : newColumnIndex,
                        activeRowIndex      : goToNextRow
                                            ? Math.min(this.state.activeRowIndex + 1, this.state.rowCount - 1)
                                            : this.state.activeRowIndex
                    },
                    () => this.selectActiveElement(event)
                );
                break;
            }

        };
    }

    //------------------------------------------------------------------------------
    onDoubleClick = (node, assetType, isFolder) =>
    {
        if (!isFolder)
        {
            this.props.openAssetEditor(assetType, node.uuid)
        }
    }

    //------------------------------------------------------------------------------
    onContextMenu = (event, node, assetType) =>
    {
        event.stopPropagation();
        event.preventDefault();

        if(assetType === 'folder')
        {
            node.folderUUID = node.uuid;
        }
        else
        {
            node.assetUUID = node.uuid;
        };

        this.props.openAssetContextMenu(
            event,
            {
                ...node,
                assetType,
            },
            {
                anchor    : 'mouse',
                eventFrom : 'CONTENT_LIST',
            }
        )
    }

    //------------------------------------------------------------------------------
    onItemsRendered = (gridProps) =>
    {
        this.props.onItemsRendered(
        {
            overscanStartIndex  : this.computeAssetIndex(gridProps.overscanRowStartIndex),
            overscanStopIndex   : this.computeAssetIndex(gridProps.overscanRowStopIndex),
            visibleStartIndex   : this.computeAssetIndex(gridProps.visibleRowStartIndex),
            visibleStopIndex    : this.computeAssetIndex(gridProps.visibleRowStopIndex)
        });
    }

    //------------------------------------------------------------------------------
    render()
    {
        return (
            <Grid
                ref={(node) => {this.grid = node; this.props.gridRef(node)}}
                onItemsRendered={this.onItemsRendered}

                // Column count used as key to remount when changing layout
                key={this.state.columnCount}
                columnCount={this.state.columnCount}
                rowCount={this.state.rowCount}

                columnWidth={this.computeColumnWeight}
                rowHeight={this.computeRowHeight}

                width={this.props.width}
                height={this.props.height}

                itemData={this.props.groups}
                className={'child-relative'}
                style={this.state.style}
            >
                {this.cellRenderer}
            </Grid>
        );
    }

    //------------------------------------------------------------------------------
    computeAssetGroup = (rowIndex, columnIndex) =>
    {
        let index = rowIndex * this.state.columnCount + columnIndex;
        const currentGroup = this.props.groups.find(group =>
        {
            if(group.hidden)
            {
                if(index < this.state.columnCount)
                {
                    return true;
                }

                index -= this.state.columnCount;
                return false;
            }

            const count = group.count + this.state.columnCount;
            if(index < count)
            {
                return true;
            }

            const leftover = group.count % this.state.columnCount;
            index -= count + (leftover > 0 ? (this.state.columnCount - leftover) : 0);

            return false;
        });

        return { currentGroup, index };
    }

    //------------------------------------------------------------------------------
    computeAssetIndex = (rowIndex) =>
    {
        const { currentGroup, index } = this.computeAssetGroup(rowIndex, 0);
        if(!currentGroup)
        {
            console.error("Failed to resolve group during computeAssetIndex", rowIndex, index, this.props.groups);
            return index;
        }

        if(index < this.state.columnCount)
        {
            return Math.max(0, currentGroup.offset - 1);
        }

        return currentGroup.offset + Math.min(Math.max(0, index - this.state.columnCount), currentGroup.count);
    }

    //------------------------------------------------------------------------------
    computeRowHeight = (rowIndex) =>
    {
        const { currentGroup, index } = this.computeAssetGroup(rowIndex, 0);

        // Add extra padding to the last line
        const extraHeight = (rowIndex === this.state.rowCount - 1) ? this.props.paddingBottom : 0;

        if(index === 0)
        {
            return ASSET_LIST_HEADER_HEIGHT + extraHeight;
        }

        if(this.props.displayMode !== 'COLUMN')
        {
            return DEFAULT_HEIGHT + extraHeight;
        }

        const tileHeight    = currentGroup?.assetType === 'scene' || currentGroup?.assetType === 'texture'
                            ? TILE_WITH_THUMBNAIL_HEIGHT
                            : DEFAULT_HEIGHT

        return tileHeight + extraHeight;
    }

    //------------------------------------------------------------------------------
    computeColumnWeight = () =>
    {
        return this.state.columnWidth;
    }

    //------------------------------------------------------------------------------
    triggerActiveElement = (event) =>
    {
        if(!this.state.navigationActive)
        {
            return;
        }

        const { currentGroup, index } = this.computeAssetGroup(this.state.activeRowIndex, this.state.activeColumnIndex);
        if(!currentGroup)
        {
            return null;
        }

        if(index < this.state.columnCount) // Asset list name
        {
            event.preventDefault();
            this.onToggleAssetList(currentGroup, this.state.activeRowIndex);
        }
    }

    //------------------------------------------------------------------------------
    selectActiveElement = (event) =>
    {
        const { currentGroup, index } = this.computeAssetGroup(this.state.activeRowIndex, this.state.activeColumnIndex);

        if(!currentGroup)
        {
            return null;
        }

        if(index < this.state.columnCount) // Asset list name
        {
            this.grid.forceUpdate();
            return;
        }

        const node = currentGroup.filteredList[index - this.state.columnCount];
        if (!node)
        {
            return;
        }

        const { assetType }     = currentGroup;
        const isFolder 	        = assetType === 'folder';
        this.activeAssetUUID    = node.uuid;

        const fromLibrary       = !this.props.isPrivateContent &&
                                ( isFolder
                                    ? Boolean(node.libraryUUID)
                                    : Boolean(this.props.workspaceMap?.get(node.workspaceUUID)?.libraryUUID)
                                );

        this.props.onTileClick(event, node, assetType, isFolder, fromLibrary);
        this.grid.scrollToItem({ rowIndex : this.state.activeRowIndex, columnIndex : this.state.activeColumnIndex });
    }

    //------------------------------------------------------------------------------
    cellRenderer = (options) =>
    {
        const { columnIndex, rowIndex, style } = options;
        const { currentGroup, index } = this.computeAssetGroup(rowIndex, columnIndex);

        if(!currentGroup)
        {
            return null;
        }

        const elementStyle = {
            ...style,
            left  : this.state.columnWidth * columnIndex,
            width : this.state.columnWidth
        };

        if(rowIndex === this.state.rowCount - 1)
        {
            elementStyle.height -= this.props.paddingBottom;
        }

        if(index === 0)
        {
            const navActive = this.state.navigationActive && rowIndex === this.state.activeRowIndex;
            return this.renderAssetListName(currentGroup, elementStyle, rowIndex, navActive);
        }
        else if(index < this.state.columnCount)
        {
            return null;
        }

        const node = currentGroup.filteredList[index - this.state.columnCount];
        if (!node)
        {
            return null;
        }

        if(node.skeleton)
        {
            return (
                <div key={node.index} style={elementStyle} className='pb-1px pr-1px'>
                    <div className='bg-color-overground rounded w-full h-full' />
                </div>
            );
        }

        //------------------------------------------------------------------------------
        this.state.tileMap.set(node.uuid, {rowIndex, columnIndex});

        const { assetDetailsUUID, assetSelection, tileInRenameMode } = this.props;
        const tileProps = {};

        // Add sessions
        if (currentGroup.assetType === 'scene' && this.props.projectSessions)
        {
            const assetSessions = this.props.projectSessions.find(p => p.sceneUUID === (node.mainAsset?.uuid || node.uuid));
            if (assetSessions)
            {
                tileProps.sessions = assetSessions.sessions;
            }
        }

		const { assetType } = currentGroup;
        const isFolder 	    = assetType === 'folder';
        const isActive      = assetSelection?.length > 0
                            ? assetSelection.find(a => a.uuid === node.uuid)
                            : assetDetailsUUID && (assetDetailsUUID === node.uuid);

        const fromLibrary   = !this.props.isPrivateContent &&
                            ( isFolder
                                ? Boolean(node.libraryUUID)
                                : Boolean(this.props.workspaceMap?.get(node.workspaceUUID)?.libraryUUID)
                            );

        const draggable     = (!isFolder || (isFolder && !node.isSystem));
        const droppable     = !fromLibrary && isFolder;

        const hasThumbnail  = node.hasThumbnail || Boolean(node.mainAsset?.uuid);

        //------------------------------------------------------------------------------
        return (
            <div key={node.pendingFolder ? 'pending' : node.uuid} style={elementStyle}>
                <TileAsset
                    uuid={node.uuid}
                    assetType={assetType}
                    name={node.name}
                    hasThumbnail={hasThumbnail}

                    isSourceFile={node.isSourceFile}
                    sourceFileAssetCount={node.isSourceFile ? node.assetCount : null}
                    mainAsset={node.mainAsset}

                    highlightString={this.props.highlightString}
                    displayMode={this.props.displayMode}
                    sessions={this.props.sessions}
                    isActive={isActive}
                    fromLibrary={fromLibrary}

                    onClick={(e) => this.props.onTileClick && this.props.onTileClick(e, node, assetType, fromLibrary)}
                    onDoubleClick={() => this.props.onTileDoubleClick && this.props.onTileDoubleClick(node, assetType)}
                    onContextMenu={(e) => this.props.openAssetContextMenu && this.onContextMenu(e, node, assetType)}

                    createFolderMode={Boolean(node.pendingFolder)}
                    renameAsset={!isFolder ? this.props.renameAsset : null}
                    renameFolder={isFolder ? this.props.renameFolder : null}
                    isTileInRenameMode={tileInRenameMode && tileInRenameMode.uuid === node.uuid}
                    closeRenameMode={this.props.closeRenameMode}
                    createFolder={isFolder ? this.props.createFolder : null}
                    closePendingFolder={this.props.closePendingFolder}
                    goTo={this.props.goTo}

                    draggable={draggable}
                    droppable={droppable}
                    onDragStart={(e) => this.props.onDragStart(e, node, assetType, fromLibrary)}
                    onDrop={isFolder ? (e) => this.props.onDrop(e, node) : null}
                    onDragEnterInFiller={(e) => this.props.onDragEnterInFiller(e)} // Using lambda is important here, since we cannot update
                    onDragLeaveInFiller={(e) => this.props.onDragLeaveInFiller(e)} // if onDragEnterInFiller / onDragLeaveInFiller change its reference

                    className='pr-1px pb-1px'
                    {...tileProps}
                />
            </div>
        );
    };

    //------------------------------------------------------------------------------
    renderAssetListName = (group, style, rowIndex, navActive) =>
    {
        const { assetType, assetListName, count, totalCount, hidden } = group;

        return (
            <div
                key={assetType}
                className={`flex items-end justify-between w-full text-left pb-1 ${hidden ? 'opacity-50' : ''}`}
                style={{ ...style, width: '100%' }}
            >
                <Button
                    onClick={() => this.onToggleAssetList(group, rowIndex)}
                    color={hidden ? 'secondary' : 'tertiary'}
                    valueClassName='w-full justify-between gap-2 pl-1'
                    contentAlign='left'
                    size='small'
                    fullWidth={hidden}
                    active={navActive}
                    noPadding
                >
                    <Text format='body4' className='text-xs uppercase'>
                        {count}
                        {' '}
                        <span className='text-color-neutral-secondary'>
                            {count < totalCount &&
                                <span>
                                    / {totalCount}{' '}
                                </span>
                            }
                            {totalCount > 1 ? assetListName : assetType}
                        </span>
                    </Text>
                    <Icon
                        className={`fal fa-chevron-down transition-transform ${hidden ? 'fa-rotate-90' : ''}`}
                        size='xsmall'
                    />
                </Button>
            </div>
        );
    }

    //------------------------------------------------------------------------------
    onToggleAssetList = (group, rowIndex) =>
    {
        this.props.onGroupToggled(group);
        this.grid.resetAfterRowIndex(rowIndex);
        this.computeDimensions();
    }
}

ContentList.defaultProps = {
    width: 300,
    height: 400,
    rowHeight: 25
};

ContentList.propTypes = {
    groups: PropTypes.arrayOf(
        PropTypes.shape({
            filteredList: PropTypes.array,
            assetType: PropTypes.string,
            count: PropTypes.number
        })
    ).isRequired,
    width: PropTypes.number,
    height: PropTypes.number,
    rowHeight: PropTypes.number,
    onClick: PropTypes.func,
};
