function pad(num)
{
    return ('0'+num).slice(-2);
}

export function timeFormat(duration)
{
    return Math.abs(Math.round(duration/3600000))
  + ':' + pad(Math.abs(Math.round(duration/60000))) 
  + ':' + pad(Math.abs(Math.round(duration/1000)));
}