//------------------------------------------------------------------------------
export default class Utils
{
    //--------------------------------------------------------------------------
    static tag(type, attributes, children)
    {
        var attributeString = '';
        for(var attrName in attributes)
        {
            attributeString += `${attrName}="${attributes[attrName]}"`
        }

        return `<${type} ${attributeString}>${children ? children : ''}</${type}>`;
    }

    //--------------------------------------------------------------------------
    static classNames(...classes)
    {
        var classString = '';
        for(var i in classes)
        {
            var c = classes[i];
            if(typeof(c) == "string")
            {
                classString += `${c} `;
            }
            else
            {
                for(var elementName in c)
                {
                    if(c[elementName])
                    {
                        classString += `${elementName} `
                    }
                }
            }
        }

        return classString;
    }

    //--------------------------------------------------------------------------
    static iconElement(className, parentClass = 'fa')
    {
        var elementClass = '';
        if(className.includes(' '))
        {
            elementClass = className
        }
        else
        {
            elementClass = Utils.classNames(parentClass, className)
        }

        return Utils.tag('i', {class: elementClass})
    }
    
    //--------------------------------------------------------------------------
    static invalidUUID  = "00000000-0000-0000-0000-000000000000";
}