//------------------------------------------------------------------------------
import React                            from 'react';
import { useTranslation }               from 'react-i18next';
import { Button, Icon, Modal, Text }    from '../../../../designSystem/components';

//------------------------------------------------------------------------------
const ProjectDeleteModal = ( props ) =>
{
    //------------------------------------------------------------------------------
    const { t }                 = useTranslation();

    //------------------------------------------------------------------------------
    const { currentProject }    = props;
    const { isLibrary }         = currentProject;
    const projectTerm           = isLibrary ? t('project:library') : t('project:project');

    //------------------------------------------------------------------------------
    const deleteProject = async (event) =>
    {
        event.preventDefault();
        props.onClose();
        props.deleteProject();
    };

    //------------------------------------------------------------------------------
    return (
        <Modal
            open={props.open}
            onClose={props.onClose}
        >
            <form onSubmit={deleteProject}>

                <header>
                    <Text format='title2'>
                        {t('t:delete')} {projectTerm}: <b>{currentProject.name}</b>
                    </Text>
                </header>

                <div className='flex items-center justify-between gap-2 mt-5'>
                    <Text format='body3' color='warning' className='flex-1'>
                        <Icon
                            className='fal fa-exclamation-circle mr-1'
                            size='xsmall'
                        />
                        {t('t:this action cannot be undone')}
                    </Text>
                    <Button
                        value={t('t:cancel')}
                        onClick={props.onClose}
                        color='tertiary'
                    />
                    <Button
                        type='submit'
                        value={t('t:delete')}
                        color='primary'
                    />
                </div>
            </form>

        </Modal>
    );
};

export default ProjectDeleteModal;
