import styled, { css } from 'styled-components';


export const TileUploadStyled = styled.button`

    ${props => props.color === 'positive' && css`
        background: linear-gradient(-45deg, #1e302e, var(--color-bg-ground)) no-repeat 100% 0;
        background-size: 150%;
    `}

    &[disabled] {
        opacity: .8;
        cursor: default;
    }

    ${props => props.isChecked === false && css`
        opacity: .5 !important;
    `}

    ${props => !props.color && css`
        &:before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: calc( var(--progress) * 100%);
            height: 100%;
            background-color: hsla(var(--color-content-feedback-positive-hsl), .5);
        }
    `}
`;