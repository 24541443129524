import React, { Fragment, useContext, useEffect, useState, useRef
}                                       from 'react';
import { useTranslation }               from 'react-i18next';
import InputAdornment                   from '@material-ui/core/InputAdornment';
import { ThemeContext }                 from 'styled-components';
import { Button, ButtonIcon, ControlText, Link, Stack, Loader
}                                       from '../../../designSystem/components';
import { routerUseHistory }             from '../../../designSystem/utils';
import parseError                       from '../../../utils/parseError';
import { loginAPI, googleOAuthAppClientId, googleOAuthSign, googleOAuthLinkAccount, oauthSign, oauthLinkAccount
}                                       from '../api';
import CallAPI                          from '../../../utils/api';
import Layout, { Box, SignInUpSwitch }  from '../LoginLayout';

//------------------------------------------------------------------------------
function SigninForm(props)
{
    //------------------------------------------------------------------------------
    const searchParams              = new URLSearchParams(window.location.search);
    const code                      = searchParams.get('code');
    const provider                  = searchParams.get('provider');
    const isSignWithOAuth           = code && provider;
    const isSignWithConfirmation    = props.user?.linkAccountHandler;

    //------------------------------------------------------------------------------
    const { t }                     = useTranslation();
    const history                   = routerUseHistory();
    const theme                     = useContext(ThemeContext);
    const buttonGoogleSignRef       = useRef();

    //------------------------------------------------------------------------------
    const [values, setValues] = useState({
        username          : props.user?.username || '',
        password          : props.user?.password || '',
        showPassword      : false,
        globalError       : false,
        showResendConfirm : false,
        emailSent         : false,
    });

    //------------------------------------------------------------------------------
    useEffect(() =>
    {
        if(isSignWithOAuth)
        {
            onOAuthSign(provider, code);
        }
        else if(!isSignWithConfirmation && showNotReadyFeatures)
        {
            initSignInWithGoogle();
        }
    }, []);

    //------------------------------------------------------------------------------
    // This is the straightforward sign in with Google flow.
    // It only provides a JWT idToken containing the Google user data.
    // It does not provide an access_token to be used to query Google API.
    // https://developers.google.com/identity/gsi/web/guides/display-button#javascript
    const initSignInWithGoogle = async () =>
    {
        const { clientId } = await googleOAuthAppClientId();

        window.google.accounts.id.initialize({
            client_id: clientId,
            callback: onGoogleCredentialResponse,
        });
        window.google.accounts.id.renderButton(
            buttonGoogleSignRef.current,
            { type: "icon" }  // https://developers.google.com/identity/gsi/web/reference/js-reference#google.accounts.id.renderButton
        );
        window.google.accounts.id.prompt(); // also display the One Tap dialog
    };

    //------------------------------------------------------------------------------
    // Google JWT idToken is verified and decoded by 3dverse API using the 3dverse OAuth App.
    // The 3dverse API returns the Google user info and signs in the user if the Google user id exists
    // in the 3dverse database and is associated to a 3dverse user.
    const onGoogleCredentialResponse = async ({credential: idToken}) =>
    {
       const response = await googleOAuthSign(idToken);

        onOAuthSignInResponse(
            response.signState,
            response.user,
            'Google',
            () => googleOAuthLinkAccount(idToken)
        );
    };

    //------------------------------------------------------------------------------
    // Use the regular OAuth flow to obtain access token.
    const onClickStandardOAuthSign= async (provider) =>
    {
        window.location = basename + 'connectApp/' + provider + `?redirectURI=signin?provider=${provider}`;
    };

    //------------------------------------------------------------------------------
    // The 3dverse API returns the OAuth provider user info and signed in the user if the OAuth
    // provider user id exists in the 3dverse database and is associated to a 3dverse user.
    const onOAuthSign = async (provider, code) =>
    {
        const response      = await oauthSign(provider, code);
        const providerText  = provider[0].toUpperCase() + provider.substr(1);

        onOAuthSignInResponse(
            response.signState,
            response.user,
            providerText,
            () => oauthLinkAccount(provider, response.tokenInfo)
        );
    };

    //------------------------------------------------------------------------------
    // OAuth sign in response has 3 alternatives
    const onOAuthSignInResponse = (signState, user, providerText, linkAccountHandler) =>
    {
        switch(signState)
        {
            case 'signedIn':
                // neo4j (:OAuthAccount).externalUserId exists and is
                // linked to a valid 3dverse user so the user has been signed in
                apiToken = user.token;
                localStorage.setItem('3dverse-api-token', apiToken);
                props.setUser({
                    ...user,
                    isAuthenticated: true
                });
                history.replace('/');
                break;
            case 'linkAccount':
                // A 3dverse user exists with the same email as the account of the oauth provider.
                // But the oauth provider account is not already linked to the 3dverse user.
                // So we ask the user to confirm his password to link the oauth provider account to his user
                // and we sign him in.
                props.setUser({
                    ...user,
                    password: values.password,
                    isAuthenticated   : false,
                    oauthProviderText : providerText,
                    linkAccountHandler
                });

                break;
            case 'signUp':
                // So far the user has to first sign up to 3dverse before being able to
                // sign him in with an oauth provider. So we just pre-fill the signup form
                // with the user data provided by the oauth provider.
                props.setUser({
                    ...user,
                    isAuthenticated   : false,
                    oauthProviderText : providerText
                });
                history.replace('/signup');
                break;
        }
    };

    //------------------------------------------------------------------------------
    const onChange = prop => event =>
    {
        setValues({
            ...values,
            [prop]      : event.target.value,
            globalError : false
        });
    };

    //------------------------------------------------------------------------------
    const onClickShowPassword = () =>
    {
        setValues({
            ...values,
            showPassword: !values.showPassword
        });
    };

    //------------------------------------------------------------------------------
    const onSubmit = async event =>
    {
        event.preventDefault();
        const body = { username: values.username, password: values.password };
        try
        {
            const user = await loginAPI(body);

            if (user?.token)
            {
                apiToken = user.token;
                localStorage.setItem('3dverse-api-token', apiToken);

                if(props.user?.linkAccountHandler)
                {
                    // User validated his oauth account linking to his 3dverse user
                    // So we link it and update user.oauthAccounts
                    const oauthAccounts = await props.user?.linkAccountHandler();
                    user.oauthAccounts = oauthAccounts;
                }

                props.setUser({
                    ...user,
                    isAuthenticated: true
                });

                history.replace('/');
            }
        }
        catch(e)
        {
            let error = parseError(e);
            console.error(error);

            switch (error.errorNum)
            {
            case 6:
            case 2008: {
                setValues({ ...values, globalError: 'Please, confirm your email before signin.', showResendConfirm : true, emailSent : false });
                break;
            }
            case 3003:
            case 2002: {
                setValues({ ...values, globalError: 'Password is invalid.', showResendConfirm : false });
                break;
            }
            case 1001:
            case 2001: {
                setValues({ ...values, globalError: 'Username or email is invalid.', showResendConfirm : false });
                break;
            }
            default:
                break;
            }
        }
    };

    if(isSignWithOAuth && !isSignWithConfirmation)
    {
        return (
            <Layout>
                <Box>
                    <div className='flex items-center justify-center'>
                        <Loader/>
                    </div>
                </Box>
            </Layout>
        );
    }

    //------------------------------------------------------------------------------
    const resendConfirmationEmail = async () =>
    {
        try
        {
            await CallAPI('/authentication/resendConfirmationEmail', 'POST', { username : values.username });
            setValues({ ...values, emailSent : true });
        }
        catch(error)
        {
            console.error(error);
        }
    };

    //------------------------------------------------------------------------------
    return (
        <Layout>

            <SignInUpSwitch currentPage='signin' history={history} />

            <div className='w-full' style={{ maxWidth: '24rem' }}>
                <Box>

                    <h1 className='hidden pb-4 text-title1'>
                        Welcome back!
                    </h1>

                    {isSignWithConfirmation &&
                        <p className='pb-5 text-color-feedback-warning break-words'>
                            {t('account:confirm link oauth account', { oauthProvider: props.user.oauthProviderText })}
                        </p>
                    }

                    {Boolean(values.globalError) &&
                        <div className='flex flex-col gap-2 pb-5'>
                            <p className='text-color-feedback-warning'>
                                {values.globalError}
                            </p>
                            {
                                values.showResendConfirm &&
                                <Button
                                    type='button'
                                    variant='contained'
                                    size='small'
                                    color='secondary'
                                    onClick={resendConfirmationEmail}
                                    disabled={values.emailSent}
                                >
                                    {values.emailSent ? 'Email sent' : 'Resend confirmation email'}
                                </Button>
                            }
                        </div>
                    }

                    <form onSubmit={onSubmit} className='flex flex-col gap-2 w-full'>
                        <ControlText
                            id='login-username'
                            value={values.username}
                            onChange={onChange('username')}
                            label={t('account:Username or email')}
                            required
                            autoFocus
                            error={values.error}
                            inputProps={{
                                autoComplete: 'username'
                            }}
                        />

                        <ControlText
                            id='login-password'
                            value={values.password}
                            onChange={onChange('password')}
                            label={t('account:Password')}
                            type={values.showPassword ? 'text' : 'password'}
                            required
                            error={values.error}
                            inputProps={{
                                autoComplete: 'current-password'
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position='end'>
                                        <ButtonIcon
                                            type='button'
                                            className={values.showPassword ? 'fal fa-eye' : 'fal fa-eye-slash'}
                                            onClick={onClickShowPassword}
                                        />
                                    </InputAdornment>
                                )
                            }}
                        />

                        <p className='text-right'>
                            <Link to='/forgotPassword' className='link text-sm mt-1'>
                                Forgot password?
                            </Link>
                        </p>

                        <Button
                            value={t('account:Sign in')}
                            type='submit'
                            color='primary'
                            size='large'
                            fullWidth
                            className='mt-2'
                        />

                    </form>

                    {/* <p className='text-color-neutral-secondary mt-5'>
                        Don&apos;t have an account yet?
                        {' '}
                        <Link to='/signup' className='link'>
                            Sign up
                        </Link>
                    </p> */}
                </Box>

                {!isSignWithConfirmation &&
                    <Fragment>
                        <p className='text-center text-color-neutral-secondary m-3'>
                            - or -
                        </p>
                        <Box style={{ paddingBlock: '1rem', marginTop: '0' }}>
                            <div className='flex flex-column justify-between items-center'>
                                <p>
                                    Sign in with
                                </p>
                                <div className='flex flex-column gap-2'>
                                    {showNotReadyFeatures &&
                                        <Button
                                            title='Google'
                                            size='large'
                                            noPadding
                                            style={{ width: '2.5rem', height: '2.5rem' }}
                                        >
                                            <div className='absolute opacity-0' ref={buttonGoogleSignRef}/>
                                            <img src={basename + 'logo/google.svg'} alt='' style={{ width: '1.4rem' }} />
                                        </Button>
                                    }
                                    <Button
                                        className='p-2'
                                        title='GitHub'
                                        onClick={() => onClickStandardOAuthSign('github')}
                                        size='large'
                                        noPadding
                                        style={{ width: '2.5rem', height: '2.5rem' }}
                                    >
                                        <img src={basename + 'logo/github-' + (theme?.name === 'dark' ? 'light' : 'dark') + '.png'} alt='' style={{ width: '2rem' }} />
                                    </Button>
                                    <Button
                                        title='GitLab'
                                        onClick={() => onClickStandardOAuthSign('gitlab')}
                                        size='large'
                                        noPadding
                                        style={{ width: '2.5rem', height: '2.5rem' }}
                                    >
                                        <img src={basename + 'logo/gitlab.svg'} alt='' style={{ width: '2.5rem' }} />
                                    </Button>
                                </div>
                            </div>
                        </Box>
                    </Fragment>
                }
            </div>
        </Layout>
    );
}

export default SigninForm;
