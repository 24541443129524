import React, { Component, createContext }  from 'react';
import moment                               from 'moment';
import momentDuration                       from 'moment-duration-format';
import { Text }                             from '../../designSystem/components';
import { plural as p, readablizeBytes }     from '../../designSystem/utils';


momentDuration(moment);

//------------------------------------------------------------------------------
const UploadContext = createContext(
{
	progress : 0,
	showProgress : () => {},
	hideProgress : () => {},
	updateProgress : (progress) => {}
});


//------------------------------------------------------------------------------
export class UploadProvider extends Component
{
	showProgress = (files) =>
	{
		this.setState({
            files,
            filesCount  : files.length,
            visible     : true,
            timeStarted : new Date()
        });
	}

	hideProgress = () =>
	{
		this.setState({visible : false});
	}

    fileNames = () =>
    {
        const [ file, ...otherFiles ] = this.state.files;
        return file.name + '.' + file.extension + (otherFiles.length > 1 ? ` and ${otherFiles.length} files.` : '');
    }

	updateProgress = (bytesUploaded, bytesToUpload) =>
	{
		const progress 			= bytesUploaded / bytesToUpload;

		const timeElapsed 		= (new Date()) - this.state.timeStarted;
		const uploadSpeed 		= bytesUploaded / (timeElapsed/1000); // Upload speed in second

		const remainingSeconds 	= (bytesToUpload - bytesUploaded) / uploadSpeed; 
		this.setState({progress, bytesUploaded, bytesToUpload, remainingSeconds});
	}
	
	state = {
		progress			: 0,
		visible				: false,
		bytesUploaded		: 0,
		bytesToUpload		: 0,
		remainingSeconds	: 0,

		timeStarted 		: null,

		showProgress		: this.showProgress,
		hideProgress		: this.hideProgress,
		updateProgress		: this.updateProgress
	};

    render()
    {
        return (
			<UploadContext.Provider value={this.state}>
				{this.props.children}
				{this.state.visible &&
					<div
                        className='fixed bottom-0 right-0 px-5 py-4 m-4 bg-color-ground shadow-sm z-30'
                        style={{ minWidth: '20rem' }}
                    >
                        <div className='flex justify-between'>
                            <Text format='body3' className='font-bold'>
                                Uploading {this.state.filesCount} file{p(this.state.filesCount)}
                            </Text>
                            {this.state.bytesToUpload &&
                                <Text format='body4' color='secondary'>
                                    {readablizeBytes(this.state.bytesUploaded)} / {readablizeBytes(this.state.bytesToUpload)}
                                </Text>
                            }
                        </div>
                        <div className='flex justify-between'>
                            <Text format='body4' color='secondary' className='pt-1'>
                                {this.fileNames()}
                            </Text>
                            <Text format='body4' color='secondary' className='mt-1 ml-2'>
                                {moment.duration(this.state.remainingSeconds, 'seconds').format('h[h],m[min],s[s]', {largest: 1, minValue : 1})} remaining
                            </Text>
                        </div>
                        <div
                            className='absolute top-0 left-0 w-full h-full border-b text-color-action-default origin-left transition-transform -z-index-1'
                            style={{
                                backgroundColor: 'hsla( var(--color-bg-accent-hsl), .1)',
                                transform: `scaleX(${this.state.progress})`
                            }}
                        />
					</div>
				}
			</UploadContext.Provider>
        );
    }
}

export const UploadConsumer = UploadContext.Consumer;
export default UploadContext;