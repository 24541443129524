//------------------------------------------------------------------------------
import React                from 'react';

//------------------------------------------------------------------------------
import { makeStyles }       from '@material-ui/core/styles';
import Table                from '@material-ui/core/Table';
import TableBody            from '@material-ui/core/TableBody';
import TablePagination      from '@material-ui/core/TablePagination';
import Paper                from '@material-ui/core/Paper';

//------------------------------------------------------------------------------
import EnhancedTableHead    from './enhancedTableHead';

//------------------------------------------------------------------------------
const useStyles = makeStyles(theme => ({
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
        backgroundColor: 'transparent',
        boxShadow: 'none'
    },
    table: {
        minWidth: 750,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    }
}));

//------------------------------------------------------------------------------
export default function TableComponent(props)
{
    const classes           = useStyles();

    const handleRequestSort = (event, property) =>
    {
        const isDesc = props.orderBy === property && props.order === 'desc';
        props.setOrder(isDesc ? 'asc' : 'desc');
        props.setOrderBy(property);
    };

    const handleChangePage  = (event, newPage) =>
    {
        props.setPage(newPage);
    };

    const handleChangeRowsPerPage = event =>
    {
        props.setRowsPerPage(parseInt(event.target.value, 10));
        props.setPage(0);
    };

    if(props.rowCount === 0)
    {
        return (<div className={classes.root}/>);
    }

    return (
        <div className={props.className}>
            <Paper className={classes.paper}>
                <div className={classes.tableWrapper}>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size='medium'
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead
                            classes={classes}
                            order={props.order}
                            orderBy={props.orderBy}
                            onRequestSort={handleRequestSort}
                            headCells={props.headCells}
                        />
                        <TableBody>
                            { props.children }
                        </TableBody>
                    </Table>
                </div>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                    component="div"
                    count={props.rowCount}
                    rowsPerPage={props.rowsPerPage}
                    page={props.page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </div>
    );
}