import React, { useState }              from 'react';
import { Button }                       from '../../../designSystem/components';
import CallAPI                          from '../../../utils/api';
import Layout, { Box }                  from '../LoginLayout';


//------------------------------------------------------------------------------
function SignupSendConfirmEmailPage(props)
{
    //------------------------------------------------------------------------------
    const { username, email } = props.location.state.userDataFromSignUp;

    //------------------------------------------------------------------------------
    const [emailSent, setEmailSent] = useState(false);

    //------------------------------------------------------------------------------
    const goToSignIn = () =>
    {
        props.history.push('/signin');
    };

    //------------------------------------------------------------------------------
    const resendConfirmationEmail = async () =>
    {
        setEmailSent(false);
        try
        {
            await CallAPI('/authentication/resendConfirmationEmail', 'POST', { username });
            setEmailSent(true);
        }
        catch(error)
        {
            console.error(error);
        }
    };

    //------------------------------------------------------------------------------
    return (
        <Layout>
            <Box className='text-center'>
                <h1 className='text-title1 mb-6'>
                    Welcome {username}
                </h1>

                <p>
                    A confirmation email has been sent at <b>{email}</b>.
                </p>
                <p className='text-sm text-color-neutral-secondary mt-1'>
                    Please check your inbox and spams.
                </p>

                <div className='flex flex-col justify-center items-center gap-3 mt-5'>
                    <Button onClick={goToSignIn} color='secondary'>
                        Sign in
                    </Button>
                    <Button onClick={resendConfirmationEmail} color='tertiary' size='small'>
                        Resend confirmation email
                    </Button>
                </div>

                {emailSent &&
                    <p className='pt-2 text-color-feedback-positive'>
                        Confirmation email sent!
                    </p>
                }
            </Box>
        </Layout>
    );
}

export default SignupSendConfirmEmailPage;
