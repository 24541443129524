//------------------------------------------------------------------------------
import React                        from 'react';
import { Icon }                     from '../../../../designSystem/components';

//------------------------------------------------------------------------------
const statusMap = {
    'waiting-approval':        'Awaiting Approval',
    'downloading':             'Downloading',
    'waiting-confirmation':    'Awaiting Confirmation',
    'approved':                'Approved',
    'pending' :                'Pending',
    'synchronizing':           'Synchronizing',
    'converting':              'Converting',
    'rejected':                'Rejected',
    'error':                   'Error',
    'with-error':              'Completed with errors',
    'ignored':                 'Ignored',
    'success':                 'Success',
};

//------------------------------------------------------------------------------
export function getStatusText(status)
{
    return statusMap[status] || status;
}

//------------------------------------------------------------------------------
export function DeletedIcon()
{
    return <Icon className='fas fa-ban' />;
}

//------------------------------------------------------------------------------
export function getStatusIcon(status)
{
    switch(status)
    {
    case 'approved':
    case 'waiting-approval':
    case 'waiting-confirmation':
    case 'pending':
    case 'synchronizing':
    default:
        return <Icon className='fas fa-hourglass-half' style={{ opacity: .5 }} />;

    case 'downloading':
        return <Icon className='fas fa-cloud-arrow-down' style={{ opacity: .5 }} />;

    case 'success':
        return <Icon className='fas fa-check-circle text-color-feedback-positive' />;

    case 'with-error':
        return <Icon className='fas fa-exclamation-triangle text-color-feedback-negative' />;

    case 'rejected':
        return <Icon className='fas fa-vote-nay text-color-feedback-negative' />;

    case 'ignored':
        return <Icon className='fas fa-empty-set text-color-feedback-negative' />;

    case 'error':
        return <Icon className='fas fa-exclamation-square text-color-feedback-negative' />;

    case 'converting':
        return <Icon className='fas fa-spinner text-color-feedback-informative' />;
    }
}

//------------------------------------------------------------------------------
export function isStatusPending(status)
{
    return status === 'pending' ||
        status === 'waiting-approval' ||
        status === 'approved' ||
        status === 'waiting-confirmation' ||
        status === 'synchronizing' ||
        status === 'converting' ||
        status === 'downloading';
}
