//------------------------------------------------------------------------------
import React, { Fragment, useState, useEffect }     from 'react';

import { useSnackbar }                              from 'notistack';

import { Button, ControlAdornment, ControlSelect, ControlSelectItem, ControlText, ControlCheckbox,
    Icon, ListSubheader, Loader, Text, Avatar }     from '../../../../designSystem/components';
import moduleAPI                                    from './moduleAPI';
import Repositories                                 from './Repositories';
import ConfirmationModal                            from '../../../../components/ConfirmationModal';

//------------------------------------------------------------------------------
const gitProviders = [
    {
        id : 'gitlab',
        icon : 'fa-gitlab',
        name : 'GitLab Access token',
        api : 'gitlab',
        webhookHelp : 'https://docs.gitlab.com/ee/user/project/integrations/webhooks.html',
        inputs :
        [
            {
                type : 'password',
                name : 'accessToken',
                title : 'Access token',
                description : 'Personal access token to authenticate with the GitLab API',
                help : 'https://docs.gitlab.com/ee/user/profile/personal_access_tokens.html'
            },
            {
                type : 'password',
                name : 'secretToken',
                title : 'Webhook secret token',
                description : 'Optional. Token used to verify that the webhook request is legitimate',
                help : 'https://docs.gitlab.com/ee/user/project/integrations/webhooks.html#secret-token'
            }
        ]
    },
    {
        id : 'github',
        icon : 'fa-github',
        name : 'GitHub Access token',
        api : 'github',
        inputs :
        [
            {
                type : 'password',
                name : 'accessToken',
                title : 'Access token',
                placeholder: '000000',
                description : 'Personal access token to authenticate with the GitHub API',
                help : 'https://docs.github.com/en/github/authenticating-to-github/keeping-your-account-and-data-secure/creating-a-personal-access-token'
            },
            {
                type : 'password',
                name : 'secretToken',
                placeholder: '000000',
                title : 'Webhook secret token',
                description : 'Optional. Token used to verify that the webhook request is legitimate',
                help : 'https://docs.github.com/en/developers/webhooks-and-events/webhooks/securing-your-webhooks'
            }
        ]
    },
    /*{
        id : 'bitbucket',
        icon : 'fa-bitbucket',
        name : 'Bitbucket ',
        disabled : true,
        inputs :
        [
            { type : 'text', name : 'accountName', title : 'Account name or email' },
            { type : 'text', name : 'appPassword', title : 'Application password' }
        ]
    },*/
    {
        id      : 'gitlab-oauth',
        icon    : 'fa-gitlab',
        name    : 'GitLab Account',
        api     : 'gitlab',
        webhookHelp : 'https://docs.gitlab.com/ee/user/project/integrations/webhooks.html',
        disabled : (user) => user.oauthAccounts.every(a => a.label !== "GitlabAccount"),
        inputs :
        [
            {
                type : 'password',
                name : 'secretToken',
                title : 'Webhook secret token',
                description : 'Optional. Token used to verify that the webhook request is legitimate',
                help : 'https://docs.gitlab.com/ee/user/project/integrations/webhooks.html#secret-token'
            }
        ]
    },
    {
        id      : 'github-oauth',
        icon    : 'fa-github',
        name    : 'GitHub Account',
        api     : 'github',
        webhookHelp : 'https://docs.github.com/en/developers/webhooks-and-events/webhooks/creating-webhooks',
        disabled : (user) => user.oauthAccounts.every(a => a.label !== "GithubAccount"),
        inputs :
            [
                {
                    type : 'password',
                    name : 'secretToken',
                    title : 'Webhook secret token',
                    description : 'Optional. Token used to verify that the webhook request is legitimate',
                    help : 'https://docs.github.com/en/developers/webhooks-and-events/webhooks/securing-your-webhooks'
                }
            ]
    },
];

//------------------------------------------------------------------------------
const commonGitProvidersInputDescriptor = [
    {
        type : 'text',
        name : 'branchName',
        title : 'Branch name',
        description : 'Could be a branch name, a tag name or a commit SHA',
    },
    {
        type : 'text',
        name : 'name',
        title : 'Repository name',
        description : 'These parameters can be used for another module. Give it a name to recognize it.'
    },
    {
        type : 'bool',
        name : 'autoPublish',
        title : 'Auto publish',
        description : 'Automatically trigger a module publish on synchronize and webhook calls. Only available on python modules.'
    }
];

//------------------------------------------------------------------------------
const moduleLinkInputDescriptor = [
    {
        type : 'text',
        name : 'moduleRootDirectory',
        title : 'Module root directory',
        description : 'Relative path to the folder containing the .3dverse.config.json file associated to this module',
    }
];

//------------------------------------------------------------------------------
export default function ModuleDeployment(props)
{
    //------------------------------------------------------------------------------
    const { enqueueSnackbar }                       = useSnackbar();

    //------------------------------------------------------------------------------
    const [isLoading, setLoading]                   = useState(true);
    const [pending, setPending]                     = useState('');
    const [editMode, setEditMode]                   = useState(null);
    const [confirmationModal, setConfirmationModal] = useState(null);


    const [currentRepository, setRepository]        = useState(null);
    const [provider, setProvider]                   = useState(gitProviders[0]);
    const [repositories, setRepositories]           = useState([]);
    const [repositoryUUID, setRepositoryUUID]       = useState('');
    const [gitPath, setGitPath]                     = useState(null);
    const [inputValue, setInputValue]               = useState(
        {
            name : 'New repository',
            branchName : 'main',
            moduleRootDirectory : props.assetDescription?.moduleRootDirectory
        }
    );

    //------------------------------------------------------------------------------
    useEffect(() =>
    {
        Promise.all([
            moduleAPI.listAvailableRepositories(),
            moduleAPI.getLinkedRepository(props.assetUUID)
        ])
            .then(([r, linkedRepository]) =>
            {
                setRepositories(r);

                if(linkedRepository)
                {
                    linkedRepository.hasAccess = r.some(({ uuid }) => linkedRepository.uuid === uuid);
                    setRepository(linkedRepository);
                }

                setLoading(false);
            });
    }, []);

    //------------------------------------------------------------------------------
    async function link()
    {
        setPending('link');
        try
        {
            const { moduleRootDirectory, ...properties } = inputValue;
            props.onAssetUpdated({...props.assetDescription, moduleRootDirectory}, true);

            let repository = null;
            if(repositoryUUID && repositoryUUID !== 'create')
            {
                await moduleAPI.linkModule(props.assetUUID, repositoryUUID);
                repository = repositories.find(r => r.uuid === repositoryUUID);
            }
            else
            {
                repository = await moduleAPI.createRepositoryAndLink(props.assetUUID, { provider : provider.id, ...properties, gitPath });
                setRepositories([...repositories, repository]);
            }

            setRepository(
            {
                ...repository,
                creatorUserUUID : props.user.uuid,
                creatorUsername : props.user.username,
                hasAccess       : true
            });
            enqueueSnackbar('Git repository linked', { variant: 'success' });
        }
        catch(error)
        {
            enqueueSnackbar(error.message, { variant: 'error' });
        }
        setPending('');
    }

    //------------------------------------------------------------------------------
    async function unlink()
    {
        setPending('unlink');
        try
        {
            await moduleAPI.unlinkModule(props.assetUUID);
            enqueueSnackbar('Git repository unlinked', { variant: 'success' });
            setRepository(null);
            setRepositoryUUID('');
        }
        catch(error)
        {
            enqueueSnackbar(error.message, { variant: 'error' });
        }
        setPending('');
    }

    //------------------------------------------------------------------------------
    function editRepository()
    {
        const { provider : providerID, gitPath, ...properties } = currentRepository;
        const gitProvider = gitProviders.find(p => p.id === providerID);

        setEditMode(
        {
            previousProviderID  : providerID,
            previousGitPath     : gitPath
        });
        setProvider(gitProvider);
        setGitPath(gitPath);
        setInputValue({...properties, moduleRootDirectory : props.assetDescription?.moduleRootDirectory});
    }

    //------------------------------------------------------------------------------
    function stopEdit()
    {
        setEditMode(null);
    }

    //------------------------------------------------------------------------------
    async function updateRepository()
    {
        setPending('update');
        try
        {
            const { previousProviderID, previousGitPath } = editMode;
            const { moduleRootDirectory , ...properties } = inputValue;

            // Do not specify provider id if it didn't change (it would required to send everything!)
            if(previousProviderID !== provider.id)
            {
                properties.provider = provider.id;
            }

            if(gitPath)
            {
                properties.gitPath = gitPath;
            }

            if(properties.accessToken === '')
            {
                delete properties.accessToken;
            }

            if(moduleRootDirectory !== props.assetDescription.moduleRootDirectory)
            {
                props.onAssetUpdated({...props.assetDescription, moduleRootDirectory}, true);
            }

            await moduleAPI.updateRepository(currentRepository.uuid, properties);
            enqueueSnackbar('Git repository updated', { variant: 'success' });
            setRepository({...properties, provider : provider.id, gitPath : gitPath || previousGitPath });
            setEditMode(null);
        }
        catch(error)
        {
            enqueueSnackbar(error.message, { variant: 'error' });
        }
        setPending('');
    }

    //------------------------------------------------------------------------------
    async function deleteRepository()
    {
        setPending('delete');
        try
        {
            await moduleAPI.deleteRepository(currentRepository.uuid);
            enqueueSnackbar('Git repository deleted', { variant: 'success' });
            setRepositories(repositories.filter(r => r.uuid !== currentRepository.uuid));
            setRepository(null);
            setRepositoryUUID('');
        }
        catch(error)
        {
            enqueueSnackbar(error.message, { variant: 'error' });
        }
        setPending('');
    }

    //------------------------------------------------------------------------------
    async function synchronize()
    {
        setPending('synchronize');
        try
        {
            const { lastCommitID } = await moduleAPI.synchronize(currentRepository.uuid);
            setRepository({ ...currentRepository, lastCommitID });
            enqueueSnackbar('Git repository synchronized', { variant: 'success' });
        }
        catch(error)
        {
            enqueueSnackbar(error.message, { variant: 'error' });
        }
        setPending('');
    }

    //------------------------------------------------------------------------------
    function renderWidget(inputDesc)
    {
        if(inputDesc.type === 'bool')
        {
            return (
                <ControlCheckbox
                    key={inputDesc.name}
                    value={inputValue[inputDesc.name] || ''}
                    onChange={(e) => setInputValue({ ...inputValue, [inputDesc.name] : e.currentTarget.checked })}
                    label={inputDesc.title}
                    labelDescription={inputDesc.description}
                    labelDirection='horizontal'
                    size='small'
                />
            );
        }

        return (
            <ControlText
                key={inputDesc.name}
                value={inputValue[inputDesc.name] || ''}
                onChange={(e) => setInputValue({ ...inputValue, [inputDesc.name] : e.currentTarget.value })}
                type={inputDesc.type}
                autoComplete='new-password'
                label={inputDesc.title}
                labelDescription={inputDesc.description}
                labelDirection='horizontal'
                placeholder={editMode && editMode.previousProviderID === provider.id && inputDesc.type === 'password' ? 'Leave this field blank to keep the same value' : inputDesc.title}
                className='flex-1'
                size='small'
                InputProps={inputDesc.help ? {
                    endAdornment: (
                        <ControlAdornment position='end'>
                            <a href={inputDesc.help} target='_blank' rel='noreferrer' title={inputDesc.title}>
                                <Icon className='fas fa-question-circle' size='small' />
                            </a>
                        </ControlAdornment>
                    )
                } : {}}
            />
        );
    }

    //------------------------------------------------------------------------------
    function renderRepositorySettings()
    {
        return (
            <div className='flex flex-col gap-3 mt-4'>
                <Text format='title2'>Git provider information</Text>
                <ControlSelect
                    value={provider?.id}
                    onChange={(e, value) =>
                    {
                        const provider = gitProviders.find(p => p.id === value);
                        setProvider(provider);
                    }}
                    label='Git Provider'
                    labelDirection='horizontal'
                    className='flex-1'
                    size='small'
                >
                    {gitProviders.map(gitProvider =>
                    {
                        const disabled = typeof gitProvider.disabled === 'function' ? gitProvider.disabled(props.user) : gitProvider.disabled;

                        return (
                            <ControlSelectItem
                                key={gitProvider.id}
                                value={gitProvider.id}
                                disabled={disabled}
                            >
                                <Icon className={`fab ${gitProvider.icon} mr-1`} size='small' />
                                <span>{gitProvider.name}{(disabled ? ' (no account linked)' : '')}</span>
                            </ControlSelectItem>
                        );
                    })}
                </ControlSelect>

                { provider &&
                    <Fragment>
                        {provider.inputs.map(renderWidget)}

                        <Repositories
                            provider={provider}
                            value={gitPath}
                            onChange={setGitPath}
                            onRepoSelected={(repo) =>
                            {
                                setInputValue({ ...inputValue, name : repo.name});
                            }}
                            {...inputValue}
                        />
                    </Fragment>
                }

                {commonGitProvidersInputDescriptor.map(renderWidget)}

                <Text format='title2' className='mt-4'>Module link options</Text>
                {moduleLinkInputDescriptor.map(renderWidget)}
            </div>
        )
    }

    let content = null;

    if(isLoading)
    {
        content = (<Loader />);
    }
    else if(currentRepository)
    {
        const gitProvider = gitProviders.find(p => p.id === currentRepository.provider);
        const canSynchronize = currentRepository.hasAccess
            || (currentRepository.provider.includes('gitlab') && props.user.oauthAccounts.some(a => a.label === "GitlabAccount"))
            || (currentRepository.provider.includes('github') && props.user.oauthAccounts.some(a => a.label === "GithubAccount"));

        if(editMode)
        {
            const { previousProviderID } = editMode;
            const isSubmittable = Boolean(previousProviderID === provider.id || gitPath)
                && Boolean(inputValue['name'] && inputValue['branchName'] && inputValue['moduleRootDirectory']);

            content = (
                <div>
                    {renderRepositorySettings()}
                    <div className='flex justify-end mt-4 gap-1'>
                        <Button
                            value={'Update repository'}
                            onClick={updateRepository}
                            disabled={!isSubmittable || Boolean(pending)}
                            style={{ flex: '0 calc(33% - var(--spacing-1))' }}
                            loader={pending === 'update'}
                        />
                        <Button
                            value={'Cancel'}
                            onClick={stopEdit}
                            disabled={Boolean(pending)}
                            style={{ flex: '0 calc(32% - var(--spacing-4))' }}
                        />
                    </div>
                </div>
            );
        }
        else
        {
            content = (
                <div className='bg-color-underground flex flex-col gap-4 pt-4 p-5 rounded-sm'>
                    <div className='flex flex-col gap-2'>
                        <Text className='font-bold'>
                            <Icon className={`fab ${gitProvider.icon} mr-1`} />
                            <span>{gitProvider.name}</span>
                        </Text>
                        <Text className='font-bold'>
                            {currentRepository.name}
                        </Text>
                        <Text format='body3' color='secondary'>
                            <a className='hover:underline' href={moduleAPI.getRepositoryURL(currentRepository)} target='_blank' rel='noreferrer'>
                                {currentRepository.gitPath}
                            </a>
                        </Text>
                        <Text format='body3' color='secondary'>
                            Branch: {currentRepository.branchName}
                        </Text>
                        {
                            currentRepository.lastCommitID &&
                            <Text format='body3' color='secondary'>
                                Last Commit:
                                <a className='ml-1 hover:underline' href={moduleAPI.getLastCommitURL(currentRepository)} target='_blank' rel='noreferrer'>
                                    {currentRepository.lastCommitID.slice(0, 8)}
                                </a>
                            </Text>
                        }
                        <Text format='body3' color='secondary'>
                            Module root directory: {props.assetDescription?.moduleRootDirectory || ''}
                        </Text>
                        <div className='flex gap-3'>
                            <Text format='body3' color='secondary'>
                                Owned by
                            </Text>

                            <Avatar
                                uuid={currentRepository.creatorUserUUID}
                                username={currentRepository.creatorUsername}
                                size='small'
                                color='var(--color-accent)'
                                backgroundColor='var(--color-bg-action-secondary)'
                                reversed
                                displayName
                            />
                        </div>
                        <div className='flex items-center'>
                            <Text format='body3' color='secondary'>
                                Webhook URL
                            </Text>
                            {gitProvider.webhookHelp &&
                                <a href={gitProvider.webhookHelp} target='_blank' rel='noreferrer'>
                                    <Icon className='fas fa-question-circle' size='small' />
                                </a>
                            }
                            :
                            <ControlText
                                value={apiGatewayURL + '/git-webhook/' + currentRepository.uuid}
                                readOnly
                                size='small'
                                fullWidth
                                className='flex-1'
                            />
                        </div>

                    </div>

                    <div className='flex justify-between gap-1'>

                        <div className='flex gap-1'>
                        {
                            <Button
                                value='Synchronize'
                                onClick={synchronize}
                                disabled={Boolean(pending) || !canSynchronize}
                                size='small'
                                loader={pending === 'synchronize'}
                            />
                        }
                        {
                            currentRepository.hasAccess &&
                            <Button
                                value='Edit'
                                onClick={editRepository}
                                disabled={Boolean(pending)}
                                size='small'
                            />
                        }
                        </div>

                        <div className='flex gap-1'>
                            <Button
                                value='Unlink'
                                onClick={unlink}
                                disabled={Boolean(pending)}
                                size='small'
                                color='tertiary'
                                loader={pending === 'unlink'}
                            />

                            {
                                currentRepository.hasAccess &&
                                <Button
                                    value='Delete the repository'
                                    onClick={() => setConfirmationModal(
                                    {
                                        titleHTML   : `Delete the repository <b>${currentRepository.name}</b> ?`,
                                        onSubmit    : deleteRepository,
                                        submitText  : 'Delete'
                                    })}
                                    disabled={Boolean(pending)}
                                    size='small'
                                    color='tertiary'
                                    loader={pending === 'delete'}
                                />
                            }
                        </div>
                    </div>
                </div>
            );
        }
    }
    else
    {
        const isSubmittable     = Boolean(repositoryUUID && repositoryUUID !== 'create' && inputValue['moduleRootDirectory'])
            || Boolean(provider?.id && gitPath && inputValue['name'] && inputValue['branchName'] && inputValue['moduleRootDirectory']);
        const isCreatingNewRepo = (!repositoryUUID || repositoryUUID === 'create');

        content = (
            <Fragment>
                {repositories.length > 0 &&
                    <ControlSelect
                        value={repositoryUUID}
                        onChange={(e, value) => setRepositoryUUID(value)}
                        label='Existing repository'
                        labelDirection='horizontal'
                        className='flex-1'
                        size='small'
                    >
                        <ListSubheader>
                            Choose a repository
                        </ListSubheader>

                        <ControlSelectItem value='create'>
                            <Text className='italic'>
                                * Create a new repository
                            </Text>
                        </ControlSelectItem>

                        {repositories.map(repository =>
                            <ControlSelectItem key={repository.uuid} value={repository.uuid}>
                                {repository.name}
                            </ControlSelectItem>
                        )}
                    </ControlSelect>
                }

                {repositoryUUID === 'create' &&
                    <div className="dropdown-divider" />
                }

                {(repositoryUUID === 'create' || repositories.length === 0) ?
                    renderRepositorySettings()
                    :
                    <div className='mt-2'>
                        { moduleLinkInputDescriptor.map(renderWidget)}
                    </div>
                }

                <div className='flex justify-end mt-4'>
                    <Button
                        value={`Link to ${isCreatingNewRepo ? 'a new ': ''} repository`}
                        onClick={isSubmittable ? link : undefined}
                        disabled={!isSubmittable || Boolean(pending)}
                        style={{ flex: '0 calc(65% - var(--spacing-4))' }}
                        loader={pending === 'link'}
                    />
                </div>
            </Fragment>
        );
    }

    return (
        <div>
            {content}

            {confirmationModal &&
                <ConfirmationModal
                    open
                    onClose={() => setConfirmationModal(null)}
                    {...confirmationModal}
                />
            }
        </div>
    );
}
