//------------------------------------------------------------------------------
import React, { Fragment, useState, useEffect }     from 'react';
import ReactJson                                    from 'react-json-view';
import { useSnackbar }                              from 'notistack';
import InputAdornment                               from '@material-ui/core/InputAdornment';

//------------------------------------------------------------------------------
import {
    Button, ButtonGroup, Text, Icon, Link, Divider, ListSubheader, ControlText, ControlSelect, ControlSelectItem
}                                       from '../../../../designSystem/components';
import { routerUseHistory, stringToKebabCase, safeJSONParse
}                                       from '../../../../designSystem/utils';

//------------------------------------------------------------------------------
import NameSection from './NameSection';

import Editor                           from '../../../../components/Editor';
import ConfirmationModal                from '../../../../components/ConfirmationModal';
import TileApplication                  from '../../../../components/TileApplication';
import TileButton                       from '../../../../components/TileApplication/TileButton';
import { buildApp, getAppPublicURL }    from '../api';

//------------------------------------------------------------------------------
const pathNameRegex = /^[a-zA-Z0-9]+(?:[_-]?[a-zA-Z0-9])*$/;

//------------------------------------------------------------------------------
export default function ApplicationPanel(props)
{
    //--------------------------------------------------------------------------
    const { currentProject, applicationUUID, canEdit } = props;
    const newApplication = applicationUUID === 'create';

    //--------------------------------------------------------------------------
    const history                                       = routerUseHistory();
    const { enqueueSnackbar }                           = useSnackbar();

    //--------------------------------------------------------------------------
    const [ localAppState, setLocalAppState ]           = useState(
        {
            name                    : '',
            description             : '',
            configuration           : '{\n\n}',
            applicationTemplateUUID : ''
        });

    //--------------------------------------------------------------------------
    const application   = newApplication
        ? localAppState
        : currentProject.applications.find(a => a.uuid === applicationUUID);

    //--------------------------------------------------------------------------
    const [ editorMode, setEditorMode ]                 = useState(newApplication);
    const [ localStateConfig, setConfig ]               = useState('');
    const [ hasChanged, setHasChanged ]                 = useState(false);
    const [ pending, setPending ]                       = useState(false);
    const [ confirmationModal, setConfirmationModal ]   = useState(null);
    const [ pathName, setPathName ]                     = useState('');
    const [ pathNameError, setPathNameError ]           = useState(null);

    //--------------------------------------------------------------------------
    async function onInfoChanged(properties, onSuccess = null)
    {
        if(newApplication)
        {
            setLocalAppState({ ...localAppState, ...properties });

            if(properties.name && !pathName)
            {
                const pathName = stringToKebabCase(properties.name);
                setPathName(encodeURI(pathName));
            }
            return;
        }

        const propertyNames = Object.keys(properties);
        if(propertyNames.every(p => application[p] === properties[p]))
        {
            return;
        }

        try
        {
            await props.requests.updateApplication({ uuid : applicationUUID, ...properties });
            enqueueSnackbar('Application settings saved', { variant: 'success' });
            if(onSuccess)
            {
                onSuccess();
            }
        }
        catch(error)
        {
            if(error.errorNum === 1019 || error.errorNum === 1020)
            {
                setPathNameError(error.message);
                return;
            }

            console.error(error);
            enqueueSnackbar('Failed to save application settings. See console for details.', { variant: 'error' });
        }
    }

    //--------------------------------------------------------------------------
    function onImageChanged()
    {
        const thumbnailUUID = prompt('Image upload not implemented yet. Paste scene uuid instead', application.thumbnailUUID || 'SCENE_UUID');
        if(thumbnailUUID)
        {
            onInfoChanged({ thumbnailUUID });
        }
    }

    //--------------------------------------------------------------------------
    function onConfigChanged(value)
    {
        if(newApplication)
        {
            setLocalAppState({ ...localAppState, configuration : value });
            return;
        }

        setConfig(value);
        setHasChanged(true);
    }

    //--------------------------------------------------------------------------
    async function onSubmit()
    {
        setPending(true);
        await props.requests.createApplication(localAppState);
        history.replace('../applications');
    }

    //--------------------------------------------------------------------------
    function updateAndPublishConfiguration()
    {
        onInfoChanged({ configuration : localStateConfig }, () => publishApp());
        setHasChanged(false);
        setEditorMode(false);
    }

    //--------------------------------------------------------------------------
    function abortChanges()
    {
        setHasChanged(false);
        setEditorMode(false);
    }

    //--------------------------------------------------------------------------
    async function publishApp()
    {
        setPending('publish');
        try
        {
            const applicationTemplate = currentProject.applicationTemplates.find(t => t.uuid === application.templateUUID);
            await buildApp(currentProject.uuid, applicationUUID, applicationTemplate.provider);
            enqueueSnackbar('Application published', { variant: 'success' });
        }
        catch(error)
        {
            enqueueSnackbar(error.message, { variant: 'error' });
        }
        setPending(false);
    }

    //--------------------------------------------------------------------------
    function onDelete()
    {
        props.requests.deleteApplication(application.uuid);
        history.replace('../applications');
    }

    //--------------------------------------------------------------------------
    useEffect(() =>
    {
        setPathName(application.pathName);
    }, [application.pathName]);

    //--------------------------------------------------------------------------
    function onPathNameChanged()
    {
        if(pathName !== '' && !pathNameRegex.test(pathName))
        {
            setPathNameError('Path can contain only english characters, numbers, and non-consecutive hyphens or underscores');
            return;
        }

        if(pathName.length > 32)
        {
            setPathNameError('Path can contain up to 32 characters');
            return;
        }

        setPathNameError(null);
        onInfoChanged({ pathName });
    }

    //--------------------------------------------------------------------------
    if(!application)
    {
        return (
            <section className={`flex flex-col items-center justify-center gap-4 w-full h-full ${props.className ? props.className : ''}`} style={props.style}>
                <Text format='title1'>
                    Application not found
                </Text>

                <Link to='../applications' className='link'>
                    Go back to application list
                </Link>
            </section>
        );
    }

    //--------------------------------------------------------------------------
    const isSubmittable = Boolean(application.name && application.templateUUID);

    return (
        <section
            className={`wrapper py-6 sm:py-4 ${props.className ?? props.className}`}
            style={props.style}
        >
            <header className='flex justify-between'>
                <Text format='title1'>
                    {newApplication ? 'New application' : 'Application settings'}
                </Text>
            </header>

            <div className='flex flex-col gap-2 mt-4'>

                <div className='flex flex-col-reverse lg:flex-row gap-2'>
                    {
                        application.thumbnailUUID
                            ? <TileApplication
                                thumbnailUUID={application.thumbnailUUID}
                                onClick={onImageChanged}
                                size='large'
                            />
                            : <TileButton size='large' className='bg-color-underground' onClick={onImageChanged}>
                                <span className='w-full h-full flex flex-col items-center justify-center'>
                                    <Icon className='fas fa-image' style={{ fontSize:'2.5rem' }} color='secondary' />
                                    <Text format='body2' className='mt-4'>
                                    Add image
                                    </Text>
                                </span>
                            </TileButton>
                    }
                    <NameSection
                        canEdit={canEdit}
                        application={application}
                        onChange={onInfoChanged}
                    />
                </div>

                <section className='flex flex-col w-full overflow-auto bg-color-underground border border-color-tertiary rounded p-4 gap-2'>
                    <ControlSelect
                        value={application.templateUUID || ''}
                        onChange={(e, value) => onInfoChanged({ templateUUID : value })}
                        label={(newApplication ? '* ' : '') + 'Application template'}
                        labelDirection='horizontal'
                        className='flex-1'
                        readOnly={!canEdit}
                    >
                        <ListSubheader value=''>
                            Choose an application template
                        </ListSubheader>

                        {currentProject.applicationTemplates.map(applicationTemplate =>
                            <ControlSelectItem key={applicationTemplate.uuid} value={applicationTemplate.uuid}>
                                {applicationTemplate.gitPath}
                            </ControlSelectItem>
                        )}
                    </ControlSelect>

                    <ControlText
                        value={pathName || ''}
                        onChange={(e, value) => setPathName(value.toLowerCase())}
                        onBlur={onPathNameChanged}
                        placeholder={canEdit ? 'Example : my-app' : 'Empty'}
                        label={'Short url path'}
                        labelDirection='horizontal'
                        readOnly={!canEdit}
                        labelDescription={pathNameError && <Text color='negative'>{pathNameError}</Text>}
                        InputProps={pathNameError && {
                            endAdornment: (
                                <InputAdornment position='end'>
                                    <Icon
                                        className='fas fa-exclamation-triangle'
                                        color='negative'
                                    />
                                </InputAdornment>
                            )
                        }}
                    />
                </section>

                <section className='w-full overflow-auto bg-color-underground border border-color-tertiary rounded overflow-hidden'>
                    <header className='flex justify-end p-4'>
                        <div className='flex-1'>
                            <Text format='body3'>
                                Application configuration file
                            </Text>
                        </div>
                        {
                            !newApplication && canEdit &&
                            <ButtonGroup outlined>
                                <Button
                                    value='Tree'
                                    color={!editorMode ? 'primary' : undefined}
                                    size='xsmall'
                                    onClick={() => setEditorMode(false)}
                                />
                                <Button
                                    value='Editor'
                                    size='xsmall'
                                    color={editorMode ? 'primary' : undefined}
                                    onClick={() => setEditorMode(true)}
                                />
                            </ButtonGroup>
                        }
                    </header>
                    {editorMode ?
                        <Fragment>
                            <div style={{ height: '25rem' }}>
                                <Editor
                                    defaultLanguage='json'
                                    defaultValue={application.configuration}
                                    onChange={onConfigChanged}
                                    options={{
                                        overviewRulerLanes: 0,
                                        hideCursorInOverviewRuler: true,
                                        overviewRulerBorder: false,
                                        minimap: { enabled: false }
                                    }}
                                />
                            </div>
                            {
                                !newApplication &&
                                <div className='flex justify-end m-4 gap-1'>
                                    <Button
                                        value={'Save changes and publish'}
                                        onClick={updateAndPublishConfiguration}
                                        disabled={!hasChanged || Boolean(pending)}
                                        loader={pending}
                                    />
                                    <Button value={'Cancel'} onClick={abortChanges} />
                                </div>
                            }
                        </Fragment>
                        :
                        <div className='px-5 pb-5'>
                            <ReactJson
                                src={safeJSONParse(application.configuration)}
                                name={null}
                                collapsed={1}
                                theme='monokai'
                                style={{ backgroundColor : 'transparent' }}
                                iconStyle='square'
                                displayDataTypes={false}
                                enableClipboard={false}
                            />
                        </div>
                    }
                </section>

                {
                    newApplication
                        ?
                        <Fragment>
                            <div className='grid justify-end my-2'>
                                <Text format='body3' className='text-right italic pr-2'>
                                    All fields marked * are mandatory
                                </Text>
                                <div className='flex gap-1 mt-4'>
                                    <Button
                                        value={newApplication ? 'Create application' : 'Save changes'}
                                        onClick={onSubmit}
                                        disabled={(newApplication ? !isSubmittable : !hasChanged) || Boolean(pending)}
                                        loader={pending}
                                    />

                                    <Link to='../applications'>
                                        <Button value={'Cancel'} disabled={pending} />
                                    </Link>
                                </div>
                            </div>

                        </Fragment>
                        : <Fragment>
                            <Divider horizontal margin={1.5} />

                            <div className='flex flex-col lg:flex-row gap-2 w-full'>
                                <section className='p-4 bg-color-underground border border-color-tertiary-alt rounded w-full'>
                                    <header className='mb-2'>
                                        <Text format='title2'>
                                            Open Application
                                        </Text>
                                    </header>
                                    <Text format='body3' color='secondary' className='mb-4'>
                                        Run your application in your browser.
                                    </Text>
                                    <Button
                                        as='a'
                                        href={getAppPublicURL(application)}
                                        target='_blank'
                                        value='Open'
                                        startIcon='fal fa-external-link-alt'
                                        size='small'
                                    />
                                </section>

                                {
                                    canEdit &&
                                    <section className='p-4 bg-color-underground border border-color-tertiary-alt rounded w-full'>
                                        <header className='mb-2'>
                                            <Text format='title2'>
                                                Publish this application
                                            </Text>
                                        </header>
                                        <Text format='body3' color='secondary' className='mb-4'>
                                            Build the application from the git repository.
                                        </Text>
                                        <Button
                                            value='Publish'
                                            startIcon='fal fa-upload'
                                            onClick={publishApp}
                                            size='small'
                                            loader={pending === 'publish'}
                                            disabled={pending}
                                        />
                                    </section>
                                }
                            </div>

                            {
                                canEdit &&
                                <Fragment>
                                    <Divider horizontal margin={1.5} />
                                    <section className='p-4 bg-color-underground border border-color-tertiary-alt rounded'>
                                        <header className='mb-2'>
                                            <Text format='title2' color='warning'>
                                                Delete this application
                                            </Text>
                                        </header>
                                        <Text format='body3' color='secondary' className='mb-4'>
                                            <div dangerouslySetInnerHTML={
                                                { __html: `This action will permanently delete ${application.name} <b>immediately</b>.` }
                                            }/>
                                        </Text>
                                        <Button
                                            value='Delete'
                                            onClick={() => setConfirmationModal(
                                                {
                                                    titleHTML   : `Delete the application <b>${application.name}</b> ?`,
                                                    onSubmit    : onDelete,
                                                    submitText  : 'Delete'
                                                })}
                                            size='small'
                                            startIcon='fal fa-trash'
                                            coor='warning'
                                            disabled={pending}
                                        />
                                    </section>
                                </Fragment>
                            }

                        </Fragment>
                }

                {confirmationModal &&
                    <ConfirmationModal
                        open
                        onClose={() => setConfirmationModal(null)}
                        {...confirmationModal}
                    />
                }
            </div>

        </section>
    );
}
