//------------------------------------------------------------------------------
function desc(a, b, orderBy)
{
    let aValue = a[orderBy] || '';
    let bValue = b[orderBy] || '';

    if(aValue.toLowerCase) aValue = aValue.toLowerCase();
    if(bValue.toLowerCase) bValue = bValue.toLowerCase();

    if (bValue < aValue)
    {
        return -1;
    }
    if (bValue > aValue)
    {
        return 1;
    }
    return 0;
}

//------------------------------------------------------------------------------
export function stableSort(array, cmp)
{
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) =>
    {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

//------------------------------------------------------------------------------
export function getSorting(order, orderBy)
{
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}