//------------------------------------------------------------------------------
import React, { useState }                          from 'react';
import urlEncodeParams                              from '../../../utils/urlEncodeParams';
import { Button, ControlAdornment, ControlCheckbox, ControlLabel, ControlNumber
}                                                   from '../../../designSystem/components';


//------------------------------------------------------------------------------
const EXPORT_FORMATS = ['obj', 'stl'];


//------------------------------------------------------------------------------
const ExportMesh = (props) =>
{
    //--------------------------------------------------------------------------
    const [ scale, setScale ]                       = useState(1);
    const [ activeMeshIndex, setActiveMeshIndex ]   = useState(false);
    const [ meshIndex, setMeshIndex ]               = useState(0);

    //--------------------------------------------------------------------------
    const downloadMesh = (fileFormat) =>
    {
        const url = `${apiGatewayURL}/asset/export/mesh/${props.assetUUID}?${
            urlEncodeParams({
                token   : apiToken,
                options : {
                    format      : fileFormat,
                    scale       : parseFloat(scale) || undefined,
                    meshIndex   : (props.assetDescription.submeshes && activeMeshIndex) ? meshIndex : undefined,
                    attachment  : `${props.assetDescription.name || 'mesh'}.${fileFormat}`
                }
            })
        }`;
        window.open(url, '_blank');
    };

    //--------------------------------------------------------------------------
    return (
        <div className='flex flex-col gap-1'>
            <ControlNumber
                value={scale}
                onChange={(e, value) => setScale(value)}
                label='Scale'
                labelDirection='horizontal'
                labelClassName='text-color-neutral-secondary'
                size='xsmall'
                contentAlign='right'
                className='flex-1'
                InputProps={{
                    endAdornment: (
                        <ControlAdornment position='end'>
                            <span className='text-xs text-color-neutral-secondary ml-1px'>x</span>
                        </ControlAdornment>
                    )
                }}
            />
            {props.assetDescription?.submeshes &&
                <ControlLabel
                    label='Mesh index only'
                    direction='horizontal'
                    size='xsmall'
                    className='items-center text-color-neutral-secondary'
                >
                    <div className='flex gap-1 w-full'>
                        <ControlCheckbox
                            value={activeMeshIndex}
                            onChange={(e, value) => setActiveMeshIndex(value)}
                            size='xsmall'
                            containerClassName='flex'
                            containerStyle={{ flex: 0 }}
                        />
                        <ControlNumber
                            value={meshIndex}
                            onChange={(e, value) => 
                            {
                                setActiveMeshIndex(true);
                                setMeshIndex(value);
                            }}
                            size='xsmall'
                            contentAlign='right'
                            className={`flex-1 ${activeMeshIndex ? '' : 'opacity-30'}`}
                        />
                    </div>
                </ControlLabel>
            }
            <div className='flex gap-1 mt-1'>
                {EXPORT_FORMATS.map(fileFormat =>
                    <Button
                        key={fileFormat}
                        value={`Export ${fileFormat.toUpperCase()}`}
                        onClick={() => downloadMesh(fileFormat)}
                        size='small'
                        format='body4'
                        className='flex-1'
                    />
                )}
            </div>
        </div>
    );
};

export default ExportMesh;