//------------------------------------------------------------------------------
import React                    from 'react';
import InfiniteLoader           from 'react-window-infinite-loader';
import loadash                  from 'lodash';

//------------------------------------------------------------------------------
import assetUtils               from '../../../utils/assetUtils';
import {
    searchAssetsAPI,
    globalSearchCountAPI }      from '../api';

import { getSourceFile }        from '../../assets/api';
//------------------------------------------------------------------------------
import ContentList              from './ContentList';
import { Loader }               from '../../../designSystem/components';
import NotificationContext      from '../../notifications/notificationContext';
import { currentFolderRegex, getAssetTypeRegex } from './SearchAdvancedPopover';

//------------------------------------------------------------------------------
const MINIMUM_BATCH_SIZE        = 10;

//------------------------------------------------------------------------------
export default class AssetView extends React.Component
{
    static contextType = NotificationContext;

    //--------------------------------------------------------------------------
    state = { groups : [], isLoading : true };

    //--------------------------------------------------------------------------
    componentDidMount()
    {
        this.search(true);
        this.subscribeToAssetEvents();
    }

    //--------------------------------------------------------------------------
    componentDidUpdate(prevProps)
    {
        if(prevProps.searchString !== this.props.searchString
            || prevProps.sourceFile !== this.props.sourceFile
            || prevProps.workspaceFilter !== this.props.workspaceFilter)
        {
            // Compute global count if previous search string was empty.
            // or if the new search string is empty.
            this.search(!prevProps.searchString || !this.props.searchString);
            return;
        }

        if(this.state.assetGroups && prevProps.assetTypeFilter !== this.props.assetTypeFilter)
        {
            this.updateActiveGroups();
            return;
        }

        if(
            prevProps.pendingFolder         !== this.props.pendingFolder
            || prevProps.tileInRenameMode   !== this.props.tileInRenameMode
            || prevProps.assetSelection     !== this.props.assetSelection
            || prevProps.updateTrigger      !== this.props.updateTrigger)
        {
            this.updateGroups();
        }

        if(this.grid &&
            (prevProps.assetDetailsUUID !== this.props.assetDetailsUUID
            || prevProps.projectSessions !== this.props.projectSessions)
        )
        {
            this.grid.forceUpdate();
        }
    }

    //--------------------------------------------------------------------------
    componentWillUnmount()
    {
        this.props.setCountList(null);
        this.unsubscribeFromAssetEvents();
    }

    //--------------------------------------------------------------------------
    computeSearchParameters()
    {
        let searchString        = this.props.searchString;
        const assetFilters      = [];

        let newString           = searchString.replace(currentFolderRegex, '');
        const isCurrentFolder   = newString !== searchString || this.props.workspaceFilter.restrictToCurrentFolder;
        if(isCurrentFolder)
        {
            searchString = newString;
        }

        for(const assetType of assetUtils.assetTypes)
        {
            const reg       = getAssetTypeRegex(assetType);
            let newString   = searchString.replace(reg, '');

            if(newString !== searchString)
            {
                assetFilters.push(
                    assetUtils.getAssetListNameFromAssetType(assetType)
                );

                searchString = newString;
            }
        }

        if(this.props.workspaceFilter.rogueDependencies)
        {
            const workspaceFilter           = this.props.requests.getWorkspaceUUIDs({...this.props.workspaceFilter, includeLibraries : false});
            const dependencyWorkspaceUUIDs  = this.props.requests.getWorkspaceUUIDs({includeLibraries : true, includeTrash : true});
            return { searchString, workspaceFilter, assetFilters, dependencyWorkspaceUUIDs };
        }

        const workspaceFilter   = (this.props.searchString && !isCurrentFolder)
                                ? this.props.requests.getWorkspaceUUIDs(this.props.workspaceFilter)
                                : this.props.workingFolder.workspaceUUID;

        return { searchString, workspaceFilter, assetFilters };
    }

    //--------------------------------------------------------------------------
    patchCountResults(countByAssetListNames, assetFilters)
    {
        delete countByAssetListNames.functions;
        delete countByAssetListNames.workspaces;

        if(assetFilters.length > 0)
        {
            const assetListNames = Object.keys(countByAssetListNames);
            for(const assetListName of assetListNames)
            {
                if(!assetFilters.includes(assetListName))
                {
                    delete countByAssetListNames[assetListName];
                }
            }
        }
    }

    //--------------------------------------------------------------------------
    async search(initGlobalCount = false)
    {
        this.props.setCountList(null);

        if(this.props.sourceFile && !this.props.searchString)
        {
            this.computeSourceFileAssets(this.props.sourceFile);
            return;
        }

        this.setState({isLoading : true});

        const folders = this.props.workingFolder.folders;
        const { searchString, workspaceFilter, assetFilters, dependencyWorkspaceUUIDs } = this.computeSearchParameters();

        const searchParamHasChanged = this.state.assetFilters &&
            (
                (this.state.assetFilters.length !== assetFilters.length || !this.state.assetFilters.every(v => assetFilters.includes(v)))
                || workspaceFilter !== this.state.workspaceFilter
            );

        let globalCountFilteredPromise = null;

        if(initGlobalCount || searchParamHasChanged)
        {
            this.globalCountPromise = globalSearchCountAPI(workspaceFilter, '', ENABLE_SOURCEFILES, dependencyWorkspaceUUIDs).then(countByAssetGroup =>
            {
                this.patchCountResults(countByAssetGroup, assetFilters);

                const values        = Object.values(countByAssetGroup);
                const countAsset    = values.reduce((a, b) => a + b, 0);

                return {
                    countFolder: folders.length,
                    countAsset,
                    countByAssetGroup
                };
            });
        }

        if (searchString)
        {
            globalCountFilteredPromise = globalSearchCountAPI(workspaceFilter, searchString, ENABLE_SOURCEFILES, dependencyWorkspaceUUIDs).then(countByGroup =>
            {
                this.patchCountResults(countByGroup, assetFilters);

                const values    = Object.values(countByGroup);
                const count     = values.reduce((a, b) => a + b, 0);

                return {
                    countByGroup,
                    count
                };
            });
        }
        else
        {
            globalCountFilteredPromise = this.globalCountPromise.then(completeListCount =>
            {
                return {
                    countByGroup : completeListCount.countByAssetGroup,
                    count        : completeListCount.countAsset
                };
            });
        }

        const assetList         = await searchAssetsAPI(
            workspaceFilter,
            searchString,
            0,
            MINIMUM_BATCH_SIZE,
            assetFilters.length > 0 ? assetFilters : null,
            ENABLE_SOURCEFILES,
            dependencyWorkspaceUUIDs
        );

        const totalCount        = await this.globalCountPromise;
        const filteredCount     = loadash.cloneDeep(await globalCountFilteredPromise);

        const folderGroup       = this.computeFolderGroup(false, searchString, workspaceFilter, assetFilters);
        const assetGroups       = this.computeAssetGroup(assetList, folderGroup.count, filteredCount.countByGroup, totalCount.countByAssetGroup);

        const groups            = this.computeGroups(folderGroup, assetGroups);
        filteredCount.count     += folderGroup.count;

        this.setState(
        {
            groups, assetGroups, folderGroup, totalCount, filteredCount,
            searchString, workspaceFilter, assetFilters, dependencyWorkspaceUUIDs,
            isLoading : false
        });

        this.props.setCountList(
        {
            countTotal              : totalCount.countAsset + totalCount.countFolder,
            filteredCount           : folderGroup.count + filteredCount.count,

            totalCountByGroup       : totalCount.countByAssetGroup,
            filteredCountByGroup    : filteredCount.countByGroup
        });
    }

    //--------------------------------------------------------------------------
    computeSourceFileAssets(sourceFile)
    {
        if(sourceFile.loading)
        {
            this.setState({isLoading : true});
            return;
        }

        const searchString      = '';
        const workspaceFilter   = null;
        const assetFilters      = [];

        const assetList         = createAssetList(assetFilters);

        for(const asset of sourceFile.assets)
        {
            pushAssetToList(asset, assetList);
        }

        const countByGroup      = {};
        let totalAssetCount     = 0;
        for(const assetListName in assetList)
        {
            const assets = assetList[assetListName]
            if(assets.length > 0)
            {
                countByGroup[assetListName] = assets.length;
                totalAssetCount += assets.length;
            }
        }

        const folderGroup       = this.computeFolderGroup(false, searchString, workspaceFilter, assetFilters);
        const assetGroups       = this.computeAssetGroup(assetList, folderGroup.count, countByGroup, countByGroup);

        const groups            = this.computeGroups(folderGroup, assetGroups);

        const totalCount        = {
            countFolder         : 0,
            countAsset          : totalAssetCount,
            countByAssetGroup   : countByGroup
        };

        const filteredCount     = {
            countByGroup : totalCount.countByAssetGroup,
            count        : totalCount.countAsset
        };

        this.setState(
        {
            groups, assetGroups, folderGroup, totalCount, filteredCount,
            searchString, workspaceFilter, assetFilters,
            isLoading : false
        });

        this.props.setCountList(
        {
            countTotal              : totalCount.countAsset + totalCount.countFolder,
            filteredCount           : folderGroup.count + filteredCount.count,

            totalCountByGroup       : totalCount.countByAssetGroup,
            filteredCountByGroup    : filteredCount.countByGroup
        });
    }

    //--------------------------------------------------------------------------
    computeFolderGroup = (isHidden, searchString, workspaceFilter, assetFilters) =>
    {
        let folders     = [];
        let totalCount  = 0;

        if((!assetFilters || assetFilters.length === 0) && !this.props.sourceFile && !this.props.workspaceFilter.rogueDependencies)
        {
            const { workingFolder, pendingFolder } = this.props;

            //------------------------------------------------------------------
            // Add Children Folders

            const compareFunc = folder => folder.name.toLowerCase().includes(searchString.toLowerCase());

            if(Array.isArray(workspaceFilter))
            {
                for(const workspaceUUID of workspaceFilter)
                {
                    const parentFolder  = this.props.workspaceMap.get(workspaceUUID);
                    const matchFolder   = parentFolder.folders.filter(compareFunc);

                    folders.splice(folders.length, 0, ...matchFolder)
                    totalCount          += parentFolder.folders.length
                }
            }
            else if(workingFolder.folders?.length > 0)
            {
                folders     = workingFolder.folders.filter(compareFunc);
                totalCount  = workingFolder.folders.length;
            }

            folders = folders.sort((a, b) => a.name.localeCompare(b.name));

            //------------------------------------------------------------------
            // Add Pending Folder (from create folder action)

            if (pendingFolder)
            {
                folders.unshift({ pendingFolder: true });
            }
        }

        return {
            assetType       : 'folder',
            assetListName   : 'folders',
            filteredList    : folders,
            count           : folders.length,
            offset          : 0,
            totalCount      : totalCount,
            isAsset         : false,
            isActive        : true,
            hidden          : isHidden
        };
    };

    //--------------------------------------------------------------------------
    computeAssetGroup = (assetList, offset, countByGroup, totalCountByGroup) =>
    {
        if (!assetList)
        {
            return [];
        }

        const assetGroup        = [];
        const assetListClean    = Object.fromEntries(Object.entries(assetList).filter(([assetListName]) => countByGroup[assetListName] > 0));
        delete assetListClean.workspaces;

        for(const [assetListName, assetList] of Object.entries(assetListClean))
        {
            const assetType = assetUtils.getAssetTypeFromAssetListName(assetListName);
            const count     = countByGroup[assetListName];
            const isActive  = this.isAssetActive(assetType);

            assetGroup.push(
            {
                assetType, assetListName, offset, count, isActive,
                totalCount      : totalCountByGroup[assetListName],
                filteredList    : assetList.map(formatAsset),
                isAsset         : true
            });

            if(isActive)
            {
                offset += count;
            }
        }

        return assetGroup;
    };

    //--------------------------------------------------------------------------
    computeGroups = (folderGroup, assetGroups) =>
    {
        const activeAssets = assetGroups.filter(g => g.isActive && g.count > 0);
        return folderGroup.count > 0 ? [folderGroup, ...activeAssets] : activeAssets;
    }

    //--------------------------------------------------------------------------
    isAssetActive(assetType)
    {
        const assetTypeFilter = this.props.assetTypeFilter;
        return assetTypeFilter.length === 0 || assetTypeFilter.includes(assetType);
    }

    //--------------------------------------------------------------------------
    updateActiveGroups()
    {
        for(const group of this.state.assetGroups)
        {
            group.isActive = this.isAssetActive(group.assetType);
        }

        this.updateGroups();
    }

    //--------------------------------------------------------------------------
    onGroupToggled = (group) =>
    {
        group.hidden = !group.hidden;
        this.updateGroups();
    }

    //--------------------------------------------------------------------------
    updateGroups = (callback) =>
    {
        const folderGroup   = this.computeFolderGroup(this.state.folderGroup.hidden, this.state.searchString, this.state.workspaceFilter, this.state.assetFilters);
        const groups        = this.computeGroups(folderGroup, this.state.assetGroups);
        const filteredCount = this.state.filteredCount;
        const activeGroups  = groups.filter(g => !g.hidden && g.isAsset);

        if(activeGroups.some(g => !filteredCount.countByGroup.hasOwnProperty(g.assetListName)))
        {
            console.warn('An active group does not have a count registered', activeGroups, filteredCount.countByGroup);
            return;
        }

        const folderCount   = (!folderGroup.hidden) ? folderGroup.count : 0;
        // Update group offsets
        let offset          = folderCount;
        for(const group of activeGroups)
        {
            group.offset = offset;
            offset += filteredCount.countByGroup[group.assetListName];
        }

        const filteredCountByGroup  = activeGroups.map(g => filteredCount.countByGroup[g.assetListName]);
        const count                 = filteredCountByGroup.reduce((a, b) => a + b, folderCount);

        this.setState(
            {
                folderGroup, groups,
                filteredCount : {...filteredCount, count }
            },
            () =>
            {
                this.infiniteLoader?.resetloadMoreItemsCache(false);

                if(callback)
                {
                    callback();
                }
                else
                {
                    // Trigger isItemLoaded on new item if needed
                    this.grid?.forceUpdate(() =>
                        this.infiniteLoader?._ensureRowsLoaded(
                            this.infiniteLoader._lastRenderedStartIndex,
                            this.infiniteLoader._lastRenderedStopIndex
                        )
                    );
                }
            }
        );
    }

    //--------------------------------------------------------------------------
    isItemLoaded = (index) =>
    {
        let initialIndex = index;
        const currentGroup = this.state.groups.find(group =>
        {
            if(group.hidden)
            {
                return false;
            }

            const count = group.count;
            if(index < group.count)
            {
                return true;
            }

            index -= count;
            return false;
        });

        if(!currentGroup)
        {
            console.warn('Cannot resolve group of asset index ' + initialIndex, index, this.state.groups);
            return true;
        }

        return index < currentGroup.filteredList.length;
    }

    //--------------------------------------------------------------------------
    loadMoreItems = async (startIndex, stopIndex) =>
    {
        // Stop index seems to be inclusive, increment it to fit with the logic.
        stopIndex += 1;

        console.log('load', startIndex, stopIndex);
        this.addSkeletons(startIndex, stopIndex);

        const firstGroup = this.state.groups.length > 0 && this.state.groups[0];
        if(firstGroup && !firstGroup.isAsset && !firstGroup.hidden)
        {
            startIndex -= firstGroup.count;
            stopIndex  -= firstGroup.count;
        }

        const assetGroups   = this.state.assetGroups.filter(group => !group.hidden && group.isActive && group.count > 0);

        //console.log('search', assetStartIndex, assetStopIndex);
        const assetList     = await searchAssetsAPI(
            this.state.workspaceFilter,
            this.state.searchString,
            startIndex,
            stopIndex - startIndex,
            assetGroups.map(g => g.assetListName),
            ENABLE_SOURCEFILES,
            this.state.dependencyWorkspaceUUIDs
        );

        for(const group of assetGroups)
        {
            const assets = assetList[group.assetListName].map(formatAsset);
            if(startIndex < group.count && assets && assets.length > 0)
            {
                const skeletonCount = Math.min(stopIndex, group.count) - startIndex;
                //console.log(`insert ${skeletonCount}/${assets.length} ${group.assetListName} at ${startIndex}`);
                if(skeletonCount !== assets.length)
                {
                    console.warn('invalid insert', assetList, assetGroups);
                }
                group.filteredList.splice(startIndex, skeletonCount, ...assets);

                if(stopIndex < group.count)
                {
                    break;
                }

                startIndex += skeletonCount;
            }

            startIndex  -= group.count;
            stopIndex   -= group.count;
        }

        if(this.grid)
        {
            this.grid.forceUpdate();
        }
    }

    //--------------------------------------------------------------------------
    addSkeletons(startIndex, stopIndex)
    {
        for(let i = 0 ; i < this.state.groups.length; ++i)
        {
            const group = this.state.groups[i];
            if(group.hidden)
            {
                continue;
            }

            if(startIndex < group.count)
            {
                const skeletonCount = Math.min(stopIndex, group.count) - startIndex;
                const skeletons = Array.from(new Array(skeletonCount))
                    .map((_, index) => ({index : index + group.offset, skeleton : true}));

                //console.log(`insert ${skeletonCount} skeleton ${group.assetListName} at ${startIndex}`);

                group.filteredList.splice(startIndex, 0, ...skeletons);

                if(stopIndex < group.count)
                {
                    break;
                }

                startIndex += skeletonCount;
            }

            startIndex  -= group.count;
            stopIndex   -= group.count;
        }
    }

    //--------------------------------------------------------------------------
    subscribeToAssetEvents()
    {
        this.context.emitter().on('asset-created',  this.onAssetCreated);
        this.context.emitter().on('asset-renamed',  this.onAssetRenamed);
        this.context.emitter().on('asset-deleted',  this.onAssetDeleted);
        this.context.emitter().on('assets-moved',   this.onAssetsMoved);

        if(ENABLE_SOURCEFILES)
        {
            this.context.emitter().on('source-file-created',    this.onSourceFileCreated);
            this.context.emitter().on('source-file-renamed',    this.onSourceFileRenamed);
            this.context.emitter().on('source-file-deleted',    this.onSourceFileDeleted);
            this.context.emitter().on('source-files-moved',     this.onSourceFilesMoved);
        }
    }

    //--------------------------------------------------------------------------
    unsubscribeFromAssetEvents()
    {
        this.context.emitter().off('asset-created', this.onAssetCreated);
        this.context.emitter().off('asset-renamed', this.onAssetRenamed);
        this.context.emitter().off('asset-deleted', this.onAssetDeleted);
        this.context.emitter().off('assets-moved',  this.onAssetsMoved);

        if(ENABLE_SOURCEFILES)
        {
            this.context.emitter().off('source-file-created',   this.onSourceFileCreated);
            this.context.emitter().off('source-file-renamed',   this.onSourceFileRenamed);
            this.context.emitter().off('source-file-deleted',   this.onSourceFileDeleted);
            this.context.emitter().off('source-files-moved',    this.onSourceFilesMoved);
        }
    }

    //--------------------------------------------------------------------------
    onAssetCreated = (...assets) =>
    {
        for(const asset of assets)
        {
            const {
                assetType, assetUUID, name, workspaceUUID, sourceUUID,
                creationTimestamp = Date.now() / 1000,
                ...assetProperties
            } = asset;

            const workspaceFilter   = this.state.workspaceFilter;

            // Skip assets referenced by a source file
            if(ENABLE_SOURCEFILES && sourceUUID && workspaceFilter !== null)
            {
                continue;
            }

            const isIncludedInView  = Array.isArray(workspaceFilter)
                                    ? workspaceFilter.includes(workspaceUUID)
                                    : (workspaceFilter === null
                                        ? this.props.sourceFile?.uuid === sourceUUID
                                        : workspaceUUID === workspaceFilter
                                    );

            if(!isIncludedInView)
            {
                continue;
            }

            const assetListName = assetUtils.getAssetListNameFromAssetType(assetType);
            if(this.state?.totalCount?.countByAssetGroup?.hasOwnProperty(assetListName))
            {
                this.state.totalCount.countByAssetGroup[assetListName] += 1;
                this.state.totalCount.countAsset += 1;
            }

            if(this.state.assetFilters?.length > 0 && !this.state.assetFilters.includes(assetListName))
            {
                continue;
            }

            let assetGroup = this.state.assetGroups.find(g => g.assetType === assetType);
            if(!assetGroup)
            {
                this.state.totalCount.countByAssetGroup[assetListName] = 1;
                this.state.totalCount.countAsset += 1;
                this.state.filteredCount.countByGroup[assetListName] = 0;

                const lastGroup = this.state.assetGroups[this.state.assetGroups.length - 1];
                const offset    = lastGroup
                                ? lastGroup.offset + lastGroup.count
                                : this.state.folderGroup.count;

                assetGroup = {
                    assetType, assetListName, offset,
                    count           : 0,
                    totalCount      : 0,
                    filteredList    : [],
                    isAsset         : true,
                    isActive        : this.isAssetActive(assetType)
                };

                this.state.assetGroups.push(assetGroup);
            }

            assetGroup.totalCount += 1;
            if(name.toLowerCase().includes(this.state.searchString.toLowerCase()))
            {
                // Insert asset at a correct location in the array, based on the sort.
                // Otherwise it could create a inconsistency in the result, and may duplicate
                // some assets in the results.

                let index = assetGroup.filteredList.findIndex(a =>
                    !a.skeleton && a.creationTimestamp < creationTimestamp
                );

                if(index === -1 && assetGroup.filteredList.length === assetGroup.count)
                {
                    index = assetGroup.filteredList.length;
                }

                if(index !== -1)
                {
                    assetGroup.filteredList.splice(
                        index,
                        0,
                        {
                            uuid                : assetUUID,
                            name                : name,
                            versions            : ["head"],
                            creationTimestamp   : creationTimestamp,
                            workspaceUUID       : workspaceUUID,
                            sourceUUID          : sourceUUID,
                            ...assetProperties
                        }
                    );
                }

                assetGroup.count += 1;

                if(this.state?.filteredCount?.countByGroup?.hasOwnProperty(assetListName))
                {
                    this.state.filteredCount.countByGroup[assetListName] += 1;
                    this.state.filteredCount.count += 1;
                }
            }
        }

        this.updateGroups(() =>
        {
            this.props.setCountList(
            {
                countTotal              : this.state.totalCount.countAsset + this.state.totalCount.countFolder,
                filteredCount           : this.state.folderGroup.count + this.state.filteredCount.count,

                totalCountByGroup       : this.state.totalCount.countByAssetGroup,
                filteredCountByGroup    : this.state.filteredCount.countByGroup
            });

            this.grid?.forceUpdate();
        });
    }

    //--------------------------------------------------------------------------
    onAssetRenamed = ({assetType, assetUUID, newName}) =>
    {
        const assetGroup = this.state.assetGroups.find(g => g.assetType === assetType);
        if(!assetGroup)
        {
            console.warn('Cannot find asset group while renaming asset', assetType);
        }

        const asset = assetGroup.filteredList.find(a => a.uuid === assetUUID);
        if(!asset)
        {
            return;
        }

        asset.name = newName;
        this.grid.forceUpdate();
    }

    //--------------------------------------------------------------------------
    onAssetDeleted = (...assets) =>
    {
        for(const { assetType, assetUUID, name, workspaceUUID, previousSourceUUID } of assets)
        {
            const workspaceFilter   = this.state.workspaceFilter;

            // Skip assets referenced by a source file
            if(ENABLE_SOURCEFILES && previousSourceUUID && workspaceFilter !== null)
            {
                continue;
            }

            const isIncludedInView  = Array.isArray(workspaceFilter)
                                    ? workspaceFilter.includes(workspaceUUID)
                                    : (workspaceFilter === null
                                        ? this.props.sourceFile?.uuid === previousSourceUUID
                                        : workspaceUUID === workspaceFilter
                                    );

            if(!isIncludedInView)
            {
                continue;
            }

            const assetListName = assetUtils.getAssetListNameFromAssetType(assetType);
            if(this.state?.totalCount?.countByAssetGroup?.hasOwnProperty(assetListName))
            {
                this.state.totalCount.countByAssetGroup[assetListName] -= 1;
                this.state.totalCount.countAsset -= 1;
            }

            if(this.state.assetFilters?.length > 0 && !this.state.assetFilters.includes(assetListName))
            {
                continue;
            }

            let assetGroup = this.state.assetGroups.find(g => g.assetType === assetType);
            if(!assetGroup)
            {
                console.warn(`Cannot find group ${assetType} during asset deletion`, assets);
                continue;
            }

            assetGroup.totalCount -= 1;
            if(name.toLowerCase().includes(this.state.searchString.toLowerCase()))
            {
                const index = assetGroup.filteredList.findIndex(a => a.uuid === assetUUID);
                if(index > -1)
                {
                    assetGroup.filteredList.splice(index, 1);
                }

                assetGroup.count -= 1;

                if(this.state?.filteredCount?.countByGroup?.hasOwnProperty(assetListName))
                {
                    this.state.filteredCount.countByGroup[assetListName] -= 1;
                    this.state.filteredCount.count -= 1;
                }
            }
        }

        this.updateGroups(() =>
        {
            this.props.setCountList(
            {
                countTotal              : this.state.totalCount.countAsset + this.state.totalCount.countFolder,
                filteredCount           : this.state.folderGroup.count + this.state.filteredCount.count,

                totalCountByGroup       : this.state.totalCount.countByAssetGroup,
                filteredCountByGroup    : this.state.filteredCount.countByGroup
            });

            this.grid.forceUpdate();
        });
    }

    //--------------------------------------------------------------------------
    onAssetsMoved = ({assets, fromWorkspaceUUID, dstWorkspaceUUID}) =>
    {
        const workspaceFilter = this.state.workspaceFilter;

        if(Array.isArray(workspaceFilter))
        {
            //TODO : Do something for assets moving out from a source file !
            const isIncludedInSource    = workspaceFilter.includes(fromWorkspaceUUID);
            const isIncludedInDest      = workspaceFilter.includes(dstWorkspaceUUID);

            if(isIncludedInDest && isIncludedInSource)
            {
                return;
            }
            else if (isIncludedInDest)
            {
                for(const asset of assets)
                {
                    asset.workspaceUUID = dstWorkspaceUUID;
                }

                this.onAssetCreated(...assets);
            }
            else if (isIncludedInSource)
            {
                for(const asset of assets)
                {
                    asset.workspaceUUID = fromWorkspaceUUID;
                }

                this.onAssetDeleted(...assets);
            }

            return;
        }

        const workspaceUUID = workspaceFilter;
        if (workspaceUUID === dstWorkspaceUUID)
        {
            for(const asset of assets)
            {
                asset.workspaceUUID = dstWorkspaceUUID;
            }

            this.onAssetCreated(...assets);
        }
        else if (workspaceUUID === fromWorkspaceUUID || (workspaceFilter === null && this.props.sourceFile))
        {
            for(const asset of assets)
            {
                asset.workspaceUUID = fromWorkspaceUUID;
            }

            this.onAssetDeleted(...assets);
        }
    }

    //--------------------------------------------------------------------------
    sourceFileTypeToAssetType(type)
    {
        return type === 'volume' ? 'scene' : type;
    }

    //--------------------------------------------------------------------------
    onSourceFileCreated = async (sourceFile) =>
    {
        const { workspaceUUID, assetType, assetUUID, sourceUUID, name, sourceType } = sourceFile;

        const { assets } = await getSourceFile(sourceUUID).catch(() => ({assets : []}));

        const asset = {
            assetType       : this.sourceFileTypeToAssetType(sourceType),
            assetUUID       : sourceUUID,
            name            : name,
            isSourceFile    : true,
            workspaceUUID   : workspaceUUID,
            assetCount      : assets.length,
            mainAsset       : {
                uuid    : assetUUID,
                label   : assetUtils.getAssetLabelFromAssetType(assetType),
                name    : name
            }
        };

        this.onAssetCreated(asset);
    }

    //--------------------------------------------------------------------------
    onSourceFileRenamed = ({workspaceUUID, sourceUUID, type, newName }) =>
    {
        const asset     = {
            assetType   : this.sourceFileTypeToAssetType(type),
            assetUUID   : sourceUUID,
            workspaceUUID,
            newName
        };

        this.onAssetRenamed(asset);
    }

    //--------------------------------------------------------------------------
    onSourceFileDeleted = ({sourceUUID, type, name, workspaceUUID}) =>
    {
        const asset     = {
            assetType   : this.sourceFileTypeToAssetType(type),
            assetUUID   : sourceUUID,
            workspaceUUID,
            name
        };

        this.onAssetDeleted(asset);
    }

    //--------------------------------------------------------------------------
    onSourceFilesMoved = ({sourceFiles, fromWorkspaceUUID, dstWorkspaceUUID}) =>
    {
        const assets = sourceFiles.map(sf =>
        {
            const { assets, uuid, type, ...sourceFile } = sf;

            sourceFile.assetUUID    = uuid;
            sourceFile.assetType    = this.sourceFileTypeToAssetType(type);
            sourceFile.mainAsset    = assets.find(a => a.isMainAsset);
            sourceFile.isSourceFile = true;

            return sourceFile;
        });

        this.onAssetsMoved({assets, fromWorkspaceUUID, dstWorkspaceUUID});
    }

    //--------------------------------------------------------------------------
    render()
    {
        if(this.state.isLoading)
        {
            return <Loader centered />;
        }

        const isPrivateContent = !Array.isArray(this.state.workspaceFilter)
            && Boolean(!this.props.workspaceMap?.get(this.state.workspaceFilter)?.libraryUUID);

        return (
            <InfiniteLoader
                ref={(node) => this.infiniteLoader = node}
                isItemLoaded={this.isItemLoaded}
                loadMoreItems={this.loadMoreItems}
                itemCount={this.state.filteredCount.count}
                minimumBatchSize={MINIMUM_BATCH_SIZE}
            >
                {({onItemsRendered, ref}) =>
                (
                    <ContentList
                        width={this.props.width}
                        height={this.props.height}
                        paddingLeft={this.props.paddingLeft}
                        paddingRight={this.props.paddingRight}
                        paddingBottom={this.props.paddingBottom}

                        groups={this.state.groups}
                        projectSessions={this.props.projectSessions}
                        highlightString={this.props.searchString}
                        displayMode={this.props.displayMode}
                        assetDetailsUUID={this.props.assetDetailsUUID}
                        assetSelection={this.props.assetSelection}

                        renameFolder={this.props.renameFolder}
                        renameAsset={this.props.renameAsset}
                        tileInRenameMode={this.props.tileInRenameMode}
                        closeRenameMode={this.props.closeRenameMode}
                        createFolder={this.props.createFolder}
                        closePendingFolder={this.props.closePendingFolder}
                        workspaceMap={this.props.workspaceMap}
                        isPrivateContent={isPrivateContent}

                        onTileClick={this.props.onTileClick}
                        onTileDoubleClick={this.props.onTileDoubleClick}
                        onDragStart={this.props.onDragStart}
                        onDrop={this.props.onDrop}
                        onDragEnterInFiller={(e) => this.props.onDragEnterInFiller(e)} // Using lambda is important here, since we cannot update
                        onDragLeaveInFiller={(e) => this.props.onDragLeaveInFiller(e)} // if onDragEnterInFiller / onDragLeaveInFiller change its reference
                        goTo={this.props.goTo}

                        openAssetEditor={this.props.openAssetEditor}
                        openAssetContextMenu={this.props.openAssetContextMenu}

                        onItemsRendered={onItemsRendered}
                        onGroupToggled={this.onGroupToggled}
                        gridRef={(node) => {this.grid = node; ref(node);}}
                    />
                )}
            </InfiniteLoader>
        );
    }
}

//------------------------------------------------------------------------------
function createAssetList(filter)
{
    if(filter.length === 0)
    {
        filter = assetUtils.assetListNames;
    }

    const assets = {};
    for (const key of filter)
    {
        assets[key] = new Array();
    }
    return assets;
}

//------------------------------------------------------------------------------
function pushAssetToList(asset, assetLists)
{
    if (assetUtils.assetLabels.indexOf(asset.label) !== -1)
    {
        const listName = assetUtils.getAssetListNameFromAssetLabel(asset.label);

        if (assetLists[listName].some(a => a.uuid === asset.uuid))
        {
            console.error(`Duplicated entry in ${listName} => ${asset.uuid}`);
            // should not happen but if it does then push the duplicate
            // to avoid messing up with the globalSearchCount
        }

        assetLists[listName].push(asset);
    }
}

//------------------------------------------------------------------------------
function formatAsset(asset)
{
    asset.hasThumbnail = Boolean(asset.hasThumbnail);
    asset.isSourceFile = asset.label === 'SourceFile';
    return asset;
}
