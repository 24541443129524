import React, { Fragment }                          from 'react';
import { useTranslation }                           from 'react-i18next';
import moment                                       from 'moment';
import momentDuration                               from 'moment-duration-format';
import { plural as p }                              from '../../../designSystem/utils';
import { Avatar, Button, Divider, Icon, ProgressBar, Text
}                                                   from '../../../designSystem/components';
import TileUpload                                   from '../../../components/TileUpload';

momentDuration(moment);

//------------------------------------------------------------------------------
function ConversionsTrack(props)
{
    //------------------------------------------------------------------------------
    const { t }                             = useTranslation();
    const { tasks, displayFolder = true }   = props;

    //------------------------------------------------------------------------------
    return (
        <div className='flex flex-col'>
            {tasks.map((task, index) =>
                <article key={index} className={`border-t border-color-tertiary py-4 ${props.articleClassName ? props.articleClassName : ''}`}>
                    <header className='flex justify-between gap-2'>
                        <Text format='body3'>
                            <b>{task.items.length} file{p(task.items.length)}</b> {task.progress === 1 ? 'converted' : 'in conversion'}
                        </Text>
                        <div className='flex items-center gap-1'>
                            <div className='flex items-center gap-1'>
                                <Avatar
                                    username={task.username}
                                    tooltipTitle={task.username}
                                    uuid={task.userUUID}
                                    size='xsmall'
                                />
                                <Text format='body3'>
                                    {task.username}
                                </Text>
                            </div>
                            <time className='text-xs text-color-neutral-secondary whitespace-nowrap opacity-80'>
                                {moment(task.creationTimestamp * 1000).fromNow()}
                            </time>
                        </div>
                    </header>
                    {task.error &&
                        <Text format='body3' color='warning'>
                            {task.name}: {task.error.message}
                        </Text>
                    }
                    {task.items?.map(file =>
                    {
                        if(file.message)
                        {
                            return (
                                <Text key={index} color={file.message.hierarchy} format='body4' className='mt-1px mb-2'>
                                    {file.message.title}
                                </Text>
                            )
                        }
                    })}
                    <ul className='flex flex-col gap-1px mt-1'>
                        {task.items?.map(file =>
                            <li key={file.uuid}>
                                <TileUpload
                                    file={file}
                                    onClick={() => props.goTo({
                                        workspaceUUID: task.workspaceUUID,
                                        assetDetails: { uuid: file.uuid, assetType: file.assetType }
                                    })}
                                />
                            </li>
                        )}
                    </ul>
                </article>
            )}
        </div>
    );
}

export default ConversionsTrack;