import React                                        from 'react';
import { useTranslation }                           from 'react-i18next';
import { Badge, Button, Text
}                                                   from '../../../designSystem/components';


//------------------------------------------------------------------------------
const AssetSelectionBar = (props) =>
{
    //------------------------------------------------------------------------------
    const { t }                = useTranslation();
    const { assetSelection }    = props;
    
    //------------------------------------------------------------------------------
    return (
        <div className='fixed bottom-0 flex justify-between items-center w-full px-5 py-4 border-t border-color-tertiary bg-color-overground z-30'>
            <Text format='body3'>
                <Badge value={assetSelection.length} className='mr-1'/> Selected
            </Text>
            <Button
                value='Delete'
                onClick={() => 
                    props.deleteAssets(
                        assetSelection,
                        { callback: props.clearAssetSelection }
                    )
                }
                size='small'
            />
            <Button
                value='Clear selection'
                onClick={props.clearAssetSelection}
                size='small'
            />
        </div>
    );
};

export default AssetSelectionBar;