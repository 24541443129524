import React                from 'react';
import { useTranslation }   from 'react-i18next';
import { ControlSelect }    from '../../../../designSystem/components';


//------------------------------------------------------------------------------
const ClusterSelect = (props) =>
{
    //------------------------------------------------------------------------------
    const { t } = useTranslation();
    const {
        preferredClusterUUID,
        setPreferredCluster,
        clusters,
        updatePreferredClusterAPI,
        removePreferredClusterAPI
    } = props;

    //------------------------------------------------------------------------------
    const changeCluster = async (event) =>
    {
        event.preventDefault();
        const clusterUUID = event.target.value;
    
        if (clusterUUID === 'remove') 
        {
            setPreferredCluster(null);
            await removePreferredClusterAPI();
        }
        else 
        {
            setPreferredCluster(clusterUUID);
            await updatePreferredClusterAPI(clusterUUID);
        }
    };
    
    //------------------------------------------------------------------------------
    return (
        <ControlSelect 
            className='select'
            label='Your cluster - hint'
            value={preferredClusterUUID}
            onChange={changeCluster}
            native
            size='small'
        >
            {clusters?.map( cluster => (
                <option key={cluster.uuid} value={cluster.uuid}>
                    {cluster.uuid}
                </option>
            ))}
            <option value='remove'>
                {t('account:No preferred cluster')}
            </option>
        </ControlSelect>
    );
};

export default ClusterSelect;
