//------------------------------------------------------------------------------
import React, { useContext }            from 'react';
import { ThemeContext }                 from 'styled-components';

//------------------------------------------------------------------------------
import { Button, Icon, Text, ControlCheckbox
}                                       from '../../../../designSystem/components';
import assetUtils                       from '../../../../utils/assetUtils';

//------------------------------------------------------------------------------
const assetTypes = assetUtils.assetTypes.filter(assetType => assetType !== 'function');

//------------------------------------------------------------------------------
export function getAssetTypeRegex(assetType)
{
    return new RegExp(`:${assetType}(?: |$)`, 'ig');
}

//------------------------------------------------------------------------------
export const currentFolderRegex = getAssetTypeRegex('current');

//------------------------------------------------------------------------------
function SearchAdvancedPopover(props)
{
    const {
        includeLibraries        = false,
        includeTrash            = false,
        restrictToCurrentFolder = false,
        rogueDependencies       = false,
    } = props.workspaceFilter;

    //------------------------------------------------------------------------------
    // const { anchorEl }         = props;
    const theme                 = useContext(ThemeContext);

    //------------------------------------------------------------------------------
    const assetTypeFilter       = assetTypes.filter(
        assetType => getAssetTypeRegex(assetType).test(props.searchString)
    );

    //------------------------------------------------------------------------------
    const toggleAssetType = (assetType) =>
    {
        if (assetTypeFilter.includes(assetType))
        {
            props.setSearchString(props.searchString.replace(getAssetTypeRegex(assetType), ''));
        }
        else
        {
            const assetLabel = assetUtils.getAssetLabelFromAssetType(assetType);
            props.setSearchString(props.searchString + `:${assetLabel} `);
        }
    }

    //------------------------------------------------------------------------------
    return (
        <div style={{ position: 'absolute', zIndex: '20', width: props.anchorEl.clientWidth }}>
            <section className='p-3 bg-color-underground border border-color-secondary rounded'>
                <div className='flex flex-wrap mb-2'>
                    <ControlCheckbox
                        id='restrict-to-current-folder'
                        insideLabel='Restrict to current folder'
                        value={restrictToCurrentFolder}
                        containerStyle={{width : 'auto'}}
                        disabled={rogueDependencies}
                        onChange={(e, checked) => props.setWorkspaceFilter({ ...props.workspaceFilter, restrictToCurrentFolder: checked, rogueDependencies: false })}
                    />
                    <ControlCheckbox
                        id='include-libraries'
                        insideLabel='Include libraries'
                        value={includeLibraries}
                        containerStyle={{width : 'auto'}}
                        disabled={restrictToCurrentFolder || rogueDependencies}
                        onChange={(e, checked) => props.setWorkspaceFilter({ ...props.workspaceFilter, includeLibraries: checked })}
                    />
                    <ControlCheckbox
                        id='include-trash'
                        insideLabel='Include trash'
                        containerStyle={{width : 'auto'}}
                        value={includeTrash}
                        disabled={restrictToCurrentFolder}
                        onChange={(e, checked) => props.setWorkspaceFilter({ ...props.workspaceFilter, includeTrash: checked })}
                    />
                    <ControlCheckbox
                        id='rogue-dependencies'
                        insideLabel='Rogue dependencies'
                        containerStyle={{width : 'auto'}}
                        value={rogueDependencies}
                        disabled={restrictToCurrentFolder}
                        onChange={(e, checked) => props.setWorkspaceFilter({ ...props.workspaceFilter, rogueDependencies: checked })}
                    />
                </div>

                <div className='flex flex-wrap'>
                    {assetTypes.map((assetType, index) =>
                    {
                        const assetLabel = assetUtils.getAssetLabelFromAssetType(assetType);
                        const assetColor = theme.assets[assetType]?.color;

                        return (
                            <Button
                                onClick={() => toggleAssetType(assetType)}
                                key={index}
                                style={assetTypeFilter.includes(assetType) ? {
                                    backgroundColor : assetColor,
                                    color           : 'var(--color-bg-ground)'
                                } : {
                                    color           : assetColor
                                }}
                                color='tertiary'
                                noPadding
                                valueClassName='flex items-center gap-1 pl-2 px-2 border border-color-secondary'
                            >
                                <Icon
                                    name='asset'
                                    assetType={assetType}
                                    size='xsmall'
                                    style={assetTypeFilter.includes(assetType) ? { color: 'inherit' } : {}}
                                />
                                <Text className='text-xs tracking-wider'>
                                    {assetLabel}
                                </Text>
                            </Button>
                        )
                    })}
                </div>
            </section>
        </div>
    );
}

export default SearchAdvancedPopover;
