import styled, { css } from 'styled-components';

export const SessionTileStyled = styled.div`
    position: relative;

    .button-stop {
        position: absolute;
        top: .2rem;
        right: 0;
        z-index: 2;
    }
`;

export const LinkStyled = styled.a`
    position: relative;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    min-height: 2rem;
    color: inherit;
    border: 1px solid hsla(var(--color-border-secondary-hsl), .5);
    border-radius: var(--border-radius-xs);
    overflow: hidden;
    pointer-events: none;
    cursor: default;

    ${props => props.size === 'small' && css`
        min-height: auto;
        border-radius: 1rem;

        .container {
            padding: var(--spacing-1);
        }
    `}

    ${props => props.as === 'a' && css`
        background-color: hsla(var(--color-bg-ground-hsl), .9)};
        transition: background-color .35s, border-color .35s;
        pointer-events: auto;
        cursor: pointer;

        &:hover,
        &:focus {
            border-color: var(--color-accent);
            background-color: hsla(var(--color-bg-ground-hsl), 1)};

            ${props => props.reversed && css`
                background-color: hsla(var(--color-bg-ground-hsl), .5)};
            `}
        }
    `}

    .container {
        .users {
            justify-content: flex-start;
            flex: 1;
        }

        .availability {
            display: block;
            flex: 1 100%;
            padding-top: var(--spacing-4);
            font-size: .7rem;
            line-height: 1rem;
            color: var(--color-accent);
        }

        .flag {
            width: .75rem !important;
            height: auto !important;
            border-radius: 2px;
            overflow: hidden;
        }
    }
`;
