import React, { useEffect }             from 'react';
import { Loader }                       from '../../../designSystem/components';
import CallAPI                          from '../../../utils/api';


//------------------------------------------------------------------------------
function SignupConfirmEmailpage(props)
{
    //------------------------------------------------------------------------------
    useEffect(() =>
    {
        async function confirmEmail ()
        {
            const urlParams     = new URLSearchParams(window.location.search);
            const confirmToken  = urlParams.get('token');
            if (confirmToken)
            {
                try
                {
                    await CallAPI('/authentication/confirmEmail', 'POST', { confirmToken });
                    props.history.push('/signin');
                }
                catch(error)
                {
                    console.error(error);
                    props.history.push('/');
                }
            }
        }
        confirmEmail();
    }, [props.history]);

    //------------------------------------------------------------------------------
    return (
        <div>
            <Loader />
        </div>
    );
}

export default SignupConfirmEmailpage;
