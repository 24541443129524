import CallAPI from '../../utils/api';

//------------------------------------------------------------------------------
export async function getAllAvailableClustersAPI()
{
    return await CallAPI('/session/getAllAvailableClusters', 'GET');
}

//------------------------------------------------------------------------------
export async function updatePreferredClusterAPI(clusterUUID)
{
    return await CallAPI('/user/updatePreferredCluster', 'POST', { clusterUUID });
}

//------------------------------------------------------------------------------
export async function removePreferredClusterAPI()
{
    return await CallAPI('/user/removePreferredCluster', 'POST');
}

//------------------------------------------------------------------------------
export async function updatePreferredRendererVersionAPI(rendererVersion)
{
    return await CallAPI('/user/updatePreferredRendererVersion', 'POST', { rendererVersion });
}

//------------------------------------------------------------------------------
export async function removePreferredRendererVersionAPI()
{
    return await CallAPI('/user/removePreferredRendererVersion', 'POST');
}
