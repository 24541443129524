//------------------------------------------------------------------------------
import React                        from "react";
import elementResizeDetectorMaker   from 'element-resize-detector';

//------------------------------------------------------------------------------
import {
    TimelineElement,
    TimelineEvent,
    EventDetails
} from "./style.js";

//------------------------------------------------------------------------------
export default class Timeline extends React.Component
{
    //--------------------------------------------------------------------------
    state = {
        width : 0,
        left  : 0
    };

    //--------------------------------------------------------------------------
    componentDidMount()
    {
        this.elementResizeDetector = elementResizeDetectorMaker(
        {
            strategy: 'object',
            callOnAdd: true
        });

        this.elementResizeDetector.listenTo(this.container, () =>
        {
            const rect = this.container.getBoundingClientRect();
            this.setState(
            {
                width  : rect.width,
                left   : rect.left
            });
        });
    }

    //--------------------------------------------------------------------------
    componentWillUnmount()
    {
        this.elementResizeDetector.uninstall(this.container);
    }

    //--------------------------------------------------------------------------
    // Unused
    onMouseMove = (e) =>
    {
        const x = this.getPositionFromEvent(e);
        console.log(x);
    }

    //--------------------------------------------------------------------------
    getPositionFromEvent = (e) =>
    {
        return Math.max(
            0,
            Math.min(
                1.0,
                (e.clientX - this.state.left) / this.state.width
            )
        );
    }

    //--------------------------------------------------------------------------
    onDoubleClick = (e) =>
    {
        if(e.target !== this.container)
        {
            return;
        }

        e.stopPropagation();
        const x = this.getPositionFromEvent(e);
        this.props.onElementCreated(x);
    }

    //--------------------------------------------------------------------------
    onElementMouseDown = (e, element, index) =>
    {
        e.preventDefault();
        this.props.onElementClicked(e, element, index);

        let hasMoved = false;
        const mouseMove = (e) =>
        {
            e.preventDefault();
            e.stopPropagation();

            const x = this.getPositionFromEvent(e);
            this.props.onElementMoved(x, false, element, index);
            hasMoved = true;
        };

        const mouseUp = (e) =>
        {
            e.stopPropagation();
            window.removeEventListener('mousemove', mouseMove);
            window.removeEventListener('mouseup', mouseUp);

            if(hasMoved)
            {
                const x = this.getPositionFromEvent(e);
                this.props.onElementMoved(x, true, element, index);
            }
        };

        window.addEventListener('mousemove', mouseMove)
        window.addEventListener('mouseup', mouseUp);
    }

    //--------------------------------------------------------------------------
    render()
    {
        return (
            <div className={this.props.className}>
                <div
                    className="py-4"
                    ref={e => this.container = e}
                    onDoubleClick={this.onDoubleClick}
                >
                    <TimelineElement>
                    {
                        this.props.elements.map((element, index) =>
                        (
                            <TimelineEvent
                                key={index}
                                shape={element.shape}
                                id={'element-' + index}
                                onMouseDown={(e) => e.target.id === 'element-'+index && this.onElementMouseDown(e, element, index)}
                                style={
                                {
                                    position        : 'absolute',
                                    transform       : 'translate(-50%, -50%)',
                                    left            : element.position * this.state.width,
                                    backgroundColor : element.color
                                }}
                            >
                                {
                                    element.children &&
                                    <EventDetails>
                                        {element.children}
                                    </EventDetails>
                                }
                            </TimelineEvent>
                        ))
                    }
                    </TimelineElement>
                </div>
            </div>
        );
    }
}
