import styled, { css, keyframes } from 'styled-components';


const appear = keyframes`
    to {  
        transform: translateX(0);
    }
`;

export const AssetDetailsStyled = styled.aside`
    transform: translateX(100%);
    animation: ${appear} .25s forwards;
    transition: width .25s;

    ${props => props.isAssetAdvanced && css`
        width: 94vw !important;
        max-width: none !important;
    `}

    .layout {
        display: grid;
        grid-template-rows: auto 1fr;
        height: calc(100vh - var(--spacing-8));
    }

    .header {
        &.imageOverlay {
            position: relative;

            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(0deg, var(--color-bg-underground) 0%, transparent 60%);
            }
        }
    }
`;


export const OverlayStyled = styled.div`
    visibility: hidden;
    opacity: 0;
    transition: visibility .25s, opacity .25s;

    ${props => props.isAssetAdvanced && css`
        visibility: visible;
        opacity: 1;
    `}
`;
