//------------------------------------------------------------------------------
import CallAPI      from '../../../utils/api';
import moduleAPI    from '../../assets/AssetDetailsAdvanced/ModuleDeployment/moduleAPI';

//------------------------------------------------------------------------------
const gitProviders = [
    {
        id          : 'gitlab-oauth',
        api         : 'gitlab'
    },
    {
        id          : 'github-oauth',
        api         : 'github'
    }
];

//------------------------------------------------------------------------------
export async function buildApp(projectUUID, applicationUUID, provider)
{
    provider = gitProviders.find(p => p.id === provider);
    const accessToken = await moduleAPI.getAccessToken(provider.api);
    return await CallAPI('/project/buildApp', 'POST', { projectUUID, applicationUUID, accessToken });
}

//------------------------------------------------------------------------------
export async function buildApplicationTemplateApps(projectUUID, applicationTemplateUUID, provider)
{
    provider = gitProviders.find(p => p.id === provider);
    const accessToken = await moduleAPI.getAccessToken(provider.api);
    return await CallAPI('/project/buildApplicationTemplateApps', 'POST', { projectUUID, applicationTemplateUUID, accessToken });
}

//------------------------------------------------------------------------------
export function getAppPublicURL(application)
{
    if(application.pathName)
    {
        return `/apps/${application.pathName}/`;
    }

    return `/apps/${application.uuid}/`;
}
