//------------------------------------------------------------------------------
import CallAPI      from '../../utils/api';

//------------------------------------------------------------------------------
export function listPublicApps()
{
    return CallAPI('/project/listPublicApps', 'GET');
}

//------------------------------------------------------------------------------
export function listLastOpenedScene(count = 7)
{
    return CallAPI(`/scene/lastOpen?count=${count}`, 'GET');
}
