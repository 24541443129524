//------------------------------------------------------------------------------
import React, { useEffect, useState, Fragment } from 'react';

//------------------------------------------------------------------------------
import { ControlTextToggle, Text }              from '../../../../designSystem/components';

//------------------------------------------------------------------------------
export default function NameSection(props)
{
    //--------------------------------------------------------------------------
    const { application }                           = props;

    //--------------------------------------------------------------------------
    const [ applicationInfo, setApplicationInfo ]   = useState({});

    //--------------------------------------------------------------------------
    useEffect(() =>
    {
        setApplicationInfo(
            {
                name        : application.name,
                description : application.description
            });
    }, [application.name, application.description]);

    //--------------------------------------------------------------------------
    const onSubmitName = async (event, name) =>
    {
        setApplicationInfo(
            {
                ...applicationInfo,
                name
            });

        props.onChange(applicationInfo);
    };

    //--------------------------------------------------------------------------
    const onSubmitDescription = async (event, description) =>
    {
        setApplicationInfo(
            {
                ...applicationInfo,
                description
            });

        props.onChange(applicationInfo);
    };

    //--------------------------------------------------------------------------
    return (
        <section className='p-4 w-full bg-color-underground border border-color-tertiary rounded'>

            {applicationInfo &&
                <div className='flex flex-col gap-3'>
                    {
                        props.canEdit ?
                            <Fragment>
                                <ControlTextToggle
                                    value={applicationInfo.name}
                                    onChange={(e, name) => setApplicationInfo({ ...applicationInfo, name })}
                                    onSubmit={onSubmitName}
                                    placeholder={'* Application name'}
                                    format='title2'
                                    fullWidth
                                />

                                <ControlTextToggle
                                    value={applicationInfo.description}
                                    onChange={(e, description) => setApplicationInfo({ ...applicationInfo, description })}
                                    onSubmit={onSubmitDescription}
                                    placeholder='Description'
                                    format='body3'
                                    multiline={true}
                                    fullWidth
                                />
                            </Fragment>
                            :
                            <Fragment>
                                <Text format='title2' fullWidth> {applicationInfo.name} </Text>
                                <Text format='body3' fullWidth multiline={true}> {applicationInfo.description} </Text>
                            </Fragment>
                    }
                </div>
            }

        </section>
    );
}
