import React                                from 'react';
import { useTranslation }                   from 'react-i18next';
import { 
    Text
}                                           from '../../../designSystem/components';


//------------------------------------------------------------------------------
function ProjectTopics(props) 
{
    //------------------------------------------------------------------------------
    const { t }                                     = useTranslation();

    //------------------------------------------------------------------------------
    return (
        <section
            className={`p-6 ${props.className ? props.className : ''}`}
            style={props.style}
        >
            <header className='flex justify-between'>
                <Text format='title1'>
                    Project Topics
                </Text>
            </header>
        </section>
    );
}

//------------------------------------------------------------------------------
export default ProjectTopics;
