import assetUtils from '../../service-config/commonProperties/assetUtils';

//------------------------------------------------------------------------------
assetUtils.creatableAssets = ['scene', 'material', 'cubemap', 'volumeMaterial', 'shader', 'script', 'algorithm', 'eventMap', 'actionMap', 'renderGraph', 'module', 'animationGraph', 'animationSequence'];
assetUtils.canBeCreated = (assetType) =>
{
    return assetUtils.creatableAssets.includes(assetType);
};

//------------------------------------------------------------------------------
assetUtils.assetsWithoutDependencies = [ 'mesh', 'texture', 'texture3d', 'skeleton', 'volumeMaterial', 'eventMap', 'actionMap', 'sound' ];
assetUtils.couldHaveDependencies = (assetType) =>
{
    return !assetUtils.assetsWithoutDependencies.includes(assetType);
};

//------------------------------------------------------------------------------
export default assetUtils;
