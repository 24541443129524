//------------------------------------------------------------------------------
import CallAPI      from '../../utils/api';
import assetUtils   from '../../utils/assetUtils';

//------------------------------------------------------------------------------
export async function createProjectAPI(name, description = '')
{
    const body = { name, description };
    return await CallAPI('/project/create', 'POST', body);
}

//------------------------------------------------------------------------------
export async function listProjectsAPI()
{
    return await CallAPI('/project/list', 'GET');
}

//------------------------------------------------------------------------------
export async function listUnusedWorkspacesAPI()
{
    return await CallAPI('/project/listUnusedWorkspaces', 'GET');
}

//------------------------------------------------------------------------------
export async function deleteProjectAPI(projectUUID)
{
    return await CallAPI('project/delete', 'DELETE', { projectUUID: projectUUID });
}

//------------------------------------------------------------------------------
export async function globalSearchCountAPI(workspaceFilter, searchString = '', searchSourceFiles, dependencyWorkspaceUUIDs)
{
    const body = { searchString, assetListNames : assetUtils.assetListNames, dependencyWorkspaceUUIDs };

    if(Array.isArray(workspaceFilter))
    {
        body.workspaceUUIDs = workspaceFilter;
    }
    else
    {
        body.workspaceUUID = workspaceFilter;
    }

    return await CallAPI(searchSourceFiles && !dependencyWorkspaceUUIDs ? '/workspace/globalSearchCountSourceFile' : '/workspace/globalSearchCount', 'POST', body);
}

//------------------------------------------------------------------------------
export async function searchAssetsAPI(workspaceFilter, searchString, offset, count, assetListNames, searchSourceFiles, dependencyWorkspaceUUIDs)
{
    const body 	= { searchString, offset, count, assetListNames : assetListNames || assetUtils.assetListNames, dependencyWorkspaceUUIDs };

    if(Array.isArray(workspaceFilter))
    {
        body.workspaceUUIDs = workspaceFilter;
    }
    else
    {
        body.workspaceUUID = workspaceFilter;
    }

    return await CallAPI(searchSourceFiles && !dependencyWorkspaceUUIDs ? '/asset/searchSourceFile' :'/asset/search', 'POST', body);
}

//------------------------------------------------------------------------------
export async function setMainAssetAPI(assetType, assetUUID, projectUUID)
{
    return await CallAPI('/project/setMainAsset', 'POST', { assetType, assetUUID, projectUUID });
}

//------------------------------------------------------------------------------
export async function getAssetProjectAPI(assetType, assetUUID)
{
    const url = `/project/getAssetProject?assetType=${assetType}&assetUUID=${assetUUID}`;
    return await CallAPI(url, 'GET');
}

//------------------------------------------------------------------------------
// USE FOR FOLDERS

export async function shareGroupAPI(workspaceUUID)
{
    const req = '?workspaceUUID=' + workspaceUUID;
    return await CallAPI('/workspace/shareGroup' + req, 'GET');
}

//------------------------------------------------------------------------------
export async function publishAssetWorkspaces(assetListName, workspaceUUIDs)
{
    const label = assetUtils.getAssetLabelFromAssetListName(assetListName);
    return await CallAPI(`/workspace/publish${label}Workspaces`, 'POST', {workspaceUUIDs});
}

//------------------------------------------------------------------------------
export async function isWorkspaceEmpty(workspaceUUIDs)
{
    return await CallAPI('/workspace/isEmpty', 'POST', { workspaceUUIDs });
}

//------------------------------------------------------------------------------
// LEGACY

//------------------------------------------------------------------------------
export async function listWorkspacesAPI()
{
    return await CallAPI('/workspace/list', 'GET');
}

//------------------------------------------------------------------------------
export async function listScenesAPI(workspaceUUID, offset, count)
{
    const url   = `/workspace/listScenes?workspaceUUID=${workspaceUUID}&offset=${offset}&count=${count}&orderBy=creation&orderDirection=DESC`;
    return await CallAPI(url, 'GET');
}

//------------------------------------------------------------------------------
export async function toggleFavouriteAPI(workspaceUUID, isFavourite)
{
    return await CallAPI('/workspace/setAsFavourite', 'POST', { workspaceUUID, isFavourite });
}

//------------------------------------------------------------------------------
export async function renameWorkspaceAPI(workspaceUUID, newName)
{
    await CallAPI('/workspace/rename', 'POST', { workspaceUUID, newName });
}

//------------------------------------------------------------------------------
export async function addWorkspaceAPI(workspaceName)
{
    return await CallAPI('/workspace/create', 'POST', { workspaceName });
}

//------------------------------------------------------------------------------
export async function deleteWorkspaceAPI(workspaceUUID)
{
    const req = '?workspaceUUID=' + workspaceUUID;
    return await CallAPI('/workspace/delete' + req, 'DELETE');
}

//------------------------------------------------------------------------------
export async function revokeAccessAPI(workspaceUUID, userUUID)
{
    return await CallAPI('/workspace/revokeAccess', 'POST', { workspaceUUID, userUUID });
}

//------------------------------------------------------------------------------
export async function cleanUpAPI(workspaceUUID)
{
    const req = '?workspaceUUID=' + workspaceUUID;
    return await CallAPI('/workspace/cleanUp' + req, 'DELETE');
}

//------------------------------------------------------------------------------
export async function getUsersAPI(uuids)
{
    return await CallAPI('/user/getUsers', 'POST', { uuids});
}
