import React, { useEffect, useState }                       from 'react';
import Moment                                               from 'react-moment';
import { useSnackbar }                                      from 'notistack';

import { ButtonIcon, Button, ControlTextToggle, Display, Icon, Loader, Text
}                                                           from '../../../designSystem/components';
import { shareGroupAPI }                                    from '../../projects/api';
import copyToClipboard                                      from '../../../utils/copyToClipboard';


//------------------------------------------------------------------------------
function FolderDetails(props)
{
    //------------------------------------------------------------------------------
    const { canEdit, permissions, user, folder }  = props;

    //------------------------------------------------------------------------------
    const { enqueueSnackbar } = useSnackbar();

    //------------------------------------------------------------------------------
    useEffect(() =>
    {
        setNewName(folder.name);

        const shareGroup = async () =>
        {
            const _shareGroup = await shareGroupAPI(folder.workspaceUUID);
            setShareGroup(_shareGroup);
        };
        shareGroup();

    }, [folder]);


    //------------------------------------------------------------------------------
    // Rename Asset

    const [shareGroup, setShareGroup] = useState(null);

    //------------------------------------------------------------------------------
    // Rename Asset

    const canRename = true;
    const [newName, setNewName] = useState(null);

    //------------------------------------------------------------------------------
    const onChangeName = (name) =>
    {
        if (name !== '')
        {
            setNewName(name);
        }
    };

    //------------------------------------------------------------------------------
    const onSubmitName = async (name) =>
    {
        if (name !== '' && name !== folder.name)
        {
            props.renameFolder({ folderUUID: folder.uuid, name });
        }
    };

    //------------------------------------------------------------------------------
    const generateFolderPackageURL = () =>
    {
        const options = {
            attachment : `${folder.name}.zip`,
            packagePublicDependencies : props.isProjectPublic
        };
        return `${apiGatewayURL}/workspace/package/${folder.workspaceUUID}?options=${encodeURI(JSON.stringify(options))}&token=${apiToken}`;
    };

    //------------------------------------------------------------------------------
    const packageFolder = () =>
    {
        window.open(generateFolderPackageURL(), '_blank');
    };

    //------------------------------------------------------------------------------
    const copyUUID = (label, uuid) =>
    {
        copyToClipboard(uuid);
        enqueueSnackbar('👍 ' + label + ' copied');
    };

    //------------------------------------------------------------------------------
    return (
        <section className='w-full'>
            <header className='header px-5 pt-4 pb-3 border-b border-color-tertiary'>
                <div className='flex items-start gap-3 w-full z-10'>
                    <div className='flex-1 flex flex-col items-start justify-end w-full h-full'>
                        <div className='flex items-center gap-1 mb-1'>
                            <Icon
                                className='fal fa-folder'
                                size='xsmall'
                            />
                            <Text format='body4'>
                                Folder
                            </Text>
                        </div>
                        {canRename ?
                            <ControlTextToggle
                                value={newName || folder.name}
                                onChange={(_, value) => onChangeName(value)}
                                onSubmit={(_, value) => onSubmitName(value)}
                                placeholder='Folder Name'
                                fullWidth
                            />
                            :
                            <Text format='title2' style={{ wordBreak: 'break-word' }}>
                                {folder.name}
                            </Text>
                        }
                    </div>
                    <div className='relative flex flex-col items-end'>
                        <ButtonIcon
                            onClick={event =>
                            {
                                event.stopPropagation(); props.onClose();
                            }}
                            className='fal fa-times'
                            size='small'
                            color='secondary'
                        />
                    </div>
                </div>
            </header>


            <div>
                <section className='w-full py-3 px-5 border-b border-color-tertiary'>
                    <Button
                        value='Open'
                        onClick={() => props.goTo({ folderUUID: folder.uuid })}
                        startIcon='fal fa-folder'
                        size='small'
                        color='secondary'
                    />
                </section>

                {/* Creation and latest operations */}
                <section className='w-full py-3 px-5 border-b border-color-tertiary'>
                    <div className='flex justify-between gap-2'>
                        {folder.creationTimestamp &&
                            <Display
                                label='Created'
                                value={<Moment unix fromNow>{folder.creationTimestamp}</Moment>}
                                vertical
                                avatar={folder.createdBy && {
                                    tooltipTitle: `By ${folder.createdBy?.username}`,
                                    uuid: folder.createdBy?.userUUID,
                                    username: folder.createdBy?.username,
                                    noTitle: true
                                }}
                                className='w-1/3'
                            />
                        }
                    </div>
                </section>

                {/* Folder UUID and Workspace UUID section*/}
                <section className='w-full py-3 px-5 border-b border-color-tertiary'>
                    <div className='flex flex-col justify-between gap-1'>
                        <Button
                            noPadding
                            contentAlign='left'
                            color='tertiary'
                            valueClassName='w-full'
                            size='xsmall'
                            onClick={() => copyUUID('Folder UUID', folder.uuid)}
                        >
                            <Display label='Folder UUID' value={folder.uuid} className='w-full'/>
                        </Button>
                        <Button
                            noPadding
                            contentAlign='left'
                            color='tertiary'
                            valueClassName='w-full'
                            size='xsmall'
                            onClick={() => copyUUID('Workspace UUID', folder.workspaceUUID)}
                        >
                            <Display label='Workspace UUID' value={folder.workspaceUUID} className='w-full'/>
                        </Button>
                    </div>
                </section>

            </div>

        </section>
    );
}

export default FolderDetails;
