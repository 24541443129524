import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useTranslation }                                   from 'react-i18next';
import { useSnackbar }                                      from 'notistack';

import { ContextMenu, Loader, Text }                        from '../../../designSystem/components';
import { plural }                                           from '../../../designSystem/utils';
import MainLayout                                           from '../../../components/Layout';
import { isEditingText }                                    from '../../../utils/textTools';
import partition                                            from '../../../utils/partition';
import Can                                                  from '../../../utils/permissions';
import NotificationContext                                  from '../../notifications/notificationContext';
import AssetWrapper                                         from '../../assets/AssetWrapper';
import AssetDetails                                         from '../../assets/AssetDetails';
import SessionsContext                                      from '../../session/sessionsContext';
import ConfirmationModal                                    from '../../../components/ConfirmationModal';
import FolderDetails                                        from '../../assets/FolderDetails';
import AssetSelectionBar                                    from '../../assets/AssetSelectionBar';
import { deleteAssetAPI, deleteSourceFilesAPI, getDepAPI, moveAssetAPI, moveSourceFilesAPI }  from '../../assets/api';
import { unlinkLibraryFromProjectAPI }                      from '../../libraries/api';
import { isWorkspaceEmpty }                                 from '../api';
import ProjectNav                                           from '../ProjectNav';
import ProjectContent                                       from '../ProjectContent';
import ProjectSettings                                      from '../ProjectSettings';
import ProjectTopics                                        from '../ProjectTopics';
import Applications                                         from '../Applications';
import ProjectConversions                                   from '../ProjectConversions';
import ProjectDetails                                       from '../ProjectDetails';
import RenameAssetPopover                                   from '../ProjectContent/RenameAssetPopover';
import { AssetDetailsStyled, OverlayStyled }                from './style';
import { mergeUserStateOfProject }                          from '../functions';
import { currentFolderRegex }                               from '../ProjectContent/SearchAdvancedPopover';
import assetUtils                                           from '../../../utils/assetUtils';
import { isWebARonARKit, isWebXRViewer }                    from '../../../utils/userAgent';

//------------------------------------------------------------------------------
function ProjectPage(props)
{
    const { currentProject, projectMap, currentLibrary, workspaceMap, user } = props;

    const headlessMode          = window.headless;

    //------------------------------------------------------------------------------
    const { t }                 = useTranslation();
    const sessionsContext       = useContext(SessionsContext);
    const notificationContext   = useContext(NotificationContext);
    const { enqueueSnackbar }   = useSnackbar();

    //------------------------------------------------------------------------------
    const projectPage           = props.projectPage || 'content';

    //------------------------------------------------------------------------------
    useEffect(() =>
    {
        //------------------------------------------------------------------------------
        // assetInfo is pass as a router state.
        // So, it's not available at the very first render of the app.
        const { assetInfo, search } = props.location;
        const query                 = new URLSearchParams(search);
        const assetUUID             = query.get('assetUUID');
        const assetType             = query.get('assetType');
        const isSourceFile          = query.get('isSourceFile') === 'true';

        //------------------------------------------------------------------------------
        // Define Current Asset Info
        if (assetUUID && assetType)
        {
            openAssetDetails({
                ...assetInfo,
                uuid: assetUUID,
                assetType,
                isSourceFile
            });
        }
        else
        {
            setAssetDetails(null);
        }

        //------------------------------------------------------------------------------
        const assetAdvanced = query.get('assetAdvanced');
        setAssetAdvanced(assetAdvanced);

    }, [props.location.search]);

    //--------------------------------------------------------------------------
    useEffect(() =>
    {
        const sessionList   = sessionsContext.sessions;
        if(workspaceMap)
        {
            const _projectSessions = [];
            for(const workspaceUUID of workspaceMap.keys())
            {
                if (!sessionList.hasOwnProperty(workspaceUUID))
                {
                    continue;
                }

                for(const sceneUUID in sessionList[workspaceUUID])
                {
                    _projectSessions.push(
                    {
                        sceneUUID,
                        sceneName   : sessionList[workspaceUUID][sceneUUID].sceneName,
                        sessions    : Object.keys(sessionList[workspaceUUID][sceneUUID].sessions).map(sessionUUID =>
                        {
                            let session     = sessionList[workspaceUUID][sceneUUID].sessions[sessionUUID];
                            session.uuid    = sessionUUID;
                            return session;
                        })
                    });
                }
            }
            setProjectSessions(_projectSessions);
        }

    }, [props.location, currentProject, sessionsContext.updateTrigger]);


    //--------------------------------------------------------------------------
    // Sessions

    //--------------------------------------------------------------------------
    const [ projectSessions, setProjectSessions ] = useState(null);


    //------------------------------------------------------------------------------
    // Asset Details

    const [ assetDetails, setAssetDetails ] = useState(null);

    //------------------------------------------------------------------------------
    useEffect(() =>
    {
        function keyboardEvents(event)
        {
            // Prevent weird behavior when editing text.
            if(isEditingText())
            {
                return;
            }

            if(projectPage !== 'content')
            {
                return;
            }

            // Delete (Mac), Delete (Window)
            if([8,46].includes(event.keyCode) && Can(props.permissions, 'deleteAsset') && Can(props.permissions, 'moveScene'))
            {
                if(assetSelection.length === 0 && !assetDetails)
                {
                    return;
                }

                const currentAssetSelection = assetSelection.length > 0
                                            ? assetSelection
                                            : [assetDetails]

                if(event.shiftKey || insideTrashFolder || !currentProject.SystemFolders.Trash)
                {
                    toggleDeleteAssetsModal(currentAssetSelection);
                }
                else
                {
                    softDeleteAssets(currentAssetSelection);
                }

                closeAssetDetails();
                return;
            }

            switch(event.keyCode)
            {
            case 13: // Enter
                if(assetDetails)
                {
                    setTileInRenameMode(assetDetails);
                }
                break;
            case 113: // F2
                if(assetSelection.length > 0)
                {
                    setTileInRenameMode(assetSelection[0]);
                }
                else if(assetDetails)
                {
                    setTileInRenameMode(assetDetails);
                }
                break;

            case 27: // Escape
                if(assetDetails)
                {
                    event.stopPropagation();
                    closeAssetDetails();
                    clearAssetSelection();
                }
                break;

            default:
                break;
            }
        }
        window.addEventListener('keydown', keyboardEvents);

        return () =>
        {
            window.removeEventListener('keydown', keyboardEvents);
        };
    }, [assetDetails, assetSelection, insideTrashFolder, currentProject, props.permissions]);

    //------------------------------------------------------------------------------
    const openAssetDetails = (asset) =>
    {
        if (!assetDetails || assetDetails.uuid !== asset.uuid)
        {
            setAssetDetails(asset);
        }
    };

    //------------------------------------------------------------------------------
    const closeAssetDetails = () =>
    {
        goTo();
    };


    //------------------------------------------------------------------------------
    // Asset Advanced Page

    const [ assetAdvanced, setAssetAdvanced ] = useState(null);

    //------------------------------------------------------------------------------
    const toggleAssetAdvanced = () =>
    {
        goTo({
            assetDetails: {
                ...assetDetails,
                isAdvanced: !Boolean(assetAdvanced)
            }
        });
    };


    //------------------------------------------------------------------------------
    // Search

    const [ searchString, setSearchString ] = useState('');
    const isInSearchMode                    = searchString && !currentFolderRegex.test(searchString);

    //------------------------------------------------------------------------------
    const openParentFolderFromSearchedAsset = (handlerProps) =>
    {
        const folder = workspaceMap.get(handlerProps.workspaceUUID);
        setSearchString('');
        // if libraryUUID then go to the library page
        goTo({ folderUUID: folder.uuid, libraryUUID: folder.libraryUUID, sourceFileUUID : null});
    };

    //------------------------------------------------------------------------------
    // Asset Selection

    const [ assetSelection, setAssetSelection ] = useState([]);

    //------------------------------------------------------------------------------
    const clearAssetSelection = () =>
    {
        setAssetSelection([]);
    };

    //------------------------------------------------------------------------------
    const toggleAssetSelection = (asset) =>
    {
        if (assetSelection.find(a => a.uuid === asset.uuid))
        {
            removeAssetsFromSelection([asset])
        }
        else
        {
            addAssetsToSelection([asset])
        }
    }

    //------------------------------------------------------------------------------
    const addAssetsToSelection = (assets) =>
    {
        setAssetSelection([ ...assetSelection, ...assets ]);
    };

    //------------------------------------------------------------------------------
    const removeAssetsFromSelection = (assets) =>
    {
        let _assetSelection = assetSelection;
        for(const asset of assets)
        {
            _assetSelection = _assetSelection.filter(a => a.uuid !== asset.uuid);
        }
        setAssetSelection(_assetSelection);
    };


    //------------------------------------------------------------------------------
    // Asset Context Menu

    const [ assetContextMenu, setAssetContextMenu ] = useState(null);

    //------------------------------------------------------------------------------
    const openAssetContextMenu = (event, asset, options = {}) =>
    {
        if (!asset) return;

        //------------------------------------------------------------------------------
        // Set contextMenuProps
        const contextMenuProps  = {};

        if (options.anchor === 'mouse')
        {
            contextMenuProps.anchorPosition = {
                mouseX: event.clientX - 2,
                mouseY: event.clientY - 4,
            };
        }
        else
        {
            contextMenuProps.anchorEl = event.currentTarget;
        }

        if (typeof options.onCloseCallback === 'function')
        {
            contextMenuProps.onCloseCallback = options.onCloseCallback;
        }

        const availableActionSections = computeAvailableActions(asset, options.eventFrom);

        //------------------------------------------------------------------------------
        if (availableActionSections.length > 0)
        {
            setAssetContextMenu({
                ...contextMenuProps,
                asset,
                availableActionSections,
                handlerProps: {
                    ...asset,
                    originalEvent: event,
                    originalCurrentTarget: event.currentTarget,
                    eventFrom: options.eventFrom
                }
            });
        }
    };

    //------------------------------------------------------------------------------
    const computeAvailableActions = (asset, eventFrom) =>
    {

        //------------------------------------------------------------------------------
        // Trash Folder

        if (asset.assetType === 'folder' && asset.isSystem)
        {
            // TODO: Add an emptyTrash action
            return [];
        }

        //------------------------------------------------------------------------------
        // Multiple Selection

        if (assetSelection.length > 1 && 'CONTENT_LIST' === eventFrom)
        {
            let multipleSelectionActions = [
                [
                    {
                        displayText : 'Delete selection',
                        section     : 2,
                        ordinal     : 1,
                        handler     : () => {
                            deleteAssets(assetSelection);
                            setAssetSelection([]);
                        },
                        icon        : 'fal fa-trash',
                    }
                ]
            ];

            // If there is some asset in selection, display `Select dependencies`.
            if (assetSelection.some(a => !a.assetType === 'folder'))
            {
                multipleSelectionActions[0].unshift(
                    {
                        displayText : 'Select dependencies',
                        section     : 1,
                        ordinal     : 1,
                        handler     : () => selectAssetsDependencies(assetSelection),
                        icon        : 'fal fa-sitemap',
                        isDisabled  : true,
                    }
                );
            }

            return multipleSelectionActions;
        }

        //------------------------------------------------------------------------------
        // Folder

        if (asset.assetType === 'folder')
        {
            const folderActions = [];

            if(Can(props.permissions, 'updateFolder'))
            {
                folderActions.push(
                    {
                        displayText : 'Rename',
                        section : 1,
                        ordinal : 0,
                        handler : (handlerProps) =>
                        {
                            handlerProps.eventFrom === 'CONTENT_LIST' ? setTileInRenameMode(handlerProps) : openRenameAssetPopover(handlerProps);
                        },
                        icon : 'fal fa-pen-alt fa-flip-horizontal',
                        shortcut : { keyCode : 113, character : 'F2', control : false, shift : false, delete : false }
                    }
                );
            }

            if(Can(props.permissions, 'deleteFolder') && Can(props.permissions, 'moveFolder'))
            {
                folderActions.push(
                    {
                        displayText : 'Delete',
                        section : 2,
                        ordinal : 1,
                        handler : (handlerProps) => deleteAssets([{ assetType : 'folder', ...handlerProps }]),
                        icon : 'fal fa-trash',
                        shortcut : { control : false, shift : false, delete : true }
                    }
                )
            }

            return [folderActions];
        }

        //------------------------------------------------------------------------------
        // Library

        if (asset.assetType === 'library')
        {
            const libraryActions = [];

            if(Can(props.permissions, 'manageLibrary'))
            {
                libraryActions.push(
                    {
                        displayText : 'Unlink',
                        section : 1,
                        ordinal : 1,
                        handler : (handlerProps) => unlinkLibraryFromProject(handlerProps),
                        icon : 'fal fa-unlink',
                    }
                );
            }

            return [ libraryActions ];
        }

        //------------------------------------------------------------------------------
        // Asset

        const assetType     = asset.mainAsset ? assetUtils.getAssetTypeFromAssetLabel(asset.mainAsset.label) : asset.assetType;
        const isScene       = assetType === 'scene';
        const isMaterial    = assetType === 'material' || assetType === 'cubemap' || assetType === 'volumeMaterial';
        const canBeOpen     = isScene || assetUtils.hasGraph(assetType) || isMaterial || assetType === 'module';

        const assetActions = [
            [
                {
                    displayText : 'Select dependencies',
                    section     : 1,
                    ordinal     : 20,
                    handler     : (handlerProps) => selectAssetsDependencies([handlerProps]),
                    icon        : 'fal fa-sitemap',
                    isDisabled  : true,
                }
            ]
        ];

        if(canBeOpen)
        {
            const items = [
                {
                    displayText : isScene ? 'Create a session' : ('Open ' + assetType),
                    section     : 1,
                    ordinal     : 1,
                    handler     : (handlerProps) => openAssetEditor(handlerProps.assetType, handlerProps.mainAsset ? handlerProps.mainAsset.uuid : handlerProps.assetUUID),
                    icon        : 'fal fa-arrow-right',
                }
            ];

            if(isScene)
            {
                items.push(
                    {
                        displayText : 'Profile',
                        section     : 1,
                        ordinal     : 1,
                        handler     : (handlerProps) => openAssetEditor(handlerProps.assetType, handlerProps.mainAsset ? handlerProps.mainAsset.uuid : handlerProps.assetUUID, { profile : true }),
                        icon        : 'fal fa-bug',
                    }
                );
            }

            assetActions[0].unshift(...items);
        }

        if(Can(props.permissions, 'browseWorkspace'))
        {
            assetActions[0].push(
            {
                displayText : 'Rename',
                section     : 1,
                ordinal     : 10,
                handler     : (handlerProps) =>
                {
                    handlerProps.eventFrom === 'CONTENT_LIST' ? setTileInRenameMode(handlerProps) : openRenameAssetPopover(handlerProps);
                },
                icon        : 'fal fa-pen-alt fa-flip-horizontal',
                shortcut    : { keyCode : 113, character : 'F2', control : false, shift : false, delete : false }
            });
        }

        if(Can(props.permissions, 'deleteAsset') && Can(props.permissions, 'moveScene'))
        {
            assetActions[0].push(
                {
                    displayText : 'Delete',
                    section     : 2,
                    ordinal     : 10,
                    handler     : (handlerProps) => deleteAssets([handlerProps]),
                    icon        : 'fal fa-trash',
                    shortcut    : { control : false, shift : false, delete : true }
                }
            );
        }

        if(isScene)
        {
            assetActions[0].push({
                section     : 1,
                ordinal     : 30,
                handler     : (handlerProps) => props.requests.setMainAsset(handlerProps),
                icon        : 'fal fa-star',
                displayText : 'Set as main scene',
            });

            // TODO
            // assetActions[0].push({
            //     section     : 1,
            //     ordinal     : 40,
            //     handler     : (handlerProps) => defineProjectThumbnail(handlerProps),
            //     icon        : 'fal fa-image',
            //     displayText : 'Set as project thumbnail',
            //     isDisabled  : !asset.hasThumbnail
            // });
        }

        //------------------------------------------------------------------------------
        // Source File

        if (asset.isSourceFile)
        {
            assetActions[0].push({
                section     : 1,
                ordinal     : 5,
                handler     : (handlerProps) => goTo({ sourceFileUUID : asset.uuid}),
                icon        : 'fal fa-th-list',
                displayText : 'Browse assets from this source file',
            });
        }

        if ((projectPage === 'content' || projectPage === 'library') && searchString !== '')
        {
            assetActions[0].push({
                section     : 1,
                ordinal     : 5,
                handler     : (handlerProps) => openParentFolderFromSearchedAsset(handlerProps),
                icon        : 'fal fa-folder',
                displayText : 'Open parent folder',
            });
        }

        return assetActions.map(actions =>
            actions.sort((a, b) =>
            {
                const sectionOrdical = a.section - b.section;
                if (sectionOrdical)
                {
                    return sectionOrdical;
                }
                return a.ordinal - b.ordinal;
            })
        );
    };

    //------------------------------------------------------------------------------
    //------------------------------------------------------------------------------
    const selectAssetsDependencies = async (assets) =>
    {
        const dependencies = [];
        const workingFolder = props.currentFolder || props.currentProject;

        for(const asset of assets)
        {
            if (asset.assetType !== 'folder')
            {
                const assetDeps = await getDepAPI(asset.assetType, asset.uuid, { workspaceUUID: workingFolder.workspaceUUID });
                dependencies.push(assetDeps);
            }
        }
        // setAssetSelection()
    }

    //------------------------------------------------------------------------------
    // Rename asset

    const [ tileInRenameMode, setTileInRenameMode ] = useState(null);


    //------------------------------------------------------------------------------
    // Delete asset

    //------------------------------------------------------------------------------
    const [confirmationModal, setConfirmationModal] = useState(null);

    //------------------------------------------------------------------------------
    const toggleDeleteAssetsModal = (assets, options) =>
    {
        const callback = () =>
        {
            // Receive specific callback from caller.
            if(typeof options?.callback === 'function')
            {
                options.callback();
            }

            // Global callbacks of assets deleting.
            if(assetDetails && assets.find(a => a.assetUUID === assetDetails.uuid))
            {
                setAssetDetails(null);
            }
        }

        if(confirmationModal)
        {
            setConfirmationModal(null);
            return;
        }

        setConfirmationModal(
        {
            titleComponent  : (
                <Fragment>
                    {t('t:permanently delete')}{' '}
                    {assets.length === 1 ?
                        <React.Fragment>
                            {assetUtils.getAssetLabelFromAssetType(assets[0].assetType)}: <b>{assets[0].name}</b>
                        </React.Fragment>
                        :
                        <React.Fragment>
                            {assets.length} elements
                        </React.Fragment>
                    }
                </Fragment>
            ),
            onSubmit    : () => hardDeleteAssets(assets, { callback }),
            submitText  : t('t:delete')
        });
    };

    //------------------------------------------------------------------------------
    const insideTrashFolder = !isInSearchMode && props.currentFolder && props.currentFolder.isSystem && props.currentFolder.name === 'Trash';

    //------------------------------------------------------------------------------
    const deleteHandler = async (items, options, handler) =>
    {
        try
        {
            const [sourceFiles, assets] = partition(items, (a) => a.isSourceFile);
            await handler(sourceFiles, assets);
        }
        catch(exception)
        {
            console.error(exception);
            enqueueSnackbar('Error during deletion, see console for more details', { variant: 'error' });
        }

        if (options?.callback)
        {
            options.callback();
        }
    };

    //------------------------------------------------------------------------------
    const softDeleteAssets = (items, options) =>
    {
        deleteHandler(items, options, async (sourceFiles, assets) =>
        {
            const trashSystemFolder = currentProject.SystemFolders.Trash;

            if(sourceFiles.length > 0)
            {
                await moveSourceFilesAPI(sourceFiles.map(sf => sf.uuid), trashSystemFolder.workspaceUUID);
            }

            for(const asset of assets)
            {
                if (asset.assetType === 'folder')
                {
                    await props.requests.moveFolder({ folderUUID: asset.uuid, parentFolderUUID : trashSystemFolder.uuid });
                }
                else
                {
                    await moveAssetAPI(asset.assetType, trashSystemFolder.workspaceUUID, asset.uuid);
                }
            }

            clearAssetSelection();
        })
    };

    //------------------------------------------------------------------------------
    const ensureFoldersAreDeletable = async (folderUUIDs) =>
    {
        if(folderUUIDs.length === 0)
        {
            return true;
        }

        const folders = folderUUIDs.map(uuid => projectMap.get(uuid));
        if(folders.includes(undefined))
        {
            console.error(folderUUIDs, folders);
            throw new Error('Missing folder from map');
        }

        const workspaceSet          = new Set(folders.reduce((acc, folder) =>
        {
            const workspaceUUIDs = props.requests.getWorkspaceUUIDs({folder});
            return [...acc, ...workspaceUUIDs];
        }, []));

        const emptyStates           = await isWorkspaceEmpty(Array.from(workspaceSet));
        const notEmptyWorkspaces    = emptyStates.filter(entry => !entry.isEmpty);
        if(notEmptyWorkspaces.length > 0)
        {
            const folders       = notEmptyWorkspaces.map(({workspaceUUID}) => workspaceMap.get(workspaceUUID));
            const folderNames   = folders.map(f => f?.name || '').slice(0, 3).join(', ')
            enqueueSnackbar(
                `Cannot delete folder${plural(folders.length)} ${folderNames} because ${plural(folders.length, 'it is', 'they are')} not empty`,
                { variant: 'error', autoHideDuration : 10000 }
            );
            return false;
        }

        return true;
    }

    //------------------------------------------------------------------------------
    const hardDeleteAssets = (items, options) =>
    {
        deleteHandler(items, options, async (sourceFiles, assets) =>
        {
            const folderUUIDs   = assets.filter(a => a.assetType === 'folder').map(a => a.uuid);
            const isDeletable   = await ensureFoldersAreDeletable(folderUUIDs);

            if(!isDeletable)
            {
                return;
            }

            if(sourceFiles.length > 0)
            {
                await deleteSourceFilesAPI(sourceFiles.map(sf => sf.uuid));
            }

            for(const asset of assets)
            {
                if (asset.assetType === 'folder')
                {
                    await props.requests.deleteFolder({ folderUUID: asset.uuid });
                }
                else
                {
                    await deleteAssetAPI(asset.assetType, asset.uuid);
                }
            }

            clearAssetSelection();
        });
    };

    //------------------------------------------------------------------------------
    const deleteAssets = (assets, options) =>
    {
        if(insideTrashFolder || !currentProject.SystemFolders.Trash)
        {
            toggleDeleteAssetsModal(assets, options);
        }
        else
        {
            softDeleteAssets(assets, options);
        }
    };

    //------------------------------------------------------------------------------
    // Rename Folder Popover

    //------------------------------------------------------------------------------
    const [ renameAssetPopover, setRenameAssetPopover ]     = useState(null);

    //------------------------------------------------------------------------------
    const openRenameAssetPopover = ({ originalCurrentTarget, originalEvent, ...asset }) =>
    {
        setRenameAssetPopover({
            anchorEl : originalCurrentTarget || originalEvent.target,
            asset
        });
    };


    //------------------------------------------------------------------------------
    // Libraries

    //------------------------------------------------------------------------------
    const unlinkLibraryFromProject = async (handlerProps) =>
    {
        const res = await unlinkLibraryFromProjectAPI(currentProject.uuid, handlerProps.uuid);
    }


    //------------------------------------------------------------------------------
    // Project Thumbnails

    //------------------------------------------------------------------------------
    const defineProjectThumbnail = (handlerProps) =>
    {
        // TODO
        console.log('defineProjectThumbnail', handlerProps);
        enqueueSnackbar('This feature is not ready yet. Stay tuned!');
    };

    //------------------------------------------------------------------------------
    const renameFolder = ({ folderUUID, name }) =>
    {
        const folder        = projectMap.get(folderUUID);
        const parentFolder  = projectMap.get(folder.parentPath);

        if (parentFolder.folders.some(f => f.name === name))
        {
            return {
                errorCode   : 1,
                message     : 'A folder with this name already exist.'
            };
        }
        else
        {
            props.requests.updateFolder({ folderUUID, name });
        }
    };


    //------------------------------------------------------------------------------
    // Utils

    //------------------------------------------------------------------------------
    const goTo = (options = {}) =>
    {
        const {
            projectPage,
            projectUUID,
            folderUUID,
            libraryUUID,
            workspaceUUID,
            sourceFileUUID,
            assetDetails:_assetDetails
         } = options;

        const workingFolder     = props.currentFolder || props.currentProject;

        const workspaceFolder   = workspaceUUID && workspaceMap.get(workspaceUUID);
        const _projectUUID      = projectUUID || currentProject.uuid;
        const _folderUUID       = workspaceFolder
                                ? workspaceFolder.uuid
                                : (folderUUID || (folderUUID === null ? null : workingFolder?.uuid));
        const _libraryUUID      = libraryUUID || (libraryUUID === null ? null : props.currentLibrary?.uuid);
        const _sourceUUID       = sourceFileUUID || (sourceFileUUID === null ? null : props.sourceFileUUID)

        const _projectPage      = projectPage
                                ? projectPage
                                : (_libraryUUID ? 'library' : 'content');

        const assetAdvanced     = _assetDetails?.isAdvanced || _assetDetails?.assetAdvanced || Boolean(assetAdvanced);
        const assetUUID         = _assetDetails?.uuid || (assetAdvanced && assetDetails.uuid);
        const assetType         = _assetDetails?.assetType || (assetAdvanced && assetDetails.assetType);
        const isSourceFile      = _assetDetails?.isSourceFile;

        props.history.push(
        {
            pathname : `/p/${_projectUUID
                        + (_projectPage ? '/' +_projectPage : '')
                        + (_libraryUUID ? '/' +_libraryUUID : '')
                        + (_folderUUID  ? '/' +_folderUUID : '')
                        + (_sourceUUID  ? '/' +_sourceUUID : '')}`,

            search   : (_assetDetails || assetAdvanced
                        ?
                            ('?'
                                + (assetUUID     ? 'assetUUID='+assetUUID+'&assetType='+assetType : '')
                                + (isSourceFile  ? '&isSourceFile=true' : '')
                                + (assetAdvanced ? '&assetAdvanced='+assetAdvanced : '')
                            )
                        :
                            ''
                        ),
            assetInfo : _assetDetails?.info || null
        });

        // Save state only if the project page isn't specified, so when browsing content/library pages.
        if(!projectPage)
        {
            mergeUserStateOfProject(_projectUUID,
            {
                history : {
                    libraryUUID     : _libraryUUID,
                    folderUUID      : _folderUUID,
                    sourceUUID      : _sourceUUID,
                    assetDetails    : _assetDetails
                }
            });
        }
    };

    //--------------------------------------------------------------------------
    // Handle events from plugin requesting to open an asset.
    useEffect(() =>
    {
        const pluginAppOpenHandler = ({ assetType, assetUUID }) =>
        {
            goTo(
            {
                assetDetails: {
                    uuid : assetUUID,
                    assetType,
                    isAdvanced : Boolean(assetAdvanced)
                }
            });
        };

        EventHub.on('open-browser', pluginAppOpenHandler)

        return () =>
        {
            EventHub.off('open-browser', pluginAppOpenHandler)
        }
    }, [assetAdvanced]);

    //--------------------------------------------------------------------------
    const openAssetEditor = (assetType, assetUUID, options = null, appName = 'default') =>
    {
        let url = null;

        switch(assetType)
        {
            case 'scene':
                url = `/app-launcher/${appName}/${assetUUID}`;
                break;

            case 'shader':
            case 'script':
            case 'algorithm':
            case 'renderGraph':
            case 'animationGraph':
            case 'module':
                url = `/graph-editor/?assetType=${assetType}&assetUUID=${assetUUID}`;
                break;

            case 'material':
            case 'cubemap':
            case 'volumeMaterial':
                goTo(
                {
                    assetDetails: {
                        uuid : assetUUID,
                        assetType,
                        assetAdvanced: 'MATERIAL_EDITOR'
                    }
                });
                return;

            default:
                return;
        };

        if(options)
        {
            url += '?' + Object.keys(options).map(key => `${key}=${options[key]}`).join('&');
        }

        const win = window.open(url, isWebARonARKit || isWebXRViewer ? '_self' : '_blank');
        win.focus();
    };

    //--------------------------------------------------------------------------
    const openProjectPageSettings = () =>
    {
        goTo({ projectPage : 'settings' });
    };

    const isFolder              = assetDetails?.assetType === 'folder';
    const folderData            = isFolder &&
                                    (currentLibrary
                                        ? currentLibrary.projectMap.get(assetDetails.uuid)
                                        : projectMap.get(assetDetails.uuid)
                                    );
    const displayAssetDetails   = assetDetails && (!isFolder || (isFolder && folderData));
    const currentWorkspaceUUID  = !searchString && (props.currentFolder || currentProject)?.workspaceUUID;

    let innerContent;
    if(!currentProject)
    {
        innerContent = <Loader centered />;
    }
    else
    {
        //----------------------------------------------------------------------
        innerContent = (
            <section>
                <header className='hidden'>
                    <Text format='title2'>
                        {t('project:project')} {currentProject.name}
                    </Text>
                </header>

                <div className='flex justify-between'>

                    {!headlessMode &&
                        <ProjectNav
                            currentProject={currentProject}
                            currentPage={projectPage}
                            goTo={goTo}
                        />
                    }

                    {(projectPage === 'content' || projectPage === 'library' || projectPage === 'libraries') &&
                        <Fragment>
                            <ProjectContent
                                canCreateContent={Can(props.permissions, 'createFolder')}
                                canManageLibrary={Can(props.permissions, 'manageLibrary')}
                                className='flex-1'
                                headlessMode={headlessMode}
                                projectPage={projectPage}
                                currentProject={currentProject}
                                projectMap={projectMap}
                                libraryMap={props.libraryMap}
                                workspaceMap={workspaceMap}
                                currentFolder={props.currentFolder}
                                currentLibrary={currentLibrary}
                                sourceFileUUID={props.sourceFileUUID}
                                projectSessions={projectSessions}
                                assetDetails={assetDetails}
                                isTrashFolder={insideTrashFolder}

                                assetSelection={assetSelection}
                                setAssetSelection={setAssetSelection}
                                clearAssetSelection={clearAssetSelection}
                                deleteAssets={deleteAssets}

                                assetContextMenu={assetContextMenu}
                                setAssetContextMenu={setAssetContextMenu}
                                openAssetContextMenu={openAssetContextMenu}

                                tileInRenameMode={tileInRenameMode}
                                setTileInRenameMode={setTileInRenameMode}

                                renameFolder={renameFolder}

                                searchString={searchString}
                                setSearchString={setSearchString}

                                tasks={notificationContext.workspaceMap[currentWorkspaceUUID]}

                                goTo={goTo}
                                openAssetEditor={openAssetEditor}
                                requests={props.requests}
                                user={props.user}
                                permissions={props.permissions}
                            />

                            {assetSelection.length > 0 &&
                                <AssetSelectionBar
                                    assetSelection={assetSelection}
                                    toggleAssetSelection={toggleAssetSelection}
                                    deleteAssets={deleteAssets}
                                    clearAssetSelection={clearAssetSelection}
                                />
                            }
                        </Fragment>
                    }

                    {projectPage === 'settings' &&
                        <ProjectSettings
                            className='flex-1 overflow-auto'
                            style={{ height: 'calc(100vh - var(--spacing-8))' }}
                            currentProject={currentProject}
                            requests={props.requests}
                            permissions={props.permissions}
                            workspaceMap={workspaceMap}
                            goTo={goTo}
                        />
                    }

                    {projectPage === 'topics' &&
                        <ProjectTopics
                            className='flex-1 overflow-auto'
                            style={{ height: 'calc(100vh - var(--spacing-8))' }}
                        />
                    }

                    {projectPage === 'applications' &&
                        <Applications
                            className='flex-1 overflow-auto'
                            style={{ height: 'calc(100vh - var(--spacing-8))' }}
                            currentProject={currentProject}
                            requests={props.requests}
                            permissions={props.permissions}
                            user={props.user}
                            match={props.match}
                        />
                    }

                    {projectPage === 'conversions' &&
                        <ProjectConversions
                            className='flex-1 overflow-auto'
                            style={{ height: 'calc(100vh - var(--spacing-8))' }}
                            currentProject={currentProject}
                            workspaceMap={workspaceMap}
                            getWorkspaceUUIDs={props.requests.getWorkspaceUUIDs}
                            user={props.user}
                            match={props.match}
                        />
                    }

                    {!headlessMode &&
                        <React.Fragment>
                            <ProjectDetails
                                className='overflow-auto'
                                projectPage={projectPage}
                                style={{ height: 'calc(100vh - var(--spacing-8))' }}
                                currentProject={currentProject}
                                projectSessions={projectSessions}
                                openProjectPageSettings={openProjectPageSettings}
                                openAssetEditor={openAssetEditor}
                                goTo={goTo}

                                tasks={notificationContext.tasks}
                                user={props.user}
                                permissions={props.permissions}
                            />
                            {displayAssetDetails &&
                                <AssetDetailsStyled
                                    className='fixed top-8 right-0 flex w-full sm:w-3/12 border-l border-color-tertiary-alt bg-color-ground shadow-sm z-20'
                                    style={{ height: 'calc(100% - var(--spacing-8))' }}
                                    isAssetAdvanced={Boolean(assetAdvanced)}
                                >
                                    {isFolder ?
                                        <FolderDetails
                                            folder={folderData}
                                            onClose={closeAssetDetails}
                                            user={user}
                                            permissions={props.permissions}
                                            renameFolder={renameFolder}
                                            isProjectPublic={currentProject.isPublic}
                                            goTo={goTo}
                                        />
                                        :
                                        <AssetWrapper
                                            assetUUID={assetDetails.uuid}
                                            assetType={assetDetails.assetType}
                                            isSourceFile={assetDetails.isSourceFile}
                                            assetInfo={assetDetails.info}
                                            apiGatewayURL={user.apiGatewayURL}
                                            apiToken={user.token}
                                        >
                                            {(wrapperProps) =>
                                                <AssetDetails
                                                    currentProject={currentProject}
                                                    currentFolder={props.currentFolder}
                                                    workspaceMap={workspaceMap}
                                                    getWorkspaceUUIDs={props.requests.getWorkspaceUUIDs}
                                                    libraryMap={props.libraryMap}
                                                    asset={wrapperProps.asset}
                                                    isSourceFile={assetDetails.isSourceFile}
                                                    isProjectPublic={currentProject.isPublic}

                                                    isInSearchMode={isInSearchMode}
                                                    setSearchString={setSearchString}

                                                    assetAdvanced={assetAdvanced}
                                                    toggleAssetAdvanced={toggleAssetAdvanced}

                                                    assetSelection={assetSelection}
                                                    toggleAssetSelection={toggleAssetSelection}

                                                    deleteAssets={deleteAssets}

                                                    openAssetContextMenu={openAssetContextMenu}
                                                    setTileInRenameMode={setTileInRenameMode}

                                                    sessionsContext={sessionsContext}

                                                    onClose={closeAssetDetails}
                                                    goTo={goTo}
                                                    openAssetEditor={openAssetEditor}
                                                    user={props.user}
                                                    permissions={props.permissions}
                                                    {...wrapperProps}
                                                />
                                            }
                                        </AssetWrapper>
                                    }
                                </AssetDetailsStyled>
                            }

                            {assetAdvanced &&
                                <OverlayStyled
                                    isAssetAdvanced={Boolean(assetAdvanced)}
                                    onClick={toggleAssetAdvanced}
                                    className='fixed top-0 left-0 w-full h-full bg-color-underground opacity-80 cursor-pointer'
                                />
                            }
                        </React.Fragment>
                    }

                    {assetContextMenu &&
                        <ContextMenu
                            {...assetContextMenu}
                            onClose={() => setAssetContextMenu(null)}
                            availableActionSections={assetContextMenu.availableActionSections}
                            handlerProps={assetContextMenu.handlerProps}
                            widthAuto
                        />
                    }

                    {confirmationModal &&
                        <ConfirmationModal
                            open
                            onClose={() => setConfirmationModal(null)}
                            {...confirmationModal}
                        />
                    }

                    {renameAssetPopover &&
                        <RenameAssetPopover
                            open
                            {...renameAssetPopover}
                            onClose={() => setRenameAssetPopover(null)}
                            renameFolder={renameFolder}
                            requests={props.requests}
                        />
                    }

                </div>
            </section>
        );
    }

    //--------------------------------------------------------------------------
    if(headlessMode)
    {
        return innerContent;
    }

    return (
        <MainLayout
            currentProject={currentProject}
            goTo={goTo}
            className='overflow-hidden'
            theme={props.theme}
            setTheme={props.setTheme}
        >
            {innerContent}
        </MainLayout>
    );
}

export default ProjectPage;
