import React                from 'react';
import { useTranslation }   from 'react-i18next';
import { ControlSelect }    from '../../../../designSystem/components';


//------------------------------------------------------------------------------
export default function RendererVersionSelect(props)
{
    //------------------------------------------------------------------------------
    const { t } = useTranslation();
    const {
        preferredClusterUUID = '',
        preferredRendererVersion = '',
        setPreferredRendererVersion,
        clusters,
        updatePreferredRendererVersion,
        removePreferredRendererVersion
    } = props;

    //------------------------------------------------------------------------------
    async function changeVersion(event) 
    {
        event.preventDefault();
        const version = event.target.value;
        setPreferredRendererVersion(version);

        if (version === 'remove') 
        {
            await removePreferredRendererVersion();
        }
        else 
        {
            await updatePreferredRendererVersion(version);
        }
    }

    //------------------------------------------------------------------------------
    const availableVersions = computeVersions(preferredClusterUUID, clusters);
    const versions          = 
    [
        ...availableVersions.map(v => (
            {
                value   : v,
                text    : v.charAt(0).toUpperCase() + v.slice(1)
            })),
        {
            value   : 'remove',
            text    : t('account:No preferred version')
        }
    ];

    //------------------------------------------------------------------------------
    return (
        <ControlSelect 
            className='select'
            label='Renderer Version'
            value={preferredRendererVersion || 'remove'}
            onChange={changeVersion}
            native
            size='small'
        >
            {
                availableVersions.length == 0
                    ?   <option value={preferredRendererVersion || 'remove'} disabled>Default</option>
                    :   versions.map((v, index) =>
                        (
                            <option key={index} value={v.value}>{v.text}</option>
                        ))
            }
        </ControlSelect>
    );
}

//------------------------------------------------------------------------------
const computeVersions = (preferredClusterUUID, clusters) =>
{
    const filters   = preferredClusterUUID
        ? clusters.filter(c => c.uuid === preferredClusterUUID)
        : clusters.filter(c => c.hasOwnProperty('process-spawner'));
    
    const clusterVersions = filters.map(cluster =>
    {
        const spawners  = cluster['process-spawner'] || [];
        const versions  = new Set();
        for(const spawner of spawners)
        {
            getSpawnerVersions(spawner, versions);
        }
        
        return Array.from(versions);
    });

    const intersectVersions = clusterVersions.length > 0
        ? clusterVersions.reduce((a, b) => a.filter(c => b.includes(c)))
        : [];

    return intersectVersions;
};

//------------------------------------------------------------------------------
const getSpawnerVersions = (spawner, versions) =>
{
    for(const service of spawner.services)
    {
        const index = service.lastIndexOf('/');
        if(index != -1)
        {
            versions.add(service.substring(index + 1));
        }
    }
};