import React, { createRef, useEffect, useState }        from 'react';
import { useTranslation }                               from 'react-i18next';

import { Button, Divider, Popover }                     from '../../../designSystem/components';
import { routerUseHistory }                             from '../../../designSystem/utils';
import { logoutAPI }                                    from '../../login/api';


//--------------------------------------------------------------------------
function AccountMenuPopover(props)
{
    //--------------------------------------------------------------------------
    const { t }         = useTranslation();
    const history       = routerUseHistory();

    //--------------------------------------------------------------------------
    const menuItems = [
        {
            label           : t('account:my profile'),
            onClick         : () => history.push('/account/profile'),
        },
        {
            label           : t('project:my projects'),
            onClick         : () => history.push('/projects'),
        },
        {
            label           : t('application:my applications'),
            onClick         : () => history.push('/account/applications'),
            isDisabled      : true
        },
        {
            label           : t('account:settings'),
            onClick         : () => history.push('/account/advanced'),
        },
        {
            label           : t('account:log out'),
            onClick         : logOut,
            hasTopDivider   : true
        }
    ];

    //------------------------------------------------------------------------------
    // Log out

    //------------------------------------------------------------------------------
    async function logOut() 
    {
        await logoutAPI();
        window.location = '/';
    }

    //------------------------------------------------------------------------------
    // Update buttonRefs
    
    //------------------------------------------------------------------------------
    const [ buttonRefs, setButtonRefs]                  = useState([]);
    const [ activeButtonIndex, setActiveButtonIndex]    = useState(0);
    
    //------------------------------------------------------------------------------
    useEffect(() => 
    {
        setButtonRefs(elRefs => (
            Array(menuItems.length).fill().map((_, i) => elRefs[i] || createRef())
        ));
    }, []);

    //------------------------------------------------------------------------------
    // Handle buttons refs
    useEffect(() =>
    {
        const onKeydown = (event) =>
        {
            switch(event.keyCode) 
            {
            case 13 : { // Enter
                event.preventDefault();
                if(menuItems[activeButtonIndex])
                {
                    menuItems[activeButtonIndex].onClick();
                }
                break;
            }
            case 38 : { // Up
                event.preventDefault();
                setActiveButtonIndex( (activeButtonIndex > 0) ? activeButtonIndex-1 : menuItems.length-1 );
                break;
            }
            case 40 : { // Down
                event.preventDefault();
                setActiveButtonIndex( (activeButtonIndex < menuItems.length-1) ? activeButtonIndex+1 : 0 );
                break;
            }
            }
        };
        window.addEventListener('keydown', onKeydown);

        return () => 
        {
            window.removeEventListener('keydown', onKeydown);
        };
    }, [activeButtonIndex, setActiveButtonIndex]);


    //--------------------------------------------------------------------------
    return (
        <Popover
            open={props.open}
            anchorEl={props.anchorEl}
            onClose={props.onClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            PaperProps={{ className: 'border border-color-secondary mt-1' }}
        >
            <ul className='list'>
                {menuItems.map((menuItem, index) => (
                    <li key={index}>
                        {menuItem.hasTopDivider &&
                            <Divider horizontal reversed />
                        }
                        <Button
                            buttonRef={buttonRefs[index]}
                            onClick={() => 
                            {
                                props.onClose();
                                menuItem.onClick(); 
                            }}
                            disabled={menuItem.isDisabled ?? null}
                            className={`relative ${activeButtonIndex === index ? 'focus' : ''}`}
                            color='tertiary'
                            size='small'
                            contentAlign='left'
                            fullWidth
                            square
                        >
                            {menuItem.label}
                        </Button>
                    </li>
                ))}
            </ul>
        </Popover>
    );
}

export default AccountMenuPopover;