import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation }                           from 'react-i18next';
import { Button, ControlText, Modal, Text
}                                                   from '../../../designSystem/components';
import { routerUseHistory }                         from '../../../designSystem/utils';
import ProjectContext                               from '../ProjectContext';



//------------------------------------------------------------------------------
function NewProjectModal(props)
{
    //------------------------------------------------------------------------------
    const history                                   = routerUseHistory();
    const { t }                                     = useTranslation();
    const projectContext                            = useContext(ProjectContext);

    //------------------------------------------------------------------------------
    const [ projectName, setProjectName ]           = useState('');
    const [ projectType, setProjectType ]           = useState('APPLICATION');
    const [ error, setError ]                       = useState(null);
    const projectNameInput                          = useRef();

    //------------------------------------------------------------------------------
    // Create Project

    const createProject = async () =>
    {
        if (projectName === '')
        {
            setError('Enter a project name.');
            projectNameInput.current.focus();
            return;
        }

        const project = await projectContext.createProject(projectType, projectName);
        history.push('/p/' + project.uuid);
        props.onClose();
    };

    //------------------------------------------------------------------------------
    useEffect(() =>
    {
        const onKeydown = async (event) =>
        {
            switch(event.keyCode)
            {
            case 13 : { // Enter
                event.preventDefault();
                await createProject();
                break;
            }
            }
        };
        window.addEventListener('keydown', onKeydown);

        return () =>
        {
            window.removeEventListener('keydown', onKeydown);
        };
    }, [projectName, projectNameInput]);


    //------------------------------------------------------------------------------
    return (
        <Modal
            open={props.open}
            onClose={props.onClose}
            fullScreen
        >
            <div className='flex flex-col justify-center max-w-xl h-full m-auto'>

                <header>
                    <Text format='title1'>
                        {t('project:new project')}
                    </Text>
                </header>

                <div className='mt-6'>
                    <Text format='body3' color='secondary' className='mb-3'>
                        Name
                    </Text>
                    <ControlText
                        inputRef={projectNameInput}
                        value={projectName}
                        onChange={(_, newName) => setProjectName(newName)}
                        placeholder='Project Name'
                        size='large'
                        fullWidth
                        autoFocus
                        required
                    />
                    {error &&
                        <Text format='body3' color='warning' className='mt-2'>
                            {error}
                        </Text>
                    }
                </div>

                <div className='mt-5 mb-3'>
                    <Text format='body3' color='secondary' className='mb-3'>
                        Type
                    </Text>
                    <div className='flex gap-1px'>
                        <Button
                            onClick={() => setProjectType('APPLICATION')}
                            className={`flex-1 ${projectType === 'APPLICATION' ? '' : 'opacity-60'}`}
                            valueClassName='py-3'
                            contentAlign='left'
                            fullHeight
                        >
                            <div>
                                <Text format='title3'>
                                    Application
                                </Text>
                                <p className='mt-1 text-sm opacity-70'>
                                    {t('project:application presentation sentence')}
                                </p>
                            </div>
                        </Button>
                        <Button
                            onClick={() => setProjectType('LIBRARY')}
                            className={`flex-1 ${projectType === 'LIBRARY' ? '' : 'opacity-60'}`}
                            valueClassName='py-3'
                            color='secondary'
                            contentAlign='left'
                            fullHeight
                        >
                            <div>
                                <Text format='title3'>
                                    Library
                                </Text>
                                <p className='mt-1 text-sm opacity-60'>
                                    {t('project:library presentation sentence')}
                                </p>
                            </div>
                        </Button>
                    </div>
                </div>

                <div className='flex justify-end items-center gap-4 mt-6'>
                    <Button
                        value={t('t:back')}
                        onClick={props.onClose}
                        color='tertiary'
                        className='opacity-80'
                    />
                    <Button
                        value={t('t:create')}
                        onClick={createProject}
                        color='primary'
                    />
                </div>
            </div>
        </Modal>
    );
}

export default NewProjectModal;
