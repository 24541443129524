import React, { createRef, useEffect, useState }    from 'react';
import { useTranslation }                                   from 'react-i18next';

import { Button, ControlSearch, Icon, Popover, Text
}                                                           from '../../../designSystem/components';
import TileProject                                          from '../../../components/TileProject';

//--------------------------------------------------------------------------
const setFocus  = (htmlElRef) => {htmlElRef?.focus()};

//--------------------------------------------------------------------------
function ProjectListPopover(props)
{
    //--------------------------------------------------------------------------
    const { projects = [] } = props;

    //--------------------------------------------------------------------------
    const { t } = useTranslation();

    //--------------------------------------------------------------------------
    const [ searchString, setSearchString ]             = useState('');
    const [ projectsFiltered, setProjectsFiltered ]     = useState(projects);

    //--------------------------------------------------------------------------
    useEffect(() =>
    {
        setProjectsFiltered(projects);
    }, [projects]);

    //--------------------------------------------------------------------------
    const updateSearchResults = (event) =>
    {
        const _searchString = event.target.value.toLowerCase();
        setSearchString(_searchString);

        const _projectsFiltered = _searchString ?
            projects.filter((p) => p.name.toLowerCase().includes(_searchString))
            :
            projects;
        setProjectsFiltered(_projectsFiltered);
    };
    
    //------------------------------------------------------------------------------
    const [ activeButtonIndex, setActiveButtonIndex]    = useState(null);

    //------------------------------------------------------------------------------
    useEffect(() => 
    {
        const onKeydown = (event) =>
        {
            const currentIndex = activeButtonIndex ?? projectsFiltered.findIndex(p => p.uuid === props.currentProject?.uuid) ?? 0;

            switch(event.keyCode) 
            {
            case 13 : { // Enter
                event.preventDefault();
                if(projectsFiltered[currentIndex])
                {
                    props.onClickOnProject(projectsFiltered[currentIndex]);
                }
                break;
            }
            case 38 : { // Up
                event.preventDefault();
                setActiveButtonIndex( (currentIndex > 0) ? currentIndex-1 : projectsFiltered.length-1 );
                break;
            }
            case 40 : { // Down
                event.preventDefault();
                setActiveButtonIndex( (currentIndex < projectsFiltered.length-1) ? currentIndex+1 : 0 );
                break;
            }
            }
        };
        window.addEventListener('keydown', onKeydown);

        return () => 
        {
            window.removeEventListener('keydown', onKeydown);
        };
    }, [projectsFiltered, activeButtonIndex, setActiveButtonIndex]);


    //--------------------------------------------------------------------------
    return (
        <Popover
            open={props.open}
            anchorEl={props.anchorEl}
            onClose={props.onClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
        >
            <div>

                <ControlSearch
                    inputRef={setFocus}
                    value={searchString}
                    onChange={updateSearchResults}
                    placeholder={t('project:search projects')}
                    size='medium'
                    autoFocus
                    color='tertiary'
                    className='sticky top-0 z-20 border-b border-color-tertiary'
                />

                {projectsFiltered.length > 0 ?
                    <ul className='list'>
                        {projectsFiltered.map((project, index) => (
                            <li key={project.uuid} className='border-b border-color-tertiary'>
                                <TileProject
                                    onClick={() => props.onClickOnProject(project)}
                                    {...project}
                                    highlightString={searchString}
                                    fullWidth
                                    square
                                    size='medium'
                                    noBackground
                                    className='relative'
                                    focus={activeButtonIndex === index}
                                    active={project.uuid === props.currentProject?.uuid}
                                />
                            </li>
                        ))}
                    </ul>
                    :
                    <Text
                        format='body4'
                        color='secondary'
                        align='center'
                        className='py-2'
                    >
                        {props.emptyMessage || t('project:no project found')}
                    </Text>
                }

            </div>
        </Popover>
    );
}

export default ProjectListPopover;
