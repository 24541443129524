import React, { useEffect }             from 'react';
import { useTranslation }               from 'react-i18next';
import { Button, Icon, Modal, Text }    from '../../designSystem/components';

//------------------------------------------------------------------------------
const ConfirmationModal = ( props ) =>
{
    //------------------------------------------------------------------------------
    const { t } = useTranslation();

    //------------------------------------------------------------------------------
    const submit = async (event) => 
    {
        event.preventDefault();
        props.onClose();
        props.onSubmit();
    };

    //------------------------------------------------------------------------------
    useEffect(() =>
    {
        const onKeydown = (event) =>
        {
            switch(event.keyCode)
            {
                case 13 :
                    submit(event);
                    break;

                case 27 :
                    props.onClose();
                    break;
            }
        };

        window.addEventListener('keydown', onKeydown);
        return () =>
        {
            window.removeEventListener('keydown', onKeydown);
        };
    }, []);

    //------------------------------------------------------------------------------
    return (
        <Modal
            open={props.open}
            onClose={props.onClose}
        >
            <form onSubmit={submit}>

                <header>
                    <Text format='title2'>
                    {
                        props.titleComponent 
                            ? props.titleComponent
                            : <div dangerouslySetInnerHTML={{__html : props.titleHTML}}/>
                    }
                    </Text>
                </header>

                <div className='flex items-center justify-between gap-2 mt-5'>
                    <Text format='body3' color='warning' className='flex-1'>
                        <Icon
                            className='fal fa-exclamation-circle mr-1'
                            size='xsmall'
                        />
                        {t('t:this action cannot be undone')}
                    </Text>
                    <Button
                        value={t('t:cancel')}
                        onClick={props.onClose}
                        color='tertiary'
                    />
                    <Button
                        type='submit'
                        value={props.submitText}
                        color='primary'
                    />
                </div>
            </form>
      
        </Modal>
    );
};

export default ConfirmationModal;