import React, { Fragment, useEffect, useState, useContext } from 'react';
import { useTranslation }                                   from 'react-i18next';

import { Logo, Button, ContextMenu, ControlSearch, Icon, Link, Loader, Text,
}                                               from '../../../designSystem/components';
import MainLayout                               from '../../../components/Layout';
import TileApplication                          from '../../../components/TileApplication';
import TileProject                              from '../../../components/TileProject';
import TileAsset                                from '../../../components/TileAsset';
import NewProjectModal                          from '../../projects/NewProjectModal';
import { getAssetProjectAPI }                   from '../../projects/api';
import { computeProjectPath, getProjectPath }   from '../../projects/functions';
import { listLastOpenedScene, listPublicApps }  from '../api';
import { getAppPublicURL }                      from '../../projects/Applications/api';
import ProjectContext                           from '../../projects/ProjectContext';
import { isWebARonARKit, isWebXRViewer }        from '../../../utils/userAgent';

const DEFAULT_PROJECT_COUNT = 10;


//------------------------------------------------------------------------------
function HomePage(props)
{
    //--------------------------------------------------------------------------
    const { t }                                                 = useTranslation();
    const projectContext                                        = useContext(ProjectContext);
    const projects                                              = projectContext.projects;

    //--------------------------------------------------------------------------
    const [ showAllProjects, setShowAllProjects ]               = useState(false);
    const [ projectsFiltered, setProjectsFiltered ]             = useState([]);
    const [ projectSearchString, setProjectSearchString ]       = useState('');

    //------------------------------------------------------------------------------
    const updateProjectsSearchResults = (event, toggleShowAllProjects) =>
    {
        let _searchString = projectSearchString;
        if(event)
        {
            _searchString = event?.target?.value?.toLowerCase();
            setProjectSearchString(_searchString);
        }

        let _showAllProjects = showAllProjects;
        if(toggleShowAllProjects)
        {
            _showAllProjects = !showAllProjects;
            setShowAllProjects(_showAllProjects);
        }

        const _projectsFiltered     = _searchString
                                    ? projects.filter(l => l.name.toLowerCase().includes(_searchString))
                                    : (_showAllProjects ? projects : projects.slice(0, DEFAULT_PROJECT_COUNT));
        setProjectsFiltered(_projectsFiltered);
    };


    //--------------------------------------------------------------------------
    useEffect(() =>
    {
        if(showAllProjects)
        {
            setProjectsFiltered(projects);
        }
        else
        {
            const _projectsFiltered = projects.slice(0, DEFAULT_PROJECT_COUNT);
            setProjectsFiltered(_projectsFiltered);
        }

    }, [projects, showAllProjects]);

    //--------------------------------------------------------------------------
    // Apps
    const [apps, setApps] = useState(null);
    const [publicApps, setPublicApps] = useState(null);

    //--------------------------------------------------------------------------
    // Hook to resolve app list once.
    useEffect(() =>
    {
        listPublicApps().then((projectApps) =>
        {
            setApps(
                projectApps.map(a =>
                ({
                    urlPath : getAppPublicURL(a),
                    ...a
                }))
            );
        });
    }, []);

    //--------------------------------------------------------------------------
    // Last Opened Scenes

    const [ lastOpenedScenes, setLastOpenedScenes ] = useState(null);

    //--------------------------------------------------------------------------
    useEffect(() =>
    {
        listLastOpenedScene(4).then(setLastOpenedScenes);
    }, []);

    //--------------------------------------------------------------------------
    // New Project/Library Modal

    //--------------------------------------------------------------------------
    const [ newProjectModal, setNewProjectModal ] = useState(null);

    //--------------------------------------------------------------------------
    const openLauncher = (sceneUUID) =>
    {
        const url       = `/app-launcher/default/${sceneUUID}`;
        const launcher  = window.open(url, isWebARonARKit || isWebXRViewer ? '_self' : '_blank');
        launcher.focus();
    };


    //------------------------------------------------------------------------------
    // Asset Context Menu

    const [ assetContextMenu, setAssetContextMenu ] = useState(null);

    //------------------------------------------------------------------------------
    const openContextMenu = (availableActionSections, event, props, options = {}) =>
    {
        const contextMenuProps  = {};

        if (options.anchor === 'mouse')
        {
            contextMenuProps.anchorPosition = {
                mouseX: event.clientX - 2,
                mouseY: event.clientY - 4,
            };
        }
        else
        {
            contextMenuProps.anchorEl = event.currentTarget;
        }

        if (typeof options.onCloseCallback === 'function')
        {
            contextMenuProps.onCloseCallback = options.onCloseCallback;
        }

        //---------------------------------------------------------------------------
        // Set state
        setAssetContextMenu({
            ...contextMenuProps,
            availableActionSections,
            handlerProps: {
                ...props,
                originalEvent: event,
                originalCurrentTarget: event.currentTarget,
                eventFrom: options.eventFrom
            }
        });
    }

    //------------------------------------------------------------------------------
    const openAssetContextMenu = (event, asset, options = {}) =>
    {
        if (!asset) return;

        const availableActionSections = [
            [
                {
                    section     : 1,
                    ordinal     : 1,
                    handler     : (handlerProps) => openLauncher(handlerProps.uuid),
                    icon        : 'fal fa-external-link-alt',
                    displayText : 'Open',
                },
                {
                    section     : 1,
                    ordinal     : 1,
                    handler     : (handlerProps) => openProjectFromAsset(handlerProps),
                    icon        : 'fal fa-arrow-right',
                    displayText : 'Open Project',
                },
            ]
        ];

        openContextMenu(availableActionSections, event, asset, options);
    };

    //------------------------------------------------------------------------------
    const openAppContextMenu = (event, props, options = {}) =>
    {
        const availableActionSections = [
            [
                {
                    section     : 1,
                    ordinal     : 1,
                    handler     : ({application}) => window.open(getAppPublicURL(application), '_blank').focus(),
                    icon        : 'fal fa-rocket-launch',
                    displayText : t('application:open application'),
                },
                {
                    section     : 1,
                    ordinal     : 1,
                    handler     : ({projectUUID, applicationUUID}) => goTo({ projectUUID, folderUUID: applicationUUID, projectPage: 'applications' }),
                    icon        : 'fal fa-pen',
                    displayText : t('application:edit application settings'),
                },
                {
                    section     : 1,
                    ordinal     : 1,
                    handler     : ({projectUUID}) => openProject(projectUUID),
                    icon        : 'fal fa-arrow-right',
                    displayText : t('project:open project'),
                }
            ]
        ];

        openContextMenu(availableActionSections, event, props, options);
    };

    //------------------------------------------------------------------------------
    const openProject = (projectUUID) =>
    {
        props.history.push(getProjectPath(projectUUID));
    }

    //------------------------------------------------------------------------------
    const openProjectFromAsset = async (asset) =>
    {
        const { uuid:projectUUID, folderUUID } = await getAssetProjectAPI(asset.assetType, asset.uuid);

        const assetDetails = {
            assetType   : asset.assetType,
            uuid        : asset.uuid,
            info        : asset
        }
        goTo({ projectUUID, folderUUID, sourceFileUUID : null, assetDetails });
    }

    //------------------------------------------------------------------------------
    const goTo = (options = {}) =>
    {
        const {pathname, search} = computeProjectPath(options);
        const { assetDetails } = options;

        props.history.push(
        {
            pathname    : pathname,
            search      : search,
            assetInfo   : assetDetails?.info || null
        });
    }

    //------------------------------------------------------------------------------
    // Tile Drag

    //------------------------------------------------------------------------------
    const onTileDragStart = (event, node, assetType, fromLibrary) =>
    {
        event.dataTransfer.setData(
            `3dverse/${node.uuid}`,
            node.name
        );

        event.dataTransfer.setData(
            '3dverse/asset',
            JSON.stringify(
            {
                name        : node.name,
                uuid        : node.uuid,
                assetType   : assetType,
                label       : assetUtils.getAssetLabelFromAssetType(assetType),
                fromLibrary : fromLibrary
            })
        );
    };


    //------------------------------------------------------------------------------
    const openWindow = (url) =>
    {
        window.open(url, '_blank');
    }


    //--------------------------------------------------------------------------
    return (
        <MainLayout theme={props.theme} setTheme={props.setTheme}>

            <section className='layout-wrapper py-5'>

                <div className='flex flex-col sm:flex-row gap-7'>

                    <div style={{ flex: '0 50%' }} className='flex flex-col gap-8'>
                        {projects && projects.some(p => p.applications.length > 0) &&
                            <div className='flex flex-col gap-4'>
                                <section className='rounded'>
                                    <header className='flex items-center gap-2 mb-4'>
                                        <Icon className='fal fa-th' fixedSize={false} size='large' color='secondary' />
                                        <Text format='title3' as='h1'>
                                            {t('application:my applications')}
                                        </Text>
                                    </header>
                                    <ul className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-1px'>
                                    {projects.map(project =>
                                        project.applications.map(application =>
                                            <li key={application.uuid}>
                                                <TileApplication
                                                    {...application}
                                                    projectUUID={project.uuid}
                                                    thumbnailUUID={application.thumbnailUUID}
                                                    href={getAppPublicURL(application)}
                                                    onContextMenu={e => openAppContextMenu(e, { projectUUID: project.uuid, applicationUUID: application.uuid, application }, { anchor: 'mouse' })}
                                                    className='w-full'
                                                    editButton
                                                    goTo={goTo}
                                                />
                                            </li>
                                        )
                                    )}
                                    </ul>
                                </section>
                            </div>
                        }

                        {
                            publicApps?.length > 0 &&
                            <section className='rounded'>
                                <header className='flex items-baseline justify-between gap-2 mb-4'>
                                    <Text format='title3' as='h1'>
                                        <Icon className='fad fa-rocket mr-3' fixedSize={false} size='large' color='secondary' />
                                        {t('application:explore applications')}
                                    </Text>
                                </header>
                                <ul className='grid grid-cols-1 xl:grid-cols-2 4xl:grid-cols-3 gap-1px'>
                                    {publicApps ? publicApps.map((app, index) =>
                                            <li key={app.thumbnailUUID} className={index === 0 ? 'col-span-auto xl:col-span-2' : ''}>
                                                <TileApplication
                                                    {...app}
                                                    description={app.description || app.tags?.join(' ') || ''}
                                                    href={app.urlPath}
                                                    isFeatured={index === 0}
                                                    size='large'
                                                    className='w-full'
                                                />
                                            </li>
                                        )
                                        : <Loader/>
                                    }
                                </ul>
                            </section>
                        }
                    </div>

                    <div className='flex-1 flex flex-col lg:flex-row gap-8 border-t sm:border-t-0 sm:border-l border-solid border-color-tertiary pt-7 sm:pt-0 sm:pl-7'>
                        <div className='lg:flex-1 flex flex-col gap-8'>
                            <section>
                                <header className='flex items-baseline justify-between gap-2 mb-4'>
                                    <Link to='/projects'>
                                        <Text format='title3' as='h1'>
                                            {t('project:projects')}
                                        </Text>
                                    </Link>

                                    <Button
                                        value={t('project:new project')}
                                        onClick={() => setNewProjectModal(true)}
                                        startIcon='fal fa-plus'
                                        color='primary'
                                        size='small'
                                    />

                                    {newProjectModal &&
                                        <NewProjectModal
                                            open
                                            onClose={() => setNewProjectModal(null)}
                                        />
                                    }
                                </header>

                                <ControlSearch
                                    value={projectSearchString}
                                    onChange={e => updateProjectsSearchResults(e)}
                                    placeholder={t('project:search projects')}
                                    className='my-2'
                                    size='small'
                                />

                                {projectContext.connected ?
                                    <Fragment>
                                        {projects.length > 0 ?
                                            <Fragment>
                                                <ul className='flex flex-col gap-1px'>
                                                    {projectsFiltered?.length > 0 ?
                                                        <Fragment>
                                                            {projectsFiltered.map(project =>
                                                                <li key={project.uuid}>
                                                                    <TileProject
                                                                        {...project}
                                                                        to
                                                                        highlightString={projectSearchString}
                                                                        size='small'
                                                                    />
                                                                </li>
                                                            )}
                                                        </Fragment>
                                                    :
                                                        <div>
                                                            <Text format='body4' color='secondary'>
                                                                {t('project:no projects match your filter')}
                                                            </Text>
                                                        </div>
                                                    }
                                                </ul>

                                                {!Boolean(projectSearchString) && projects.length > DEFAULT_PROJECT_COUNT &&
                                                    <Button
                                                        value={showAllProjects ? t('t:show less') : t('t:show more')}
                                                        onClick={() => updateProjectsSearchResults(null, true)}
                                                        className='mt-2'
                                                        color='tertiary'
                                                        noPadding
                                                        size='xsmall'
                                                    />
                                                }
                                            </Fragment>
                                            :
                                            <div className='flex flex-col items-center justify-center'>
                                                <Text format='title3' color='secondary'>
                                                    {'Don\'t have a project yet?'}
                                                </Text>
                                                <Button
                                                    value='Create your first project'
                                                    onClick={() => setNewProjectModal(true)}
                                                    className='mt-2'
                                                />
                                            </div>
                                        }
                                    </Fragment>
                                    :
                                    <div className='flex justify-center'>
                                        <Loader />
                                    </div>
                                }
                            </section>

                            <section>
                                <div className='flex flex-col gap-1px'>
                                    <div className='flex flex-col align-center gap-5 px-5 py-3 rounded bg-color-underground'>
                                        <div className='max-w-2xl'>
                                            <div className='grid grid-flow-col mb-3'>
                                                <Icon className='fad fa-chart-network opacity-50 rotate-45 transform' style={{ fontSize: '1.5rem', height: 'auto' }} fixedSize />
                                                <Text format='title3' className='p-3'>
                                                    Learn how to create your first application
                                                </Text>
                                            </div>
                                            <div className='flex flex-wrap items-start gap-1'>
                                                <a href='https://docs.3dverse.dev/api/' target='_blank'>
                                                    <Button
                                                        value='Discover the Web API'
                                                        onClick={() => {}}
                                                        size='small'
                                                        contentAlign='left'
                                                    />
                                                </a>
                                                <a href='https://docs.3dverse.com/sdk/' target='_blank'>
                                                    <Button
                                                        value='Discover the SDK'
                                                        onClick={() => {}}
                                                        size='small'
                                                        contentAlign='left'
                                                    />
                                                </a>
                                            </div>
                                        </div>
                                    </div>

                                    <a className='py-4 px-5 rounded bg-color-underground' href='https://community.3dverse.com' target='_blank' rel='noopener'>
                                        <p className='flex items-center gap-1'>
                                            Join the <Logo height={0.90} shapeOnly style={{marginBottom: "1px"}}/> community
                                        </p>
                                    </a>
                                </div>
                            </section>
                        </div>

                        <div className='flex flex-col flex-1 gap-7 border-solid border-color-tertiary border-t sm:border-t-0 border-l-0 lg:border-l pt-7 sm:pt-0 lg:pl-7'>

                            <section className='block md:sticky' style={{ top: '4.5rem' }}>
                                <header className='mb-3'>
                                    <Text format='title3' as='h1'>
                                        {t('scene:last opened scenes')}
                                    </Text>
                                </header>
                                {lastOpenedScenes ?
                                    <ul className='grid grid-cols-1 xl:grid-cols-2 gap-1'>
                                        {lastOpenedScenes.map(scene =>
                                            <li key={scene.uuid}>
                                                <TileAsset
                                                    onClick={() => openLauncher(scene.uuid)}
                                                    onContextMenu={e => openAssetContextMenu(e, { ...scene, assetType: 'scene' }, { anchor: 'mouse'})}
                                                    draggable
                                                    onDragStart={(e) => onTileDragStart(e, scene, 'scene')}
                                                    goTo={goTo}

                                                    uuid={scene.uuid}
                                                    assetType='scene'
                                                    name={scene.name}
                                                    hasThumbnail={scene.hasThumbnail}
                                                    isActive={assetContextMenu?.handlerProps.uuid === scene.uuid}
                                                />
                                            </li>
                                        )}
                                    </ul>
                                    :
                                    <p className='text-xs text-color-neutral-secondary'>You haven't opened a scene yet.</p>
                                }
                            </section>

                            {showNotReadyFeatures &&
                                <section>
                                    <header className='mb-4'>
                                        <Text format='title3' as='h1'>
                                            Latest Activities
                                        </Text>
                                    </header>
                                    <ul className='flex flex-col gap-2'>
                                        {[1,2,3,4,5,6,7,8,9,10].map(session =>
                                            <li key={session}>
                                                <div className='flex flex-col text-xs'>
                                                    <Text>
                                                        <Link to='/' className='link'>Houssem</Link> shares a project with you:
                                                    </Text>
                                                    <Link to='/' className='link inline'>ONU Museum</Link>
                                                </div>
                                            </li>
                                        )}
                                    </ul>
                                    <Button
                                        value='All activities'
                                        size='xsmall'
                                        color='tertiary'
                                        contentAlign='left'
                                        className='mt-3'
                                    />
                                </section>
                            }
                        </div>
                    </div>
                </div>
            </section>

            {assetContextMenu &&
                <ContextMenu
                    {...assetContextMenu}
                    onClose={() => setAssetContextMenu(null)}
                    availableActionSections={assetContextMenu.availableActionSections}
                    handlerProps={assetContextMenu.handlerProps}
                    widthAuto
                />
            }

        </MainLayout>
    );
}

export default HomePage;
