import React, { Fragment, useContext, useEffect, useState
}                                                   from 'react';
import { useTranslation }                           from 'react-i18next';
import { Avatar, Button, Icon, ControlSelect, ControlSelectItem, Divider, Tag, Text
}                                                   from '../../../../designSystem/components';
import Can                                          from '../../../../utils/permissions';
import { getRoleListWithPermissionsAPI }            from '../../../login/api';
import UserContext                                  from '../../../account/userContext';
import UserListPopover                              from '../../UserListPopover';

const roleOrder = [
    '77421d10-48c6-4f65-8d0a-3d3a4a5d1ee4', // owner
    '383a3400-a643-4b01-bf22-78739e83aea1', // contributor
    '97b435f5-75d1-4235-94d2-b44d6c17494f'  // guest
];

//------------------------------------------------------------------------------
function ProjectShareGroupSection(props)
{
    //------------------------------------------------------------------------------
    const { currentProject }                        = props;

    //------------------------------------------------------------------------------
    const { t }                                     = useTranslation();
    const userData                                  = useContext(UserContext);

    //------------------------------------------------------------------------------
    const [ roleList, setRoleList ]                 = useState(null);
    const [ userListPopover, setUserListPopover ]   = useState(null);

    //------------------------------------------------------------------------------
    useEffect(() =>
    {
        async function getRoleList()
        {
            const _roleList = await getRoleListWithPermissionsAPI();
            setRoleList(_roleList);
        }
        getRoleList();
    }, []);

    //------------------------------------------------------------------------------
    const closeUserListPopover = () =>
    {
        setUserListPopover(null);
    };

    //------------------------------------------------------------------------------
    const onRoleChange = (collaborator, roleUUID) =>
    {
        if (roleUUID === 'revoke')
        {
            props.requests.revokeAccess(collaborator);
        }
        else
        {
            const role = roleList.find(r => r.uuid === roleUUID);
            grantAccess(collaborator, role);
        }
    };

    //------------------------------------------------------------------------------
    const grantAccess = (collaborator, role) =>
    {
        props.requests.grantAccess({
            ...collaborator,
            role
        });
    };

    //------------------------------------------------------------------------------
    const shareGroup = currentProject.shareGroup.sort((a, b) =>
    {
        const ownerDiff = Boolean(currentProject.group.creator.userUUID === b.user.uuid) - Boolean(currentProject.group.creator.userUUID === a.user.uuid);
        if(ownerDiff)
        {
            return ownerDiff;
        }
        return roleOrder.indexOf(a.access.uuid) - roleOrder.indexOf(b.access.uuid);
    });

    const activeUserUUIDs = shareGroup.map(collaborator => collaborator.user.uuid);

    //------------------------------------------------------------------------------
    return (
        <section className='p-4 bg-color-underground border border-color-tertiary-alt rounded'>
            <header className='flex justify-between items-baseline gap-4 mb-3'>
                <Text format='title2'>
                    {t('project:collaborators')}
                </Text>

                {Can(props.permissions, 'manageAccess') &&
                    <Button
                        value={t('project:add a collaborator')}
                        onClick={(e) => setUserListPopover(e.currentTarget)}
                        size='small'
                    />
                }

                {userListPopover &&
                    <UserListPopover
                        open
                        anchorEl={userListPopover}
                        onClose={closeUserListPopover}
                        hasSearchAPI
                        userData={userData}
                        clientUserUUID={userData.uuid}
                        activeUserUUIDs={activeUserUUIDs}
                        hideMyself
                        onClickOnUser={(user) =>
                        {
                            grantAccess(user, roleList[0]);
                            closeUserListPopover();
                        }}
                        searchPlaceholderMessage={t('project:search collaborator')}
                        emptyMessage=' '
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                    />
                }
            </header>

            <ul className='flex flex-col gap-2 mt-2'>
                {shareGroup?.map(collaborator =>
                {
                    const isOwner = currentProject.group.creator.userUUID === collaborator.user.uuid;

                    return (
                        <li
                            key={collaborator.user.uuid}
                            className='flex items-center justify-between gap-2 pt-2 border-t border-color-tertiary'
                        >
                            <div className='flex items-center gap-3'>
                                <Avatar
                                    uuid={collaborator.user.uuid}
                                    username={collaborator.user.username}
                                    tooltipTitle={`${collaborator.user.username} ${isOwner ? '(Owner)' : ''}`}
                                    circleStyle={userData.uuid === collaborator.user.uuid}
                                    color='var(--color-accent)'
                                    reversed
                                />
                                <Text format='body2' className='username'>
                                    {collaborator.user.username}
                                </Text>
                            </div>

                            {isOwner ?
                                <div className='flex items-baseline'>
                                    <Icon className='fas fa-crown mr-1' size='small' color='secondary' />
                                    <p className='text-sm text-color-neutral-secondary'>
                                        Project owner
                                    </p>
                                </div>
                                :
                                <Fragment>
                                    {Can(props.permissions, 'manageAccess') ?
                                        <ControlSelect
                                            value={collaborator.access.uuid}
                                            onChange={(e) => onRoleChange(collaborator.user, e.target.value)}
                                            color={isOwner ? 'secondary' : 'tertiary'}
                                            size='small'
                                        >
                                            {roleList
                                                ? roleList.filter(role => role.isAllowed || role.uuid === collaborator.access.uuid).map(role =>
                                                    <ControlSelectItem value={role.uuid} key={role.uuid}>
                                                        {role.name}
                                                    </ControlSelectItem>
                                                )
                                                :
                                                <ControlSelectItem value={collaborator.access.uuid}>
                                                    {collaborator.access.name}
                                                </ControlSelectItem>
                                            }
                                            <Divider horizontal reversed />
                                            <ControlSelectItem value='revoke'>
                                                Revoke
                                            </ControlSelectItem>
                                        </ControlSelect>
                                        :
                                        <p className='text-sm text-color-neutral-secondary'>
                                            {collaborator.access.name}
                                        </p>
                                    }
                                </Fragment>
                            }

                        </li>
                    );
                }
                )}
            </ul>
        </section>
    );
}

//------------------------------------------------------------------------------
export default ProjectShareGroupSection;
