
function isObject(item) 
{
    return (item && typeof item === 'object' && !Array.isArray(item));
}

export default function mergeDeep(target, source) 
{
    if (isObject(target) && isObject(source)) 
    {
        for (const key in source)
        {
            if (isObject(source[key]))
            {
                if (!target[key]) Object.assign(target, { [key]: {} });
                mergeDeep(target[key], source[key]);
            }
            else
            {
                Object.assign(target, { [key]: source[key] });
            }
        }
    }
    return target;
}