//------------------------------------------------------------------------------
import React, { useEffect, useState }               from 'react';

//------------------------------------------------------------------------------
import { Button, ButtonGroup, Loader, Text }        from '../../../../designSystem/components';
import AssetTypeButtonGroup                         from '../../AssetTypeButtonGroup';
import GraphDependencies                            from './GraphDependencies';
import ListDependencies                             from './ListDependencies';


//------------------------------------------------------------------------------
export default function Dependencies(props)
{
    //------------------------------------------------------------------------------
    const [ displayMode, setDisplayMode ]                   = useState(JSON.parse(localStorage.getItem('3dverse/showDirectDependency')) ? 'DLIST' : 'LIST');
    const [ countByGroup, setCountByGroup ]                 = useState([]);
    const [ filteredDependencies, setFilteredDependencies ] = useState(props.dependencies);

    //------------------------------------------------------------------------------
    useEffect(() =>
    {
        if(!props.dependencies)
        {
            return;
        }

        //------------------------------------------------------------------------------
        const countByGroup = [];
        for (const group of props.dependencies.groups)
        {
            countByGroup[group.assetListName] = group.assets.length;
        }

        setCountByGroup(countByGroup);
        setFilteredDependencies(props.dependencies);
        setAssetTypeFilter([]);

    }, [props.dependencies]);

    //------------------------------------------------------------------------------
    // Filter

    const [ assetTypeFilter, setAssetTypeFilter ] = useState([]);

    //------------------------------------------------------------------------------
    useEffect(() =>
    {
        if(!props.dependencies)
        {
            return;
        }

        if(assetTypeFilter.length === 0)
        {
            for(const node of props.dependencies.nodes)
            {
                node.isVisible  = true;
                node.isImplicit = false;
            }

            for(const link of props.dependencies.links)
            {
                link.isVisible = true;
            }

            setFilteredDependencies(props.dependencies);
            return;
        }

        const _filteredDependencies = { ...props.dependencies };
        _filteredDependencies.groups = _filteredDependencies.groups.filter(g =>
        {
            return assetTypeFilter.includes(g.assetType);
        });

        const nodeMap = {};
        for(const link of _filteredDependencies.links)
        {
            link.isVisible = assetTypeFilter.some(a => link.relationShipTypes.includes(a));

            if(link.isVisible)
            {
                nodeMap[link.source.id] = true;
                nodeMap[link.target.id] = true;
            }
        }

        for(const node of _filteredDependencies.nodes)
        {
            node.isVisible  = node.isRoot || assetTypeFilter.includes(node.assetType);
            node.isImplicit = !node.isVisible && nodeMap[node.id];
        }

        setFilteredDependencies(_filteredDependencies);

    }, [assetTypeFilter]);

    //------------------------------------------------------------------------------
    const toggleAssetTypeFilter = (assetType) =>
    {
        if (assetTypeFilter.includes(assetType))
        {
            const _assetTypeFilter = assetTypeFilter.filter(a => a !== assetType);
            setAssetTypeFilter(_assetTypeFilter);
        }
        else
        {
            const _assetTypeFilter = Array.from(assetTypeFilter);
            _assetTypeFilter.push(assetType);
            setAssetTypeFilter(_assetTypeFilter);
        }
    };

    //------------------------------------------------------------------------------
    const clearAssetTypeFilter = () =>
    {
        setAssetTypeFilter([]);
    };

    //------------------------------------------------------------------------------
    if(!filteredDependencies)
    {
        return (
            <div className='flex justify-center items-center h-full'>
                <Loader />
            </div>
        );
    }
    //------------------------------------------------------------------------------
    if(filteredDependencies.dependencyCount === 0)
    {
        return (
            <div className='flex justify-center items-center h-full'>
                <Text format='body3' color='secondary'>
                    No dependencies.
                </Text>
            </div>
        );
    }

    //------------------------------------------------------------------------------
    return (
        <div className='flex flex-col h-full'>
            <header className='flex w-full items-baseline justify-between px-5 py-4'>
                <AssetTypeButtonGroup
                    countByGroup={countByGroup}
                    assetTypeFilter={assetTypeFilter}
                    clearAssetTypeFilter={clearAssetTypeFilter}
                    toggleAssetTypeFilter={toggleAssetTypeFilter}
                    clearButtonPosition='right'
                />
                <ButtonGroup outlined>
                    <Button
                        value='All dependencies'
                        onClick={() => setDisplayMode('LIST')}
                        color={displayMode === 'LIST' ? 'primary' : null}
                        size='xsmall'
                    />
                    <Button
                        value='Direct dependencies'
                        onClick={() => setDisplayMode('DLIST')}
                        color={displayMode === 'DLIST' ? 'primary' : null}
                        size='xsmall'
                    />
                    <Button
                        value='3D Graph'
                        onClick={() => setDisplayMode('GRAPH3D')}
                        color={displayMode === 'GRAPH3D' ? 'primary' : null}
                        size='xsmall'
                    />
                    <Button
                        value='Graph'
                        onClick={() => setDisplayMode('GRAPH')}
                        color={displayMode === 'GRAPH' ? 'primary' : null}
                        size='xsmall'
                    />
                </ButtonGroup>
            </header>

            <div className='overflow-auto'>
                {displayMode === 'GRAPH' || displayMode === 'GRAPH3D' ?
                    <GraphDependencies
                        nodes={filteredDependencies?.nodes}
                        links={filteredDependencies?.links}
                        dimensions={displayMode === 'GRAPH3D' ? 3 : 2}
                        goTo={props.goTo}
                    />
                    :
                    <ListDependencies
                        dependencyGroups={filteredDependencies.groups}
                        direct={displayMode === 'DLIST'}
                        clearAssetTypeFilter={clearAssetTypeFilter}
                        goTo={props.goTo}
                    />
                }
            </div>
        </div>
    );
}
