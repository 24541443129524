import React                    from 'react';
import { getProjectPath }       from '../../apps/projects/functions';
import { Link, Icon, Text }     from '../../designSystem/components';
import { TileProjectStyled }    from './style';


//------------------------------------------------------------------------------
function TileProject(props) 
{

    const { size = 'large' } = props;

    //------------------------------------------------------------------------------
    const nodeName = () => 
    {
        if (props.highlightString)
        {
            const regEx = new RegExp(props.highlightString, 'ig');
            const name = props.name.replace(regEx, '<mark>$&</mark>');
            return <span dangerouslySetInnerHTML={{ __html: name }} />;
        }
        else
        {
            return props.name;
        }
    };


    //------------------------------------------------------------------------------
    return (
        <TileProjectStyled
            as={props.to ? Link : 'button'}
            to={props.to ? getProjectPath(props.uuid) : undefined}
            onClick={props.onClick}
            className={`
                flex h-full overflow-hidden transition-colors
                ${props.fullWidth ? 'w-full' : ''}
                ${props.noBackground ? '' : 'bg-color-overground '}
                ${props.square ? '' : 'rounded'}
                ${['small', 'medium'].includes(size) ? 'items-stretch' : ''}
                ${props.focus ? 'focus' : ''}
                ${props.active ? 'active' : ''}
                ${props.className ? props.className : null}
            `}
        >
            <span
                className={`
                    block h-auto bg-color-action-secondary-hover bg-cover bg-center 
                    ${['small', 'medium'].includes(size) ? 'w-6' : ''}
                    ${size === 'large' ? 'w-10' : ''}
                `}
                style={props.mainAssetUUID ? { backgroundImage: `url(${apiGatewayURL}/asset/thumbnail?assetUUID=${props.mainAssetUUID}&size=medium&token=${apiToken})` } : {}}
            />
            <span className={`
                h-full flex-1 flex flex-col items-start justify-center gap-1
                ${(size === 'small' ? 'text-sm px-3 py-1' : '')}
                ${(size === 'medium' ? 'text-sm px-3 py-1 pr-6' : '')}
                ${(size === 'large' ? 'px-4 py-5' : '')}
            `}>
                <span className='flex items-center'>
                    {props.isLibrary &&
                        <Icon className='fal fa-link mr-1' color='secondary' size='xsmall' />
                    }
                    <span className='text-ellipsis max-w-sm'>
                        {nodeName()}
                    </span>
                </span>
                {props.isPublic &&
                    <span className='text-xs uppercase text-color-neutral-secondary'>
                        Public
                    </span>
                }
            </span>
        </TileProjectStyled>
    );
}

export default TileProject;
