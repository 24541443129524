//------------------------------------------------------------------------------
import React, { useEffect, useState }   from 'react';
import { getFileExtension } from '../../../../utils/textTools';

//------------------------------------------------------------------------------
export function BoxFilePicker(props)
{
    const [ filePicker, setFilePicker ] = useState(null);

    const openBoxFilePicker = () =>
    {
        if(filePicker && !filePicker.closed)
        {
            filePicker.focus();
            return;
        }

        const urlParams = new URLSearchParams();
        urlParams.set('multipleSelection', Boolean(props.multipleSelection));
        if(props.extensions)
        {
            urlParams.set('extensions', JSON.stringify(props.extensions.map(s => s.slice(1))))
        }

        setFilePicker(
            window.open(
                `${basename}boxFilePicker?${urlParams.toString()}`,
                'File picker',
                'menubar=0,resizable=1,width=800,height=800'
            )
        );
    };

    useEffect(() =>
    {
        if(!filePicker)
        {
            return;
        }

        const filePickerMessageHandler = (event) =>
        {
            if (event.origin !== window.origin)
            {
                return;
            }
        
            if(!event.data.hasOwnProperty('file-picker-data'))
            {
                return;
            }

            const items     = event.data['file-picker-data'];
            const files     = items.map(file =>
            {
                const { filename, extension } = getFileExtension(file.name);
                return {
                    id          : file.id,
                    name        : filename,
                    extension   : extension,
                    size        : file.size,
                    isDir       : false,
                    provider    : 'Box'
                };
            });

            props.success(files);
            setFilePicker(null);
        };

        window.addEventListener('message', filePickerMessageHandler, false);

        return () =>
        {
            window.removeEventListener('message', filePickerMessageHandler);
            filePicker.close();
        }
    }, [filePicker]);

    return (
        <div onClick={openBoxFilePicker}>
            {props.children}
        </div>
    );
}
