//------------------------------------------------------------------------------
import React, { Component, createContext }  from 'react';
import EventSource                          from 'eventsource';
import mergeDeep                            from '../../utils/mergeObject';


//------------------------------------------------------------------------------
const SessionsContext = createContext(
{
    sessions: {},
    updateTrigger : 0,
    getSessionCount: () => { },
    getActiveSessionCount: () => { }
});

//------------------------------------------------------------------------------
export class SessionsProvider extends Component
{
    //------------------------------------------------------------------------------
    checkUpdateFavicon()
    {
        const favicon = document.getElementById('favicon');
        if (!favicon) return;
        if (this.getActiveSessionCount() > 0)
        {
            favicon.href = '/favicons/favicon-badge.svg';
        }
        else
        {
            favicon.href = '/favicons/favicon.svg';
        }
    }

    //------------------------------------------------------------------------------
    componentDidMount()
    {
        // TODO: Refactor the service as websocket endpoint to remove this limitation.
        if (window.headless)
        {
            return;
        }

        const apiToken = this.props.apiToken;
        const apiGatewayURL = this.props.apiGatewayURL;
        const eventSourceURL = apiGatewayURL + "/workspace/watchAll?token=" + apiToken;
        this.listener = new EventSource(eventSourceURL);

        this.listener.addEventListener('all-sessions', (event) =>
        {
            const sessions = JSON.parse(event.data);
            this.setState({ sessions, updateTrigger : this.state.updateTrigger + 1 });
            this.checkUpdateFavicon();
        });

        this.listener.addEventListener('session-created', (event) =>
        {
            let sessionCreated = JSON.parse(event.data);
            const { workspaceUUID, sceneUUID, uuid, sceneName } = sessionCreated;

            let newSession = {
                [workspaceUUID]: {
                    [sceneUUID]: {
                        sceneName: sceneName,
                        sessions: {
                            [uuid]: {
                                ...sessionCreated,
                                users: [],
                                masterUUID : sessionCreated.userUUID
                            }
                        }
                    }
                }
            };

            const sessions = mergeDeep(this.state.sessions, newSession)
            this.setState({ sessions, updateTrigger : this.state.updateTrigger + 1 });
            this.checkUpdateFavicon();
        })

        this.listener.addEventListener('session-deleted', (event) =>
        {
            let sessionDeleted = JSON.parse(event.data);
            const { workspaceUUID, sceneUUID, sessionUUID } = sessionDeleted;

            let sessions = this.state.sessions;
            const startedAt = sessions[workspaceUUID][sceneUUID].sessions[sessionUUID].startedAt;
            const startDate = new Date(startedAt).getTime();
            const endDate = Date.now();
            const duration = startDate - endDate;
            sessions[workspaceUUID][sceneUUID].sessions[sessionUUID].duration = duration;
            sessions[workspaceUUID][sceneUUID].sessions[sessionUUID].ended = true;

            this.setState({ sessions, updateTrigger : this.state.updateTrigger + 1 });
            this.checkUpdateFavicon();

            window.setTimeout(() =>
            {
                delete sessions[workspaceUUID][sceneUUID].sessions[sessionUUID];

                if (Object.keys(sessions[workspaceUUID][sceneUUID].sessions).length === 0)
                {
                    delete sessions[workspaceUUID][sceneUUID];
                }

                if (Object.keys(sessions[workspaceUUID]).length === 0)
                {
                    delete sessions[workspaceUUID];
                }

                this.setState({ sessions, updateTrigger : this.state.updateTrigger + 1 });
                this.checkUpdateFavicon();
            }, 4000);
        });

        this.listener.addEventListener('user-joined', (event) =>
        {
            let userJoined = JSON.parse(event.data);
            const { userUUID, clientUUID, username, isGuest, workspaceUUID, sceneUUID, sessionUUID } = userJoined;
            let sessions = this.state.sessions;
            sessions[workspaceUUID][sceneUUID].sessions[sessionUUID].users.push({ userUUID, clientUUID, username, isGuest });

            this.setState({ sessions, updateTrigger : this.state.updateTrigger + 1 });
        });

        this.listener.addEventListener('user-left', (event) =>
        {
            let userLeft = JSON.parse(event.data);
            const { clientUUID, workspaceUUID, sceneUUID, sessionUUID } = userLeft;
            let sessions = this.state.sessions;
            const currentSession = sessions[workspaceUUID][sceneUUID].sessions[sessionUUID];
            currentSession.users = currentSession.users.filter(user => user.clientUUID !== clientUUID);

            this.setState({ sessions, updateTrigger : this.state.updateTrigger + 1 });
        });
    }

    //------------------------------------------------------------------------------
    componentWillMount()
    {
        if (this.listener)
        {
            this.listener.close();
        }
    }

    //------------------------------------------------------------------------------
    getSessionCount = () =>
    {
        let sessionCount = 0;
        const sessions = this.state.sessions;
        Object.keys(sessions).map(workspaceUUID => {
            return Object.keys(sessions[workspaceUUID]).map(assetUUID => {
                return Object.keys(sessions[workspaceUUID][assetUUID].sessions).map(sessionUUID => {
                    return sessionCount++
                });
            })
        })
        return sessionCount;
    }

    //------------------------------------------------------------------------------
    getActiveSessionCount = () =>
    {
        let sessionCount = 0;
        const sessions = this.state.sessions;
        Object.keys(sessions).map(workspaceUUID => {
            return Object.keys(sessions[workspaceUUID]).map(assetUUID => {
                return Object.keys(sessions[workspaceUUID][assetUUID].sessions).forEach(sessionUUID => {
                    if (!sessions[workspaceUUID][assetUUID].sessions[sessionUUID].ended) {
                        sessionCount++
                    }
                });
            })
        })
        return sessionCount;
    }

    //------------------------------------------------------------------------------
    state = {
        sessions: {},
        updateTrigger : 0,
        getSessionCount: this.getSessionCount,
        getActiveSessionCount: this.getActiveSessionCount
    };

    //------------------------------------------------------------------------------
    render()
    {
        return (
            <SessionsContext.Provider value={this.state}>
                {this.props.children}
            </SessionsContext.Provider>
        );
    }
}

export const SessionsConsumer = SessionsContext.Consumer;
export default SessionsContext;
