//------------------------------------------------------------------------------

import styled, { css } from 'styled-components';

//------------------------------------------------------------------------------
export const TimelineElement = styled.div`
    display: inline-block;
    background: white;
    width: 100%;
    height: 1px;
`;

//------------------------------------------------------------------------------
export const TimelineEvent = styled.div`
    display: flex;
    height: 15px;
    width: 15px;
    cursor: grab;

    ${props => Boolean(props.shape === 'circle') && css`
        border-radius: 50%;
    `}
`;

//------------------------------------------------------------------------------
export const EventDetails = styled.div`
    position: absolute;
    background-color: var(--color-bg-underground);
    min-width: 10rem;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 35px;
    padding: 1rem;
    z-index: 100;
    cursor: default;

    &:after, &:before
    {
        bottom: 100%;
        border: solid transparent;
        content: "";
        height: 0;
        width: 0;
        position: absolute;
    }

    &:after
    {
        border-bottom-color: var(--color-bg-underground);
        border-width: 10px;
        left: 50%;
        margin-left: -10px;
    }

    &:before
    {
        border-bottom-color: var(--color-bg-underground);
        border-width: 13px;
        left: 50%;
        margin-left: -13px;
    }
`;
