//------------------------------------------------------------------------------
export const projectPages = [
    {
        id      : null, // Special case, causes this page to use the user's state saved in local storage.
        altIds  : ['content', 'library', 'libraries'],
        label   : 'Content',
        icon    : 'fa-folders'
    },
    {
        id          : 'applications',
        label       : 'Applications',
        icon        : 'fa-rocket-launch',
        isVisible   : (project => !project.isLibrary)
    },
    {
        id      : 'settings',
        label   : 'Settings',
        icon    : 'fa-cog'
    },
    // {
    //     id      : 'topics',
    //     label   : 'Topics',
    //     icon    : 'fa-comments-alt'
    // },
    {
        id      : 'conversions',
        label   : 'Conversions',
        icon    : 'fa-cloud-upload'
    },
];
