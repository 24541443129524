import styled from 'styled-components';

export const LayoutStyled = styled.div`
  position: relative;
  overflow-x: hidden;
  min-height: calc(100vh - 4rem);

  .main {
    margin-left: 17rem;
    padding: ${props => props.theme.spacing.large} ${props => props.theme.spacing.xxxlarge};

    @media (max-width:768px) {
      margin-left: 0;
    }
  }
`;