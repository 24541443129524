//------------------------------------------------------------------------------
import CallAPI      from '../../utils/api';

//------------------------------------------------------------------------------
export async function listLibrariesAPI()
{
    return await CallAPI('/project/listLibraries');
}

//------------------------------------------------------------------------------
export async function convertProjectToLibraryAPI(projectUUID)
{
    const body = { projectUUID };
    return await CallAPI('/project/convertProjectToLibrary', 'POST', body);
}

//------------------------------------------------------------------------------
export async function listLibraryPublicFoldersAPI(libraryUUID)
{
    const req = `?libraryUUID=${libraryUUID}`;
    return await CallAPI('/project/listLibraryPublicFolders' + req, 'GET');
}

//------------------------------------------------------------------------------
export async function linkProjectToLibraryAPI(projectUUID, libraryUUID)
{
    const body = { projectUUID, libraryUUID };
    return await CallAPI('/project/linkProjectToLibrary', 'POST', body);
}

//------------------------------------------------------------------------------
export async function unlinkLibraryFromProjectAPI(projectUUID, libraryUUID)
{
    const body = { projectUUID, libraryUUID };
    return await CallAPI('/project/unlinkLibraryFromProject', 'DELETE', body);
}

//------------------------------------------------------------------------------
export async function grantPublicAccess(projectUUID)
{
    const body = { projectUUID };
    return await CallAPI('/project/grantPublicAccess', 'POST', body);
}
