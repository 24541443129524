//------------------------------------------------------------------------------
import React, { useState, useEffect }   from 'react';
import { isMobile }                     from 'react-device-detect';

//------------------------------------------------------------------------------
import { routerUseHistory }             from '../../../designSystem/utils';
import { ButtonIcon }                   from '../../../designSystem/components';
import { projectPages }                 from '../projectPages';
import { getProjectPath }               from '../functions';

//------------------------------------------------------------------------------
function ProjectNav(props)
{
    //------------------------------------------------------------------------------
    const currentProject    = props.currentProject;
    const history           = routerUseHistory();
    const [pages, setPages] = useState([]);

    //------------------------------------------------------------------------------
    useEffect(() =>
    {
        setPages(projectPages.filter(page => !page.isVisible || page.isVisible(currentProject)));
    }, [currentProject, currentProject.isLibrary]);

    //------------------------------------------------------------------------------
    const buttonProps   = {
        tabStyle    : true,
        size        : 'large',
        color       : 'tertiary',
        square      : true,
        fullWidth   : true,
        style       : {
        }
    };

    //------------------------------------------------------------------------------
    const onClickNavItem = (navItem) =>
    {
        if(navItem.id !== null)
        {
            props.goTo({ projectPage: navItem.id, folderUUID : null, libraryUUID : null, sourceFileUUID : null });
            return;
        }

        history.push(getProjectPath(currentProject.uuid));
    }

    //------------------------------------------------------------------------------
    return (
        <nav
            className={`
                flex bg-color-underground
                ${isMobile ? 'fixed bottom-0 w-full flex-row z-40' : 'w-9 flex-col border-r border-color-tertiary-alt'}
            `}
        >
            {isMobile &&
                <ButtonIcon
                    onClick={() => props.goTo({ projectPage: 'dashboard' })}
                    active={props.currentPage === 'dashboard'}
                    title='Home'
                    iconClassName={`fa-analytics ${(props.currentPage === 'dashboard') ? 'fas' : 'fal'}`}
                    {...buttonProps}
                    tabStylePosition={isMobile ? 'bottom' : 'left'}
                    style={{
                        ...buttonProps.style,
                        backgroundColor: (props.currentPage === 'dashboard') ? 'var(--color-bg-ground)' : 'inherit'
                    }}
                />
            }

            {pages.map((navItem, index) =>
            {
                const active = props.currentPage === navItem.id || navItem.altIds?.includes(props.currentPage);
                return (
                    <ButtonIcon
                        key={index}
                        onClick={() => onClickNavItem(navItem)}
                        active={active}
                        title={navItem.label}
                        iconClassName={`${navItem.icon} ${active ? 'fas' : 'fal'}`}
                        {...buttonProps}
                        tabStylePosition={isMobile ? 'bottom' : 'left'}
                        style={{
                            ...buttonProps.style,
                            backgroundColor: active ? 'var(--color-bg-ground)' : 'inherit'
                        }}
                    />
                );
            })}
        </nav>
    );
}

export default ProjectNav;