//------------------------------------------------------------------------------
import React, {
    Fragment,
	useContext,
	useState,
	useEffect
}                       from 'react';
import moment 			from 'moment';
import momentDuration   from 'moment-duration-format';

//------------------------------------------------------------------------------
import NotificationContext from '../../../../notifications/notificationContext';
import { Display, Link, Text, WorkspaceTag, Loader
}                       from '../../../../../designSystem/components';
//------------------------------------------------------------------------------
import ItemTable 		from '../ItemTable';

//------------------------------------------------------------------------------
momentDuration(moment);

//------------------------------------------------------------------------------
function TaskDetail(props)
{
    const { taskUUID }              = props;
    const notificationContext		= useContext(NotificationContext);

    const [task, setTask]			= useState(null);
    const [details, setDetails]		= useState([]);

    useEffect(() =>
    {
        const fetchTask = async () =>
        {
            const task = await notificationContext.getTask(taskUUID);
            setTask(task);
        };
        fetchTask();
    }, [notificationContext.tasks, notificationContext.completedTasks, taskUUID]);

	useEffect(() =>
	{
		if(!task)
        {
            return;
        }

		const creationDate  = moment(new Date(task.creationTimestamp * 1000));

		const details		= {
			name            : task.name,
			workspaceUUID   : task.workspaceUUID,
			creationDate    : creationDate.format('MMMM Do YYYY, HH:mm:ss') + " (" + creationDate.fromNow() +")"
		};

		if(task.endedAtTimestamp)
		{
			const endDate = moment(new Date(task.endedAtTimestamp * 1000));
			details.completionDate = endDate.format('MMMM Do YYYY, HH:mm:ss') + " (" +
				moment.duration(task.endedAtTimestamp - task.creationTimestamp, "seconds")
				.format(" d [days], h [hours], m [minutes], s [seconds]") + ")";
		}

		if(task.errorMessage)
		{
			let errorMessage = '';
			try
			{
				errorMessage = JSON.parse(task.errorMessage);
			}
			catch
			{
				errorMessage = task.errorMessage;
			}

			while(errorMessage.error) errorMessage = errorMessage.error;

			details.error = ((typeof errorMessage === 'object' && errorMessage.message) || "unknown") || errorMessage;
		}

		setDetails(details);

	}, [task]);

	return (
        <section className={props.className} style={props.style}>
            <header className='bg-color-ground sticky top-0 px-6 pt-6 z-10'>
                <div className='border-b border-color-tertiary'>
                    <Text format='title1'>
                        <Link to={`/p/${props.projectUUID}/conversions/${window.location.search}`} className='link'>
                            Conversions
                        </Link>
                    </Text>
                    {
                        details &&
                        <Fragment>
                            <Text format='title2' className='mt-4'>
                                {details.name}
                            </Text>
                            <div className='flex gap-6 my-4'>
                                {details.workspaceUUID &&
                                    <WorkspaceTag
                                        name={details.workspaceUUID}
                                        onClick={() => props.history.push('/p/' + details.workspaceUUID)}
                                        size='xsmall'
                                    />
                                }
                                {details.creationDate &&
                                    <Display vertical label='Creation Date' value={details.creationDate} />
                                }
                                {details.completionDate &&
                                    <Display vertical label='Completion Date' value={details.completionDate} />
                                }
                            </div>
                        </Fragment>
                    }
                </div>
            </header>

            {notificationContext.taskLoading || !task ?
                <Loader centered />
                :
                <ItemTable
                    title='Items'
                    tasks={task.items}
                    projectUUID={props.projectUUID}
                    className='mx-6'
                />
            }
        </section>
	);
}

export default TaskDetail;
