import React, { useState, useEffect }               from 'react';
import { useTranslation }                           from 'react-i18next';
import ReactJson                                    from 'react-json-view';

import Editor                                       from '../../../components/Editor';
import { Button, ButtonGroup }                      from '../../../designSystem/components';
import Can                                          from '../../../utils/permissions';
import assetUtils                                   from '../../../utils/assetUtils';
import AssetEditor                                  from './AssetEditor';
import ModuleDeployment                             from './ModuleDeployment';
import Dependencies                                 from './Dependencies';
import { getAssetCode }                             from '../api';

//------------------------------------------------------------------------------
function AssetDetailsAdvanced(props)
{
    //------------------------------------------------------------------------------
    const { assetAdvanced, assetUUID, assetType, updateTrigger, assetDescription, permissions, canEdit } = props;

    //------------------------------------------------------------------------------
    const { t }     = useTranslation();

    //------------------------------------------------------------------------------
    const hasCode   = assetUtils.hasCode(assetType);

    //------------------------------------------------------------------------------
    const computeTabItems = () =>
    {
        const tabItems = [
            { id: 'DEPENDENCIES', title:t('Dependencies'), onClick:() => props.goTo({ assetDetails: { assetAdvanced: 'DEPENDENCIES' } }) },
            { id: 'DESCRIPTION',  title:t('Description'),  onClick:() => props.goTo({ assetDetails: { assetAdvanced: 'DESCRIPTION' } }) },
        ];

        /* TODO:
            timeline (contributions)
            [scenes] historique des sessions (avec leur duree et les participants)
        */

        if(assetType === 'module' && Can(permissions, 'editRepository'))
        {
            tabItems.unshift(
                { id: 'GIT_PROVIDER', title:t('Git Provider'), onClick:() => props.goTo({ assetDetails: { assetAdvanced: 'GIT_PROVIDER' } }) },
            );
        }
        else if(assetType === 'material' || assetType === 'cubemap' || assetType === 'volumeMaterial' || assetType === 'animation')
        {
            tabItems.unshift(
                { id: 'ASSET_EDITOR', title:t('Editor'), onClick:() => props.goTo({ assetDetails: { assetAdvanced: 'ASSET_EDITOR' } }) },
            );
        }
        else if(assetType === 'texture')
        {
            tabItems.unshift(
                { id: 'TEXTURE_VIEWER', title:t('Viewer'), onClick:() => props.goTo({ assetDetails: { assetAdvanced: 'TEXTURE_VIEWER' } }) },
            );
        }

        if(hasCode)
        {
            tabItems.push(
                { id: 'CODE', title:t('Code'), onClick:() => props.goTo({ assetDetails: { assetAdvanced: 'CODE' } }) },
            );
        }

        return tabItems;
    };
    const tabItems = computeTabItems();

    //------------------------------------------------------------------------------
    const [ currentTab, setCurrentTab ] = useState(null);

    //------------------------------------------------------------------------------
    useEffect(() =>
    {
        if(tabItems.find(tab => tab.id === assetAdvanced))
        {
            setCurrentTab(assetAdvanced);
        }
        else
        {
            setCurrentTab(tabItems[0].id);
        }

    }, [assetAdvanced, assetUUID]);

    //------------------------------------------------------------------------------
    const [editorMode, setEditorMode]               = useState(false);
    const [editorDescription, setEditorDescription] = useState('');
    const [hasChanged, setHasChanged]               = useState(false);

    useEffect(() =>
    {
        setEditorDescription(JSON.stringify(assetDescription, null, 4));
    }, [updateTrigger, editorMode]);

    //------------------------------------------------------------------------------
    const [ code, setCode ]             = useState(null);

    //------------------------------------------------------------------------------
    useEffect(() =>
    {
        if(hasCode)
        {
            getAssetCode(assetUUID).then(setCode);
        }
    }, [updateTrigger]);

    //------------------------------------------------------------------------------
    return (
        <div className='relative flex-1 flex flex-col border-l border-color-tertiary-alt'>
            <div className='flex justify-between items-center border-b border-color-tertiary pr-3'>
                <div className='flex gap-4 mx-5'>
                    {tabItems.map(tab =>
                        <Button
                            key={tab.id}
                            onClick={tab.onClick}
                            value={tab.title}
                            active={tab.id === currentTab}
                            color='tertiary'
                            square
                            tabStyle
                            tabStylePosition='bottom'
                            noPadding
                            noHeight
                            noFocusStyle
                            className='py-3'
                        />
                    )}
                </div>
                <Button
                    value='Close'
                    onClick={props.toggleAssetAdvanced}
                    startIcon='fal fa-times'
                    size='small'
                />
            </div>

            {currentTab === 'DEPENDENCIES' &&
                <section className='flex-1 overflow-auto'>
                    <Dependencies
                        assetUUID={assetUUID}
                        dependencies={props.dependencies}
                        goTo={props.goTo}
                    />
                </section>
            }

            {currentTab === 'DESCRIPTION' &&
                <section className='h-full w-full overflow-auto'>
                    <header className='flex justify-end px-5 py-4'>
                        <div className='flex-1'>
                            {editorMode &&
                                <Button
                                    value='Save change'
                                    onClick={() =>
                                    {
                                        const assetDescription = JSON.parse(editorDescription);
                                        props.assetRequests.updateAsset(assetDescription, true);
                                        setHasChanged(false);
                                    }}
                                    disabled={!hasChanged}
                                    color='primary'
                                    size='small'
                                />
                            }
                        </div>
                        {canEdit &&
                            <ButtonGroup outlined>
                                <Button
                                    value='Tree'
                                    color={!editorMode ? 'primary' : undefined}
                                    size='xsmall'
                                    onClick={() => setEditorMode(false)}
                                />
                                <Button
                                    value='Editor'
                                    size='xsmall'
                                    color={editorMode ? 'primary' : undefined}
                                    onClick={() => setEditorMode(true)}
                                />
                            </ButtonGroup>
                        }
                    </header>
                    {assetDescription && editorMode ?
                        <Editor
                            defaultLanguage='json'
                            defaultValue={editorDescription}
                            onChange={(value) => {setEditorDescription(value); setHasChanged(true);}}
                        />
                        :
                        <div className='px-5'>
                            <ReactJson
                                key={assetUUID}
                                src={assetDescription}
                                name={null}
                                collapsed={1}
                                theme='monokai'
                                style={{ backgroundColor : 'transparent' }}
                                iconStyle='square'
                                displayDataTypes={false}
                                enableClipboard={false}
                            />
                        </div>
                    }
                </section>
            }

            {currentTab === 'GIT_PROVIDER' &&
                <section className='px-5 py-4'>
                    <ModuleDeployment
                        assetUUID={assetUUID}
                        assetDescription={assetDescription}
                        onAssetUpdated={(desc, doCommit) => props.assetRequests.updateAsset(desc, doCommit, true)}
                        user={props.user}
                    />
                </section>
            }

            {currentTab === 'ASSET_EDITOR' &&
                <section className='p-3 h-full'>
                    <AssetEditor
                        assetType={assetType}
                        assetUUID={assetUUID}
                        assetDescription={assetDescription}
                        isReadOnly={!canEdit}
                        onAssetUpdated={(desc, doCommit, updateState) => props.assetRequests.updateAsset(desc, doCommit, updateState)}
                        undo={props.assetRequests.undo}
                        redo={props.assetRequests.redo}
                        undoRedo={props.undoRedo}
                        getWorkspaceUUIDs={props.getWorkspaceUUIDs}
                        workspaceMap={props.workspaceMap}
                    />
                </section>
            }

            {currentTab === 'TEXTURE_VIEWER' &&
                <section className='h-full' style={{ backgroundColor: 'black' }}>
                    <img
                        className='w-full h-full object-contain'
                        src={apiGatewayURL + '/asset/thumbnail?assetUUID=' + assetUUID + '&token=' + apiToken + '&size=x-large'}
                    />
                </section>
            }

            {currentTab === 'CODE' &&
                <section className='h-full'>
                    <Editor
                        language='cpp'
                        value={code}
                        options={{readOnly: true}}
                    />
                </section>
            }
        </div>
    );
}

export default AssetDetailsAdvanced;
