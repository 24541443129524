//------------------------------------------------------------------------------
import React                        from 'react';
import EventEmitter                 from 'events';
import elementResizeDetectorMaker   from 'element-resize-detector';

//------------------------------------------------------------------------------
import { Loader }                   from '../designSystem/components';

//------------------------------------------------------------------------------
export default class PluginContainer extends React.Component
{
    //--------------------------------------------------------------------------
    state = {
        isLoading       : true,
        mainComponent   : null,
        glContainer     : new EventEmitter()
    };

    //--------------------------------------------------------------------------
    componentDidMount()
    {
        this.parentNode             = ReactDOM.findDOMNode(this).parentNode;
        this.elementResizeDetector  = elementResizeDetectorMaker(
        {
            strategy: 'scroll',
            callOnAdd: false
        });

        this.elementResizeDetector.listenTo(
            this.parentNode,
            () => this.state.glContainer.emit('resize')
        );

        this.loadPlugin();
    }

    //--------------------------------------------------------------------------
    componentWillUnmount()
    {
        this.elementResizeDetector.uninstall(this.parentNode);
    }

    //--------------------------------------------------------------------------
    async loadPlugin()
    {
        try
        {
            const url           = await this.resolveURL();
            const { exports }   = await import(/* webpackIgnore: true */url);

            this.setState(
            {
                mainComponent : exports[this.props.component],
                isLoading : false
            });
        }
        catch(error)
        {
            console.error(error);
        }
    }

    //--------------------------------------------------------------------------
    async resolveURL()
    {
        if(this.props.url)
        {
            return this.props.url;
        }

        return await PluginContainer.resolvePluginURL(this.props.pluginName, this.props.version);
    }

    //--------------------------------------------------------------------------
    static async resolvePluginURL(pluginName, version = 'master')
    {
        const response   	= await fetch('/editor/pluginList');
        const pluginList    = await response.json();
        const plugin        = pluginList.find(p => p.name == pluginName);

        if(!plugin)
        {
            throw 'No plugin ' + pluginName;
        }

        if(!plugin.versions.includes(version))
        {
            throw `Version ${version} is not available on plugin ${pluginName}`;
        }

        return window.location.origin + `/editor-modules/plugins/${plugin.uuid}/${version}/index.js`;
    }

    //--------------------------------------------------------------------------
    render()
    {
        if(this.state.isLoading)
        {
            return <Loader/>;
        }

        const MainComponent = this.state.mainComponent;
        if(!MainComponent)
        {
            return (
                <div>
                    {this.props.component} is not a valid export of this plugin
                </div>
            )
        };

        return (
            <MainComponent
                {...this.props}
                glContainer={this.state.glContainer}
            />
        );
    }
}
