//------------------------------------------------------------------------------
export function getFileExtension(filename)
{
    if(!filename)
    {
        return {
            filename    : 'Unnamed file',
            extension   : ''
        };
    }

    const fileArray = filename?.split('.');
    if(fileArray.length === 1)
    {
        return {
            filename    : filename,
            extension   : ''
        };
    }

    return {
        filename    : fileArray.slice(0, -1).join('.'),
        extension   : fileArray.pop().toLowerCase()
    };
}

//------------------------------------------------------------------------------
export const isEditingText = () =>
{
    const activeElement = document.activeElement.tagName.toLocaleLowerCase();
    return activeElement === 'input' || activeElement === 'textarea';
};