//------------------------------------------------------------------------------
import React, { useEffect, useState }               from 'react';
import { SnackbarProvider }                         from 'notistack';

//------------------------------------------------------------------------------
import Routes                                       from './routes';
import { AppGlobalStyle, GlobalStyle }              from './styles/GlobalStyle';

import { getTheme }                                 from './designSystem/themes';
import { ThemeProvider }                            from './designSystem/components';


//------------------------------------------------------------------------------
function App()
{
    //--------------------------------------------------------------------------
    // Themes

    const defaultThemeName  = localStorage.getItem('theme') || getPreferredColorScheme();
    const defaultTheme      = getTheme(defaultThemeName);
    const [theme, setTheme] = useState(defaultTheme);


    //------------------------------------------------------------------------------
    useEffect(() =>
    {
        // Disable Right Click
        document.addEventListener('contextmenu', event => event.preventDefault());

        return () =>
        {
            document.removeEventListener('contextmenu', event => event.preventDefault());
        };
    },[]);

    //--------------------------------------------------------------------------
    useEffect(() =>
    {
        localStorage.setItem('theme', theme.name);
    }, [theme.name]);

    //------------------------------------------------------------------------------
    return (
        <ThemeProvider theme={theme}>
            <SnackbarProvider
                maxSnack={4}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                autoHideDuration={4000}
            >
                <AppGlobalStyle/>
                <GlobalStyle />

                <Routes
                    theme={theme}
                    setTheme={setTheme}
                />
            </SnackbarProvider>
        </ThemeProvider>
    );
}

//------------------------------------------------------------------------------
// Detect device themes
function getPreferredColorScheme()
{
    if(!window.matchMedia || window.matchMedia('(prefers-color-scheme: dark)').matches)
    {
        return 'dark';
    }

    return 'light';
}

export default App;
