import React, { Fragment }                          from 'react';
import { ControlCheckbox, Icon, Text }              from '../../designSystem/components';
import { TileUploadStyled }                         from './style';


//------------------------------------------------------------------------------
function TileUpload(props)
{
    //------------------------------------------------------------------------------
    const { file, showIcon = true } = props;


    //------------------------------------------------------------------------------
    return (
        <div className='flex items-end bg-color-ground'>
            {props.displayCheckbox &&
                <ControlCheckbox
                    value={props.isChecked}
                    onChange={props.onCheckboxChange}
                    containerClassName='flex-1 pl-2'
                    disabled={props.disabled}
                />
            }
            <TileUploadStyled
                disabled={file.progress !== 1}
                onClick={props.onClick}
                className={`relative w-full h-full pl-2 pr-1 py-1 bg-color-ground rounded overflow-hidden ${props.className ? props.className : ''}`}
                style={{ '--progress': file.progress }}
                color={file.message?.hierarchy || (file.progress === 1 ? 'positive' : undefined)}
                isChecked={props.isChecked}
            >
                <span className='relative flex items-center justify-between gap-2'>
                    <span className='flex items-center gap-1'>
                        {file.extension &&
                            <span className='text-xxs leading-normal text-color-neutral-secondary bg-color-underground rounded uppercase px-1 mr-1'>
                                {file.extension}
                            </span>
                        }
                        <span className='flex'>
                            <Text format='body3' as='span' className='text-ellipsis'>
                                {file.name}
                            </Text>
                        </span>
                    </span>
                    {showIcon &&
                        <Fragment>
                            {getStatusIcon(file.status)}
                        </Fragment>
                    }
                </span>
            </TileUploadStyled>
        </div>
    );
}

export default TileUpload;



//------------------------------------------------------------------------------
function getStatusIcon(status)
{
    switch(status)
    {
    case 'approved':
    case 'waiting-approval':
    case 'waiting-confirmation':
    case 'pending':
    case 'synchronizing':
    default:
        return <Icon className='fas fa-hourglass-half opacity-60' size='small' />;

    case 'success':
        return <Icon className='fas fa-check-circle' size='small' color='positive' />;

    case 'with-error':
        return <Icon className='fas fa-exclamation-triangle text-color-feedback-negative' size='small' color='negative' />;

    case 'rejected':
        return <Icon className='fas fa-vote-nay text-color-feedback-negative' size='small' color='negative' />;

    case 'ignored':
        return <Icon className='fas fa-empty-set text-color-feedback-negative' size='small' color='negative' />;

    case 'error':
        return <Icon className='fas fa-exclamation-square text-color-feedback-negative' size='small' color='negative' />;

    case 'converting':
        return <Icon className='fas fa-spinner opacity-60' size='small' />;
    }
}