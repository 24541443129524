import React, { useEffect, useState, useContext }   from 'react';
import MainLayout                                   from '../../../components/Layout';
import Profile                                      from '../Profile';
import Advanced                                     from '../Advanced';
import { LayoutStyled }                             from './style';


//------------------------------------------------------------------------------
function ProfilePage(props)
{
    //------------------------------------------------------------------------------
    const [page, setPage]   = useState('PROFILE');

    //------------------------------------------------------------------------------
    useEffect(() =>
    {
        if (props.match.params.page === 'advanced')
        {
            setPage('ADVANCED');
        }
        else
        {
            setPage('PROFILE');
        }
    }, [props.match.params.page]);


    //------------------------------------------------------------------------------
    return (
        <MainLayout theme={props.theme} setTheme={props.setTheme}>
            <LayoutStyled>
                <div className='flex-1 max-w-screen-md m-auto wrapper py-6'>
                    {page === 'PROFILE' &&
                        <Profile
                            user={props.user}
                            theme={props.theme}
                            setUser={props.setUser}
                            setTheme={props.setTheme}
                        />
                    }
                    {page === 'ADVANCED' &&
                        <Advanced
                            user={props.user}
                        />
                    }
                </div>
            </LayoutStyled>
        </MainLayout>
    );
}

export default ProfilePage;
