import React, { useState }              from 'react';
import { useTranslation }               from 'react-i18next';
import CheckCircleIcon                  from '@material-ui/icons/CheckCircle';
import InputAdornment                   from '@material-ui/core/InputAdornment';

import { Button, ButtonIcon, ControlText, Link
}                                       from '../../../designSystem/components';
import { resetPasswordAPI }             from '../api';
import Layout, { Box }                  from '../LoginLayout';


//------------------------------------------------------------------------------
function ResetPasswordForm()
{
    //------------------------------------------------------------------------------
    const { t } = useTranslation();

    //------------------------------------------------------------------------------
    const [values, setValues] = useState({
        password: {
            value       : '',
            error       : false,
            errorMessage: null,
            showPassword: false,
        },
        globalError : false,
        submitted   : false,
        sendSuccess : null
    });

    //------------------------------------------------------------------------------
    const handleChange = prop => event => 
    {
        setValues({ ...values, 
            [prop]: {
                value       : event.target.value, 
                error       : false,
                errorMessage: values[prop].errorMessage,
                showPassword: values[prop].showPassword
            } 
        });
    };

    //------------------------------------------------------------------------------
    const handleSubmit = async event => 
    {
        event.preventDefault();
        setValues({ ...values, submitted: true });

        try
        {   
            const urlParams = new URLSearchParams(window.location.search);
            const resetToken = urlParams.get('token');
            if(resetToken) 
            {
                const userData = {
                    resetToken,
                    password: values.password.value
                };
                await resetPasswordAPI(userData);
                setValues({ ...values, sendSuccess: true });
            }
            else 
            {
                setValues({ ...values, 
                    globalError: 'Oups. There is no token.',
                    submitted: false
                });
            }
        }
        catch(error) 
        {
            console.error(error);
            switch (error.errorNum) 
            {
            case 3004 : {
                setValues({ ...values, 
                    globalError: 'Oups. Your token is expired.',
                    submitted: false
                });
                break;
            }
            default:
                break;
            }
        }
    };

    //------------------------------------------------------------------------------
    const handleClickShowPassword = prop => 
    {
        setValues({ ...values, 
            [prop]: {
                value: values[prop].value,
                error: values[prop].error,
                errorMessage: values[prop].errorMessage,
                showPassword: !values[prop].showPassword
            }
        });
    };

    //------------------------------------------------------------------------------
    const handleMouseDownPassword = event => 
    {
        event.preventDefault();
    };

    //------------------------------------------------------------------------------
    return (
        <Layout>
            <Box>
                <form onSubmit={handleSubmit} className='relative'>

                    <h1 className='pb-3 text-title1'>
                        Reset your Password
                    </h1>

                    {values.sendSuccess ?
                        <div>
                            <CheckCircleIcon fontSize='large' style={{ position: 'absolute', right: '-2rem', top: '-1rem', color: '#225226' }} />
                            <h2 style={{ color: '#225226' }} className='pb-2 text-title2'>
                                Your password has been reset.
                            </h2>
                            <Link to='/signin'>
                                <Button color='primary'>
                                    Sign in
                                </Button>
                            </Link>
                        </div>
                        :
                        <div>
                            {Boolean(values.globalError) &&
                                <p className='text-color-feedback-warning pt-2'>
                                    {values.globalError}
                                </p>
                            }
                            
                            <ControlText
                                value={values.password.value}
                                onChange={handleChange('password')}
                                label={t('account:Password')}
                                id='signup-password'
                                required
                                type={values.password.showPassword ? 'text' : 'password'}
                                error={values.password.error}
                                inputProps={{
                                    pattern: '^(?=.*?[a-zA-Z])(?=.*?[0-9]).{7,}$'
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            <ButtonIcon
                                                className={values.showPassword ? 'fal fa-eye' : 'fal fa-eye-slash'}
                                                onClick={() => handleClickShowPassword('password')}
                                                onMouseDown={handleMouseDownPassword}
                                            />
                                        </InputAdornment>
                                    ),
                                }}
                                helperText='Use at least a letter, a numeral, and 7 characters.'
                            />

                            <Button
                                value={t('account:Reset password')}
                                type='submit'
                                color='primary'
                                size='large'
                                className='mt-3'
                                disabled={values.submitted}
                            />
                        </div>
                    }

                </form>
            </Box>
        </Layout>
    );
}

export default ResetPasswordForm;
