//------------------------------------------------------------------------------
import React, { useRef, useEffect, useContext } from 'react';
import styled, { ThemeContext }                 from 'styled-components';
import * as THREE                               from 'three';
import ForceGraph2D                             from 'react-force-graph-2d';
import ForceGraph3D                             from 'react-force-graph-3d';

//------------------------------------------------------------------------------
import ContainerDimensions from '../../../../../components/ContainerDimensions';

//------------------------------------------------------------------------------
const textureLoader     = new THREE.TextureLoader();
const nodeResolution    = 8;

//------------------------------------------------------------------------------
export const Container = styled.div`
    width   : 100%;
    height  : calc(100vh - 129px);
`;

//------------------------------------------------------------------------------
export default function GraphContainer(props)
{
    return (
        <Container>
            <ContainerDimensions>
                {({ width, height }) =>
                    <GraphDependencies
                        width={width}
                        height={height}
                        {...props}
                    />
                }
            </ContainerDimensions>
        </Container>
    );
}

//------------------------------------------------------------------------------
function GraphDependencies(props)
{
    const theme     = useContext(ThemeContext);
    const graphRef  = useRef();

    useEffect(() =>
    {
        if(props.dimensions !== 3)
        {
            return;
        }

        import(/* webpackIgnore: true */'//cdn.skypack.dev/three/examples/jsm/postprocessing/UnrealBloomPass.js')
        .then(module =>
        {
            const bloomPass     = new module.UnrealBloomPass();
            bloomPass.strength  = 0.30;
            bloomPass.radius    = 0.75;
            bloomPass.threshold = 0.15;
            graphRef.current.postProcessingComposer().addPass(bloomPass);
        });

    }, [props.dimensions]);

    const commonProps = {
        width   : props.width,
        height  : props.height,
        graphData : {
            nodes : props.nodes,
            links : props.links
        },

        nodeVisibility  : (node) => node.isVisible || node.isImplicit,
        linkVisibility  : (link) => link.isVisible,
        linkLabel       : (link) => `Dependency count: ${link.count}`,
        linkWidth       : (link) => Math.ceil(Math.log(link.count)) + 1,
        nodeThreeObject : (node) =>
        {
            if(!node.isRoot && !node.hasThumbnail && !node.isImplicit)
            {
                return false;
            }

            const scale     = node.isRoot ? 1.5 : (!node.isImplicit ? 1 : 0.5);
            const geometry  = new THREE.SphereGeometry(1, nodeResolution * Math.ceil(scale), nodeResolution * Math.ceil(scale));
            const material  = new THREE.MeshPhongMaterial({ color : theme.assets[node.assetType]?.color });
            const nodeMesh  = new THREE.Mesh(geometry, material);
            nodeMesh.scale.setScalar(scale * 4);

            if(node.isRoot && node.hasThumbnail)
            {
                textureLoader.load(
                    apiGatewayURL + '/asset/thumbnail?assetUUID=' + node.id + '&size=' + (node.isRoot ? 'large' : 'small') + '&token=' + apiToken,
                    (texture) =>
                    {
                        material.map = texture;
                        material.color.setColorName('white');
                        material.needsUpdate = true;
                    }
                );
            }

            return nodeMesh;
        },

        /*onNodeClick : (node) => props.goTo(
        {
            assetDetails: {
                uuid       : node.id,
                assetType  : node.assetType,
                isAdvanced : true
            }
        }),*/

        linkDirectionalArrowLength : 4,
        linkDirectionalArrowRelPos : 1.0,

        backgroundColor : theme.colors.bgGround,
        nodeColor : (node) => theme.assets[node.assetType]?.color,
        linkColor : (link) => link.direct ? 'green' : 'red',
    };

    if(props.dimensions === 3)
    {
        return (
            <ForceGraph3D
                {...commonProps}
                ref={graphRef}
                linkWidth={0.5}
                linkOpacity={0.5}
                nodeResolution={nodeResolution}
            />
        );
    }

    return (
        <ForceGraph2D {...commonProps} />
    );
}
