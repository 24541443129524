import React, { Fragment, useContext, useState }    from 'react';
import i18n                                         from '../../i18n';

import { Avatar, Button, ButtonIcon, Icon, Link, Logo, Popover
}                                                   from '../../designSystem/components';
import { plural as p, routerUseHistory }            from '../../designSystem/utils';
import { getTheme }                                 from '../../designSystem/themes';

import ProjectListPopover                           from '../../apps/projects/ProjectListPopover';
import { getProjectPath }                           from '../../apps/projects/functions';
import AccountMenuPopover                           from '../../apps/account/AccountMenuPopover';
import UserContext                                  from '../../apps/account/userContext';
import CurrentSessionsPopover                       from '../../apps/session/CurrentSessionsPopover';
import SessionsContext                              from '../../apps/session/sessionsContext';
import ChangeClusterPopover                         from '../../apps/cluster/ChangeClusterPopover';


//------------------------------------------------------------------------------
export function NotConnectedHeader()
{
    return (
        <header className='bg-color-transparent border-b z-30'>
            <Link to='/'>
                <Logo />
            </Link>
        </header>
    );
}

//------------------------------------------------------------------------------
function Header(props)
{

    //------------------------------------------------------------------------------
    const history               = routerUseHistory();
    const user                  = useContext(UserContext);
    const sessionsContext       = useContext(SessionsContext);

    //------------------------------------------------------------------------------
    // Project Select

    //------------------------------------------------------------------------------
    const [ projectListPopover, setProjectListPopover ] = useState(null);

    //------------------------------------------------------------------------------
    const closeProjectListPopover = () =>
    {
        setProjectListPopover(null);
    };

    //------------------------------------------------------------------------------
    const onClickOnProject = (project) =>
    {
        closeProjectListPopover();
        history.push(getProjectPath(project.uuid));
    };


    //------------------------------------------------------------------------------
    // Current Sessions Modal

    //------------------------------------------------------------------------------
    const sessionCount = sessionsContext.getSessionCount();
    const [ currentSessionsPopover, setCurrentSessionsPopover ] = useState(null);

    //------------------------------------------------------------------------------
    function toggleCurrentSessionsPopover(event)
    {
        const target = currentSessionsPopover ? null : event.currentTarget;
        setCurrentSessionsPopover(target);
    }


    //------------------------------------------------------------------------------
    // Change Cluster

    //------------------------------------------------------------------------------
    const [changeClusterPopover, setChangeClusterPopover] = useState(null);

    //------------------------------------------------------------------------------
    function toggleChangeClusterPopover(event)
    {
        const target = changeClusterPopover ? null : event.currentTarget;
        setChangeClusterPopover(target);
    }


    //------------------------------------------------------------------------------
    // Language switch for debug purpose only. TODO: Show only behind a `i18nShorcut` flag (?)

    //------------------------------------------------------------------------------
    const currentLanguage = localStorage.getItem('i18nextLng') === 'en' ? 'en' : 'fr';
    const [ language, setLanguage ] = useState(currentLanguage);

    //------------------------------------------------------------------------------
    const toggleLanguage = () =>
    {
        const _language = language === 'en' ? 'fr' : 'en';
        i18n.changeLanguage(_language);
        setLanguage(_language);
    };

    //------------------------------------------------------------------------------
    const [ accountMenuPopover, setAccountMenuPopover ] = useState(null);


    //------------------------------------------------------------------------------
    // Beta Popover

    //------------------------------------------------------------------------------
    const [ betaPopover, setBetaPopover ] = useState(null);

    //------------------------------------------------------------------------------
    const toggleBetaPopover = (e) =>
    {
        setBetaPopover(betaPopover ? null : e.currentTarget);
    };

    //------------------------------------------------------------------------------
    const toggleTheme = () =>
    {
        const newTheme = (props.theme.name === 'dark') ? 'light' : 'dark';
        props.setTheme(getTheme(newTheme));
    };


    //------------------------------------------------------------------------------
    return (
        <header className='fixed top-0 flex items-center justify-between w-full h-8 pr-4 sm:pr-6 bg-color-underground border-b z-30'
            style={{ borderColor: 'hsla(var(--color-border-secondary-hsl), .6)' }}
        >
            <div className='flex-1 flex items-center gap-2'>
                <div className='relative'>
                    <Link to='/' className={`flex items-center gap-2 ml-2 px-2 py-1 ${props.currentProject ? 'bg-color-ground' : ''} hover:bg-color-overground rounded-sm transition-colors`}>
                        <Logo height={1.45} shape />
                        {/* {props.currentProject ?
                            <Logo height={1} shapeOnly />
                            :
                            <Logo height={1} className='relative' style={{ top: '-2px' }} inline variant='Labs' />
                        } */}
                        <span className='mb-1px tracking-wider'>LABS</span>
                    </Link>
                </div>

                {props.currentProject &&
                    <Fragment>
                        <Button
                            onClick={(event) => setProjectListPopover(event.currentTarget)}
                            className='relative block bg-color-overground rounded ml-0 pr-2 text-xs overflow-hidden'
                            style={{
                                border: '1px solid var(--color-border-secondary)',
                                paddingBlock: '.9rem',
                                paddingLeft: '2.6rem',
                            }}
                            size='small'
                            noPadding
                        >
                            <div
                                className='absolute top-0 left-0 h-full w-6 bg-cover bg-center bg-color-ground'
                                style={props.currentProject.mainAssetUUID ? { backgroundImage: `url(${apiGatewayURL}/asset/thumbnail?assetUUID=${props.currentProject.mainAssetUUID}&size=small&token=${apiToken})` } : {}}
                            />
                            {props.currentProject.isLibrary &&
                                <Icon className='fal fa-link mr-2' size='xsmall' fixedSize={false} color='secondary' />
                            }
                            <span className='text-sm text-ellipsis'>
                                {props.currentProject.name}
                            </span>
                            <Icon
                                className='fal fa-chevron-down ml-2'
                                size='xsmall'
                                style={{ fontSize: '.6rem' }}
                            />
                        </Button>

                        {projectListPopover &&
                            <ProjectListPopover
                                open
                                anchorEl={projectListPopover}
                                onClose={closeProjectListPopover}
                                projects={props.projects}
                                currentProject={props.currentProject}
                                onClickOnProject={onClickOnProject}
                            />
                        }
                    </Fragment>
                }
            </div>

            <div className='container-actions flex items-center gap-3'>

                {/* Toggle theme button */}
                {props.theme &&
                    <ButtonIcon
                        className={'fal ' + (props.theme.name === 'dark' ? 'fa-moon' : 'fa-sun')}
                        onClick={toggleTheme}
                        size='small'
                        tooltipTitle={props.theme.name === 'dark' ? 'Switch to Light theme' : 'Switch to Dark Theme'}
                    />
                }

                <Button
                    value={language}
                    onClick={toggleLanguage}
                    size='xsmall'
                    className='uppercase'
                />

                {sessionCount > 0 && props.projects &&
                    <Fragment>
                        <Button
                            value={`${sessionCount} session${p(sessionCount)}`}
                            onClick={toggleCurrentSessionsPopover}
                            round
                            size='small'
                            startIconProps={{ name: 'session' }}
                            color='primary'
                        />
                        {currentSessionsPopover &&
                            <CurrentSessionsPopover
                                open
                                sessions={sessionsContext.sessions}
                                anchorEl={currentSessionsPopover}
                                onClose={toggleCurrentSessionsPopover}
                                projects={props.projects}
                            />
                        }
                    </Fragment>
                }

                <ButtonIcon
                    className='fal fa-server'
                    onClick={toggleChangeClusterPopover}
                    active={Boolean(changeClusterPopover)}
                    TooltipTitle='Change Cluster'
                    color='tertiary'
                    size='small'
                    reversed
                />

                {changeClusterPopover &&
                    <ChangeClusterPopover
                        open
                        anchorEl={changeClusterPopover}
                        onClose={toggleChangeClusterPopover}
                    />
                }

                <Button
                    onClick={(e) => setAccountMenuPopover(e.currentTarget)}
                    color='tertiary'
                    noPadding
                    noFocusStyle
                    reversed
                    round
                >
                    <Avatar {...user} circleStyle color='var(--color-accent)' />
                </Button>

                {accountMenuPopover &&
                    <AccountMenuPopover
                        open
                        anchorEl={accountMenuPopover}
                        onClose={() => setAccountMenuPopover(null)}
                        goTo={props.goTo}
                    />
                }

            </div>

            <button
                onClick={toggleBetaPopover}
                className={`fixed bottom-0 right-0 py-1 px-2 text-xxs text-color-action-light hover:bg-color-accent transition-colors ${betaPopover ? 'bg-color-accent' : 'bg-color-overground'}`}
            >
                BETA Version
            </button>
            {betaPopover &&
                <Popover
                    open
                    onClose={toggleBetaPopover}
                    anchorEl={betaPopover}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    size='small'
                >
                    <aside className='max-w-xl px-6 py-5'>
                        <div className='flex gap-5'>
                            <Icon className='fas fa-traffic-cone mt-1' style={{ fontSize : '1.5rem', color: '#ff8300' }} />
                            <div>
                                <h1 className='mb-1 text-title3'>
                                    BETA Disclaimer
                                </h1>
                                <p className='text-xs text-color-neutral-secondary mb-3'>
                                    Please note that we are currenty in our beta test phase and we are updating the site on a regular basis.
                                </p>
                                <p className='text-sm'>
                                    Should you encounter any bugs, glitches, lack of functionality or
                                    other problems on the platform, drop us a line on
                                    <a className='inline-block ml-1 p-1 px-2 rounded bg-color-ground align-middle' href='https://3dverse-inc.slack.com/join/shared_invite/zt-fytto08l-8t9btNKPqdo1AA_KMVWl7Q#/' target='_blank' rel="noopener noreferrer">
                                        <img width='50px' height='20px' src={basename+'logo/slack.svg'} alt='Slack' />
                                    </a>.
                                </p>
                            </div>
                        </div>
                    </aside>
                </Popover>
            }
        </header>
    );
}

export default Header;
